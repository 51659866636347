import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";


function Aduser() {
  const [report, setReport] = useState([]);

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      filterBy: pytype,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getUserdtlsAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setReport(response.po_DETAIL);
      } else {
        setReport(response.po_DETAIL);
        toast.error(response.po_MSG);
      }
    });
  };

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "UserRpt.xlsx");
    toast.success("Excel file downloaded successfully!");
  };

  const downloadExcel = () => {
    var modifiedData = report.map(function (obj) {
      return {
        "USER ID": obj.ID,
        "REGISTRATION DATE": obj.TIME_STAMP,
        "USER NAME": obj.FULL_NAME,

        "MOBILE NO": obj.MOBILENO,
        "LAST ORDER DATE": obj.LAST_ORDER_DATE,
        CITY: obj.PI_CITY,
        "USER STATUS": obj.PI_STATUS === "A" ? "ACTIVE" : "INACTIVE",
      };
    });
    downloadExcelFile(modifiedData);
  };

  const filterListing = () => {
    let filter = $("#_filterUser").val();
    fetchData(filter);
  };

  const changeSts = (param, dltId) => {

    if (window.confirm('Are You Sure To '+(param === "A" ? "Inactive" : "Active")+' The User ?')) {
      var valdata = {
        userId : $("#adminId").val(),
        statusPi: (param === "A" ? "I" : "A"),
        did : dltId
      };
  
      const settings = {
        async: true,
        crossDomain: true,
        url: `${Baseurl.baseUrl}chgUserSts`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(valdata),
      };
  
      $.ajax(settings).done(function (response) {
        if(parseInt(response.po_STATUS) === 1){
          toast.success(response.po_MSG);
        }else{
          toast.error(response.po_MSG);
        }
        fetchData("");
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">All User(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search users:</p>
                </div>
                <form class="d-flex" style={{ height: "30px" }}>
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search User ID"
                    aria-label="Search"
                  />
                  <button class="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2"> &nbsp; {report.length} Order(s)</p>
                </div>
              </div>
            </div>

            <div class="container ">
              <div class="  pt-1 pb-0">
                <div class="d-flex">
                  <div>
                    {" "}
                    <p className="mgrt2">Filters:</p>
                  </div>
                  <div class="p-3 pt-0 pb-0">
                    <label>User Type : &nbsp;</label>
                    <select className="selectoption" id="_filterUser" onChange={filterListing}>
                      <option value="">All User Type</option>
                      <option value="A">Active User</option>
                      <option value="I">Inactive User</option>
                    </select>
                  </div>

                  <div class="p-3 pt-0 pb-0">
                    <label>Date : &nbsp;</label>
                    <input type="date" className="selectoption" name="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-6 pt-2 pb-2  ">
                <div class="vhp1 pt-0">All User(s)</div>
              </div>
              <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                
                <div class="vhp1 pt-0">
                  <button
                    class="button1"
                    disabled={report.length === 0}
                    onClick={downloadExcel}
                  >
                    Download{" "}
                  </button>
                </div>
              </div>
            </div>

            <div class="hyeus1 mt-2 p-2">
              <table class="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>User ID</td>
                    <td>Registration Date</td>
                    <td>User Name</td>
                    <td>Mobile</td>
                    <td>Last Order Date</td>
                    <td>City</td>
                    <td>User Status</td>
                    <td>View Details</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {report.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={8}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    report.map((rpt) => {
                      const params = {
                        q: "search",
                        requestType: "user",
                        getfilter: rpt.ID,
                        
                      };
  
                      const queryStringParams = queryString.stringify(params);
                    
                    
                    
                    return (
                      <tr key={`user${rpt.ID}`}>
                        <td>{rpt.ID}</td>
                        <td>{rpt.TIME_STAMP}</td>
                        <td>{rpt.FULL_NAME}</td>

                        <td>{rpt.MOBILENO}</td>
                        <td>{rpt.LAST_ORDER_DATE}</td>
                        <td>{rpt.PI_CITY}</td>
                        <td>
                          {rpt.PI_STATUS === "A" ? "Active" : "In-active"}
                        </td>
                        <td><button class="btnvd add-cart-btn bdrradius1" onClick={() => changeSts(rpt.PI_STATUS, rpt.ID)}>{rpt.PI_STATUS === "A" ? "In-Active" : "Active"}</button></td>
                        <td><Link to={`/Userdetails?${queryStringParams}`}><button class="btnvd add-cart-btn bdrradius1" >  View</button></Link></td>
                      </tr>
                    );})
                  )}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Aduser;
