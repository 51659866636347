import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import "./Common.css";
import queryString from "query-string";
import Noresult from "../component/Noresult.js";
import { FaFacebook } from "react-icons/fa";
import Filter from "../component/Filter.js";
import Mobfilter from "../component/Mobfilter.js";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function Allproduct() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const subCategoryId = queryParams.subCategory;
  const requestType = queryParams.requestType;
  const q = queryParams.q;

  const [prodDtls, setProdDtls] = useState({
    productCount: 0,
    productResp: [],
    categoryName : ""
  });
  const [pageNo, setPageNo] = useState(1);
  const [paginationItems, setPaginationItems] = useState([]);

  useEffect(() => {
    loadProduct("", "", "", "", "", "", "", "", "", "");
    loadPagination();

    console.log("test");
  }, []);

  function loadProduct(
    brandCheckValues,
    displaySizeCheckValues,
    modelYearCheckValues,
    sizeCheckValues,
    volumeCheckValues,
    colorCheckValues,
    priceCheck,
    outofstock,
    discount,
    stars,
    prodType
  ) {
    var valdata = {
      subCatId: subCategoryId,
      prodId: "4",
      wishListId: "1",
      pageNo: $("#_pageNo").val().trim(),
      brandName: brandCheckValues,
      displaySize: displaySizeCheckValues,
      modelYr: modelYearCheckValues,
      sizeval: sizeCheckValues,
      volumeval: volumeCheckValues,
      colorval: colorCheckValues,
      priceval: priceCheck,
      outstock: outofstock,
      discount: discount,
      stars: stars,
      prodType : prodType
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getProductAll`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setProdDtls({
        productCount: response.po_COUNT,
        productResp: response.po_DETAIL,
        categoryName : response.other_DETAILS
      });
    });
  }

  function loadPagination() {
    const totalPages =
      parseFloat(prodDtls.productCount) > 40
        ? Math.ceil(parseFloat(prodDtls.productCount) / 40)
        : 1;
    const isLastPage = pageNo === totalPages;
    paginationItems.push(
      <span key="previous" onClick={loadLessProduct}>
        &laquo;
      </span>
    );

    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <span
          key={i}
          className={pageNo === i ? "active" : ""}
          onClick={loadMoreProduct}
        >
          {i}
        </span>
      );
    }

    paginationItems.push(
      <span
        key="next"
        onClick={loadMoreProduct}
        className={isLastPage ? "disabled" : ""}
      >
        &raquo;
      </span>
    );

    console.log(paginationItems);
  }

  const loadMoreProduct = () => {
    const totalPages =
      parseFloat(prodDtls.productCount) > 20
        ? Math.ceil(parseFloat(prodDtls.productCount) / 20)
        : 1;

    if (pageNo + 1 == totalPages || pageNo + 1 > totalPages) {
      return false;
    }
    $("#_pageNo").val(pageNo + 1);
    setPageNo(pageNo + 1);
    loadProduct();
  };

  const loadLessProduct = () => {
    const totalPages =
      parseFloat(prodDtls.productCount) > 20
        ? Math.ceil(parseFloat(prodDtls.productCount) / 20)
        : 1;
    if (pageNo - 1 == totalPages || pageNo - 1 < totalPages) {
      return false;
    }
    $("#_pageNo").val(pageNo - 1);
    setPageNo(pageNo - 1);
    loadProduct();
  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  // Function to update selected filters
  const handleFilterSelection = (selectedData) => {
    setSelectedFilters(selectedData);
    console.log(selectedData);

    const result = selectedData.reduce((acc, obj) => {
      if (!acc[obj.name]) {
        acc[obj.name] = [];
      }
      acc[obj.name].push(obj.value);
      return acc;
    }, {});

    if (!result["brandCheck"]) {
      result["brandCheck"] = [""];
    }

    if (!result["displaySizeCheck"]) {
      result["displaySizeCheck"] = [""];
    }
    ///////
    if (!result["modelYearCheck"]) {
      result["modelYearCheck"] = [""];
    }
    if (!result["sizeCheck"]) {
      result["sizeCheck"] = [""];
    }
    if (!result["volumeCheck"]) {
      result["volumeCheck"] = [""];
    }
    if (!result["colorCheck"]) {
      result["colorCheck"] = [""];
    }
    ////////

    if (!result["priceCheck"]) {
      result["priceCheck"] = [""];
    }

    if (!result["outofstock"]) {
      result["outofstock"] = [""];
    }

    if (!result["discount"]) {
      result["discount"] = [""];
    }

    if (!result["stars"]) {
      result["stars"] = [""];
    }

    if (!result["genderCheck"]) {
      result["genderCheck"] = [""];
    }

    const brandCheckValues = result["brandCheck"].join(", ");
    const displaySizeCheckValues = result["displaySizeCheck"].join(", ");
    const modelYearCheckValues = result["modelYearCheck"].join(", ");
    const sizeCheckValues = result["sizeCheck"].join(", ");
    const volumeCheckValues = result["volumeCheck"].join(", ");
    const colorCheckValues = result["colorCheck"].join(", ");

    /////

    const priceCheck = result["priceCheck"].join(", ");
    const outofstock = result["outofstock"].join(", ");
    const discount = result["discount"].join(", ");
    const stars = result["stars"].join(", ");
    const prodType = result["genderCheck"].join(", ");


   
    console.log(prodType)
    loadProduct(
      brandCheckValues,
      displaySizeCheckValues,
      modelYearCheckValues,
      sizeCheckValues,
      volumeCheckValues,
      colorCheckValues,
      priceCheck,
      outofstock,
      discount,
      stars,
      prodType
    );
  };

  return (
    <>
      <input id="_pageNo" value="1" type="hidden" />
      <ScrollToTop />
      <section className="bgsection1">
        <div className="container-fluid mt-2 mb-2">
          <div className="row g-2">
            <Filter
              subCategoryId={subCategoryId}
              selectedFilters={selectedFilters}
              onFilterSelection={handleFilterSelection}
            />

            <div className="col-md-10">
              <div className="hyeus1">
                <div className="p-3">
                  <div className="row">
                    <div className="col-6"><h5>{prodDtls.categoryName}</h5></div>
                    <div className="col-6 text-end d-lg-none"><h5 className="jswnds ptrr"> <button className="btn" style={{ textDecoration: 'underline' }} data-bs-toggle="modal" data-bs-target="#exampleModal">Filter</button> </h5></div>
                  </div>

                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
      <Mobfilter
              subCategoryId={subCategoryId}
              selectedFilters={selectedFilters}
              onFilterSelection={handleFilterSelection}
            />
      </div>
      
    </div>
  </div>
</div>
                  
                  <p>
                    <span className="spap1">Sort By</span>{" "}
                    
                    <span className="spap2 navhvr">Price-Low To High</span>{" "}
                    <span className="spap2 navhvr">Price-High To Low</span>
                  </p>
                  <hr className="hr-1" />
                </div>

                <div className="row g-2">
                  {prodDtls.productResp.length === 0 ? (
                    <Noresult />
                  ) : (
                    prodDtls.productResp.map((proddtl, index) => {
                      const params = {
                        q: "search",
                        requestType: "product",
                        subCategory: proddtl.SUB_CATEGORY_ID,
                        productId: proddtl.ID,
                        catId: proddtl.CATEGORY_ID,
                      };

                      const queryStringParams = queryString.stringify(params);
                      const isLastItem =
                        index === prodDtls.productResp.length - 1;
                      const linkClassName = isLastItem
                        ? "col-md-3 col-lg-3 col-xl-3 col-6"
                        : "col-md-3 col-lg-3 col-xl-3 col-6 w-100";

                      return (
                        <Link
                          to={`/Product?${queryStringParams}`}
                          className="col-md-3 col-6 td-0 txt-cl-11"
                          key={`prod${proddtl.ID}`}
                          target="_blank"
                        >
                          <div className="col-md-3 col-lg-3 col-xl-3 col-6 w-100">
                            <div className="product py-2 crdhvr1">
                              <div className="text-center">
                                <img
                                  src={`${Baseurl.getImgUrl}prodImages/${proddtl.IMAGES_NAME_1}`}
                                  className="prwidh img-fluid"
                                />
                              </div>
                              <div className="about text-center">
                                <h1 className="crtitmhd">
                                  {proddtl.PRODUCT_NAME}
                                </h1>
                                <p className="fetr-pr-1 dis-unset">
                                  ₹{proddtl.CURRENT_AMOUNT}{" "}
                                  <s className="strk-2">{proddtl.MRP}</s>{" "}
                                  <span className="spfea-2">
                                    {Math.round(
                                      100 -
                                        (parseFloat(proddtl.CURRENT_AMOUNT) *
                                          100) /
                                          (parseFloat(proddtl.MRP) == 0
                                            ? 1
                                            : parseFloat(proddtl.MRP))
                                    )}
                                    % Off
                                  </span>{" "}
                                </p>
                              </div>
                              <div className="cart-button mt-1 px-2 text-center ">
                                <button className="btn bthvr1 text-uppercase">
                                  Add to cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  )}
                </div>

                <div>
                  <div className="col-12 d-flex justify-content-center p-3">
                    {/* 1 of{" "}
                    {parseFloat(prodDtls.productCount) > 20
                      ? Math.ceil(parseFloat(prodDtls.productCount) / 20)
                      : "1"} */}
                    <div class="pagination">{paginationItems}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Allproduct;
