import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFacebook } from "react-icons/fa";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

function Adorder() {
  const [orderdtl, setOrderlsts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      vendorId: $("#vendorId").val(),
      filterBy: "PENDING",
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getOrderdtlsAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setOrderlsts(response.po_DETAIL);
      } else {
        toast.error(response.po_MSG);
      }
      console.log(orderdtl.length);
    });
  };

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "OrderList.xlsx");
    toast.success("Excel file downloaded successfully!");
  };

  const downloadExcel = () => {
    var modifiedData = orderdtl.map(function (obj) {
      return {
        "ORDER NO": obj.ORDER_ID,
        DATE: obj.ORDER_DATE,
        "BUYER NAME": obj.USER_NAME,

        "FULFILMENT STATUS": obj.ORDER_STATUS === "PENDING" ? "Active" : null,
        PRICE: obj.PRICE,
        "PAYMENT MODE": "",
      };
    });
    downloadExcelFile(modifiedData);
  };

  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">All Order(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search orders:</p>
                </div>
                <form class="d-flex" style={{ height: "30px" }}>
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button class="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2">&nbsp; {orderdtl.length} Order(s)</p>
                </div>
              </div>
            </div>

            <div class="container ">
              <div class="  pt-1 pb-0">
                <div class="d-flex">
                  <div>
                    {" "}
                    <p className="mgrt2">Filters:</p>
                  </div>
                  <div class="p-3 pt-0 pb-0">
                    <label className="mgrt2">Order Type :</label>
                    <select className="selectoption">
                      <option className="option">All Order Type</option>
                      <option className="option">Completed Order</option>
                      <option className="option">Cancelled Order</option>
                      <option className="option">Return Order</option>
                    </select>
                  </div>

                  <div class="p-3 pt-0 pb-0">
                    <label className="mgrt2 ">Date :</label>
                    <input type="date" className="selectoption" name="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-6 pt-2 pb-2  ">
                <div class="vhp1 pt-0">All Order(s)</div>
              </div>
              <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                
                <div class="vhp1 pt-0">
                  <button
                    class="button1"
                    disabled={orderdtl.length === 0}
                    onClick={downloadExcel}
                  >
                    Download{" "}
                  </button>
                </div>
              </div>
            </div>
            
            <div className="container ">
            <div class="hyeus1 mt-2 p-2">
              <table class="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>Order_No.</td>

                    <td>Date</td>
                    <td>Buyer_Name</td>
                    <td>Fulfilment_Status</td>
                    <td>Total_Price</td>
                    <td>Payment_Mode</td>
                  </tr>
                </thead>
                <tbody>
                  {orderdtl.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    orderdtl.map((orderlts) => (
                      <tr key={`ord${orderlts.ORDER_ID}`}>
                        <td>{orderlts.ORDER_ID}</td>
                        <td>{orderlts.ORDER_DATE}</td>
                        <td>{orderlts.USER_NAME}</td>
                        <td>
                          {orderlts.ORDER_STATUS === "PENDING"
                            ? "Active"
                            : null}
                        </td>
                        <td>{orderlts.PRICE}</td>
                        <td>{orderlts.PAYMENT_TYPE}</td>
                      </tr>
                    ))
                  )}

                  {/* <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>Active</td>
                    <td>₹234</td>
                    <td>UPI</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>Active</td>
                    <td>₹234</td>
                    <td>UPI</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>Active</td>
                    <td>₹234</td>
                    <td>UPI</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>Active</td>
                    <td>₹234</td>
                    <td>UPI</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>Active</td>
                    <td>₹234</td>
                    <td>UPI</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>Active</td>
                    <td>₹234</td>
                    <td>UPI</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>Active</td>
                    <td>₹234</td>
                    <td>UPI</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Adorder;
