import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa'


function Adpayout() {
	const [payout, setPayout] = useState([]);

  useEffect(() => {
    loadPayouts();
  }, []);

  function loadPayouts() {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAdminPayout`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      
        setPayout(response.po_DETAIL);
      
    });
  }



  return (
    <>

<section class="" style={{backgroundColor:"#fff"}}>

	

	<div class="container-fluid">
		
		<div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
			<p class="vhp3">All Payout(s)</p><span class="hdspn1">Learn More</span>
		</div>

	</div>
</section>

<section class="p-3 bdrtb1 ft13" >
	<div class="container ">
		<div class=" mb-1 p-2 pt-1 pb-0" >
				<div class="d-flex" >
					<div> <p className="mgrt2">Filters:</p></div>
					<div class="p-3 pt-0 pb-0">
						<label className="mgrt2">Transaction Type :</label>
				  <select  className="selectoption">
				  	<option>All Transaction Type</option>
				  	<option>Order Payment</option>
				  	<option>Refund</option>
				  	<option>Cancelled</option>
				  </select>
					</div>

					<div class="p-3 pt-0 pb-0">
						<label className="mgrt2">Date :</label>
				  <input type="date" className="selectoption" name=""/>
					</div>
					<button class="button1 mxhtsh m-0 " type="submit">Search</button>
				  
        </div>
			</div>

			
	</div>
	
</section>

<section class="bgcldiv1 p-3">
	<div class="container">
		<div class="card p-3">

					<div class="row">
						<div class="col-md-6 col-6 pt-2 pb-2  ">
						<div class="vhp1 pt-0">Payout(s)</div>
					</div>

					<div class="">
			<div class="row p-3 bgcldiv1 mb-2 bdrtb1">
				<div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13" >
				<div class="d-flex justify-content-end">
					<div> <p className="mgrt2">Find a transaction</p></div>
					<form class="d-flex" style={{height:"30px"}}>
        <input class="form-control me-2" type="search" placeholder="Search Payouts" aria-label="Search"/>
        <button class="btn searchbtn p-1 pt-0" type="submit">Search</button>
      </form>
					<div> <p className="mgrt2"> 3 transaction(s)</p></div>
        </div>
			</div>
			</div>
		</div>

					 <div class="col-md-12 col-12 pt-2 pb-2 d-flex justify-content-end">
						<div class="vhp1 pt-0"><button class="button1"><i class="fa fa-refresh" aria-hidden="true"></i></button></div>
						<div class="vhp1 pt-0"><button class="button1">Download </button></div>
					</div> 
					</div>
					

				<table class="table table-hover table-lg ft13" >
				<thead className="tbhd1">
					<tr>
						<td>Payout Date</td>
						<td>Vendor </td>
						<td>Payout Mode</td>
						<td>Scheduled payout</td>
						<td>Processing</td>
						<td>Sent to bank</td>
						<td>Payout Status</td>
						<td>Payout Amount</td>
						<td>View Summary</td>
						
					</tr>
					
				</thead>
				<tbody>
				{payout.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={9}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    payout.map((pyout) => (
						<tr key={`payout${pyout.ID}`}>
						<td>{pyout.StartDateOfWeek}</td>
						<td>{pyout.NAME}</td>
						<td>{pyout.PAYMENTMODE}</td>
						<td>{pyout.StartDateOfWeek}</td>
						<td>{pyout.PAYOUTPROCESSINGDATE}</td>
						<td>{pyout.COMPLETEDDATE}</td>
						<td className="done1">
						  {pyout.ISDONE}
						</td>
						<td>
						₹ {pyout.TotalPayoutAmount}
						</td>
						<td>Report</td>
					  </tr>
                    ))
                  )}
				  
					{/* <tr>
						<td>1/4/2023</td>
						<td>Vendor 1</td>
						<td>Electronic Transactions</td>
						<td>9/10/2021</td>
						<td>9/10/2021</td>
						<td>10/10/2021</td>
						<td class="done1">Done</td>
						<td>₹134.44</td>					
						<td>Report</td>					
					</tr> */}
					{/* <tr>
						<td>1/4/2023</td>
						<td>Vendor 1</td>
						<td>Electronic Transactions</td>
						<td>9/10/2021</td>
						<td>9/10/2021</td>
						<td>10/10/2021</td>
						<td class="done1">Done</td>
						<td>₹134.44</td>					
						<td>Report</td>					
					</tr>
					<tr>
						<td>1/4/2023</td>
						<td>Vendor 1</td>
						<td>Electronic Transactions</td>
						<td>9/10/2021</td>
						<td>9/10/2021</td>
						<td>10/10/2021</td>
						<td class="done1">Done</td>
						<td>₹134.44</td>					
						<td>Report</td>					
					</tr>
					<tr>
						<td>1/4/2023</td>
						<td>Vendor 1</td>
						<td>Electronic Transactions</td>
						<td>9/10/2021</td>
						<td>9/10/2021</td>
						<td>10/10/2021</td>
						<td class="done1">Done</td>
						<td>₹134.44</td>					
						<td>Report</td>					
					</tr>
					<tr>
						<td>1/4/2023</td>
						<td>Vendor 1</td>
						<td>Electronic Transactions</td>
						<td>9/10/2021</td>
						<td>9/10/2021</td>
						<td>10/10/2021</td>
						<td class="done1">Done</td>
						<td>₹134.44</td>					
						<td>Report</td>					
					</tr>
					<tr>
						<td>1/4/2023</td>
						<td>Vendor 1</td>
						<td>Electronic Transactions</td>
						<td>9/10/2021</td>
						<td>9/10/2021</td>
						<td>10/10/2021</td>
						<td class="done1">Done</td>
						<td>₹134.44</td>					
						<td>Report</td>					
					</tr> */}
					
				</tbody>
			</table>
			</div>
	</div>

	
</section>  
    
    </>
	
  )
}

export default Adpayout

