import Shop from "./router/Shop.js";
import Headernew from "./component/Headernew.js";
import Mobilehead2 from "./component/Mobilehead2.js";
import Cart from "./component/Cart.js";
import Sitemap from "./component/Sitemap.js";
import Footer from "./component/Footer.jsx";
import Singleproduct from "./component/Singleproduct.js";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import Allproduct from "./component/Allproduct.js";
import Order from "./component/Order.js";
import Review from "./component/Review.js";
import Wishlist from "./component/Wishlist.js";
import Checkout from "./component/Checkout.js";
import Myaccount from "./component/Myaccount.js";
import { MyContextProvider } from "./AppContext";

import Vdheader from "./component/Vdheader.js";
import Vdhome from "./component/Vdhome.js";
import Vdmanagelisting from "./component/Vdmanagelisting.js";
import Vdmanageinventory from "./component/Vdmanageinventory.js";
import Vdmanageorder from "./component/Vdmanageorder.js";
import Vdpayout from "./component/Vdpayout.js";
import Vdtransaction from "./component/Vdtransaction.js";
import Vdlistingreport from "./component/Vdlistingreport.js";
import Vdinventoryreport from "./component/Vdinventoryreport.js";
import Vdorderreport from "./component/Vdorderreport.js";
import Vdpayoutreport from "./component/Vdpayoutreport.js";
import Vdticket from "./component/Vdticket.js";

import Vdfooter from "./component/Vdfooter.js";
import Seller from "./component/Seller.js";
import Sellerregister from "./component/Sellerregister.js";
import Adproduct from "./component/Adproduct.js";

import Adhome from "./component/Adhome.js";
import Adorder from "./component/Adorder.js";
import Adheader from "./component/Adheader.js";
import Adpayout from "./component/Adpayout.js";
import Adcart from "./component/Adcart.js";
import Adsku from "./component/Adsku.js";
import Adtransactions from "./component/Adtransactions.js";
import Aduser from "./component/Aduser.js";
import Advendor from "./component/Advendor.js";
import Adticket from "./component/Adticket.js";

import Privacy from "./component/Privacy.js";
import Terms from "./component/Terms.js";
import Allreview from "./component/Allreview.js";
import Noresult from "./component/Noresult.js";
import Invoice from "./component/Invoice.js";

import Adlistingreport from "./component/Adlistingreport.js";
import Adinventoryreport from "./component/Adinventoryreport.js";
import Adorderreport from "./component/Adorderreport.js";

import Adpayoutreport from "./component/Adpayoutreport.js";
import Addadmin from "./component/Addadmin.js";
import Viewadmin from "./component/Viewadmin.js";
import Homesetup from "./component/Homesetup.js";
import Adaccount from "./component/Adaccount.js";
import Vdaccount from "./component/Vdaccount.js";
import Adlogin from "./component/Adlogin.js";
import Sellerheader from "./component/Sellerheader.js";
import Herosetup from "./component/Herosetup.js";
import Fullbanner from "./component/Fullbanner.js";
import Halfbanner from "./component/Halfbanner.js";
import Userdetails from "./component/Userdetails.js";
import Vendordetails from "./component/Vendordetails.js";
import Thankyou from "./component/Thankyou.js";


import Entersku from "./component/Entersku.js";
import Searchasin from "./component/Searchasin.js";
import Coupons from "./component/Coupons.js";

import Shopbycategory from "./component/Shopbycategory.js";
import Addreviews from "./component/Addreviews.js";
import Vdticketaction from "./component/Vdticketaction.js";
import Pincode from "./component/Pincode.js";
import Filtersetup from "./component/Filtersetup.js";
import Usernotification from "./component/Usernotification.js";
import Cancelrequest from "./component/Cancelrequest.js";
import Mobfilter from "./component/Mobfilter.js";


import Login from "./component/Login.js";
import Register from "./component/Register.js";




function App() {
  function HeaderManager() {
    const location = useLocation();

    if (
      location.pathname === "/" ||
      location.pathname === "/Cart" ||
      location.pathname === "/Product" ||
      location.pathname === "/Allproduct" ||
      location.pathname === "/Order" ||
      location.pathname === "/Review" ||
      location.pathname === "/Wishlist" ||
      location.pathname === "/Checkout" ||
      location.pathname === "/Myaccount" ||
      location.pathname === "/Privacy" || 
      location.pathname === "/Terms" ||
      location.pathname === "/Allreview" || 
      location.pathname === "/Noresult"
    ) {
      return (
        <>
          <Mobilehead2 />
          <Headernew />
        </>
      );
    }
     else if (
      location.pathname === "/Seller" ||
      location.pathname === "/Sellerregister"
    ) {
      return <Sellerheader />;
    } 
    else if (
      location.pathname === "/Vdhome" ||
      location.pathname === "/Vdmanagelisting" ||
      location.pathname === "/Vdmanageinventory" ||
      location.pathname === "/Vdmanageorder" ||
      location.pathname === "/Vdpayout" ||
      location.pathname === "/Vdtransaction" ||
      location.pathname === "/Vdlistingreport" ||
      location.pathname === "/Vdinventoryreport" ||
      location.pathname === "/Vdpayoutreport" ||
      location.pathname === "/Vdticket" ||
      location.pathname === "/Vdfooter" ||
      location.pathname === "/Adproduct" ||
      location.pathname === "/Vdorderreport" ||
      location.pathname === "/Vdaccount"
    ) {
      return <Vdheader />;
    } else if (
      location.pathname === "/Adhome" ||
      location.pathname === "/Adorder" ||
      location.pathname === "/Adpayout" ||
      location.pathname === "/Adcart" ||
      location.pathname === "/Adsku" ||
      location.pathname === "/Adtransactions" ||
      location.pathname === "/Aduser" ||
      location.pathname === "/Advendor" ||
      location.pathname === "/Adticket" ||
      location.pathname === "/Adlistingreport" ||
      location.pathname === "/Adinventoryreport" ||
      location.pathname === "/Adorderreport" ||
      location.pathname === "/Adpayoutreport" ||
      location.pathname === "/Addadmin" ||
      location.pathname === "/Viewadmin" ||
      location.pathname === "/Homesetup" ||
      location.pathname === "/Herosetup" || 
      location.pathname === "/Fullbanner" || 
      location.pathname === "/Halfbanner" || 
      location.pathname === "/Userdetails" || 
      location.pathname === "/Vendordetails" ||
      location.pathname === "/Coupons" ||
      location.pathname === "/Searchasin" ||
      location.pathname === "/Adaccount"
    ) {
      return <Adheader />;
    }else{
      return "";
    }
  }

  function FooterManager() {
    const location = useLocation();

    if (
      location.pathname === "/" ||
      location.pathname === "/Cart" ||
      location.pathname === "/Product" ||
      location.pathname === "/Allproduct" ||
      location.pathname === "/Order" ||
      location.pathname === "/Review" ||
      location.pathname === "/Wishlist" ||
      location.pathname === "/Checkout" ||
      location.pathname === "/Myaccount"
    ) {
      return (
        <>
          <Sitemap />
          <Footer />
        </>
      );
    } else {
      return "";
    }
  }

  return (
    <>
      <MyContextProvider>
        <BrowserRouter>
          <HeaderManager />
          <Routes>
            <Route exact path="/" element={<Shop />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Product" element={<Singleproduct />} />
            <Route path="/Allproduct" element={<Allproduct />} />
            <Route path="/Order" element={<Order />} />
            <Route path="/Review" element={<Review />} />
            <Route path="/Wishlist" element={<Wishlist />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/Myaccount" element={<Myaccount />} />

            {/* vendor start */}
            <Route path="/Vdhome" element={<Vdhome />} />
            <Route path="/Vdmanagelisting" element={<Vdmanagelisting />} />
            <Route path="/Vdmanageinventory" element={<Vdmanageinventory />} />
            <Route path="/Vdmanageorder" element={<Vdmanageorder />} />
            <Route path="/Vdpayout" element={<Vdpayout />} />
            <Route path="/Vdtransaction" element={<Vdtransaction />} />
            <Route path="/Vdlistingreport" element={<Vdlistingreport />} />
            <Route path="/Vdinventoryreport" element={<Vdinventoryreport />} />
            <Route path="/Vdorderreport" element={<Vdorderreport />} />
            <Route path="/Vdpayoutreport" element={<Vdpayoutreport />} />
            <Route path="/Vdticket" element={<Vdticket />} />
            <Route path="/Vdfooter" element={<Vdfooter />} />
            <Route path="/Sellerregister" element={<Sellerregister />} />
            <Route path="/Seller" element={<Seller />} />

            <Route path="/Adproduct" element={<Adproduct />} />

            {/* vendor end */}

            <Route path="/Adhome" element={<Adhome />} />
            <Route path="/Adorder" element={<Adorder />} />
            <Route path="/Adpayout" element={<Adpayout />} />
            <Route path="/Adcart" element={<Adcart />} />
            <Route path="/Adsku" element={<Adsku />} />
            <Route path="/Adtransactions" element={<Adtransactions />} />
            <Route path="/Aduser" element={<Aduser />} />
            <Route path="/Advendor" element={<Advendor />} />
            <Route path="/Adticket" element={<Adticket />} />
            <Route path="/Adlistingreport" element={<Adlistingreport />} />
            <Route path="/Adinventoryreport" element={<Adinventoryreport />} />
            <Route path="/Adorderreport" element={<Adorderreport />} />
            <Route path="/Adpayoutreport" element={<Adpayoutreport />} />
            <Route path="/Addadmin" element={<Addadmin />} />
            <Route path="/Viewadmin" element={<Viewadmin />} />
            <Route path="/Homesetup" element={<Homesetup />} />

            <Route path="/Invoice" element={<Invoice />} />

            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/Terms" element={<Terms />} />
            <Route path="/Allreview" element={<Allreview />} />
            <Route path="/Noresult" element={<Noresult />} />


            <Route path="/Adaccount" element={<Adaccount />} />
            <Route path="/Vdaccount" element={<Vdaccount />} />
            <Route path="/Adlogin" element={<Adlogin />} />


            <Route path="/Sellerheader" element={<Sellerheader />} />


            <Route path="/Herosetup" element={<Herosetup />} />
            <Route path="/Fullbanner" element={<Fullbanner />} />
            <Route path="/Halfbanner" element={<Halfbanner />} />
            <Route path="/Userdetails" element={<Userdetails />} />
            <Route path="/Vendordetails" element={<Vendordetails />} />
            <Route path="/Thankyou" element={<Thankyou />} />


            <Route path="/Entersku" element={<Entersku />} />
            <Route path="/Searchasin" element={<Searchasin />} />
            <Route path="/Coupons" element={<Coupons />} />


            <Route path="/Shopbycategory" element={<Shopbycategory />} />
            <Route path="/Addreviews" element={<Addreviews />} />
            <Route path="/Vdticketaction" element={<Vdticketaction />} />
            <Route path="/Pincode" element={<Pincode />} />
            <Route path="/Filtersetup" element={<Filtersetup />} />
            <Route path="/Usernotification" element={<Usernotification />} />
            <Route path="/Cancelrequest" element={<Cancelrequest />} />
            <Route path="/Mobfilter" element={<Mobfilter />} />



            <Route path="/Login" element={<Login />} />
            <Route path="/Register" element={<Register />} />

            
            
            
            
          </Routes>
          <FooterManager />
        </BrowserRouter>
      </MyContextProvider>
    </>
  );
}

export default App;
