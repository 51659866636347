import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import logo from "../media/logo6.png";

import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Login() {
  const location = useLocation();
  const navigate = useNavigate();

  
const [inputValue, setInputValue] = useState('');
const [suggestions, setSuggestions] = useState([]);
const [verify, setVerify] = useState(false);

const [mobileNumber, setMobileNumber] = useState('');
const [error, setError] = useState('');

///////////

const handleInputChange = (e) => {
  setMobileNumber(e.target.value);
};

const handleButtonClick = (dtls) => {
  // Validate the mobile number here.
  const mobileNumberRegex = /^[6789]\d{9}$/; // 6, 7, 8, or 9 followed by 9 digits.

  if (!mobileNumber.match(mobileNumberRegex)) {
    setError('Mobile number must start with 6, 7, 8, or 9 and be 10 digits.');
  } else {
    localStorage.setItem('tempMobileNo', mobileNumber);

    validateLogin(dtls);

  }
};






// modal ends

const dropdownStyle = {
  zIndex: 1,
};

const [userid, setUserid] = useState("");
useEffect(() => {
  setUserid(localStorage.getItem('userId'));
  
  
  
  
}, []);


const handleKeyPress = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const regex = /^\d+$/; // Only allow digits
  const value = event.target.value + keyValue;

  if (!regex.test(keyValue) && keyCode !== 8) {
    event.preventDefault();
  }
  if (value.length === 1 && !/[6-9]/.test(keyValue)) {
    event.preventDefault();
  }
};

const validateLogin = (dtls) => {
  debugger
  
if(dtls === 'login'){
if (!$("#_logMob").val() || $("#_logMob").val().length < 10) {
  toast.error("Invalid Mobile No!");
  return false;
}
}else{
if (!$("#_mobSignup").val() || $("#_mobSignup").val().length < 10) {
  toast.error("Invalid Mobile No!");
  return false;
}
}
  

  var valdata = {
    mobileNo: (dtls === 'login' ? $("#_logMob").val().trim() : $("#_mobSignup").val() ),
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}validateUser`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {

    if (parseInt(response.po_STATUS) == 1 && dtls === 'login') {
      sendOtp("send", $("#_logMob").val());
      setError('');
      $(".displayMobile").text($("#_logMob").val());
      //$("#otpmodalopen").click();
      
    } else if(parseInt(response.po_STATUS) == 1 && dtls === 'signUp'){
     toast.error('Already Registered.');
    }else if(dtls === 'signUp' && parseInt(response.po_STATUS) == 0){
      sendOtp("send", $("#_mobSignup").val());
      
    }else if(parseInt(response.po_STATUS) == 0 && dtls === 'login'){
      toast.error('No User Found. Please SignUp.');
    }
  });
};

const sendOtp = (typed, userNo) => {
  var valdata = {
    mobileNo: userNo,
    typed: typed,
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}sendOtp`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) == 1) {
      toast.success("OTP Send Successfully..");
      setVerify(true);
    } else {
      toast.error("Failed To Send OTP.");
    }
  });
};

const verifyOtp = (typed) => {
  
  if (!$("#_otplogin").val().trim()) {
    toast.error("Please Enter Valid OTP.");
    return false;
  }
  var valdata = {
    mobileNo: localStorage.getItem('tempMobileNo'),
    otp: $("#_otplogin").val().trim() ,
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}verifyOtp`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) == 1 && typed === 'login') {
      loginuser();
    }else if (parseInt(response.po_STATUS) == 1 && typed === 'signup') {
      registeruser();
    } else {
      toast.error("Invalid OTP.");
    }
  });
};

const loginuser = () => {
  if (!$("#_otplogin").val().trim()) {
    toast.error("Please Enter Valid OTP.");
    return false;
  }
  var valdata = {
    mobileNo: localStorage.getItem('tempMobileNo'),
    otp: $("#_otplogin").val().trim(),
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}loginchk`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) == 1) {
      localStorage.setItem("userId", response.USERID);
      setUserid(response.USERID);
      $("#userId").val(response.USERID);
      localStorage.setItem("userName", response.USER_NAME);
      toast.success("Logged In Successfully.");
      const url = `/`;
      window.location.href = url;

      navigate(url);
    } else {
      toast.error("Invalid OTP.");
    }
  });
};

const registeruser = () => {
  
  var valdata = {
    mobileNo: localStorage.getItem('tempMobileNo'),
    
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}registeruser`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) == 1) {
      
      toast.success("Register Successfully. Please Login.");
      
    } else {
      toast.error("Invalid OTP.");
    }
  });
};

const [category, setCategory] = useState([]);
const [subcategory, setSubCategory] = useState([]);

useEffect(() => {
  const settings = {
    async: false,
    crossDomain: true,
    url: `${Baseurl.baseUrl}getCategory`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  $.ajax(settings).done(function (response) {
    setCategory(response);
  });
}, []);

const navigateto = (queryStringParams) => {
  const url = `Allproduct?${queryStringParams}`;
  window.location.href = url;
  navigate(url);
};

const navigatetoblank = (queryStringParams) => {
  queryStringParams = (queryStringParams ? queryStringParams : "11")
  const url = `Allproduct?q=search&requestType=banner&subCategory=${queryStringParams}`;
  window.location.href = url;
  navigate(url);
};




  return (
    <>
      <ToastContainer />
      


      <section className="bgsec1 p-3" style={{ height: "100vh" }}>
        <div className="container">

        <div className="container-fluid text-center">
          <Link className="navbar-brand" to="/Adhome">
            <img src={logo} style={{ height: "40px" }} alt="Logo" />
          </Link>
        </div>


          <div className="row  mt-3 mb-3 d-flex justify-content-center">
            <div className="col-md-4">
              <div className=" hyeus1 p-3 mb-3">
                
                  <div className="row">
                    <div className="col-6">
                      <h1 className="crtitmhd11">Sign in</h1>
                    </div>
                  </div>
                  <hr />

                  <div className="row g-3">
                    <div className="col-sm-12">
                      <label htmlFor="adloginmail" className="form-label lka88 ">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control inpt1 "
                        value={mobileNumber}
                        onChange={handleInputChange}
                        maxLength={10}
                        id="_logMob"
                      />
                      {error && <p className="text-danger">{error}</p>}
                    </div>
                    {verify && <div className="col-sm-12">
                      <label htmlFor="adloginpw" className="form-label lka88">
                        OTP
                      </label>
                      <input
                        type="password"
                        className={`form-control inpt1 `}
                        id="_otplogin"
                        placeholder="OTP Here"
                        required
                        name="password"
                        
                        autoComplete="off"
                      />
                      
                    </div>}
                    
                  </div>
{!verify && <div className="pt-3 col-md-12">
                    <button
                      className={`btnvd add-cart-btn `}
                      onClick={()=> handleButtonClick('login')}
                    >
                      Verify
                    </button>
                  </div>}
                  
{verify && <div className="pt-3 col-md-12">
                    <button
                      className={`btnvd add-cart-btn `}
                      onClick={()=>verifyOtp('login')}
                    >
                      Login Now
                    </button>
                  </div>}
                  



                  <div className="col-6 d-flex justify-content-start mt-3">
                    <p>
                      <a href="" className="share-spn">
                        Forgot Password{" "}
                      </a>
                    </p>
                  </div>
                
              </div>

              <div className="text-center">
                  <div><span className="sapnjsj">New here</span> </div>
                  <hr className="hrgs11"></hr>
              </div>

              <div className=" hyeus1 p-3 ">
                <form>
                  <div className="col-md-12">
                   


                    <Link className="navbar-brand" to="/Register">
            
                    <div className="btnbdr22">Create Your Account Now</div>
                    </Link>



                  </div>

                  
                </form>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
