import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import img8 from "../media/male.jpg"
import queryString from "query-string";


import { Link, useLocation } from "react-router-dom";

import { FaThumbsDown, FaThumbsUp,FaStar,FaStarHalfAlt } from 'react-icons/fa'


function Allreview() {
  
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const subCategoryId = queryParams.subCategory;
  const requestType = queryParams.requestType;
  const q = queryParams.q;
  const productId = queryParams.productId;
  const catId = queryParams.catId;

  const [prodDtls, setProdDtls] = useState({
    productDetails: [],
    productSpecs: [],
  });

  const [reviews, setReviews] = useState({});
  const [reviewbreakdw, setReviewbreakdw] = useState({
    beforedecimal: 0,
    afterdecimal: false,
    productReviews: [],
  });
  
  useEffect(() => {
    loadProduct();
  }, []);


  function loadProduct() {
    var valdata = {
      subCatId: subCategoryId,
      prodId: productId,
      q: q,
      catId: catId,
      requestType: requestType,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getProductDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setProdDtls({
        productDetails: response.po_DETAIL[0],
        productSpecs: response.po_SPECIFICATION,
      });
      reviewDtls(response.po_DETAIL[0].ID);
      console.log(response.po_DETAIL[0]);
    });
  }

  function reviewDtls(prodId) {
    var valdata = {
      prodId: productId,
      requestType: requestType,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getProductratingdtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setReviews(response.hsmp);
      setReviewbreakdw({
        beforedecimal: Math.floor(
          parseFloat(response.hsmp.AVGRating ? response.hsmp.AVGRating : 0)
        ),
        afterdecimal:
          parseFloat(response.hsmp.AVGRating) % 1 > 0 ? true : false,
        productReviews: response.po_DETAIL_OTHER,
      });
      console.log(Math.floor(parseFloat(reviews.AVGRating)));
    });
  }



    return (

      
      <>
  
  <section style={{ backgroundColor: "#f1f3f6" }}>
      <div className="container " style={{ backgroundColor: "#fff" }}>
          <div className="row justify-content-start text-start pt-4">
            <div className="col-md-4 col-12">
                <img src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_1}`} className="img-fluid" />

                <h1 className="pro-hdng1 p-2">{prodDtls.productDetails.PRODUCT_NAME}</h1>

                <div class="product-rating align-items-center">
                    {/* <div className="px-1" style={{ backgroundColor: "#fff600",borderRadius: "5px"}}>
                    <span>4.1</span>
                        <span><FaStar/></span>
                    </div> */}
                        <span>{reviews.AVGRating != null || parseInt(reviews.reviewsCount) !== 0 ? `${reviews.AVGRating} ratings | ${reviews.reviewsCount} Reviews` : '' }</span>
                    </div>
                    <hr/>
            </div>
            <div className="col-md-8 col-12 bdrlft1">
                <h1 className="pro-hdng1">{prodDtls.productDetails.PRODUCT_NAME}</h1>
                <div>
                
          {/* <div className="sold-by p-3 mb-2 shd22">
            <div>
              <img src={img8} className="img-fulil-1"/>
            <p className="sldby-p1 dis-unset">Buyer name here</p>
              <div className = "product-rating">
            <span><FaStar/></span>
            <span><FaStar/></span>
            <span><FaStar/></span>
            <span><FaStar/></span>
            <span><FaStarHalfAlt/></span>
            <span>On 20/7/2022</span>
          </div>
            </div>
            <p>Good Product Love It.</p>
            <hr/>
            <p className="p-0 rev-p-1">Is this Review helpful for you? <Link to=""><FaThumbsUp /> (18)</Link>
            <Link to=""><FaThumbsDown/>(5)</Link></p>
          </div> */}

{reviewbreakdw.productReviews.length === 0 ? (
                      ""
                    ) : (
                      reviewbreakdw.productReviews.map((rpt) => (
                        <div className="hyeus1 p-3 mb-2">
                          <div>
                            <img src={img8} className="img-fulil-1" />
                            <p className="sldby-p1 dis-unset">{rpt.username}</p>
                            <div className="product-rating">
                              {[...Array(parseInt(rpt.RATING))].map(
                                (_, index) => (
                                  <span key={index}>
                                    <FaStar />
                                  </span>
                                )
                              )}

                              <span>On {rpt.TIME_STAMP}</span>
                            </div>
                          </div>
                          <p>{rpt.PI_COMMENT}</p>
                        </div>
                      ))
                    )}

          {/* <div className="sold-by p-3 mb-2 shd22">
            <div>
              <img src={img8} className="img-fulil-1"/>
            <p className="sldby-p1 dis-unset">Buyer name here</p>
              <div className = "product-rating">
            <span><FaStar/></span>
            <span><FaStar/></span>
            <span><FaStar/></span>
            <span><FaStar/></span>
            <span><FaStarHalfAlt/></span>
            <span>On 20/7/2022</span>
          </div>
            </div>
            <p>Good Product Love It.</p>
            <hr/>
            <p className="p-0 rev-p-1">Is this Review helpful for you? <Link to=""><FaThumbsUp /> (18)</Link>
            <Link to=""><FaThumbsDown/>(5)</Link></p>
          </div> */}

                </div>
            </div>
          </div>
  
      </div>
  </section>
  
      
      </>
      
    )
  }
  
  export default Allreview