import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";

import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Searchasin() {
  const [prod, setProd] = useState([]);
  useEffect(() => {}, []);

  const fetchData = () => {
    if (!$("#_asinNo").val().trim()) {
      toast.error("Invalid ASIN No.");
      return false;
    }
    var valdata = {
      asinNo: $("#_asinNo").val().trim(),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchAsinNo`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        $("#_vendorName").text(response.po_DETAIL[0].NAME);
        $("#_storeName").text(response.po_DETAIL[0].STORENAME);
        $("#_phoneNo").text(response.po_DETAIL[0].PHONE_NUMBER);
        $("#_emailId").text(response.po_DETAIL[0].EMAIL_ID);
        $("#_panNo").text(response.po_DETAIL[0].PANNO);
        $("#_gstNo").text(response.po_DETAIL[0].GSTNO);
        $("#_asinNo").text(response.po_DETAIL[0].ASINNO);
        $("#_skuNo").text(response.po_DETAIL[0].Total_SKU_Count);
        setProd(response.po_DETAIL_OTHER);
      } else {
        toast.error("No ASIN Found.");
      }
    });
  };

  const updateSts = (id, sts, featured, FeaturedSts) => {
    var valdata = {
      rowId: id,
      statusPi: sts,
      updateFor : featured,
      featuredSts : FeaturedSts
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}updateProductStsAD`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        if (sts === "D") {
          toast.success("Status Updated");
        } else {
          toast.success("Status Updated");
        }
        fetchData();
      } else {
        toast.error("No ASIN Found.");
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Search ASIN(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div className="">
              <div className="row p-3 bgcldiv1 mb-2 bdrtb1">
                <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
                  <div className="d-flex justify-content-end">
                    <div>
                      {" "}
                      <p className="mgrt2">Find a ASIN</p>
                    </div>
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Enter ASIN Number"
                      id="_asinNo"
                      aria-label="Search"
                    />
                    <button
                      className="button1 mxhtsh m-0"
                      type="submit"
                      onClick={() => fetchData()}
                    >
                      Search
                    </button>{" "}
                    &nbsp;
                    <div>
                      {" "}
                      <p className="mgrt2"> 3 SKU(s)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="seccl1 py-2 mb-2">
              <div className="container">
                <div className="hyeus1 p-2 my-3">
                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">Vendor Name :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_vendorName"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">Store Name :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_storeName"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">Phone :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_phoneNo"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">Email :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_emailId"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">PAN Number :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_panNo"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">GST Number :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_gstNo"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">ASIN Number :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_asinNo"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <div className="crtitmhd">Total SKU :-</div>
                    </div>
                    <div className="col-9">
                      <div className="jswnds">
                        <span id="_skuNo"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table class="table table-hover ft13">
              <thead className="tbhd1">
                <tr>
                  <td>Sr. No</td>
                  <td>SKU(s)</td>
                  <td>Product Name</td>
                  <td>Status</td>
                  <td>Action</td>
                  <td>Featured</td>
                </tr>
              </thead>
              <tbody>
                {prod.length === 0 ? (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      No data found
                    </td>
                  </tr>
                ) : (
                  prod.map((prd, index) => (
                    <tr key={`prod${prd.ORDER_ID}`}>
                      <td>{index + 1}</td>
                      <td>{prd.SKU_ID}</td>
                      <td>{prd.PRODUCT_NAME}</td>

                      <td>{prd.PI_STATUS === "A" ? "Active" : "In-Active"}</td>
                      <td>
                        {prd.PI_STATUS === "A" ? (
                          <button
                            class="btnvd add-cart-btn"
                            onClick={() => updateSts(prd.ID, "D", "action", "")}
                          >
                            INACTIVE
                          </button>
                        ) : (
                          <button
                            class="btnvd add-cart-btn"
                            onClick={() => updateSts(prd.ID, "A", "action", "")}
                          >
                            ACTIVE
                          </button>
                        )}
                      </td>

                      <td>
                        {parseInt(prd.ISFULFILLED) === 0 ? <button class="btnvd add-cart-btn" onClick={() => updateSts(prd.ID, "", "featured", "1")}>Featured</button> : <button class="btnvd add-cart-btn" onClick={() => updateSts(prd.ID, "", "featured", "0")}>Un-Featured</button> }
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Searchasin;
