import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";

function Vdticket() {

  const [tickectDtls, setTicketDtls] = useState([]);

  useEffect(() => {
    $("#vdtcname").val($("#_vName").val());
    $("#vdtcemail").val($("#_vmail").val());
    loadDepatment();
    fetchAllQuery();
  }, []);

  function loadDepatment() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getDepartment`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        $("#vdtcdepartment").empty();
        $("#vdtcdepartment").append('<option value="">Select</option>');
        $.each(response.po_DETAIL, function (key, value) {
          $("#vdtcdepartment").append(
            '<option value="' +
              response.po_DETAIL[key].ID +
              '" >' +
              response.po_DETAIL[key].DEPARTMENT_NAME +
              ""
          );
        });
      }
    });
  }

  const saveData = () => {
      console.log("in")
    if (!$("#vdtcname").val().trim()) {
      toast.error("Name is required.");
      return false;
    }
    if (!$("#vdtcemail").val().trim()) {
      toast.error("Email is required.");
      return false;
    }
    if (!$("#vdtcsubject").val().trim()) {
      toast.error("Subject is required.");
      return false;
    }
    if (!$("#vdtcdepartment option:selected").val().trim()) {
      toast.error("Department is required.");
      return false;
    }
    if (!$("#vdtcpriority option:selected").val().trim()) {
      toast.error("Priority is required.");
      return false;
    }
    if (!$("#vdtcmsg").val().trim()) {
      toast.error("Message is required.");
      return false;
    }
    var valdata = {
        vendorId: $("#vendorId").val().trim(),
      vname: $("#vdtcname").val().trim(),
      vemail: $("#vdtcemail").val().trim(),
      vsubject: $("#vdtcsubject").val().trim(),
      vdepatment: $("#vdtcdepartment option:selected").val().trim(),
      vpriority: $("#vdtcpriority option:selected").val().trim(),
      vquery: $("#vdtcmsg").val().trim(),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addqueryRaised`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
          toast.success(response.po_MSG);
          $("#vdtcsubject,#vdtcmsg").val('');
          $("#vdtcdepartment,#vdtcpriority").val('')
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  const fetchAllQuery = () => {

    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}fetchAllQuery`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) === 1) {
      setTicketDtls(response.po_DETAIL);
    } else {
      
    }
  });
  };


  const handleClick = (ticket, subject, query, remarks, status) => {
    $("#_token").text(ticket);
    $("#_sub").text(subject);
    $("#_query").text(query);
    $("#_remark").text(remarks ? "# "+remarks : "NA");
    $("#_status").text(status);

  };

  return (
    <>
      <ToastContainer />
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">All Ticket(s) </p>
            <span className="hdspn1">Learn More</span>
          </div>
        </div>
      </section>

      <section className=" bgcldiv1 p-3">
        <div className="row">
          <div className="col-md-3">
            <div className="card px-3">
              <div className="row">
                <div className="col-md-12 col-6 pt-2 pb-2  ">
                  <div className="vhp1 pt-0">Recent Ticket(s)</div>
                  <hr />
                </div>
                {tickectDtls.length === 0 ? (
                      ""
                    ) : (
                      tickectDtls.map((rpt) => (

<a data-bs-toggle="modal" data-bs-target="#viewticket"  key={`ord${rpt.ID}`}  onClick={() => handleClick(rpt.ID, rpt.QUERY_SUB, rpt.MESSAGE_QUERY, rpt.REMARKS, rpt.PI_STATUS)}>
                  <div className="hyeus1 p-1 rwhvr1 mb-1 acljs1">
                    <div className="juehys1">#{rpt.ID}</div>
                    <div className="hgt67">
                      Subject :- <span className="tksubj2">{rpt.QUERY_SUB} </span>{" "}
                    </div>
                    <div className="hgt67">
                      Status :- <span className="tksubj2">{rpt.PI_STATUS} </span>{" "}
                    </div>
                  </div>
                </a>



                      ))
                    )}
                

                {/* <a data-bs-toggle="modal" data-bs-target="#viewticket">
                  <div className="hyeus1 p-1 rwhvr1 mb-1 acljs1">
                    <div className="juehys1">#ID63452</div>
                    <div className="hgt67">
                      Subject :- <span className="tksubj2">Subject here </span>{" "}
                    </div>
                    <div className="hgt67">
                      Status :- <span className="tksubj2">Open </span>{" "}
                    </div>
                  </div>
                </a> */}


              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="viewticket"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content laxza">
                <div class="modal-header m-0 w-100">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Ticket Id
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  
                  <div className="row mb-2">
                    <div className="col-4 crtitmhd">Tokken No.</div>
                    <div className="col-8">:- #<span id="_token"></span></div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 crtitmhd">Subject</div>
                    <div className="col-8">:- #<span id="_sub"></span></div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 crtitmhd">Query</div>
                    <div className="col-8">:- #<span id="_query"></span></div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 crtitmhd">Remark</div>
                    <div className="col-8">:- <span id="_remark"></span></div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 crtitmhd">Status</div>
                    <div className="col-8">:- #<span id="_status"></span></div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>

          <div className="col-md-9">
            <div className="card p-3">
              <div className="row">
                <div className="col-md-12 col-12 pt-2 pb-2   mb-3">
                  <div className="vhp1 pt-0">Open New Ticket :</div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label vhlab1"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="inputform"
                      id="vdtcname"
                      placeholder="Vendor name"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label vhlab1"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      className="inputform"
                      id="vdtcemail"
                      placeholder="example@gmail.com"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label vhlab1"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      className="inputform"
                      id="vdtcsubject"
                      aria-describedby="emailHelp"
                      maxLength={2000}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label vhlab1"
                    >
                      Department&nbsp;: &nbsp;
                    </label>
                    <select
                      type="text"
                      className="inpstat3 w-100"
                      id="vdtcdepartment"
                    ></select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label vhlab1"
                    >
                      Priority&nbsp;: &nbsp;
                    </label>
                    <select
                      type="text"
                      className="inpstat3 w-100"
                      id="vdtcpriority"
                    >
                      <option value="">Select</option>
                      <option value="High" className="option">
                        High
                      </option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label vhlab1"
                    >
                      Message
                    </label>
                    <textarea
                      className="inputform"
                      rows="10"
                      id="vdtcmsg"
                      maxLength={5000}
                    ></textarea>
                  </div>
                </div>

                <div className="row d-flex justify-content-end">
                  <div className="p-3 col-md-3 text-end">
                    <button className="btnvd add-cart-btn">Cancel</button>
                  </div>
                  <div className="p-3 col-md-3 text-end">
                    <button className="btnvd add-cart-btn" onClick={saveData}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdticket;
