import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FaTrash } from "react-icons/fa";

function Herosetup() {
  const [banners, setBanners] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);



  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState('');
  const [imageValidationError, setImageValidationError] = useState('');
  const [imageName, setImageName] = useState('');
  const [imageExtension, setImageExtension] = useState('');


  useEffect(() => {
    loadmainmenu();
    fetchBannered();

    
  }, []);


  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    // Check if a file was selected
    if (!selectedFile) {
      return;
    }

    // Validate file size (2MB limit)
    if (selectedFile.size > 2 * 1024 * 1024) {
      setImageValidationError('Image size exceeds 2MB limit.');
      return;
    }

    // Validate file extension (e.g., allow only JPEG, PNG, GIF)
    const allowedExtensions = /\.(jpg|jpeg|png|gif)$/i;
    if (!allowedExtensions.test(selectedFile.name)) {
      setImageValidationError('Invalid file format. Please use JPG, JPEG, PNG, or GIF.');
      return;
    }

    // Read the selected image file and convert it to base64
    const reader = new FileReader();
    reader.onload = () => {
      const base64Parts = reader.result.split(',');
      const base64Value = base64Parts[1];
      setSelectedImage(selectedFile);
      setImageBase64(base64Value);
      setImageName(selectedFile.name);
      setImageExtension(selectedFile.name.split('.').pop());
      setImageValidationError('');
    };
    reader.readAsDataURL(selectedFile);
  };


  function loadmainmenu() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getMainMenu`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if(parseInt(response.po_STATUS) == 1){
		$("#_Hero_MainCat").empty();
			$("#_Hero_MainCat").append('<option value="">Select</option>');
			$.each(response.po_DETAIL,function(key,value){
				
			  $("#_Hero_MainCat").append(
						'<option value="'+response.po_DETAIL[key].ID+'" >'
								+ response.po_DETAIL[key].CATEGORY_NAME + '');

                setCategory(response.po_DETAIL);


			});


	  }
    });
  };

  const fetchBannered = () => {
    var valdata = {
      pindex: "I",
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchBanner`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setBanners(response.po_DETAIL);
    });
  };

  const loadSubcat = () => {
		
    var valdata = {
		
		catId: $("#_Hero_MainCat option:selected").val()
	  };
  
	  const settings = {
		async: true,
		crossDomain: true,
		url: `${Baseurl.baseUrl}getsubCategory`,
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		data: JSON.stringify(valdata),
	  };
  
	  $.ajax(settings).done(function (response) {
		if(parseInt(response.po_STATUS) == 1){
			$("#_hero_subCat").empty();
			$("#_hero_subCat").append('<option value="">Select</option>');
			$.each(response.po_DETAIL,function(key,value){
				
				$("#_hero_subCat").append(
						  '<option value="'+response.po_DETAIL[key].ID+'" >'
								  + response.po_DETAIL[key].SUB_CATEGORY_NAME + '');
			  });
	  }
	  });
  };



  const deleteBanner = (id) => {
    if (window.confirm('Are You Sure To Delete The Record ?')) {
	  		
      var valdata = {
        pindex : 'I',
          dltId : id
        };
      
      $.ajax({
        url: `${Baseurl.baseUrl}dltBanner`,
        method : "post",
        async : false,
        headers : {
          "Content-Type": "application/json"
        },
        data : JSON.stringify(valdata),
        'timeout' : 60000,
      })
        .done(function( resp ) {
          if(parseInt(resp.po_STATUS) == 1 ){
            toast.success(resp.po_MSG);
            fetchBannered();
        }
        else{
          toast.error(resp.po_MSG);
        }
        })
        .fail(function( jqXHR, textStatus ) {
          
        });
    }
  };





  const saveBannerd = () => {
    var valdata = {
      pindex: "I",
      subCatId : $("#_hero_subCat option:selected").val(),
      catId : $("#_Hero_MainCat option:selected").val(),
      fileBase : imageBase64,
      fileName : imageName,
      extension : imageExtension,
      userId : localStorage.getItem('addminId')
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}saveBannerd`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if(parseInt(response.po_STATUS) == 1){
          toast.success(response.po_MSG);
          fetchBannered();
      }else{
        toast.error(response.po_MSG);
      }
    });
  };



  return (
    <>
    <ToastContainer />
      <section className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card p-3 crdbdrnone">
                
                  <div class="row">
                    <div class="col-md-6 col-6 pt-2 pb-2  ">
                      <div class="vhp1 pt-0">Add Hero/Slider(s)</div>
                    </div>
                  </div>
                  <hr />

                  <div className="row g-3">
                  <div className="col-12">
                    <label htmlFor="firstName" className="form-label chkf1">
                      Upload Image
                    </label>
                    <input
                      type="file"
                      className="form-control inpt1"
                      id="imageInput"
                      onChange={handleImageChange}
                      accept="image/*"
                      required
                    />
                    {imageValidationError && (
                      <div className="text-danger">{imageValidationError}</div>
                    )}
                    {/* {imageBase64 && (
                      <div>
                        <img
                          src={imageBase64}
                          alt="Selected Image"
                          className="prwidh1 img-fluid"
                        />
                      </div>
                    )} */}
                  </div>

                    <div className="col-12">
                      <label htmlFor="firstName" className="form-label chkf1">
                        Select Category
                      </label>
                      <select
                        id="_Hero_MainCat"
                        className="form-control"
                        name=""
                        onChange={loadSubcat}
                      ></select>
                    </div>

                    <div className="col-12">
                      <label htmlFor="firstName" className="form-label chkf1">
                        Select Sub-Category
                      </label>
                      <select
                        id="_hero_subCat"
                        className="form-control"
                        name=""
                      >
                        <option>Select</option>
                        
                      </select>
                    </div>
                  </div>

                  <div className="pt-3 col-md-4">
                    <button className="btnvd add-cart-btn" onClick={saveBannerd}>Add</button>
                  </div>
            
              </div>
            </div>
            <div className="col-md-6">
              <div class="card  p-2 crdbdrnone">
                <div class="row">
                  <div class="col-md-6 col-6 pt-2 pb-2  ">
                    <div class="vhp1 pt-0">Hero/Slider(s) List</div>
                  </div>
                </div>
                <hr />
                <table class="table table-hover ft13">
                  <thead className="tbhd1">
                    <tr>
                      <td>Image Preview</td>
                      <td>Category</td>
                      <td>Sub-Category</td>

                      <td>Edit</td>
                    </tr>
                  </thead>
                  <tbody>
                    {banners.length === 0 ? (
                      <tr>
                        <td className="text-center" colSpan={4}>
                          No data found
                        </td>
                      </tr>
                    ) : (
                      banners.map((rpt) => (
                        <tr key={`fetchBn${rpt.ID}`}>
                          <td>
                            <img
                              src={`${Baseurl.getImgUrl}bannerimg/${rpt.BANNER_IMAGE}`}
                              className="prwidh1 img-fluid"
                            />
                          </td>
                          <td>{rpt.CATEGORY_NAME}</td>
                          <td>{rpt.SUB_CATEGORY_NAME}</td>
                          <td>
                            <button className="btnvd add-cart-btn" onClick={() => deleteBanner(rpt.ID)}>
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        {/* <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img1} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="..." class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="..." class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div> */}



      </section>
    </>
  );
}

export default Herosetup;
