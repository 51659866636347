import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import img2 from "../media/prod12.jpg";
import img3 from "../media/prod13.jpg";

import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FaFacebook } from "react-icons/fa";

function Vdmanagelisting() {

  const [listings, setListings] = useState([]);

  useEffect(() => {
    fetchData();
    console.log($('input[name=inlineRadioOptions]:checked').val())
    $('input[name=inlineRadioOptions]:first').prop('checked', true);
  }, []);


  
  const fetchData = (pytype) => {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
      piType : pytype
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getListingDetails`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setListings(response.po_DETAIL);
    });
  };

  function checkboxvalueretrival(cls)
  {
    const rbs = document.querySelectorAll('input[name="'+cls+'"]');
      let selectedValue;
      for (var rb of rbs) {
          if (rb.checked) {
              selectedValue = rb.value;
              break;
          }
      }
      return selectedValue;
  }

  const handleChange = () => {
console.log($('input[name=inlineRadioOptions]:checked').val())
fetchData($('input[name=inlineRadioOptions]:checked').val());
  };
  
const HandleEdit = () => {
  toast.error("Temporily Blocked.");
};


const handleDelete = (id, sts, featured, FeaturedSts) => {
  var valdata = {
    rowId: id,
    statusPi: sts,
    updateFor : featured,
    featuredSts : FeaturedSts
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}updateProductStsAD`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) === 1) {
      if (sts === "D") {
        toast.success("Status Updated");
      } else {
        toast.success("Status Updated");
      }
      fetchData();
    } else {
      toast.error("No ASIN Found.");
    }
  });
};



  return (
    <>
    <ToastContainer />
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">Listing Management </p>
            <span className="hdspn1">Learn More</span>
          </div>

          <div>
            <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2 py-2">Action on 0 Selected:</p>
                </div>
                
                  <input
                    className="form-control me-2 serch1"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  
                  <button class="button1 mxhtsh m-0" >Search </button>
        
                <div>
                  {" "}
                  <p className="py-2 px-2"> {listings.length} product(s)</p>
                </div>
              </div>
            </div>

            <div className="card mb-1 p-2 pb-0 ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Filters:</p>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value=""
                    onChange={handleChange}
                    
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    All
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="A"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Active
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="I"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    Inactive
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio4"
                    value="D"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio4">
                    Removed
                  </label>
                </div>
              </div>
            </div>
            <div className="table-responsive">
            <table className="table table-bordered ft13" style={{backgroundColor: "#fff"  }}>
              <thead className="tbhd1">
                <tr>
                  
                  <td>Product Details</td>
                  <td>Listing Date</td>
                  <td>Listing Price</td>
                  <td>MRP</td>
                  <td>Total Stock</td>
                  <td>Category</td>
                  <td>Fulfilment</td>
                  <td>Status</td>
                  <td>Edit Listing</td>
                </tr>
              </thead>
              <tbody>
              {listings.map(listing =>  (
          <tr  key={`listing${listing.ID}`}>
          
          <td>
            <div className="d-flex ">
              <img src={`${Baseurl.getImgUrl}prodImages/${listing.IMAGES_NAME_1}`} style={{ height: "40px" }} />
              <p>{listing.PRODUCT_NAME}</p>
            </div>
          </td>
          <td>{listing.TIME_STAMP}</td>
          <td>{listing.CURRENT_AMOUNT}</td>
          <td>{listing.MRP}</td>
          <td>{listing.STOCK_QUANTITY}</td>
          <td>{listing.SUB_CATEGORY_NAME}</td>
          <td>Seller Only</td>
          <td>{listing.PI_STATUS == "A" ?'Active' : (listing.PI_STATUS == "D" ? "Removed" : "Inactive")}</td>
          <td><Link to={`/Adproduct?catId=${listing.CATEGORY_ID}&subCatId=${listing.SUB_CATEGORY_ID}&q=search&requestType=listing&prodId=${listing.ID}`}><button class="btnvd add-cart-btn bdrradius1" >Edit Listing</button></Link></td>
          <td><button class="btnvd add-cart-btn bdrradius1" onClick={()=>handleDelete(listing.ID, 'D', "action", "")}>Delete Product</button></td>
        </tr>
			))}
                

                
              </tbody>
            </table>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdmanagelisting;
