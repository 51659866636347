import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { FaFacebook } from 'react-icons/fa'


function Usernotification() {
  const [orderdtls, setOrderDtls] = useState([]);

  useEffect(() => {
    loadOrder("fetch", "", "");
  }, []);

  function loadOrder(flag, did, status) {
    var valdata = {
      userId: $("#userId").val(),
      flagPi :flag,
      did : did,
      status : status
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getUserNotification`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setOrderDtls(response.po_DETAIL);
        if(flag === 'M'){
          loadOrder("fetch", "", "");
        }
      } else {
        toast.error(response.po_MSG);
      }
    });
  }



  return (
    <>
<ToastContainer/>
<section>
    <div className="container-fluid">
    <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">User Notification(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>


          <div className="table-responsive">
            <table class="table table-bordered ft13">
              <thead className="tbhd1">
                <tr>
                 
                  <td>User Name</td>
                  <td>UserID</td>
                  <td>Joining Date</td>
                  <td>City, State</td>
                  <td>Action</td>
                 
                </tr>
              </thead>
              <tbody>
                
                {orderdtls.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    orderdtls.map((orderlts) => (
                      <tr key={`usr${orderlts.ID}`}>
                        <td>{orderlts.FULL_NAME}</td>
                        <td>{orderlts.ID}</td>
                        <td>{orderlts.TIME_STAMP}</td>
                        <td>
                          {orderlts.PI_CITY  +(orderlts.PI_STATE ? "," + orderlts.PI_STATE : "")}
                        </td>
                        <td>{orderlts.TIME_STAMP}</td>
                        <td>{orderlts.NOTIFICATIONSTATUS === 'A' ? <button class="btnbto  w-50" onClick={() => loadOrder("M", orderlts.ID, 'D')}>Clear Notification</button> : ''}</td>
                        
                      </tr>
                    ))
                  )}
                  
                                
              </tbody>
            </table>
            </div>


    </div>
</section>

    
    </>
	
  )
}

export default Usernotification
