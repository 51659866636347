import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from 'react-toastify';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

function Vdtransaction() {
  const [payout, setPayout] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    loadPayouts("");
  }, []);


  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(excelData, 'payoutRpt.xlsx');
    toast.success('Excel file downloaded successfully!');
    };


  function loadPayouts(filterBy) {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
      filterBy: filterBy,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getVendorTransaction`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      
        setPayout(response.po_DETAIL);
      
    });
  }

  const handleDateChange = (event) => {
    loadPayouts(event.target.value);
    
    
  };


  const downloadExcel = () => {
    var modifiedData = payout.map(function(obj) {
       return {
         						
           "Date" : obj.TIME_STAMP,
           "Transaction type" : obj.PAYMENTMODE,
           "Order ID":obj.ORDER_ID,
           "Product Details" :	obj.PRODUCT_NAME,
           "Total product charges" :	parseFloat(obj.PAYOUT_AMOUNT) + parseFloat(obj.PLATFORM_CHARGES) + parseFloat(obj.GST_AMOUNT),
           "	Tej Bazar fees (-)" : obj.PLATFORM_CHARGES,
           "Other (-)" :	obj.GST_AMOUNT,
           "Total" :	obj.PAYOUT_AMOUNT,
          				
           
       };
       });
      downloadExcelFile(modifiedData);
    };


  return (
    <>
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">View Transaction </p>
            <span className="hdspn1">Learn More</span>
          </div>

          <div className="">
            <div className="row p-3 bgcldiv1 mb-2 bdrtb1">
              <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
                <div className="d-flex justify-content-end">
                  <div>
                    {" "}
                    <p className="mgrt2">Find a transaction</p>
                  </div>
                  <form className="d-flex" style={{ height: "30px" }}>
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Enter Order Number"
                      aria-label="Search"
                    />
                    <button className="button1 mxhtsh m-0" type="submit">
                      Search
                    </button>{" "}
                    &nbsp;
                  </form>
                  <div>
                    {" "}
                    <p className="mgrt2"> 3 Order(s)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-3 ft13">
        <div className=" mb-1 p-2 pb-0">
          <div className="d-flex">
            <div>
              {" "}
              <p className="mgrt2">Filters:</p>
            </div>
            <div className="p-3 pt-0 pb-0">
              <label>Transaction Type :</label> &nbsp;
              <select className="selectoption">
                <option>All Transaction Type</option>
                <option>Order Payment</option>
                <option>Refund</option>
                <option>Cancelled</option>
              </select>
            </div>

            <div className="p-3 pt-0 pb-0">
              <label>Date :</label> &nbsp;
              <input
                type="date"
                className="selectoption"
                name=""
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bgcldiv1 p-3">
        <div className="card p-3">
          <div className="row">
            <div className="col-md-6 col-6 pt-2 pb-2  ">
              <div className="vhp1 pt-0">Transactions</div>
            </div>
            <div className="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
              <div className="vhp1 pt-0">
                <button className="button1"  disabled={payout.length === 0} onClick={downloadExcel}>Download </button>
              </div>
            </div>
          </div>

          <table className="table table-bordered table-striped ft13">
            <thead className="tbhd1">
              <tr>
                <td>Date</td>
                <td>Transaction&nbsp;type</td>
                <td>Order&nbsp;ID</td>
                <td>Product&nbsp;Details</td>
                <td>Total&nbsp;product&nbsp;charges</td>
                <td>Tej&nbsp;Bazar&nbsp;fees&nbsp;(-)</td>
                <td>Other&nbsp;(-)</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>

            {payout.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={8}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    payout.map((pyout) => (
						<tr key={`payout${pyout.ID}`}>
						<td>{pyout.TIME_STAMP}</td>
						<td>{pyout.PAYMENTMODE}</td>
						<td>{pyout.ORDER_ID}</td>
						<td>{pyout.PRODUCT_NAME}</td>
						<td>₹ {parseFloat(pyout.PAYOUT_AMOUNT) + parseFloat(pyout.PLATFORM_CHARGES) + parseFloat(pyout.GST_AMOUNT)}</td>
						<td >
                        ₹ {pyout.PLATFORM_CHARGES}
						</td>
						<td>
						₹ {pyout.GST_AMOUNT}
						</td>
						<td>{pyout.PAYOUT_AMOUNT ? parseFloat(pyout.PAYOUT_AMOUNT) : ""}</td>
					  </tr>
                    ))
                  )}
			  


              {/* <tr>
                <td>1/4/2023</td>
                <td>Order Payment</td>
                <td>407-4119871-5561111</td>
                <td>IPS Ultra Casserole Purple 2000 ml</td>
                <td>₹296.61</td>
                <td>₹6.24</td>
                <td>₹25.66</td>
                <td>₹296.66</td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Order Payment</td>
                <td>407-4119871-5561111</td>
                <td>IPS Ultra Casserole Purple 2000 ml</td>
                <td>₹296.61</td>
                <td>₹6.24</td>
                <td>₹25.66</td>
                <td>₹296.66</td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Order Payment</td>
                <td>407-4119871-5561111</td>
                <td>IPS Ultra Casserole Purple 2000 ml</td>
                <td>₹296.61</td>
                <td>₹6.24</td>
                <td>₹25.66</td>
                <td>₹296.66</td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Order Payment</td>
                <td>407-4119871-5561111</td>
                <td>IPS Ultra Casserole Purple 2000 ml</td>
                <td>₹296.61</td>
                <td>₹6.24</td>
                <td>₹25.66</td>
                <td>₹296.66</td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Order Payment</td>
                <td>407-4119871-5561111</td>
                <td>IPS Ultra Casserole Purple 2000 ml</td>
                <td>₹296.61</td>
                <td>₹6.24</td>
                <td>₹25.66</td>
                <td>₹296.66</td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Order Payment</td>
                <td>407-4119871-5561111</td>
                <td>IPS Ultra Casserole Purple 2000 ml</td>
                <td>₹296.61</td>
                <td>₹6.24</td>
                <td>₹25.66</td>
                <td>₹296.66</td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Order Payment</td>
                <td>407-4119871-5561111</td>
                <td>IPS Ultra Casserole Purple 2000 ml</td>
                <td>₹296.61</td>
                <td>₹6.24</td>
                <td>₹25.66</td>
                <td>₹296.66</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default Vdtransaction;
