import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from 'react-toastify';
import img1 from "../media/prod11.jpg";
import img2 from "../media/prod12.jpg";
import img3 from "../media/prod13.jpg";

import { Link, useLocation } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";

function Vdmanageinventory() {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    fetchData();
    console.log($("input[name=inlineRadioOptions]:checked").val());
    $("input[name=inlineRadioOptions]:first").prop("checked", true);
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      vendorId: $("#vendorId").val(),
      piType: pytype,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getinventoryDetails`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setListings(response.po_DETAIL);
    });
  };

  const handleChange = () => {
    console.log($("input[name=inlineRadioOptions]:checked").val());
    fetchData($("input[name=inlineRadioOptions]:checked").val());
  };

  const saveRowClick = (event) => {
    // Get the values of the clicked row
    const row = event.currentTarget.parentNode.parentNode;
    const mrp = row.cells[5].querySelector("input").value;
    const listingPrice = row.cells[4].querySelector("input").value;
    const stockQty = row.cells[3].querySelector("input").value;
    const skuId = row.cells[6].querySelector("input").value;
    const prodId = row.cells[7].querySelector("input").value;

    updateData(mrp, listingPrice, stockQty, skuId, prodId);
  };

  const updateData = (mrp, listingprice, stockqty, skuid, prodid) => {
    var valdata = {
      p_mrp: mrp,
      p_listingPrize: listingprice,
      p_stockQty: stockqty,
      p_skuid: skuid,
      p_prodid: prodid,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}updateinventoryData`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if(parseInt(response.po_STATUS) === 0){
        toast.error(response.po_MSG);
      }else if(parseInt(response.po_STATUS) === 1){
      toast.success(response.po_MSG);
      fetchData($("input[name=inlineRadioOptions]:checked").val());
      }
     
      
    });
  };

  return (
    <>
    	<ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Manage Inventory </p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2 py-2">Action on 0 Selected:</p>
                </div>
                <form class="d-flex" style={{ height: "30px" }}>
                  <input
                    class="form-control me-2 serch1"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button class="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>
                </form>
                

               

                <div>
                  {" "}
                  <p className="py-2 px-2"> {listings.length} product(s)</p>
                </div>
              </div>
            </div>

            <div class="card mb-1 p-2 pb-0 ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Filters:</p>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value=""
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    All
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="A"
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Active
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="I"
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Inactive
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio4"
                    value="D"
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio4">
                    Removed
                  </label>
                </div>
              </div>
            </div>
            <div className="table-responsive">
            <table class="table table-bordered ft13">
              <thead className="tbhd1">
                <tr>
                  {/* <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                    />
                  </td> */}
                  <td>Product Details</td>
                  <td>SKU</td>
                  <td>Listing Date</td>
                  <td>Available Stock</td>
                  <td>Listing Price</td>
                  <td>MRP</td>
                  <td>Category</td>
                  <td>Status</td>
                  <td>Action</td>
                  {/* <td>
                    <button class="btn btclr w-100">Save All</button>
                  </td> */}
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  
                  <td>
                    <div class="d-flex ">
                      <img src={img1} style={{ height: "40px" }} />
                      <p>ThriveCo Hair Grow & Capilia Longa & Women | 50ml</p>
                    </div>
                  </td>
                  <td>nelco_D15pc</td>
                  <td>20/09/2020 14:27:15</td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>Hair Style</td>
                  <td>Active</td>
                  <td>
                    <button class="btn btclr w-100">Save</button>
                  </td>
                </tr> */}

                {listings.map((listing) => (
                  <tr key={`listing${listing.ID}`}>
                    <td>
                      <div className="d-flex ">
                        <img
                          src={`${Baseurl.getImgUrl}prodImages/${listing.IMAGES_NAME_1}`}
                          style={{ height: "40px" }}
                        />
                        <p>{listing.PRODUCT_NAME}</p>
                      </div>
                    </td>
                    <td>{listing.SKU_ID}</td>

                    <td>{listing.TIME_STAMP}</td>
                    <td>
                      <input
                        type=""
                        name=""
                        defaultValue={listing.STOCK_QUANTITY}
                      />
                    </td>
                    <td>
                      <input
                        type=""
                        name=""
                        defaultValue={listing.CURRENT_AMOUNT}
                      />
                    </td>

                    <td>
                      <input type="" name="" defaultValue={listing.MRP} />
                    </td>
                    <td className="d-none">
                      <input type="" name="" value={listing.SKU_ID} />
                    </td>
                    <td className="d-none">
                      <input type="" name="" value={listing.ID} />
                    </td>

                    <td>{listing.SUB_CATEGORY_NAME}</td>

                    <td>
                      {listing.PI_STATUS == "A"
                        ? "Active"
                        : listing.PI_STATUS == "D"
                        ? "Removed"
                        : "Inactive"}
                    </td>
                    <td>
                      <button class="btnvd add-cart-btn bdrradius1" onClick={saveRowClick}>
                        Save
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdmanageinventory;
