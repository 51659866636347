import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";

import { Link, useLocation } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";
import { toast } from "react-toastify";

function Vdmanageorder() {

  const [listings, setListings] = useState([]);

  useEffect(() => {
    fetchData();
    console.log($('input[name=inlineRadioOptions]:checked').val())
    $('input[name=inlineRadioOptions]:first').prop('checked', true);
  }, []);


  
  const fetchData = (pytype) => {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
      filterBy : pytype
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchVendororderDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setListings(response.po_DETAIL);
    });
  };


  const updateOrderSts = (sts, rowId, orderId) => {
    var valdata = {
      statusName: sts,
      rowId : rowId,
      orderId : orderId,
      userId : localStorage.getItem("userId")
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}updateOrderSts`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if(parseInt(response.po_STATUS) === 1){
toast.success("Status Updated.");
fetchData("");
      }else{
        toast.error("Failed To Update Status.");
      }
    });
  };

  
  const handleChange = () => {
    console.log($('input[name=inlineRadioOptions]:checked').val())
    fetchData($('input[name=inlineRadioOptions]:checked').val());
      };
	
  return (
    <>
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">Manage Orders </p>
            <span className="hdspn1">Learn More</span>
          </div>

          <div>
            <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2 py-2">Action on 0 Selected:</p>
                </div>
                <form className="d-flex" style={{ height: "30px" }}>
                  <input
                    className="form-control me-2 serch1"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="py-2 px-2"> {listings.length} Order(s)</p>
                </div>
              </div>
            </div>

            <div className="card mb-1 p-2 pb-0 ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Filters:</p>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value=""
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    All
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="'PENDING', 'ACTIVE'"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Active
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="'DELIVERED'"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    Completed
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio4"
                    value="'CANCELED'"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio4">
                    Cancelled
                  </label>
                </div>
              </div>
            </div>

            <div className="table-responsive">
            <table className="table table-bordered ft13">
              <thead className="tbhd1">
                <tr>
                  <td>Product Details</td>
                  <td>SKU</td>
                  <td>Order Date</td>
                  <td>Expected Delivery Date</td>
                  <td>Price & Shipping Cost</td>
                  <td>Payment Type</td>
                  <td>Address</td>
                  <td>Invoice</td>
                  {/* <td>More Details</td> */}
                  <td>Status</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>

              {listings.map(listing =>  (
          <tr  key={`listing${listing.ID}`}>
          
         
          <td>
                    <div className="d-flex ">
                      <img src={`${Baseurl.getImgUrl}prodImages/${listing.IMAGES_NAME_1}`} style={{ height: "40px" }} />
                      <p>{listing.PRODUCT_NAME}</p>
                    </div>
                  </td>

          <td>{listing.SKU_ID}</td>
          <td>{listing.ORDER_DATE}</td>
          <td>{listing.EXPECTED_DELIVERY_DATE}</td>
          <td>&#8377;{listing.PRICE} + &#8377;{listing.DELIVERY_CHARGE}</td>
          <td>{listing.PAYMENT_TYPE}</td>
          <td>{listing.LOCALITY} , {listing.PI_ADDRESS}</td>
          <td>
                    <Link to="">Print Invoice</Link>
                  </td>
                  {/* <td>
                    <Link to="">See Now</Link>
                  </td> */}
                  <td>{listing.ORDER_STATUS}</td>
                  <td>
                    {listing.ORDER_STATUS === 'PENDING' ? <button className="btnbto " onClick={() => updateOrderSts("ACTIVE", listing.ID, listing.ORDER_ID)}>Order Ready</button>  : (listing.ORDER_STATUS === 'ACTIVE' ? <button className="btnbto " onClick={() => updateOrderSts("DELIVERED", listing.ID, listing.ORDER_ID)}>Order Completed</button> : "") }
                  </td>
        </tr>
			))}


                
              </tbody>
            </table>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdmanageorder;
