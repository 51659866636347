import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';

import { FaFile } from 'react-icons/fa'
import queryString from "query-string";

function Vendordetails() {
  const { pathname } = useLocation();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const userId = queryParams.getfilter;

  const [listings, setListings] = useState([]);
  useEffect(() => {
    fetchData();
    fetchOderDtls();
  }, []);

  const fetchData = () => {
    var valdata = {
      filterBy: userId,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchVendordtlsAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        $("#_userName").text(response.po_DETAIL[0].NAME);
        $("#_mobNo").text(response.po_DETAIL[0].PHONE_NUMBER ? response.po_DETAIL[0].PHONE_NUMBER : "NA");
        $("#_address").text(response.po_DETAIL[0].ADDRESS ? response.po_DETAIL[0].ADDRESS : "NA");
        $("#_city").text(response.po_DETAIL[0].CITY ? response.po_DETAIL[0].CITY : "NA");
        $("#_state").text(response.po_DETAIL[0].STATE_NAME ? response.po_DETAIL[0].STATE_NAME : "NA");
        $("#_rDate").text(response.po_DETAIL[0].TIME_STAMP ? response.po_DETAIL[0].TIME_STAMP : "NA");
        $("#_storeName").text(response.po_DETAIL[0].STORENAME ? response.po_DETAIL[0].STORENAME : "NA");
        $("#_email").text(response.po_DETAIL[0].EMAIL_ID ? response.po_DETAIL[0].EMAIL_ID : "NA");

        $("#_pan").text(response.po_DETAIL[0].PANNO ? response.po_DETAIL[0].PANNO : "NA");
        $("#_gst").text(response.po_DETAIL[0].GSTNO ? response.po_DETAIL[0].GSTNO : "NA");
        
        $("#_asinn").text(response.po_DETAIL[0].ASINNO ? response.po_DETAIL[0].ASINNO : "NA");
      } else {
        
      }
    });
  };

  const fetchOderDtls = () => {
    var valdata = {
      vendorId: userId,
      filterBy : ""
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchVendororderDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setListings(response.po_DETAIL);
    });
  };


  return (
    <>

<section className="mt-4">
          <div className="container-fluid">

          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
          <p class="vhp3">Vendor Detail(s)</p>
        </div>

        <section className="seccl1 p-3">
        <div className="container py-4  hyeus1 ">
        <div className="row">

        <div className="vhp1 seccl1 my-2 p-3">
        Vendor Detail
        </div>

             <div className="col-md-6">
                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">Vendor Name </b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_userName"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">Phone </b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_mobNo"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">Email </b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_email"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">Address </b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_address"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">City</b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_city"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">State</b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_state"></span></p>
                     </div>
                 </div>
                 
                 
             </div>

             <div className="col-md-6">
                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">Store Name</b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_storeName"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">GST Number</b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_gst"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">PAN Number</b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_pan"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6">
                     <b className="crtitmhd">ASIN</b>
                     </div>
                     <div className="col-6">
                     <p>: <span id="_asinn"></span></p>
                     </div>
                 </div>

                 
                 
                 
             </div>
             
            </div>
        </div>
        </section>

        <div class="row">
              <div class="col-md-6 col-6 pt-2 pb-2  ">
                <div class="vhp1 pt-0">Order Detail(s)</div>
              </div>
              <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">

              </div>
            </div>

        <div class="hyeus1 mt-2 p-2 tblht1">
              <table class="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>Vendor ID</td>
                    <td>Order ID</td>
                    <td>Phone</td>
                    <td>Product</td>
                    <td>Amount</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {listings.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    listings.map((orderlts) => (
                      <tr key={`ord${orderlts.ID}`}>
                        <td>{orderlts.VENDOR_ID}</td>
                        <td>{orderlts.ORDERIDNINE}</td>
                        <td>{orderlts.PHONENO}</td>
                        <td>{orderlts.PRODUCT_NAME}</td>
                        <td>{orderlts.PRICE}</td>
                        <td>{orderlts.ORDER_STATUS}</td>
                        
                      </tr>
                    ))
                  )}
                  
                  
                </tbody>
              </table>
            </div>



            
          </div>
        </section>

    
    </>
	
  )
}

export default Vendordetails
