import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import logo from "../media/logo6.png";

import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Adlogin() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/Adlogin') {
      const userId = localStorage.getItem('addminId');
      if (userId) {
        navigate("/Adhome");
      }
    }
  }, [location, navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const validateEmail = (inputEmail) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(inputEmail);
  };

  const validatePassword = (inputPassword) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;
    return passwordPattern.test(inputPassword);
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
  };

  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    setPassword(inputPassword);
    setIsValidPassword(validatePassword(inputPassword));
  };
  

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const isFormValid = isValidEmail && isValidPassword;
    if (isFormValid) {
      getlogin();
    } else {
      toast.error("Form is invalid. Please check your inputs.");
    }
  };


  const getlogin = () => {
    var valdata = {
      emailid: $("#adloginmail").val().trim(),
      _passwrdvd : $("#adloginpw").val().trim()
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}adminLoginchk`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        toast.success(response.po_MSG);
       
        $("#adloginmail").val('');
        $("#adloginpw").val('');
        localStorage.setItem("Addauthenticated", true);
        localStorage.setItem("addminId", response.po_DETAIL[0].USERID);
        localStorage.setItem("addminMOBILENO", response.po_DETAIL[0].MOBILENO);
        navigate("/Adhome");
      } else {
        toast.error(response.po_MSG);
      }
    });
  };


  return (
    <>
      <ToastContainer />
      <nav className="navbar navbar-expand-lg navbar-light navvbgcl1">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/Adhome">
            <img src={logo} style={{ height: "40px" }} alt="Logo" />
          </Link>
        </div>
      </nav>

      <section className="bgsec1 p-3">
        <div className="container">
          <div className="row  mt-3 mb-3 d-flex justify-content-center">
            <div className="col-md-6">
              <div className="card p-3 ">
                <form>
                  <div className="row">
                    <div className="col-6">
                      <p className="crtitmhd">Admin Login</p>
                    </div>
                  </div>
                  <hr />

                  <div className="row g-3">
                    <div className="col-sm-12">
                      <label htmlFor="adloginmail" className="form-label chkf1">
                        Email Id
                      </label>
                      <input
                        type="text"
                        className={`form-control inpt1 ${!isValidEmail ? 'is-invalid' : ''}`}
                        id="adloginmail"
                        placeholder="Enter Email Id"
                        required
                        name="email" 
                        value={email}
                        autoComplete="off"
                        onChange={handleEmailChange}
                      />
                      {!isValidEmail && (
                        <div className="invalid-feedback">
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12">
                      <label htmlFor="adloginpw" className="form-label chkf1">
                        Password
                      </label>
                      <input
                        type="password"
                        className={`form-control inpt1 ${!isValidPassword ? 'is-invalid' : ''}`}
                        id="adloginpw"
                        placeholder="Enter Password"
                        required
                        name="password"
                        value={password}
                        autoComplete="off"
                        onChange={handlePasswordChange}
                      />
                      {!isValidPassword && (
                        <div className="invalid-feedback">
                          Password should have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 special character, and at most 25 characters.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="pt-3 col-md-12">
                    <button
                      className={`btnvd add-cart-btn ${!isValidEmail || !isValidPassword ? 'disabled' : ''}`}
                      disabled={!isValidEmail || !isValidPassword}
                      onClick={handleFormSubmit}
                    >
                      Login Now
                    </button>
                  </div>

                  <div className="col-6 d-flex justify-content-start mt-3">
                    <p>
                      <a href="" className="share-spn">
                        Forgot Password{" "}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adlogin;
