import React, { useEffect, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

function Counter(props) {
    const [count, setCount] = useState(parseInt(props.qty));
  const max = props.max;
  const min = props.min;

  const handleChange = (e) => {
    console.log(parseInt(e.target.value));

  }

  return (
    <div>
<button class="btn btn-link px-2 btnbd1" 
disabled={count === min}
 onClick={(event) =>{
                count <= min ? setCount(min) : setCount(count - 1);
                props.sendDataToParent(count - 1, props.prodId, props.rowId);
              }}>
						                       <FaMinus />
						                      </button>
                                              <span className="a-button-inner ">
                          <input
                            type="number"
                            className="inptnb a-button-inner"
                            placeholder="Qty:"
                            name=""
                            value={count} onChange={handleChange}
                          />
                          <i className="a-icon a-icon-dropdown"></i>
                        </span>
        <button class="btn btn-link px-2 btnbd1" disabled={count === max}
        onClick={(event) =>{
                count >= max ? setCount(max) : setCount(count + 1);
                props.sendDataToParent(count + 1,props.prodId, props.rowId);
              } }>
						                        <FaPlus />
						                      </button>
    </div>
  )
}

export default Counter