import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from "react-toastify";



import logo from "../media/logo6.png";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Sellerheader() {


    const navigate = useNavigate();
    const location = useLocation();
  
    const [mobileNumber, setMobileNumber] = useState('');
    const [storePassword, setStorePassword] = useState('');
    const [isValidMobile, setIsValidMobile] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
  
    useEffect(() => {
      if (location.pathname === '/Seller') {
        const userId = localStorage.getItem('vendorId');
        if (userId) {
          navigate("/Vdhome");
        }
      }
    }, [location, navigate]);
  
    function handleMobileNumberChange(event) {
      const inputMobileNumber = event.target.value;
      setMobileNumber(inputMobileNumber);
  
      setIsValidMobile(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputMobileNumber));
    }
  
    function handlePasswordChange(event) {
        const inputPassword = event.target.value;
        setStorePassword(inputPassword);
    
        setIsValidPassword(inputPassword.length >= 7);
      }

    const handleLoginButtonClick = (event) => {
        event.preventDefault();
    
        if (
          mobileNumber === "" ||
          storePassword === ""
        ) {
          toast.error("All fields are required.");
        } else if (
          !isValidMobile ||
          !isValidPassword
        ) {
          toast.error("Please fill all fields correctly.");
        } else {
          getlogin();
     
        }
      };
    


      const getlogin = () => {
        var valdata = {
          mobileno: $("#vdloginphn").val().trim(),
          _passwrdvd : $("#vdloginpw").val().trim()
        };
    
        const settings = {
          async: true,
          crossDomain: true,
          url: `${Baseurl.baseUrl}vendorLoginchk`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(valdata),
        };
    
        $.ajax(settings).done(function (response) {
          if (parseInt(response.po_STATUS) === 1) {
            toast.success(response.po_MSG);
            document.getElementById('_close').click();
            $("#_mobileno").val('');
            $("#_emailAddrs").val('');
            localStorage.setItem("authenticated", true);
            localStorage.setItem("vendorId", response.po_DETAIL[0].USERID);
            localStorage.setItem("MOBILENO", response.po_DETAIL[0].MOBILENO);
            navigate("/Vdhome");
          } else {
            toast.error(response.po_MSG);
          }
        });
      };


  return (
    <>

<section>
<div className="container-fluid " >
 <div className="container crdbdrnone p-3" style={{backgroundColor:"#fff" , borderRadius:"4px"}}>
  <div className="row">
   <div className="col-md-6 col-4">
   <Link to="/" className="logo1">
                  <img src={logo} style={{ height: "40px" }} />
                  </Link>
   </div>
   <div className="col-md-6 col-8 d-flex justify-content-end">
   <form className="d-flex">
   {/* <div> <Link to="/Sellerregister" className="sellerbtn1">Start Selling</Link></div> */}
   <div> <Link to="" className="sellerbtn2" data-bs-toggle="modal" data-bs-target="#sellerlogin">Login</Link></div>
   </form>
   </div>
  </div>
 </div>
</div>
</section>


<div
        class="modal fade"
        id="sellerlogin"
        tabIndex="-1"
        aria-labelledby="sellerlogin"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header m-0 w-100">
              <h5 class="" id="sellerlogin">
                Log In
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="_close"
              ></button>
            </div>
            <div className="modal-body">
        <form>
          <div className="col-md-12">
            <div className="form-floating mb-2">
              <input
                type="text"
                className={`form-control ${!isValidMobile ? 'is-invalid' : ''}`}
                id="vdloginphn"
                maxLength={50}
                placeholder="9878657895"
                required
                value={mobileNumber}
                onChange={handleMobileNumberChange}
              />
              <label htmlFor="floatingInput">Enter Email*</label>
              {!isValidMobile && (
                <div className="invalid-feedback">
                  Please enter a valid Email.
                </div>
              )}
            </div>

            <div className="form-floating mb-2">
              <input
                type="password"
                className={`form-control ${!isValidPassword ? 'is-invalid' : ''}`}
                id="vdloginpw"
                placeholder=""
                required
                value={storePassword}
                onChange={handlePasswordChange}
              />
              <label htmlFor="floatingInput">Enter Password</label>
              {!isValidPassword && (
                <div className="invalid-feedback">
                  Please enter a valid password (minimum 7 characters).
                </div>
              )}
            </div>
          </div>
          <div className="row d-flex mt-4">
            <div className="col-md-12 text-end my-3">
              <button
                type="button"
                className="btnvd add-cart-btn"
                onClick={handleLoginButtonClick}
              >
                Login Now
              </button>
            </div>
          </div>
        </form>
      </div>
          </div>
        </div>
      </div>




    
    </>
	
  )
}

export default Sellerheader
