import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";

import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaFacebook, FaShare, FaTrash } from "react-icons/fa";

function Wishlist() {
  const [wishlistval, setWishlistval] = useState({
    resp: [],
    resplength: 0,
  });
  useEffect(() => {
    getWishlistData();
  }, []);

  const handleClick = (ID, prodId) => {
    var valdata = {
      userId: $("#userId").val(),
      prodId: prodId,
      wishListId: ID,
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}removeWishlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (response.po_STATUS == 1) {
        // toast.success("Removed Product From WishList");
        getWishlistData();
      } else {
        // toast.success("Removed Product From WishList");
      }
    });
  };

  function getWishlistData() {
    var valdata = {
      userId: $("#userId").val(),
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getWishlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setWishlistval({
        resp: response,
        resplength: response.length,
      });
    });
  }

  const moveCart = (prodId, Id) => {
    var valdata = {
      userId: $("#userId").val(),
      prodId: prodId,
      quantity: 1,
      typeW: "WL",
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addCartlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      console.log();
      if (
        parseInt(response.po_STATUS) === 1 &&
        parseInt(response.po_DETAIL[0].INDEX) === 1
      ) {
        //alert("Product Successfully Added To The Cart.");
        //getWishlistData();
        handleClick(Id, prodId);
      } else {
        if (![null, undefined, "null", "undefined"].includes(response.po_MSG)) {
          toast.success(response.po_MSG);
        } else {
          toast.error("Failed To Add Product In The Cart.");
        }
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <section className="bgcrt1">
        <div className="container p-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-9 col-lg-9">
              <div className="card" style={{ border: "0px" }}>
                <div className="bdbt-1">
                  <h3 className="p-3 pt-2 pb-1 text-capitalize">
                    Your Wishlist ({wishlistval.resplength})
                  </h3>
                </div>

                <div className=" m-3 mb-2 ">
                  {wishlistval.resp.map((wishv) => (
                    <div
                      className="card bdr-0 p-1"
                      style={{ border: "0px" }}
                      key={`wishlst${wishv.ID}`}
                    >
                      <div className="row align-items-start py-2 crdhvr2">
                        <div className="col-md-2 col-3 col-lg-2">
                          <img
                            src={`${Baseurl.getImgUrl}prodImages/${wishv.IMAGES_NAME_1}`}
                            className="wid-100 img-fluid omhj"
                          />
                        </div>
                        <div className="col-md-8 col-9 col-lg-8 bdbt-1">
                          <Link className="text-decoration-none text-bg-info "
                            to={`/Product?catId=${wishv.CATEGORY_ID}&productId=${wishv.PRODUCT_ID}&q=search&requestType=product&subCategory=${wishv.SUB_CATEGORY_ID}`}
                          >
                            <h1 className="crtitmhd2 hyas">{wishv.PRODUCT_NAME}</h1>
                          </Link>

                          <p className="order-prc">
                            &#8377;{parseInt(wishv.CURRENT_AMOUNT).toLocaleString('en-IN')}
                          </p>

                          <div>
                            {/* <span
                              className="aclass acljs1"
                              onClick={() =>
                                handleClick(wishv.ID, wishv.PRODUCT_ID)
                              }
                            >
                              Remove From Wishlist
                            </span> */}

                            {/* <span
                              className="aclass bdrt1 acljs1"
                              onClick={() =>
                                moveCart(wishv.PRODUCT_ID, wishv.ID)
                              }
                            >
                              Add To Cart
                            </span> */}
                            {/* <Link
                              to={`/Checkout?requestType=singleProduct&productDtls=${wishv.PRODUCT_ID}&prodqty=1`}
                              className="td-0"
                            >
                              <span className="aclass acljs1">Buy Now</span>
                            </Link> */}
                          </div>
                        </div>
                        <div className="col-md-2 col-lg-2">
                          <div className="col-md-12 text-end">
                            <span className="share-spn" onClick={() =>
                                handleClick(wishv.ID, wishv.PRODUCT_ID)
                              }>
                              <FaTrash />
                              
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Wishlist;
