import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";

function Mobfilter(props) {
  const subCategoryId = props.subCategoryId;
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  /////color iscolor
  /////

  const [brand, setBrand] = useState([]);

  const [category, setCategory] = useState([]);
  const [color, setColor] = useState([]);
  const [displaySize, setDisplaySize] = useState([]);
  const [modelYear, setModelYear] = useState([]);
  const [ram, setRam] = useState([]);
  const [size, setSize] = useState([]);
  const [volume, setVolume] = useState([]);
  const [producttype, setProductType] = useState([]);

  useEffect(() => {
    loadFilter();
  }, []);

  const loadFilter = () => {
    var valdata = {
      subCatId: subCategoryId,
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAllfilterComponent`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setBrand(response.po_DATA.Brand_Name);
      setColor(response.po_DATA.Color);
      setDisplaySize(response.po_DATA.DisplaySize);
      setModelYear(response.po_DATA.ModelYear);
      setRam(response.po_DATA.Ram);
      setSize(response.po_DATA.Size);
      setCategory(response.po_DATA.SubCateGoryName);
      setVolume(response.po_DATA.Volume);
      setProductType(response.po_DATA.Gender);
    });
  };
/////////////////


const handleCheckboxChange = (event) => {
  const { name, value, checked } = event.target;

  if (name === 'priceCheck') {
    // Handle price checkboxes separately
    if (checked) {
      setSelectedCheckbox(value);
      // Call onFilterSelection with the selected price range
      props.onFilterSelection([{ name, value }]);
    } else {
      setSelectedCheckbox(null);
      // Call onFilterSelection with an empty array to indicate deselection
      props.onFilterSelection([]);
    }
  } else {
    // Handle other checkboxes
    const selectedData = [...props.selectedFilters];

    if (checked) {
      selectedData.push({ name, value });
    } else {
      const indexToRemove = selectedData.findIndex(
        (item) => item.name === name && item.value === value
      );
      if (indexToRemove !== -1) {
        selectedData.splice(indexToRemove, 1);
      }
    }

    props.onFilterSelection(selectedData);
  }
};


const setpriceValue = (e) => {
  const checkboxId = e.target.id;
  if (selectedCheckbox === checkboxId) {
    setSelectedCheckbox(null);
  } else {
    setSelectedCheckbox(checkboxId);
  }
};

const handlereset = () => {
  window.location.reload();
};

  return (
    <>
    

      <div className="col-md-2 ">
        <div className="hyeus1" style={{ width: '100%' }}s>
          <div className="p-2">Filter</div>
          <hr className="m-0" />
          {brand && brand.length > 0 ? (
            <div className="p-2">
              <div className="nhsx1">Brand</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {brand && brand.length > 0
              ? brand.map((brd, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={brd.Brand_Name}
                      id={`brand${index}`}
                      name={`brandCheck`}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label hstd1" for={`brand${index}`}>
                      {brd.Brand_Name}
                    </label>
                  </div>
                ))
              : ""}
          </div>
          {category && category.length > 0 ? (
            <div className="p-2">
              <div className="nhsx1">Category</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {category && category.length > 0
              ? category.map((catid, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id={`category${index}`}
                      name={`categoryCheck`}
                      checked
                    />
                    <label
                      class="form-check-label hstd1"
                      for={`category${index}`}
                    >
                      {catid.SubCateGoryName}
                    </label>
                  </div>
                ))
              : ""}
          </div>

          <div className="p-2">
            <div className="nhsx1">Price</div>
          </div>
          <div className="mx-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="0-1000"
                id="price1"
                name='priceCheck'
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setpriceValue(e);
                }}
                checked={selectedCheckbox === 'price1'}
              />
              <label class="form-check-label hstd1" for="price1">
                Under &#x20b9;1,000
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="1000-5000"
                id="price2"
                name='priceCheck'
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setpriceValue(e);
                }}
                
                checked={selectedCheckbox === 'price2'}
              />
              <label class="form-check-label hstd1" for="price2">
              &#x20b9;1,000 to &#x20b9;5,000
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="5000-10000"
                id="price3"
                name='priceCheck'
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setpriceValue(e);
                }}
                checked={selectedCheckbox === 'price3'}
              />
              <label class="form-check-label hstd1" for="price3">
              &#x20b9;5,000 to &#x20b9;10,000
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="10000-15000"
                id="price4"
                name='priceCheck'
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setpriceValue(e);
                }}
                checked={selectedCheckbox === 'price4'}
              />
              <label class="form-check-label hstd1" for="price4">
              &#x20b9;10,000 to &#x20b9;15,000
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="15000-25000"
                id="price5"
                name='priceCheck'
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setpriceValue(e);
                }}
                checked={selectedCheckbox === 'price5'}
              />
              <label class="form-check-label hstd1" for="price5">
              &#x20b9;15,000 to &#x20b9;25,000
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="25000-35000"
                id="price6"
                name='priceCheck'
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setpriceValue(e);
                }}
                checked={selectedCheckbox === 'price6'}
              />
              <label class="form-check-label hstd1" for="price6">
              &#x20b9;25,000 to &#x20b9;35,000
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="35000-1000000"
                id="price7"
                name='priceCheck'
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setpriceValue(e);
                }}
                checked={selectedCheckbox === 'price7'}
              />
              <label class="form-check-label hstd1" for="price7">
              Over &#x20b9;35,000 
              </label>
            </div>
          </div>

          

          {producttype && producttype.length > 0 ? (
            <div className="p-2">
            <div className="nhsx1">Product Type For</div>
          </div>
          ) : (
            ""
          )}

          <div className="mx-2">
          {producttype && producttype.length > 0
              ? producttype.map((prodtype, index) => (
                <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value={prodtype.Gender}
                  id={`prodType${index}`}
                  name='genderCheck'
                  onChange={handleCheckboxChange}
                />
                <label class="form-check-label hstd1" for={`prodType${index}`}>
                  {prodtype.Gender}
                </label>
              </div>
                ))
              : ""}
            


          </div>




          {color && color.length > 0 ? (
            <div className="p-2">
              <div className="nhsx1">Color</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {color && color.length > 0
              ? color.map((clr, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={clr.Color}
                      id={`color${index}`}
                      name={`colorCheck`}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label hstd1" for={`color${index}`}>
                      {clr.Color}
                    </label>
                  </div>
                ))
              : ""}
          </div>

          <div className="p-2">
            <div className="nhsx1">Availibility</div>
          </div>
          <div className="mx-2">
            {/* <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label hstd1" for="flexCheckDefault">
                Instock
              </label>
            </div> */}

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="outofstock"
                name='outofstock'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="outofstock">
                Out Of Stock
              </label>
            </div>
          </div>

          <div className="p-2">
            <div className="nhsx1">Discount</div>
          </div>
          <div className="mx-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="5"
                id="discount1"
                name='discount'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="discount1">
                Upto 5%
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="10"
                id="discount2"
                name='discount'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="discount2">
                Upto 10%
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="10"
                id="discount3"
                name='discount'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="discount3">
                Upto 20%
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="10"
                id="discount4"
                name='discount'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="discount4">
                Upto 30%
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="10"
                id="discount5"
                name='discount'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="discount5">
                Upto 40%
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="10"
                id="discount6"
                name='discount'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="discount6">
                Upto 50%
              </label>
            </div>


          </div>
          {size && size.length > 0 ? (
            <div className="p-2">
              <div className="nhsx1">Size</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {size && size.length > 0
              ? size.map((sz, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={sz.Size}
                      id={`size${index}`}
                      name={`sizeCheck`}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label hstd1" for={`size${index}`}>
                      {sz.Size}
                    </label>
                  </div>
                ))
              : ""}
          </div>
          {ram && ram.length > 0 ? (
            <div className="p-2">
              <div className="nhsx1">RAM</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {ram && ram.length > 0
              ? ram.map((ram, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={ram.Ram}
                      id={`ram${index}`}
                      name={`ramCheck`}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label hstd1" for={`ram${index}`}>
                      {ram.Ram}
                    </label>
                  </div>
                ))
              : ""}
          </div>
          {volume && volume.length > 0 ? (
            <div className="p-2">
              <div className="nhsx1">Volume</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {volume && volume.length > 0
              ? volume.map((vlm, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={vlm.Volume}
                      id={`volume${index}`}
                      name={`volumeCheck`}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      class="form-check-label hstd1"
                      for={`volume${index}`}
                    >
                      {vlm.Volume}
                    </label>
                  </div>
                ))
              : ""}
          </div>
          {displaySize && displaySize.length > 0 ? (
            <div className="p-2">
              <div className="nhsx1">Display Size</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {displaySize && displaySize.length > 0
              ? displaySize.map((dpsize, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={dpsize.DisplaySize}
                      id={`displaySize${index}`}
                      name={`displaySizeCheck`}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      class="form-check-label hstd1"
                      for={`displaySize${index}`}
                    >
                      {dpsize.DisplaySize}
                    </label>
                  </div>
                ))
              : ""}
          </div>
          {modelYear && modelYear.length > 0 && modelYear !== "null" ? (
            <div className="p-2">
              <div className="nhsx1">Year Model</div>
            </div>
          ) : (
            ""
          )}

          <div className="mx-2">
            {modelYear && modelYear.length > 0 && modelYear !== "null"
              ? modelYear.map((mdlyr, index) => (
                  <div class="form-check" key={index}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={mdlyr.ModelYear}
                      id={`modelYear${index}`}
                      name={`modelYearCheck`}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      class="form-check-label hstd1"
                      for={`modelYear${index}`}
                    >
                      {mdlyr.ModelYear}
                    </label>
                  </div>
                ))
              : ""}
          </div>

          <div className="p-2">
            <div className="nhsx1">Customer Rating</div>
          </div>
          <div className="mx-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="star1"
                name='stars'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="star1">
                ★
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="2"
                id="star2"
                name='stars'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="star2">
                ★★
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="3"
                id="star3"
                name='stars'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="star3">
                ★★★
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="4"
                id="star4"
                name='stars'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="star4">
                ★★★★
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="5"
                id="star5"
                name='stars'
                onChange={handleCheckboxChange}
              />
              <label class="form-check-label hstd1" for="star5">
                ★★★★★
              </label>
            </div>


            <div className="d-lg-none">
              <div className="row">
                <div className="col-6"><button className="btnvd add-cart-btn" onClick={handlereset}>Reset</button></div>
                <div className="col-6"><button className="btnvd add-cart-btn"  data-bs-dismiss="modal" aria-label="Close">Apply</button></div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mobfilter;
