import React from 'react'
import visa from "../media/visa.png"
import net from "../media/net banking.png"
import cash from "../media/cash.jpg"
import ameri from "../media/american.png"
import ftimgpay from "../media/ftimgpay.webp"
import "./Footer.css"
import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaMailBulk, FaPinterest, FaTwitter, FaYoutube } from 'react-icons/fa'


function Footer() {
  return (
    <>
    <section className="ftbg1 pb-1">
    <div className="container d-none d-sm-block">
		<div className="row pd50 pdt30">
			<div className="col-md-3 col-lg-3 col-xl-3">
					<ul className="lst-01">
						<li><h6 className="ts-std70">TEJ BAZAR</h6></li>
						<li><Link to="" className="tx-hvr1">About Us</Link></li>
						<li><Link to="" className="tx-hvr1">Contact Us</Link></li>
						<li><Link to="" className="tx-hvr1">Our Team</Link></li>
						<li><Link to="" className="tx-hvr1">Sitemap</Link></li>
						<li><Link to="" className="tx-hvr1">Press</Link></li>
						<li><Link to="" className="tx-hvr1">Compliance</Link></li>
					</ul>
			</div>

			<div className="col-md-3 col-lg-3 col-xl-3">
					<ul className="lst-01">
						<li><h6 className="ts-std70">TEJ BAZAR BUSINESS</h6></li>
						<li><Link to="/Seller" className="tx-hvr1">Sell On Tej-Bazar</Link></li>
						<li><Link to="/Adlogin" className="tx-hvr1">Admin Login</Link></li>
					</ul>
			</div>

			<div className="col-md-3 col-lg-3 col-xl-3">
					<ul className="lst-01">
						<li><h6 className="ts-std70">TEZBAZAR POLICY INFO</h6></li>
						<li><Link to="/Privacy" className="tx-hvr1">Privacy Policy</Link></li>
						<li><Link to="/Terms" className="tx-hvr1">Terms Of Use</Link></li>
						<li><Link to="" className="tx-hvr1">Return &#38; Cancellation Policy </Link></li>
					</ul>
			</div>

			<div className="col-md-3 col-lg-3 col-xl-3">
					<ul className="lst-01">
						<li><h6 className="ts-std70">NEED HELP?</h6></li>
						<li><Link to="" className="tx-hvr1">FAQ's</Link></li>
						<li><Link to="" className="tx-hvr1">Contact Us</Link></li>
					</ul>
			</div>
		</div>
		<hr/>

    <div className="row">
			<div className="col-md-6 col-lg-6 col-xl-6 d-flex flex-row crtaln">
				<h6 className="ts-std70">PAYMENT</h6>
        <ul className="lst-02 d-flex flex-row">
						<li><img className="img-fluid" src={ftimgpay} alt="" /></li>

					</ul>
			</div>
			<div className="col-md-6 col-lg-6 col-xl-6 d-flex flex-row crtaln">
				<h6 className="ts-std70">CONNECT</h6>
        <ul className="lst-02 d-flex flex-row">
        <li className="soclft"><Link to="" className="tx-hvr1"><FaFacebook size={20} style={{color: "#4267B2"}}/></Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"><FaGoogle size={20} style={{color: "#DB4437"}}/></Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"><FaInstagram size={20} style={{color: "#E1306C"}}/></Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaLinkedin size={20} style={{color: "#4267B2"}}/> </Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaPinterest size={20} style={{color: "#E60023"}}/> </Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaTwitter size={20} style={{color: "#1DA1F2"}}/> </Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaYoutube size={20} style={{color: "#FF0000"}}/> </Link></li>

					</ul>
			</div>
		</div>
		<div>
			<p className="crfttxt">Copyright &#169; 2023 TejBazar. All Rights Reserved. Powered By Motherwit Digital Solutions.</p>
		</div>
	</div>
  </section>



<section>
	<div className="container-fluid mobile-ban">
		<div className="row stcl1 pdt30 pb-5">

        <div className=" d-flex flex-row crtaln">
				<h6 className="txtmb1">CONNECT</h6>
        <ul className="lst-02 d-flex flex-row">
        <li className="soclft"><Link to="" className="tx-hvr1"><FaFacebook size={20} style={{color: "#4267B2"}}/></Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"><FaGoogle size={20} style={{color: "#DB4437"}}/></Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"><FaInstagram size={20} style={{color: "#E1306C"}}/></Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaLinkedin size={20} style={{color: "#4267B2"}}/> </Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaPinterest size={20} style={{color: "#E60023"}}/> </Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaTwitter size={20} style={{color: "#1DA1F2"}}/> </Link></li>
      <li className="soclft"><Link to="" className="tx-hvr1"> <FaYoutube size={20} style={{color: "#FF0000"}}/> </Link></li>

					</ul>
			</div>
        <div>
        <ul className="lst-02 algbase p-0">
        <h6 className="txtmb1 ts-stdst">Useful Links</h6>
          <p>
            <Link to="" className="tx-hvr1">About Us / </Link>
            <Link to="" className="tx-hvr1">Our Team / </Link>
            <Link to="" className="tx-hvr1">Press / </Link>
            <Link to="" className="tx-hvr1">Sitemap / </Link>
            <Link to="" className="tx-hvr1">Compliance / </Link>
            <Link to="" className="tx-hvr1">Sell On TezBazar / </Link>
            <Link to="" className="tx-hvr1">Privacy Policy / </Link>
            <Link to="" className="tx-hvr1">Terms Of Use / </Link>
            <Link to="" className="tx-hvr1">Return &#38; Cancellation Policy / </Link>
            <Link to="" className="tx-hvr1">Contact Us </Link>

          </p>
		</ul>
        <ul className="lst-02 algbase p-0">
        <h6 className="txtmb1">Makeup: </h6>
          <p>
            <Link to="" className="tx-hvr1"> Primer | </Link>
            <Link to="" className="tx-hvr1">Lipstick | </Link>
            <Link to="" className="tx-hvr1">Foundation | </Link>
            <Link to="" className="tx-hvr1">Eyeshadow | </Link>
            <Link to="" className="tx-hvr1">Eyeliner | </Link>
            <Link to="" className="tx-hvr1">Kajal | </Link>
            <Link to="" className="tx-hvr1">Concealer | </Link>
            <Link to="" className="tx-hvr1">Nail Polish | </Link>
            <Link to="" className="tx-hvr1">Compact | </Link>
            <Link to="" className="tx-hvr1">Mascara | </Link>
            <Link to="" className="tx-hvr1">Highlighters &#38; Illuminator | </Link>
            <Link to="" className="tx-hvr1">Mackup Remover | </Link>
          </p>
		</ul>

        <ul className="lst-02 algbase p-0">
        <h6 className="txtmb1">Skin: </h6>
          <p>
            <Link to="" className="tx-hvr1"> Primer | </Link>
            <Link to="" className="tx-hvr1">Lipstick | </Link>
            <Link to="" className="tx-hvr1">Foundation | </Link>
            <Link to="" className="tx-hvr1">Eyeshadow | </Link>
            <Link to="" className="tx-hvr1">Eyeliner | </Link>
            <Link to="" className="tx-hvr1">Kajal | </Link>
            <Link to="" className="tx-hvr1">Concealer | </Link>
            <Link to="" className="tx-hvr1">Nail Polish | </Link>
            <Link to="" className="tx-hvr1">Compact | </Link>
            <Link to="" className="tx-hvr1">Mascara | </Link>
            <Link to="" className="tx-hvr1">Highlighters &#38; Illuminator | </Link>
            <Link to="" className="tx-hvr1">Mackup Remover | </Link>
          </p>
		</ul>

        <ul className="lst-02 algbase p-0">
        <h6 className="txtmb1">Hair: </h6>
          <p>
            <Link to="" className="tx-hvr1"> Primer | </Link>
            <Link to="" className="tx-hvr1">Lipstick | </Link>
            <Link to="" className="tx-hvr1">Foundation | </Link>
            <Link to="" className="tx-hvr1">Eyeshadow | </Link>
            <Link to="" className="tx-hvr1">Eyeliner | </Link>
            <Link to="" className="tx-hvr1">Kajal | </Link>
            <Link to="" className="tx-hvr1">Concealer | </Link>
            <Link to="" className="tx-hvr1">Nail Polish | </Link>
            <Link to="" className="tx-hvr1">Compact | </Link>
            <Link to="" className="tx-hvr1">Mascara | </Link>
            <Link to="" className="tx-hvr1">Highlighters &#38; Illuminator | </Link>
            <Link to="" className="tx-hvr1">Mackup Remover | </Link>
          </p>
		</ul>

        <ul className="lst-02 algbase p-0">
        <h6 className="txtmb1">Brands: </h6>
          <p>
            <Link to="" className="tx-hvr1"> Primer | </Link>
            <Link to="" className="tx-hvr1">Lipstick | </Link>
            <Link to="" className="tx-hvr1">Foundation | </Link>
            <Link to="" className="tx-hvr1">Eyeshadow | </Link>
            <Link to="" className="tx-hvr1">Eyeliner | </Link>
            <Link to="" className="tx-hvr1">Kajal | </Link>
            <Link to="" className="tx-hvr1">Concealer | </Link>
            <Link to="" className="tx-hvr1">Nail Polish | </Link>
            <Link to="" className="tx-hvr1">Compact | </Link>
            <Link to="" className="tx-hvr1">Mascara | </Link>
            <Link to="" className="tx-hvr1">Highlighters &#38; Illuminator | </Link>
            <Link to="" className="tx-hvr1">Mackup Remover | </Link>
          </p>
		</ul>


        </div>
        <div>
			<p className="crfttxt">Copyright &#169; 2023 TejBazar. All Rights Reserved. Powered By Motherwit Digital Solutions.</p>
		</div>
		</div>
    
	</div>
  </section>
    </>
  )
}

export default Footer