import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./Headernew.css";
import logo from "../media/logo6.png";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  FaHeart,
  FaUserAlt,
  FaShoppingCart,
  FaSearch,
  FaUser,
  FaList,
  FaCaretDown,
  FaBars
  ,
} from "react-icons/fa";
import { HiLocationMarker, HiSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";

function Headernew() {
  const { variable1, updateVariable1Value } = useContext(AppContext);
  const { variable2, updateLogin } = useContext(AppContext);

  // model start

  const [showModal, setShowModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [showModalOtp, setShowModalOtp] = useState(false);

  const [showModalSignUp, setShowModalSignUp] = useState(false);

  const [showModalOtpSignup, setShowModalOtpSignup] = useState(false);

///////////

const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);


///////////

  const handleClose = () => {
    setShowModal(false);
    setError('');
  };

  const handleShow = () => {
    setShowModal(true);
    setError('');
    handleCloseSignUp();
  $("#_logMob,#_mobSignup").val('');
  };


//
const handleCloseOtp = () => {
  setShowModalOtp(false);
 
};

const handleShowotp = () => {
  setShowModalOtp(true);
  
};
////
const handleCloseOtpsignup = () => {
  setShowModalOtpSignup(false);
 
};

const handleShowotpsignup = () => {
  setShowModalOtpSignup(true);
  
};
  
  const handleInputChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleButtonClick = (dtls) => {
    // Validate the mobile number here.
    const mobileNumberRegex = /^[6789]\d{9}$/; // 6, 7, 8, or 9 followed by 9 digits.

    if (!mobileNumber.match(mobileNumberRegex)) {
      setError('Mobile number must start with 6, 7, 8, or 9 and be 10 digits.');
    } else {
      localStorage.setItem('tempMobileNo', mobileNumber);

      validateLogin(dtls);

    }
  };



  const handleCloseSignUp = () => {
    setShowModalSignUp(false);
    setError('');
  };
  
  const handleShowSignUp = () => {
    setShowModalSignUp(true);
    handleClose();
    setError('');
  };




  // modal ends
  
  const dropdownStyle = {
    zIndex: 1,
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [userid, setUserid] = useState("");
  useEffect(() => {
    setUserid(localStorage.getItem('userId'));
    loadCartCount();
    
    
    
  }, []);

  function loadCartCount() {
    var valdata = {
      userId: localStorage.getItem('userId'),
    };
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getCartlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      updateVariable1Value(response.length);
    });
  }

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^\d+$/; // Only allow digits
    const value = event.target.value + keyValue;

    if (!regex.test(keyValue) && keyCode !== 8) {
      event.preventDefault();
    }
    if (value.length === 1 && !/[6-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const validateLogin = (dtls) => {
    debugger
    
if(dtls === 'login'){
  if (!$("#_logMob").val() || $("#_logMob").val().length < 10) {
    toast.error("Invalid Mobile No!");
    return false;
  }
}else{
  if (!$("#_mobSignup").val() || $("#_mobSignup").val().length < 10) {
    toast.error("Invalid Mobile No!");
    return false;
  }
}
    

    var valdata = {
      mobileNo: (dtls === 'login' ? $("#_logMob").val().trim() : $("#_mobSignup").val() ),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}validateUser`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {

      if (parseInt(response.po_STATUS) == 1 && dtls === 'login') {
        sendOtp("send", $("#_logMob").val());
        $(".displayMobile").text($("#_logMob").val());
        //$("#otpmodalopen").click();
        handleClose();
        handleShowotp();
      } else if(parseInt(response.po_STATUS) == 1 && dtls === 'signUp'){
       toast.error('Already Registered.');
      }else if(dtls === 'signUp' && parseInt(response.po_STATUS) == 0){
        sendOtp("send", $("#_mobSignup").val());
        handleCloseSignUp();
        handleShowotpsignup();
      }else if(parseInt(response.po_STATUS) == 0 && dtls === 'login'){
        toast.error('No User Found. Please SignUp.');
      }
    });
  };

  const sendOtp = (typed, userNo) => {
    var valdata = {
      mobileNo: userNo,
      typed: typed,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}sendOtp`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        toast.success("OTP Send Successfully..");
      } else {
        toast.error("Failed To Send OTP.");
      }
    });
  };

  const verifyOtp = (typed) => {
    debugger
    if (typed === 'login' && !$("#_otplogin").val().trim()) {
      toast.error("Please Enter Valid OTP.");
      return false;
    }
    if (typed === 'signup' && !$("#_otploginsignup").val().trim()) {
      toast.error("Please Enter Valid OTP.");
      return false;
    }
    var valdata = {
      mobileNo: localStorage.getItem('tempMobileNo'),
      otp: (typed === 'login' ? $("#_otplogin").val().trim() : $("#_otploginsignup").val().trim()) ,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}verifyOtp`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1 && typed === 'login') {
        loginuser();
      }else if (parseInt(response.po_STATUS) == 1 && typed === 'signup') {
        registeruser();
      } else {
        toast.error("Invalid OTP.");
      }
    });
  };

  const loginuser = () => {
    if (!$("#_otplogin").val().trim()) {
      toast.error("Please Enter Valid OTP.");
      return false;
    }
    var valdata = {
      mobileNo: localStorage.getItem('tempMobileNo'),
      otp: $("#_otplogin").val().trim(),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}loginchk`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        localStorage.setItem("userId", response.USERID);
        setUserid(response.USERID);
        $("#userId").val(response.USERID);
        localStorage.setItem("userName", response.USER_NAME);
        toast.success("Logged In Successfully.");
        handleCloseOtp();
      } else {
        toast.error("Invalid OTP.");
      }
    });
  };

  const registeruser = () => {
    
    var valdata = {
      mobileNo: localStorage.getItem('tempMobileNo'),
      
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}registeruser`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        
        toast.success("Register Successfully. Please Login.");
        handleCloseOtpsignup();
      } else {
        toast.error("Invalid OTP.");
      }
    });
  };

  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);

  useEffect(() => {
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getCategory`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      setCategory(response);
    });
  }, []);

  const navigateto = (queryStringParams) => {
    const url = `Allproduct?${queryStringParams}`;
    window.location.href = url;
    navigate(url);
  };

  const navigatetoblank = (queryStringParams) => {
    queryStringParams = (queryStringParams ? queryStringParams : "11")
    const url = `Allproduct?q=search&requestType=banner&subCategory=${queryStringParams}`;
    window.location.href = url;
    navigate(url);
  };


  const logout = () => {
localStorage.removeItem('userId');
$("#userId").val('');
setUserid("");
localStorage.removeItem('userName');
  };

  ////search

  const serachTerms = () => {
    var valdata = {
      terms: "mamaear",
      
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}searchProduct`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        setSuggestions(response.po_DETAIL);
      }
    });
  };

  const handleInputsearchChange = (e) => {
    const text = e.target.value;
    setInputValue(text);

if (inputValue.length == 2) {
      serachTerms(inputValue);
    }
    
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.PRODUCT_NAME);
    setSelectedValue(suggestion);
    navigatetoblank(suggestion.SUB_CATEGORY_ID);
    setSuggestions([]);
  };




  return (
    <section>
      <ToastContainer />
      <input type="hidden" id="userId" value={userid} />

      <input id="_skuId" type="hidden" value="iujhy" />
      <input id="_tempMobileNo" type="hidden" value=""/>

      <div className="main-navbar shadow-sm ">
        <div className="top-navbar">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2 my-auto d-none d-sm-none d-md-block d-lg-block text-center">
                <h5 className=" brand-name">
                  <Link to="/" className="logo1">
                    <img src={logo} style={{ height: "40px" }} />
                  </Link>
                </h5>
              </div>
              <div className="col-md-7 my-auto">
                
                  <div className="input-group">
                    <input
                      type="search"
                      placeholder="Search your product"
                      className="form-control"
                      value={inputValue}
        onChange={handleInputsearchChange}
                    />
                    <button className="btn bg-white" type="submit">
                      <FaSearch />
                    </button>
                    <ul>
        {suggestions.map((suggestion) => (
          <li className="dropdown-item  lok1p py-1 px-2" key={suggestion.ID} onClick={() => handleSuggestionClick(suggestion)}>
            {suggestion.PRODUCT_NAME}
          </li>
        ))}
      </ul>
      
                  </div>
                
              </div>
              <div className="col-md-3 my-auto d-none d-xl-flex justify-content-center">
                <ul className="nav justify-content-end">
                  {userid ? (
                    <li role="menuitem">
                      <Link
                        href="/Login"
                        className="dmhvr text-dark "
                        style={{ backgroundColor: "#fff60000" }}
                      >
                        <FaUser size={25} style={{ color: "#161616" }} /> 
                      </Link>
                      <div
                        className="mega-menu "
                        aria-hidden="true"
                        role="menu"
                      >
                        <div className="nav-column row m-0">
                          <div className="">
                            <ul>
                              <li role="menuitem">
                                <Link
                                  className="dropdown-item py-1"
                                  to="/Myaccount"
                                >
                                  <FaUser />
                                  &nbsp; Profile
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link
                                  className="dropdown-item py-1"
                                  to="/Order"
                                >
                                  <FaList />
                                  &nbsp; My Orders
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link
                                  className="dropdown-item py-1"
                                  to="/Wishlist"
                                >
                                  <FaHeart />
                                  &nbsp; My Wishlist
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Cart">
                                  <FaShoppingCart />
                                  &nbsp; My Cart
                                </Link>
                              </li>
                              <li role="menuitem">
                              <Link className="dropdown-item py-1"  onClick={logout}>
                                  <i className="fa fa-sign-out"></i> Logout
                                  </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    
                    
                    <button className="btn btnlogin" style={{border: "2px solid #000", fontWeight: "700"}}
                        >
                      <Link className="navhv1"  to="/Login">
                                Login <FaUser />
                                  </Link>
                    </button>
                  )}

                  <li className="nav-item">
                    <Link
                      className="nav-link navhv1"
                      to="/Wishlist"
                      style={{ backgroundColor: "#fff60000" }}
                    >
                      <FaHeart size={25}   style={{ color: "#161616" }}/>{" "}
                      
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link navhv1"
                      to="/Cart"
                      style={{ backgroundColor: "#fff60000" }}
                    >
                      <FaShoppingCart size={25}  style={{ color: "#161616" }} />{" "}
                      <sup className="suxped bxda ">{variable1}</sup>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="menu-wrapper d-none d-xl-flex justify-content-center"
          style={{ backgroundColor: "#000" }}
          role="navigation"
        >
          <ul className="nav zindexr" role="menubar">


            <li role="menuitem">
              <Link href="#" className="dmhvr " style={{ borderRight: "1px solid #fbfbfbb8" }}>
              <FaBars style={{ color: "#fff" }} /> All Category{" "}
              </Link>
              <div
                className="mega-menu mgmnhj"
                style={{ width: "1000px" }}
                aria-hidden="true"
                role="menu"
              >
                <div className="nav-column row m-0">



                  <div className="col-md-3">
                    <h3 className="cateall">Face Makeup</h3>
                    <ul>
                    <li role="menuitem">
                        <Link href="#">Primer</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Concealer</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Blush</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Bath & Soap</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Silver Jewellery</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Silver Jewellery</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Silver Jewellery</Link>
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Indian & Festive Wear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Kurtas & Kurta Sets</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Sherwanis</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Nehru Jackets</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Dhotis</Link>
                      </li>
                      <hr />
                    </ul>
                  </div>




                  <div className="col-md-3 ploki">
                    <h3 className="cateall">Bottomwear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Jeans</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Casual Trousers</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Formal Trousers</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Shorts</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Track Pants & Joggers</Link>
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Innerwear & Sleepwear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Briefs & Trunks</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Boxers</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Vests</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Sleepwear & Loungewear</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Thermals</Link>
                      </li>
                      <hr />
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h3 className="cateall">Women Western </h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Topwear</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Dresses</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Jeans</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Shorts</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Skirts</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Jeggings & Tights</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Trousers & Capris</Link>
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Footwear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Sandals</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Heels</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Flats</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Shoes</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Sports Shoes</Link>
                      </li>
                      
                      <hr />
                    </ul>

                  </div>
                  <div className="col-md-3 ploki">
                    <h3 className="cateall">Ethnic Wear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Sarees</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Kurtas & Kurtis </Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Lehenga Choli</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Duppatas</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Jackets & Sweatshirts</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Sports Accessories</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Swimwear</Link>
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Skincare </h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Makeups</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Skin care</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Haircare</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Silver Jewellery</Link>
                      </li>
                      <hr />
                    </ul>
                  </div>


                  
                </div>
              </div>
            </li>


            <li role="menuitem">
              <Link href="#" className="dmhvr " style={{ borderRight: "1px solid #fbfbfbb8" }}>
                Fashion{" "}
              </Link>
              <div
                className="mega-menu mgmnhj"
                style={{ width: "1000px" }}
                aria-hidden="true"
                role="menu"
              >
                <div className="nav-column row m-0">
                  <div className="col-md-3">
                    <h3 className="cateall">Men Topwear</h3>
                    <ul>
                      <li role="menuitem">
                        <span onClick={()=>navigatetoblank('')}>T-Shirts</span>
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Casual Shirts</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Formal Shirts</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Sweatshirts</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Sweaters</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Jackets</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Blazers & Coats</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Suits</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Rain Jackets</span>
                        
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Indian & Festive Wear</h3>
                    <ul>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Kurtas & Kurta Sets</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Sherwanis</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Nehru Jackets</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Dhotis</span>
                        
                      </li>
                      <hr />
                    </ul>
                  </div>
                  <div className="col-md-3 ploki">
                    <h3 className="cateall">Bottomwear</h3>
                    <ul>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Jeans</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Casual Trousers</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Formal Trousers</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Shorts</span>
                        
                      </li>
                      <li role="menuitem">
                      <span onClick={()=>navigatetoblank('')}>Track Pants & Joggers</span>
                        
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Innerwear & Sleepwear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Briefs & Trunks</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Boxers</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Vests</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Sleepwear & Loungewear</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Thermals</Link>
                      </li>
                      <hr />
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h3 className="cateall">Women Western </h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Topwear</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Dresses</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Jeans</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Shorts</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Skirts</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Jeggings & Tights</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Trousers & Capris</Link>
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Footwear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Sandals</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Heels</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Flats</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Shoes</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Sports Shoes</Link>
                      </li>
                      
                      <hr />
                    </ul>

                  </div>
                  <div className="col-md-3 ploki">
                    <h3 className="cateall">Ethnic Wear</h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Sarees</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Kurtas & Kurtis </Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Lehenga Choli</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Duppatas</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Jackets & Sweatshirts</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Sports Accessories</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Swimwear</Link>
                      </li>
                      <hr />
                    </ul>

                    <h3 className="cateall">Skincare </h3>
                    <ul>
                      <li role="menuitem">
                        <Link href="#">Makeups</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Skin care</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Haircare</Link>
                      </li>
                      <li role="menuitem">
                        <Link href="#">Silver Jewellery</Link>
                      </li>
                      <hr />
                    </ul>
                  </div>
                </div>
              </div>
            </li>



            {category
              .filter((categ) =>
                [
                  "Electronics",
                  "Home & Kitchen",
                  "Appliances",
                  "Beauty & Personal Care",
                  "Sports & Outdoors",
                ].includes(categ.name)
              )
              .map((categ) => (
                <li role="menuitem " key={`cat${categ.id}`}>
                  <Link
                    href="#"
                    className="dmhvr text-white"
                    style={{ borderRight: "1px solid #fbfbfbb8" }}
                  >
                    {categ.name}
                  </Link>
                  <div className="mega-menu " aria-hidden="true" role="menu">
                    <div className="nav-column row m-0">
                      <div className="p-0">
                        <ul>
                          {categ.children.map((submenuItem) => {
                            const params = {
                              q: "search",
                              requestType: "features",
                              subCategory: submenuItem.id,
                            };
                            const queryStringParams =
                              queryString.stringify(params);
                            return (
                              <li
                                role="menuitem lok1p"
                                key={`subcat${submenuItem.id}`}
                              >
                                {/* <Link
                                  className="dropdown-item py-1"
                                  to={`/Allproduct?${queryStringParams}`}
                                >
                                  {submenuItem.name}
                                </Link> */}
<span className="dropdown-item  lok1p py-1 px-2" onClick={()=> navigateto(queryStringParams)}>{submenuItem.name}</span>

                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              ))}

            {/* <li role="menuitem">
                      <Link
                        href="#"
                        className="dmhvr text-white "
                        style={{ color: "#fff" }}
                      >
                          Electronics
                      </Link>
                      <div
                        className="mega-menu "
                        aria-hidden="true"
                        role="menu"
                      >
                        <div className="nav-column row m-0">
                          <div className="p-0">
                            <ul>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Myaccount">
                                 
                                 Profile
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Order">
                                  My Orders
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Wishlist">
                                My Wishlist
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Cart">
                                My Cart
                                </Link>
                              </li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li role="menuitem">
                      <Link
                        href="#"
                        className="dmhvr text-white "
                        style={{ color: "#fff" }}
                      >
                         Home & Furniture
                      </Link>
                      <div
                        className="mega-menu "
                        aria-hidden="true"
                        role="menu"
                      >
                        <div className="nav-column row m-0">
                          <div className="p-0">
                            <ul>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Myaccount">
                                 
                                 Profile
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Order">
                                  My Orders
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Wishlist">
                                My Wishlist
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Cart">
                                My Cart
                                </Link>
                              </li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li role="menuitem">
                      <Link
                        href="#"
                        className="dmhvr text-white "
                        style={{ color: "#fff" }}
                      >
                          Appliances
                      </Link>
                      <div
                        className="mega-menu "
                        aria-hidden="true"
                        role="menu"
                      >
                        <div className="nav-column row m-0">
                          <div className="p-0">
                            <ul>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Myaccount">
                                 
                                 Profile
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Order">
                                  My Orders
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Wishlist">
                                My Wishlist
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Cart">
                                My Cart
                                </Link>
                              </li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li role="menuitem">
                      <Link
                        href="#"
                        className="dmhvr text-white "
                        style={{ color: "#fff" }}
                      >
                          Beauty & Skincare
                      </Link>
                      <div
                        className="mega-menu "
                        aria-hidden="true"
                        role="menu"
                      >
                        <div className="nav-column row m-0">
                          <div className="p-0">
                            <ul>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Myaccount">
                                 
                                 Profile
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Order">
                                  My Orders
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Wishlist">
                                My Wishlist
                                </Link>
                              </li>
                              <li role="menuitem">
                                <Link className="dropdown-item py-1" to="/Cart">
                                My Cart
                                </Link>
                              </li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                   */}
          </ul>
        </div>
      </div>

      {/* <div class="tab-container">
    
    <section class="nav dropdown">
       
        <p className="nj">All Categories</p>
        <div class="menu mnhnh">
          <ul class="main-menu">
            <li className="liji py-1 px-1">Men's Top Wear <span></span>
                <ul class="inner-menu">
                    <li className="py-1 px-2">All</li>
                    <li className="py-1 px-2">Shirt</li>
                    <li className="py-1 px-2">T-Shirt</li>
                  </ul>
            </li>
            <li className="liji py-1 px-1">Men's Bottom Wear <span></span>
                <ul class="inner-menu">
                    <li className="py-1 px-2">Shoe</li>
                    <li className="py-1 px-2">Slipper</li>
                    <li className="py-1 px-2">Special</li>
                    <li className="py-1 px-2">Crocs</li>
                  </ul>
            </li>
            <li className="liji py-1 px-1">Women Ethinc </li>
            <li className="liji py-1 px-1">Women Western </li>
            <li className="liji py-1 px-1">Women Footwear</li>
            <li className="liji py-1 px-1">Men Footwear </li>
            <li className="liji py-1 px-1">Kid </li>
            <li className="liji py-1 px-1">Winter </li>
            <li className="liji py-1 px-1">Summer </li>
          </ul>
       
        </div>
    </section>


    <section class="nav dropdown">
       
        <p className="nj">All Categories</p>
        <div class="menu22 mnhnh">
          <ul class="main-menu">
            <li className="liji py-1 px-1">Women Ethinc </li>
            <li className="liji py-1 px-1">Women Western </li>
            <li className="liji py-1 px-1">Women Footwear</li>
            <li className="liji py-1 px-1">Men Footwear </li>
            <li className="liji py-1 px-1">Kid </li>
            <li className="liji py-1 px-1">Winter </li>
            <li className="liji py-1 px-1">Summer </li>
          </ul>
       
        </div>
    </section>


    <section class="nav">
 
    <p className="nj">Home</p>
        
    </section>
    <section class="nav">
       
        <p className="nj">Home</p>
    </section>
    <section class="nav">
        
        <p className="nj">Appliances</p>
    </section>
    <section class="nav">
        
        <p className="nj">Travel</p>
    </section>

    <section class="nav">
        
        <p className="nj">Top Offers</p>
    </section>
    <section class="nav">
        
        <p className="nj">Beauty, Toys & More</p>
    </section>
    <section class="nav">
        
        <p className="nj">Two Wheelers</p>
    </section>
</div> */}

      <div className="row">
        <div className="col-12">
          <div class="tab-container">
            <section class="nav dropdown" style={dropdownStyle}>
              {/* <li role="menuitem">
                <a href="/" class="dmhvr ">
                  Electronics
                </a>
                <div class="mega-menu " aria-hidden="true" role="menu">
                  <div class="nav-column ">
                    <ul>
                      <li role="menuitem">
                        <a href="/Allproduct?q=search&amp;requestType=features&amp;subCategory=1">
                          Laptops
                        </a>
                      </li>
                      <li role="menuitem">
                        <a href="/Allproduct?q=search&amp;requestType=features&amp;subCategory=2">
                          Mobile Phones
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li> */}
            </section>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="_login"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="row m-0 minhtlg1">
                <div className="col-md-5 col-5 bglg1 p-3">
                  <h2 className="lgnh2 lgnfmdv1">
                    Sign up with your mobile number to get started
                  </h2>
                </div>
                <div className="col-md-7 col-7 p-4">
                  <div className="lgnfmdv1">
                    <input
                      type="text"
                      className="inptpc-sgp wd-100"
                      id=""
                      onKeyPress={handleKeyPress}
                      maxLength={10}
                      placeholder="Enter Mobile"
                      name=""
                    />
                  </div>
                  <div>
                    <p className="pc1">
                      By continuing, you agree to TejBazar's Terms of Use and
                      Privacy Policy.
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="adtcrtbtn add-cart-btn"
                      onClick={validateLogin}
                    >
                      Continue
                    </button>
                  </div>
                  <div className="mt-2">
                    <Link
                      className="txtac1 bdr-0"
                      data-bs-target="#_signup"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      Existing User? Log In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}



<Modal show={showModalSignUp} onHide={handleCloseSignUp}>
        <div className="modal-dialog modal-dialog-centered m-0">
          <div className="modal-content">
            {/* <Modal.Header closeButton>
              
            </Modal.Header> */}
            <Modal.Body>


            <div className="modal-body p-0">
              <div className="row m-0 minhtlg1">
                <div className="col-md-5 col-5 bglg1 p-3">
                  <h2 className="lgnh2 lgnfmdv1">
                    Sign up with your mobile number to get started
                  </h2>
                </div>
                <div className="col-md-7 col-7 p-4">
                  <div className="lgnfmdv1">
                    <input
                      type="text"
                      className="inptpc-sgp wd-100"
                      value={mobileNumber}
                      onChange={handleInputChange}
                      maxLength={10}
                      placeholder="Enter Mobile"
                      id="_mobSignup"
                    />
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                  <div>
                    <p className="pc1">
                      By continuing, you agree to TejBazar's Terms of Use and
                      Privacy Policy.
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="adtcrtbtn add-cart-btn"
                      // onClick={validateLogin}
                      onClick={()=> handleButtonClick('signUp')}
                    >
                      Continue
                    </button>
                  </div>
                  <div className="mt-2">
                    <span
                     onClick={handleShow}
                    >
                      Existing User? Log In
                    </span>
                  </div>
                </div>
              </div>
            </div>
             
            </Modal.Body>
          </div>
        </div>
      </Modal>



      {/* <div
        className="modal fade"
        id="_signup"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="row m-0 minhtlg1">
                <div className="col-md-5 col-5 bglg1 p-3">
                  <h1>Login</h1>
                  <h2 className="lgnh2 lgnfmdv1">Get Access to your Account</h2>
                </div>
                <div className="col-md-7 col-7 p-4">
                  <div className="lgnfmdv1">
                    <input
                      type="text"
                      className="inptpc-sgp wd-100"
                      onKeyPress={handleKeyPress}
                      maxLength={10}
                      placeholder="Enter Mobile"
                      name=""
                    />
                  </div>
                  <div>
                    <p className="pc1">
                      By continuing, you agree to TejBazar's Terms of Use and
                      Privacy Policy.
                    </p>
                  </div>
                  <div>
                    <button type="button" className="adtcrtbtn add-cart-btn">
                      Request OTP
                    </button>
                  </div>
                  <div className="mt-2">
                    <Link
                      className="txtac1 bdr-0 "
                      data-bs-target="#_login"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      New To TezBazar? Create an account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

<Modal show={showModal} onHide={handleClose}>
        <div className="modal-dialog modal-dialog-centered m-0">
          <div className="modal-content">
            {/* <Modal.Header closeButton>
              
            </Modal.Header> */}
            <Modal.Body>


            <div className="row m-0 minhtlg1">
                <div className="col-md-5 col-5 bglg1 p-3">
                  <h1>Login</h1>
                  <h2 className="lgnh2 lgnfmdv1">Get Access to your Account</h2>
                </div>
                <div className="col-md-7 col-7 p-4">
                  <div className="lgnfmdv1">
                    <input
                      type="text"
                      className="inptpc-sgp wd-100"
                      value={mobileNumber}
                      onChange={handleInputChange}
                      maxLength={10}
                      id="_logMob"
                      placeholder="Enter Mobile"
                    />
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                  <div>
                    <p className="pc1">
                      By continuing, you agree to TejBazar's Terms of Use and
                      Privacy Policy.
                    </p>
                  </div>
                  <div>
                    <button type="button" className="adtcrtbtn add-cart-btn" onClick={()=> handleButtonClick('login')}>
                      Request OTP
                    </button>
                  </div>
                  <div className="mt-2">
                    
                  <a
                  className="txtac1 bdr-0"
                  data-bs-target="#_login"
                  onClick={handleShowSignUp}
                >
                  New To TezBazar? Create an account
                </a>
                    
                  </div>
                </div>
              </div>


             
            </Modal.Body>
          </div>
        </div>
      </Modal>
      
      <div className="d-none">
        <button
          data-bs-toggle="modal"
          data-bs-target="#otpdigit"
          id="otpmodalopen"
        >
          otpmodalopen
        </button>
      </div>
      {/* <div
        className="modal fade"
        id="otpdigit"
        aria-hidden="true"
        aria-labelledby="otpdigit"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="row m-0">
                <div className="col-md-12 col-12 p-4">
                  <div className="lgnfmdv1">
                    <div>
                      OTP send to <span className="displayMobile"></span>{" "}
                      <Link
                        to=""
                        data-bs-toggle="modal"
                        data-bs-target="#_login"
                      >
                        [Edit]
                      </Link>
                    </div>
                    <input
                      type="text"
                      className="inptpc-sgp wd-100"
                      placeholder="Enter OTP"
                      name=""
                      id="_otplogin"
                    />
                  </div>
                  <div>
                    <p className="pc1"></p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="adtcrtbtn add-cart-btn"
                      onClick={verifyOtp}
                    >
                      Submit OTP
                    </button>
                  </div>
                  <div className="mt-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}









<Modal show={showModalOtp} onHide={handleCloseOtp}>
        <div className="modal-dialog modal-dialog-centered m-0">
          <div className="modal-content">
            {/* <Modal.Header closeButton>
              
            </Modal.Header> */}
            <Modal.Body>


            <div className="row m-0">
                <div className="col-md-12 col-12 p-4">
                  <div className="lgnfmdv1">
                    <div>
                      OTP send to <span className="displayMobile">{localStorage.getItem('tempMobileNo')}</span>{" "}
                      <Link
                        to=""
                        data-bs-toggle="modal"
                        data-bs-target="#_login"
                      >
                        [Edit]
                      </Link>
                    </div>
                    <input
                      type="text"
                      className="inptpc-sgp wd-100"
                      placeholder="Enter OTP"
                      name=""
                      id="_otplogin"
                    />
                  </div>
                  <div>
                    <p className="pc1"></p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="adtcrtbtn add-cart-btn"
                      onClick={()=>verifyOtp('login')}
                    >
                      Submit OTP
                    </button>
                  </div>
                  <div className="mt-2"></div>
                </div>
              </div>


             
            </Modal.Body>
          </div>
        </div>
      </Modal>









      <Modal show={showModalOtpSignup} onHide={handleCloseOtpsignup}>
        <div className="modal-dialog modal-dialog-centered m-0">
          <div className="modal-content">
            {/* <Modal.Header closeButton>
              
            </Modal.Header> */}
            <Modal.Body>


            <div className="row m-0">
                <div className="col-md-12 col-12 p-4">
                  <div className="lgnfmdv1">
                    <div>
                      OTP send to <span className="displayMobile">{localStorage.getItem('tempMobileNo')}</span>{" "}
                      <Link
                        to=""
                        data-bs-toggle="modal"
                        data-bs-target="#_login"
                      >
                        [Edit]
                      </Link>
                    </div>
                    <input
                      type="text"
                      className="inptpc-sgp wd-100"
                      placeholder="Enter OTP"
                      name=""
                      id="_otploginsignup"
                    />
                  </div>
                  <div>
                    <p className="pc1"></p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="adtcrtbtn add-cart-btn"
                      onClick={()=>verifyOtp('signup')}
                    >
                      Submit OTP
                    </button>
                  </div>
                  <div className="mt-2"></div>
                </div>
              </div>


             
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default Headernew;
