import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";

function Adticket() {
	const [report, setReport] = useState([]);

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      filterBy: pytype,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchTicketDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setReport(response.po_DETAIL);
      } else {
        setReport(response.po_DETAIL);
        toast.error(response.po_MSG);
      }
    });
  };



  
  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "TicketRpt.xlsx");
    toast.success("Excel file downloaded successfully!");
  };

  const downloadExcel = () => {
    var modifiedData = report.map(function (obj) {
      return {
        "DATE": obj.formatted_date,
        "TICKET TYPE": obj.DEPARTMENT_NAME,
        "TOKEN ID": obj.FormattedID,

        "TICKET DETAILS": obj.MESSAGE_QUERY,
        "VENDOR MOBILE NO": obj.PHONE_NUMBER,
        
        "TICKET STATUS": obj.PI_STATUS === "PENDING" ? "ACTIVE" : "CLOSED",
      };
    });
    downloadExcelFile(modifiedData);
  };

  const filterListing = () => {
    let filter = $("#_filterTck").val();
    fetchData(filter);
  };



  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">All Ticket(s) </p>
            <span class="hdspn1">Learn More</span>
          </div>
        </div>
      </section>

      <section class="p-3 ft13">
        <div class=" mb-1 p-2 pb-0">
          <div class="d-flex">
            <div>
              {" "}
              <p className="mgrt2">Filters:</p>
            </div>
            <div class="p-3 pt-0 pb-0">
              <label>Ticket Type</label>
              <select class="" id="_filterTck" onChange={filterListing}>
                <option value=''>All Ticket Type</option>
                <option value='PENDING'>Active Ticket</option>
                <option value='CLOSE'>Inactive Ticket</option>
              </select>
            </div>

            <div class="p-3 pt-0 pb-0">
              <label>Date</label>
              <input type="date" className="" name="" />
            </div>
          </div>
        </div>
      </section>

      <section class="bgcldiv1 p-3">
        <div class="card p-3">
          <div class="row">
            <div class="col-md-6 col-6 pt-2 pb-2  ">
              <div class="vhp1 pt-0">All Ticket(s)</div>
            </div>
            <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
              <div class="vhp1 pt-0">
                <button class="button1"  disabled={report.length === 0}
                    onClick={downloadExcel}>Download </button>
              </div>
            </div>
          </div>

          <div class="">
            <div class="row p-3 bgcldiv1 mb-2 bdrtb1">
              <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
                <div class="d-flex justify-content-end">
                  <div>
                    {" "}
                    <p className="mgrt2">Find a Ticket</p>
                  </div>
                  <form class="d-flex" style={{ height: "30px" }}>
                    <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Search Ticket"
                      aria-label="Search"
                    />
                    <button class="button1 mxhtsh m-0" type="submit">
                      Search
                    </button>
                  </form>
                  <div>
                    {" "}
                    <p className="mgrt2"> {report.length} Ticket(s)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table class="table table-hover table-striped ft13">
            <thead className="tbhd1">
              <tr>
                <td>Date</td>
                <td>Ticket type</td>
                <td>Tokken ID</td>
                <td>Ticket Details</td>
                <td>Vendor Mobile</td>
                <td>Status</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
			{report.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={8}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    report.map((rpt) => (
                      <tr key={`tick${rpt.FormattedID}`}>
                        <td>{rpt.formatted_date}</td>
                        <td>{rpt.DEPARTMENT_NAME}</td>
                        <td>{rpt.FormattedID}</td>

                        <td>{rpt.MESSAGE_QUERY}</td>
                        <td>{rpt.PHONE_NUMBER}</td>
                        
                        <td>
                          {rpt.PI_STATUS === "PENDING" ? "Active" : "Close"}
                        </td>
                        <td>
                          {/* Vdticketaction */}
                        {rpt.PI_STATUS === "PENDING" ? <Link to={`/Vdticketaction?ticketNo=${rpt.ID}&q=closeTicket&requestType=Admin`}><button class="btnvd add-cart-btn" >View</button></Link> : ''}
                          </td>
                      </tr>
                    ))
                  )}
			  
			  
			  {/* <tr>
                <td>1/4/2023</td>
                <td>Technical</td>
                <td>407-4119871-5561111</td>
                <td>
                  I am having issue in technical part, please guide me, how to
                  add products.
                </td>
                <td>7485961255</td>
                <td>Active</td>
                <td>
                  <button class="btn btclr w-100">Edit</button>
                </td>
              </tr>

              <tr>
                <td>1/4/2023</td>
                <td>Technical</td>
                <td>407-4119871-5561111</td>
                <td>
                  I am having issue in technical part, please guide me, how to
                  add products.
                </td>
                <td>7485961255</td>
                <td>Active</td>
                <td>
                  <button class="btn btclr w-100">Edit</button>
                </td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Technical</td>
                <td>407-4119871-5561111</td>
                <td>
                  I am having issue in technical part, please guide me, how to
                  add products.
                </td>
                <td>7485961255</td>
                <td>Active</td>
                <td>
                  <button class="btn btclr w-100">Edit</button>
                </td>
              </tr>
              <tr>
                <td>1/4/2023</td>
                <td>Technical</td>
                <td>407-4119871-5561111</td>
                <td>
                  I am having issue in technical part, please guide me, how to
                  add products.
                </td>
                <td>7485961255</td>
                <td>Close</td>
                <td></td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default Adticket;
