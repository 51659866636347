import React, { Suspense, useState, useEffect } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import LoaderFallback from '../component/LoaderFallback'
const LazyHero = React.lazy(() => import("../component/Hero.jsx"));
const LazyFeatured = React.lazy(() => import("../component/Featured.jsx"));
const LazyHeromob = React.lazy(() => import("../component/Heromob.js"));
const LazyBanner3 = React.lazy(() => import("../component/Banner3.js"));



const Shop = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [catlist, setCatlist] = useState([]);


  const handleComponentLoaded = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    loadmainmenu();
  }, []);

  function shuffleArray(array) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }
  
  
  function loadmainmenu() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getMainMenu`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        setCatlist(response.po_DETAIL);
      }

     
    });
  }



  return (
    <>
    <Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyHero pindex='I' />
    </Suspense>

    {/* <Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyBanner3 pindex='A' />
    </Suspense> */}

    {/* <Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyFeatured  pindex='featured' subCatId='' catId ='1' catName = 'Electronics'/>
    </Suspense> */}

    


    {catlist.filter((catlst) =>
                [
                  1, 2, 3, 5, 6
                ].includes(parseInt(catlst.ID))
              ).map(catlst =>  (
          <Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
          <LazyFeatured  pindex='featured' subCatId='' catId ={catlst.ID} catName = {catlst.CATEGORY_NAME}/>
        </Suspense>
			))}

    {/* <Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyFeatured pindex='featured' subCatId='' catId ='5'/>
    </Suspense> */}

<Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyBanner3 pindex='A' />
    </Suspense>

<Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyHeromob />
    </Suspense>

    <Suspense fallback={<div>Loading Banner3...</div>}>
      <LazyBanner3 pindex='C' />
    </Suspense>

    {/* <Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyFeatured pindex='featured' subCatId=''  catId ='2'/>
    </Suspense> */}

    {/* <Suspense fallback={<LoaderFallback />} onResolve={handleComponentLoaded}>
      <LazyBanner3 pindex='C' />
    </Suspense>  */}
    {isLoading && <LoaderFallback />}
  </>
);
}

export default Shop;

