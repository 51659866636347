import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { FaFacebook } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Adorderreport() {
  const [orderdtl, setOrderlsts] = useState([]);

  useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      vendorId: $("#vendorId").val(),
      filterBy: pytype,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getOrderdtlsAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
		  console.log("in");
        setOrderlsts(response.po_DETAIL);
      } else {
		console.log("out");
		setOrderlsts(response.po_DETAIL);
        toast.error(response.po_MSG);
      }
    });
  };

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "OrderListRpt.xlsx");
    toast.success("Excel file downloaded successfully!");
  };

  const downloadExcel = () => {
    var modifiedData = orderdtl.map(function (obj) {
      return {
        "ORDER ID": obj.ORDER_ID,
        "PRODUCT DETAILS": obj.PRODUCT_NAME,
        "VENDOR NAME": obj.NAME,

        "USER NAME": obj.USER_NAME,
        "PRICE AND SHIPPING COST": obj.PRICE,
        "PLATFORM CHANGE": "",
		"TOTAL PRICE": obj.PRICE,
		"STATUS": obj.ORDER_STATUS === "COMPLETE" ? "Complete" : "Active",
      };
    });
    downloadExcelFile(modifiedData);
  };

const filterOrder = () => {
	let filterBy = $("#filterOrd option:selected").val();
	fetchData(filterBy);
};

  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">All Order(s) Report</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search Order:</p>
                </div>
                <form class="d-flex" style={{ height: "30px" }}>
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search Order "
                    aria-label="Search"
                  />
                  <button class="button1 mxhtsh m-0 " type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2">&nbsp;
 {orderdtl.length} Products(s)</p>
                </div>
              </div>
            </div>

            <div class="container ">
              <div class="  pt-1 pb-0">
                <div class="d-flex">
                  <div>
                    {" "}
                    <p className="mgrt2">Filters:</p>
                  </div>
                  <div className="p-3 pt-0 pb-0">
                    <label>Order Type : &nbsp; </label>
                    <select className="selectoption" id="filterOrd" onChange={filterOrder}>
                      <option value=''>All Order Type</option>
                      <option value='PENDING'>Active Order</option>
                      <option value='COMPLETE'>Completed Order</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-6 pt-2 pb-2  ">
                <div class="vhp1 pt-0">All Order(s)</div>
              </div>
              <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                
                <div class="vhp1 pt-0">
                  <button class="button1" disabled={orderdtl.length === 0}
				  onClick={downloadExcel}>Download </button>
                </div>
              </div>
            </div>

            <div class=" hyeus1 mt-2 p-2">
              <table class="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>Order_ID</td>
                    <td>Product_Details</td>
                    <td>Vendor_Name</td>
                    <td>User_Name</td>
                    <td>Price_&_Shipping Cost</td>
                    <td>Platform_Charge</td>
                    <td>Total_Price</td>
                    <td>Invoice</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
				{orderdtl.length === 0 ? (
        <tr>
          <td className="text-center" colSpan={9}>No data found</td>
        </tr>
      ) : (orderdtl.map((orderlts) => (
		<tr key={`ord${orderlts.ORDER_ID}`}>
		  <td>{orderlts.ORDER_ID}</td>
		  <td>{orderlts.PRODUCT_NAME}</td>
		  <td>{orderlts.NAME}</td>

		  <td>{orderlts.USER_NAME}</td>
		  <td>{orderlts.PRICE}</td>
		  <td></td>
		  <td>{orderlts.PRICE}</td>
		  <td>
			<button class="btn btclr w-100">See Invoice</button>
		  </td>

		  <td>
			{orderlts.ORDER_STATUS === "PENDING" ? "Active" : null}
		  </td>
		</tr>
	  )))}



                  
                  {/* <tr>
                    <td>45871254</td>
                    <td>
                      mCaffeine Cocoa Kiss Creamy Matte Lipstick-Mocha Muse
                      (Mocha Muse, 4.2 g)
                    </td>
                    <td>Example Name</td>
                    <td>User 1</td>
                    <td>52 + 40</td>
                    <td>6</td>
                    <td>98</td>
                    <td>
                      <button class="btn btclr w-100">See Invoice</button>
                    </td>
                    <td>Active</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>
                      mCaffeine Cocoa Kiss Creamy Matte Lipstick-Mocha Muse
                      (Mocha Muse, 4.2 g)
                    </td>
                    <td>Example Name</td>
                    <td>User 1</td>
                    <td>52 + 40</td>
                    <td>6</td>
                    <td>98</td>
                    <td>
                      <button class="btn btclr w-100">See Invoice</button>
                    </td>
                    <td>Completed</td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>8574961236</td>
                    <td>78</td>
                    <td>Delhi</td>
                    <td>Active</td>
                    <td>
                      <button class="btn btclr w-100">Edit</button>
                    </td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>8574961236</td>
                    <td>98</td>
                    <td>Delhi</td>
                    <td>Active</td>
                    <td>
                      <button class="btn btclr w-100">Edit</button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adorderreport;
