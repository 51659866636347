import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Footer.css";
import queryString from "query-string";
import { Link } from "react-router-dom";

function Sitemap() {
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);

  useEffect(() => {
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getCategory`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      setCategory(response);
    });
  }, []);

  return (
    <>
      <section className="ftbg1 pb-1">
        <div className="container d-none d-sm-block pt-5">
          <div className="row pdt30">
            <h6 className="ts-std70">Top Stories:Brand Directory</h6>
            <div>
              {category.map((categ) => (
                <ul className="lst-02 algbase" key={`cat${categ.id}`}>
                  <h6 className="ts-stdst">{categ.name} : </h6>
                  <p>
                    {categ.children.map((submenuItem) => {
                       const params = {
                        q: "search",
                        requestType: "banner",
                        subCategory: submenuItem.id,
                      };
                      const queryStringParams = queryString.stringify(params);
                    return(
                      <Link
                        to={`/Allproduct?${queryStringParams}`}
                        className="tx-hvr1"
                        key={`subcat${submenuItem.id}`}
                      >
                        {" "}
                        {submenuItem.name} |{" "}
                      </Link>
                    )})}
                  </p>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sitemap;
