import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from "react-toastify";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";

import { FaFacebook,FaAngleRight, FaCheckSquare, FaWindowClose, FaEye,FaEyeSlash } from "react-icons/fa";

function Adsku() {
	const [listings, setListings] = useState([]);

	useEffect(() => {
		fetchData();
		$("input[name=inlineRadioOptions]:first").prop("checked", true);
	  }, []);


	  const fetchData = (pytype) => {
		var valdata = {
		  vendorId: $("#vendorId").val(),
		  piType: pytype,
		};
	
		const settings = {
		  async: true,
		  crossDomain: true,
		  url: `${Baseurl.baseUrl}getSkuDtls`,
		  method: "POST",
		  headers: {
			"Content-Type": "application/json",
		  },
		  data: JSON.stringify(valdata),
		};
	
		$.ajax(settings).done(function (response) {
		  setListings(response.po_DETAIL);
		});
	  };


	  const handleChange = () => {
		console.log($("input[name=inlineRadioOptions]:checked").val());
		fetchData($("input[name=inlineRadioOptions]:checked").val());
	  };
	  


    const updateSts = (id, sts, featured, FeaturedSts) => {
      var valdata = {
        rowId: id,
        statusPi: sts,
        updateFor : featured,
        featuredSts : FeaturedSts
      };
  
      const settings = {
        async: true,
        crossDomain: true,
        url: `${Baseurl.baseUrl}updateProductStsAD`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(valdata),
      };
  
      $.ajax(settings).done(function (response) {
        if (parseInt(response.po_STATUS) === 1) {
          if (sts === "D") {
            toast.success("Status Updated");
          } else {
            toast.success("Status Updated");
          }
          fetchData();
        } else {
          toast.error("No ASIN Found.");
        }
      });
    };



  return (
    <>
    <ToastContainer/>
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Manage SKU(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Action on 0 Selected:</p>
                </div>
                <form class="d-flex" style={{ height: "30px" }}>
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button class="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2"> &nbsp; {listings.length} product(s)</p>
                </div>
              </div>
            </div>

            <div class="card mb-1 p-2 pb-0 ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Filters:</p>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value=""
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    All
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="A"
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Active
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="I"
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Inactive
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="D"
                    onChange={handleChange}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Removed
                  </label>
                </div>
              </div>
            </div>
            <table class="table table-hover ft13">
              <thead className="tbhd1">
                <tr>
                  <td>ID</td>
                  <td>Product_Details</td>
                  <td>SKU</td>
                  <td>Vendor</td>
                  <td>Listing_Price</td>
                  <td>MRP</td>
                  <td>Category</td>
                  <td>Status</td>
                  <td>Action</td>
                  <td>Action</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>524158</td>
                  <td>
                    <div class="d-flex ">
                      <img src={img1} style={{ height: "40px" }} />
                      <p>ThriveCo Hair Grow & Capilia Longa & Women | 50ml</p>
                    </div>
                  </td>
                  <td>nelco_D15pc</td>
                  <td>xyz stores</td>

                  <td>
                    <input type="" name="" />
                  </td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>Hair Style</td>
                  <td>Active</td>
                  <td>
                    <button class="btn btclr w-100">Save</button>
                  </td>
                </tr>
                <tr>
                  <td>524158</td>
                  <td>
                    <div class="d-flex ">
                      <img src={img1} style={{ height: "40px" }} />
                      <p>ThriveCo Hair Grow & Capilia Longa & Women | 50ml</p>
                    </div>
                  </td>
                  <td>nelco_D15pc</td>
                  <td>xyz stores</td>

                  <td>
                    <input type="" name="" />
                  </td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>Hair Style</td>
                  <td>Active</td>
                  <td>
                    <button class="btn btclr w-100">Save</button>
                  </td>
                </tr>
                <tr>
                  <td>524158</td>
                  <td>
                    <div class="d-flex ">
                      <img src={img1} style={{ height: "40px" }} />
                      <p>ThriveCo Hair Grow & Capilia Longa & Women | 50ml</p>
                    </div>
                  </td>
                  <td>nelco_D15pc</td>
                  <td>xyz stores</td>

                  <td>
                    <input type="" name="" />
                  </td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>Hair Style</td>
                  <td>Active</td>
                  <td>
                    <button class="btn btclr w-100">Save</button>
                  </td>
                </tr>
                <tr>
                  <td>524158</td>
                  <td>
                    <div class="d-flex ">
                      <img src={img1} style={{ height: "40px" }} />
                      <p>ThriveCo Hair Grow & Capilia Longa & Women | 50ml</p>
                    </div>
                  </td>
                  <td>nelco_D15pc</td>
                  <td>xyz stores</td>

                  <td>
                    <input type="" name="" />
                  </td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>Hair Style</td>
                  <td>Active</td>
                  <td>
                    <button class="btn btclr w-100">Save</button>
                  </td>
                </tr>
                <tr>
                  <td>524158</td>
                  <td>
                    <div class="d-flex ">
                      <img src={img1} style={{ height: "40px" }} />
                      <p>ThriveCo Hair Grow & Capilia Longa & Women | 50ml</p>
                    </div>
                  </td>
                  <td>nelco_D15pc</td>
                  <td>xyz stores</td>

                  <td>
                    <input type="" name="" />
                  </td>
                  <td>
                    <input type="" name="" />
                  </td>
                  <td>Hair Style</td>
                  <td>Active</td>
                  <td>
                    <button class="btn btclr w-100">Save</button>
                  </td>
                </tr> */}



{listings.map((listing) => (
                  <tr key={`listing${listing.ID}`}>
                    <td>
                      {listing.ID}
                    </td>
                    <td>{listing.PRODUCT_NAME}</td>

                    <td>{listing.SKU_ID}</td>
                    <td>
                      {listing.NAME}
                    </td>
                    <td>
                      {listing.CURRENT_AMOUNT}
                      
                    </td>

                    <td>
                      {listing.MRP}
                    </td>
                    <td className="">
                      {listing.SUB_CATEGORY_NAME}
                    </td>
                   


                    <td>
                      {listing.PI_STATUS == "A"
                        ? "Active"
                        : listing.PI_STATUS == "D"
                        ? "Removed"
                        : "Inactive"}
                    </td>

                    <td>
                    {listing.PI_STATUS == "A" ? <button class="btnvd add-cart-btn w-50" onClick={() => updateSts(listing.ID, "D", "action", "")}><FaEyeSlash/></button> : <button class="btnvd add-cart-btn w-50" onClick={() => updateSts(listing.ID, "A", "action", "")}><FaEye/></button> }
                    
                    
                    </td>
                    <td>
                    {parseInt(listing.ISFULFILLED) === 0 ? <button class="btnvd add-cart-btn" onClick={() => updateSts(listing.ID, "", "featured", "1")}>Featured</button> : <button class="btnvd add-cart-btn" onClick={() => updateSts(listing.ID, "", "featured", "0")}>Un-Featured</button> }
                    </td>
                    <td>
                    <Link to={`/Addreviews?productId=${listing.ID}&q=addReview&requestType=Admin`}>Add Review</Link>
                    </td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adsku;
