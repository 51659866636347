import React from 'react'
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa'


function Terms() {
  return (
    <>

<section>
    <div className="container px-4">
        <div className="row justify-content-center text-center pt-4">
        <h2>Terms of Use</h2>
        


        <div className="col-12 col-md-10 text-start">
        <div className="px-4">
            <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
            <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name [www.TejBazar.com] (“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”)</p>
            <p>Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with TejBazar Internet Private Limited and these terms and conditions including the policies constitute Your binding obligations, with TejBazar.</p>
            <p>For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a buyer on the Platform by providing Registration Data while registering on the Platform as Registered User using the computer systems. TejBazar allows the User to surf the Platform or making purchases without registering on the Platform. The term "We", "Us", "Our" shall mean TejBazar Internet Private Limited.</p>
            <p>ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by TejBazar Policies ((including but not limited to Privacy Policy available at Privacy) as amended from time to time.</p>
            <p>Membership Eligibility</p>
            <p>Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e. under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian.</p>
            <p>As a minor if you wish to transact on the Platform, such transaction on the Platform may be made by your legal guardian or parents. TejBazar reserves the right to terminate your membership and / or refuse to provide you with access to the Platform if it is brought to TejBazar's notice or if it is discovered that You are under the age of 18 years and transacting on the Platform.</p>
            <p>Your Account and Registration Obligations</p>
            <p>If You use the Platform, You shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide You with access to the Platform.</p>
            <p>Your mobile phone number and/or e-mail address is treated as Your primary identifier on the Platform. It is your responsibility to ensure that Your mobile phone number and your email address is up to date on the Platform at all times. You agree to notify Us promptly if your mobile phone number or e-mail address changes by updating the same on the Platform through a onetime password verification.</p>
            <p>You agree that TejBazar shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your Account in cases, including, where You have failed to update Your revised mobile phone number and/or e-mail address on the Website Platform.</p>
            <p>If You share or allow others to have access to Your account on the Platform (“Account”), by creating separate profiles under Your Account, or otherwise, they will be able to view and access Your Account information. You shall be solely liable and responsible for all the activities undertaken under Your Account, and any consequences there from.</p>
            <p>You must maintain confidentiality of the account information and for all the activities that occur under Your account. You must not share your login details, ie, username and password of Your account with any other person, else it would be considered breach of this Terms of Use.</p>
            <p>You agree to: (a) ensure that You successfully log out from Your account at the end of each session; and (b) immediately notify TejBazar of any unauthorized use of Your account. If there is reason to believe that there is likely to be a breach of security or misuse of Your account, We may request You to change the password or we may suspend Your account without any liability to TejBazar, for such period of time as we deem appropriate in the circumstances. We shall not be liable for any loss or damage arising from Your failure to comply with this provision.</p>
            <p>You will have access to other apps owned by TejBazar group companies through the same login username and password. You agree to have single login access to all the apps owned by TejBazar group companies.</p>
            <p>Communications</p>
            <p>When You use the Platform or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.</p>
            <p>Platform for Transaction and Communication</p>
            <p>The Platform enables the Buyer and Seller to transact on the Platform. . TejBazar is not and cannot be a party to or control in any manner any transaction between the Platform's Users.</p>
            <p>Charges</p>
            <p>TejBazar may charge a nominal fee for browsing and buying on the Platform. TejBazar reserves the right to change its Fee Policy from time to time. In particular, TejBazar may at its sole discretion introduce new services/fees and modify some or all of the existing services/fees offered on the Platform. In such an event, TejBazar reserves the right to introduce fees for the new services offered or amend/introduce fees for existing/new services, as the case may be. Changes to the Fee Policy shall be posted on the Platform and such changes shall automatically become effective immediately after they are posted on the Platform. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for compliance of all applicable laws including those in India for making payments to TejBazar Internet Private Limited.</p>
            <p>Use of the Platform</p>
            <p>You agree, undertake and confirm that Your use of Platform shall be strictly governed by the following binding principles:

You shall not host, display, upload, modify, publish, transmit, update or share any information which:</p>
            <p>(a) belongs to another person and to which You do not have any right to;</p>
            <p>(b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p>
            <p>(c) is misleading in any way;</p>
            <p>(d) is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</p>
            <p>(e) harasses or advocates harassment of another person;</p>
            <p>(f) involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</p>
            <p>(g) promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</p>
            <p>(h) infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];</p>
            <p>(i) promotes an illegal or unauthorized copy of another person's copyrighted work (see "Copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices;</p>
            <p>(j) contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</p>
            <p>(k) provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</p>
            <p>(l) provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</p>
            <p>(m) contains video, photographs, or images of another person (with a minor or an adult).</p>
            <p>(n) tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</p>
            <p>(o) engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" products related to the Platform. Throughout this Terms of Use, TejBazar's prior written consent means a communication coming from TejBazar's Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;</p>
            <p>(p) solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</p>
            <p>(q) interferes with another USER's use and enjoyment of the Platform or any other individual's User and enjoyment of similar services;</p>
            <p>(r) refers to any Platform or URL that, in Our sole discretion, contains material that is inappropriate for the Platform or any other Platform, contains content that would be prohibited or violates the letter or spirit of these Terms of Use.</p>
            <p>(s) harm minors in any way;</p>
            <p>(t) infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</p>
            <p>(u) violates any law for the time being in force;</p>
            <p>(v) deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
            <p>Terms & Conditions:</p>
            <p>TejBazar Foundation is a Section 8 company registered under the Companies Act 2013.</p>
            <p>The contribution, once made, will not be refunded.</p>
            <p>No certificate under section 80G of the Income Tax Act, 1961 will be issued.</p>
            <p>By proceeding to donate, you understand and confirm that this donation is not from any foreign source as defined under Foreign Contribution (Regulation) Act, 2010.</p>
            <p>By proceeding to donate the money, you consent and expressly waive any and all claims, rights of action and/or remedies (under law or otherwise) that you may have against any of the TejBazar Group entities arising out of or in connection with the aforesaid transaction.</p>
            <p>TejBazar Internet Pvt. Ltd may share your Personal Identifiable Information which may include your name, phone number, etc with TejBazar Foundation.</p>
            <p>Privacy</p>
            <p>We view protection of Your privacy as a very important principle. We understand clearly that You and Your Personal Information is one of Our most important assets. We store and process Your Information including any sensitive financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and Rules there under. Our current Privacy Policy is available at Privacy. if You object to Your Information being transferred or used in this way please do not use the Platform.</p>
            <p>We may share personal information with our other corporate entities and affiliates. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out.</p>
            <p>Contacting the Seller</p>
            <p>At TejBazar we are committed towards ensuring that disputes between sellers and buyers are settled amicably by way of the above dispute resolution mechanisms and procedures. However, in the event that a buyer wishes to contact the seller, he/ she may proceed to do so by accessing the seller related information made available by the sellers on their product listing pages. Alternatively, the buyers may also reach out to customer support at 044-45614700 or access help center at https://www.TejBazar.com/helpcentre.</p>
            <p></p>

            














        </div>
        </div>
        
        </div>

    </div>
</section>

    
    </>
	
  )
}

export default Terms
