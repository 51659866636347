import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { toast, ToastContainer } from "react-toastify";
import { json, Link, useLocation } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";
import Vdfooter from "./Vdfooter";
import ImageUpload from "./ImagesUpload.js";
import Pincode from "./Pincode.js";
import queryString from "query-string";

let retArrg = "";
function Adproduct() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const subCategoryId = queryParams.subCatId;
  const requestType = queryParams.requestType;
  const q = queryParams.q;
  const productId = queryParams.prodId;
  const catId = queryParams.catId;

  const [jsonData, setJsonData] = useState([]);
  const [allFilenames, setAllFilenames] = useState("");
  const [allFullFilenames, setAllFullFilenames] = useState("");

  const [defauldimg, setDefauldImg] = useState([]);

  const handleFileDataChange = (retArr, allFilenames, allFullFilenames) => {
    console.log("retArr  0   " + retArr);
    retArrg = retArr;
    setAllFilenames(allFilenames);
    setAllFullFilenames(allFullFilenames);
  };

  useEffect(() => {
    loadasinNo();
    loadmainmenu();

    $(".alphaNumeric").on("keypress paste", function (event) {
      var clipboardData, pastedData;
      if (event.type === "paste") {
        clipboardData =
          event.originalEvent.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData("text");
        if (!/^[a-z0-9 ]+$/i.test(pastedData)) {
          event.preventDefault();
        }
      } else {
        var inputValue = event.which;
        if (
          !(inputValue >= 65 && inputValue <= 90) && // A-Z
          !(inputValue >= 97 && inputValue <= 122) && // a-z
          !(inputValue >= 48 && inputValue <= 57) && // 0-9
          !(inputValue === 32)
        ) {
          // Space
          event.preventDefault();
        }
      }
    });

    $(".number,.onlynumber").on("input", function () {
      var value = $(this).val();
      value = value.replace(/[^\d.]/g, "");
      $(this).val(value);
    });
    $(".number").on("blur", function () {
      var value = $(this).val();
      if (/^\d+$/.test(value)) {
        value += ".00";
        $(this).val(value);
      }
    });

    $(".addNewRow").click(function (e) {
      e.preventDefault();

      // Get the values from the input fields
      var input1Value = $("#attrkey").val(); //$(this).closest('.row').find('.inputform_new').eq(0).val();
      var input2Value = $("#attrvalue").val(); //$(this).closest('.row').find('.inputform_new').eq(1).val();

      // Validate that both input fields are not empty
      if (input1Value.trim() === "" || input2Value.trim() === "") {
        toast.error("Both input fields must be filled!");
        return;
      }

      // Create and append the new row
      var row =
        "<tr>" +
        "<td>" +
        input1Value +
        "</td>" +
        "<td>" +
        input2Value +
        "</td>" +
        '<td><button class="removeRow">Remove</button></td>' +
        "</tr>";
      $("#myTable tbody").append(row);

      // Clear the input fields
      $("#attrkey,#attrvalue").val("");
    });

    // Remove row
    $(document).on("click", ".removeRow", function () {
      $(this).closest("tr").remove();
    });

    $("#_mrp").on("blur", function () {
      var newPrice = parseFloat($("#_newPrice").val());
      var mrp = parseFloat($("#_mrp").val());

      if (mrp >= newPrice) {
      } else {
        toast.error("MRP Should Be Greather Than or Equals To Listing Price.");
      }
    });
  }, []);

  function validateField(cls) {
    var isValid = true; // Flag to track the validation status

    // Iterate over each input field
    $("." + cls).each(function () {
      var value = $(this).val();
      var fieldName = $(this).attr("errortxt");

      // Check if the field is empty
      if (value === "") {
        isValid = false;
        $(this).addClass("error");
        toast.error(fieldName + " is required");
      } else {
        $(this).removeClass("error");
      }
    });

    if (isValid) {
      return true;
    } else {
      return false;
    }
  }

  function loadasinNo() {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAsinNo`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      var asinNo = response.po_DETAIL[0].ASINNO;
      try {
        $("#asin").val(asinNo).toUpperCase();
      } catch (err) {}
    });
  }

  function loadmainmenu() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getMainMenu`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        $("#_mainCat").empty();
        $("#_mainCat").append('<option value="">Select</option>');
        $.each(response.po_DETAIL, function (key, value) {
          $("#_mainCat").append(
            '<option value="' +
              response.po_DETAIL[key].ID +
              '" >' +
              response.po_DETAIL[key].CATEGORY_NAME +
              ""
          );
        });
      }

      if (requestType === "listing") {
        $("#_mainCat").val(catId);
        loadSubcat();
      }
    });
  }

  const loadSubcat = () => {
    var valdata = {
      catId: $("#_mainCat option:selected").val(),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getsubCategory`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        $("#_subCat").empty();
        $("#_subCat").append('<option value="">Select</option>');
        $.each(response.po_DETAIL, function (key, value) {
          $("#_subCat").append(
            '<option value="' +
              response.po_DETAIL[key].ID +
              '" >' +
              response.po_DETAIL[key].SUB_CATEGORY_NAME +
              ""
          );
        });
      }

      if (requestType === "listing") {
        $("#_subCat").val(subCategoryId);
        fillseleted(productId);
      }
    });
  };

  const saveContinue = (flag) => {
    if (
      validateField("requiredtxtoffer") &&
      validateField("requiredtxtinventory") &&
      validateField("requiredtxtattribute") &&
      validateField("requiredtxtmoreinfo")
    ) {
      var jsonArray = [];

      $("#myTable tbody tr").each(function () {
        var attributeKey = $(this).find("td:eq(0)").text();
        var attributeValue = $(this).find("td:eq(1)").text();

        var obj = {
          Attribute_Key: attributeKey,
          Attribute_Value: attributeValue,
        };

        jsonArray.push(obj);
      });

      var jsonString = JSON.stringify(jsonArray);
      console.log("retarr  " + retArrg);

      var valdata = {
        menuId: $("#_mainCat option:selected").val(),
        catId: $("#_subCat option:selected").val(),
        skuId: $("#_skuId").val().trim().toUpperCase(),
        productNAme: $("#_productName").val().trim(),
        prodDesc: $("#_desc").val().trim(),
        newPrice: $("#_newPrice").val().trim(),
        qty: $("#_stockQty").val().trim(),
        //maxAllowed : $("#_maxAllowed").val().trim(),
        hsnCode: $("#hsnCode").val().trim().toUpperCase(),
        mrp: $("#_mrp").val().trim(),
        discQty: $("#ofrqty").val().trim() ? $("#ofrqty").val().trim() : "0",
        discPer: $("#oframt").val().trim() ? $("#oframt").val().trim() : "0",

        discQtySecond: $("#oframtSecond").val().trim()
          ? $("#oframtSecond").val().trim()
          : "0",
        discPerSecond: $("#oframtSecond").val().trim()
          ? $("#oframtSecond").val().trim()
          : "0",

        discQtythird: $("#ofrqtythird").val().trim()
          ? $("#ofrqtythird").val().trim()
          : "0",
        discPerthird: $("#oframtthird").val().trim()
          ? $("#oframtthird").val().trim()
          : "0",
        //	deliveredIn : $("#_deliverdIn").val().trim(),
        mrpprice: $("#_mrp").val().trim(),
        expiryday: $("#exry").val().trim()
          ? parseInt($("#exry").val().trim()) *
            parseInt($("#exryunt option:selected").val())
          : "",
        usageHow: $("#use").val().trim(),
        prodType: $("#ptype").val().trim(),
        skintype: $("#hstype").val().trim(),
        bulletone: $("#_bulletOne").val().trim(),
        bullettwo: $("#_bulletTwo").val().trim(),
        bulletthree: $("#_bulletThree").val().trim(),
        bulletfour: $("#_bulletFour").val().trim(),
        bulletfive: $("#_bulletFive").val().trim(),
        includedcomponent: $("#inclditm").val().trim(),

        stockdesc: $("#_stockdes").val().trim(),
        purchasert: $("#purrate").val().trim(),
        batchno: $("#batchno").val().trim(),
        purtype: $("#purtype").val().trim(),
        salee: $("#salee").val().trim(),
        damagee: $("#damagee").val().trim(),
        returndays: $("#return").val().trim() ? $("#return").val().trim() : "0",
        sizeunit: $("#unitSizeIdOne").prop("checked")
          ? $("#size1").val().trim() +
            " " +
            $("#unit1 option:selected").val().trim().toUpperCase()
          : $("#unitSizeIdTwo").prop("checked")
          ? $("#clothSize").val()
          : $("#unitSizeIdThree").prop("checked") ? $("#sizeChartSelect").val() : "",
        sizeTypeS: $("#unitSizeIdOne").prop("checked")
          ? "1"
          : $("#unitSizeIdTwo").prop("checked")
          ? "2"
          : $("#unitSizeIdThree").prop("checked") ? "3" : "",
        flavour: ($("#flavourDisid").prop("checked") ? $("#flavour").val().trim() : ""),
        dimentionheight: ($("#dimensionDisid").prop("checked") ? $("#_height").val().trim() : ""),
        dimentionwidth: ($("#dimensionDisid").prop("checked") ? $("#_width").val().trim() : ""),
        dimentionlength: ($("#dimensionDisid").prop("checked") ? $("#_length").val().trim() : ""),
        volumec: ($("#volumeDisid").prop("checked") ? 
        $("#volume").val().trim() +
          " " +
          $("#volumeunit option:selected").val().trim() : ""),

        brandName: ($("#brandDisid").prop("checked") ? $("#brandname").val().trim() : ""),
        modelyear : ($("#modelyrDisid").prop("checked") ? $("#modelyear_").val().trim() : ""),
        gear: ($("#gearDisid").prop("checked") ? $("#gear").val().trim() : ""),
        framsize: ($("#frameDisid").prop("checked") ? 
        $("#framesize").val().trim() +
          " " +
          ($("#frmunit option:selected").val() ? $("#frmunit option:selected").val() : "" ) :  ""),
        itemweight: ($("#itemWgDisid").prop("checked") ? $("#itmwt").val().trim() : ""),
        ramsize: ($("#ramDisid").prop("checked") ? $("#ramsize").val().trim() : ""),
        asinno: $("#asin").val().trim().toUpperCase(),
        manufacturerdtl: $("#_manufacture").val().trim(),
        importardtl: $("#importer").val().trim(),
        packerdtl: $("#packer").val().trim(),
        nation: $("#country").val().trim(),

        inventoryleft: $("#shwinvtry").val().trim(),

        ordermdregion: $("#odrshow").val().trim(),
        attributeData: jsonString,
        vendorId: $("#vendorId").val(),
        userId: $("#vendorId").val(),
        imageData: JSON.stringify(retArrg),
        imageFileNAme: allFilenames,
        imagesFullName: allFullFilenames,
        listingvariant: $("#listingexist").is(":checked")
          ? $("#_existlist option:selected").val()
          : "0",
        shadesname: ($("#shadeDisid").prop("checked") ? $("#shadename").val().trim() : ""),
        colourname: ($("#colorDisid").prop("checked") ? $("#colorname").val().trim() : ""),
        displaySize: ($("#displaySizeDisid").prop("checked") ? $("#dissize").val() + " " + $("#disunit option:selected").val() : ""),
        gender: ($("#unitSizeId").prop("checked") ? $("#_gender option:selected").val() : ""),
        opFlag : flag,
        updateStrId : productId,
        colorId : $("#colorId").val()

      };

      const settings = {
        async: true,
        crossDomain: true,
        url: `${Baseurl.baseUrl}saveProductDtls`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(valdata),
      };

      $.ajax(settings).done(function (response) {
        if (parseInt(response.po_STATUS) === 0) {
          toast.error(
            ["", null, undefined, "null", "undefined"].includes(response.po_MSG)
              ? "Failed To Saved Record!!"
              : response.po_MSG
          );
        } else if (parseInt(response.po_STATUS) === 1) {
          (flag === 'N' ? toast.success("Record Saved Successfully!!") : toast.success("Record Updated Successfully!!"));
          $("#rowId").val(response.ROW_ID);
         // window.location.reload();
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        toast.error("An error occurred while making the request.");
      });
    }
  };

  const existingprodlst = () => {
    
    if ($("#listingexist").is(":checked") && $("#_existlist").is(":empty")) {
      if (!$("#_mainCat option:selected").val().trim()) {
        toast.error("Please Select Main Category.");
        return false;
      }

      if (!$("#_subCat option:selected").val().trim()) {
        toast.error("Please Select Sub Category.");
        return false;
      }

      var valdata = {
        vendorId: $("#vendorId").val(),
        mainCat: $("#_mainCat option:selected").val(),
        subCat: $("#_subCat option:selected").val(),
      };

      const settings = {
        async: true,
        crossDomain: true,
        url: `${Baseurl.baseUrl}existingProd`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(valdata),
      };

      $.ajax(settings).done(function (response) {
        if (parseInt(response.po_STATUS) == 1) {
          $("#_existlist").empty();
          $("#_existlist").append('<option value="">Select</option>');
          $.each(response.po_DETAIL, function (key, value) {
            $("#_existlist").append(
              '<option value="' +
                response.po_DETAIL[key].ID +
                '" >' +
                response.po_DETAIL[key].PRODUCT_NAME +
                ""
            );
          });
        } else {
          toast.error("No Data Found For The Selection.");
          $("#listingNew").prop("checked", true);
        }
      });
    }
  };

  const fillseleted = (prodId) => {
    
    var selectedProd = "";
    try {
      selectedProd = prodId
        ? prodId
        : $("#_existlist option:selected").val().trim();
    } catch (err) {
      return false;
    }

    var valdata = {
      vendorId: $("#vendorId").val(),
      prodId: selectedProd,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchexistingProd`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      var mainStr = "";
      
      setDefauldImg([`${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_1}`,
      response.po_DETAIL[0].IMAGES_NAME_2 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_2}` : "",
      response.po_DETAIL[0].IMAGES_NAME_3 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_3}` : "",
      response.po_DETAIL[0].IMAGES_NAME_4 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_4}` : "",
      response.po_DETAIL[0].IMAGES_NAME_5 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_5}` : "",
      response.po_DETAIL[0].IMAGES_NAME_6 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_6}` : "",
      response.po_DETAIL[0].IMAGES_NAME_7 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_7}` : "",
      response.po_DETAIL[0].IMAGES_NAME_8 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_8}` : "",
      response.po_DETAIL[0].IMAGES_NAME_9 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_9}` : "",
      response.po_DETAIL[0].IMAGES_NAME_10 ? `${Baseurl.getImgUrl}prodImages/${response.po_DETAIL[0].IMAGES_NAME_10}` : ""
       ]);
      if (parseInt(response.po_STATUS) == 1) {
        var valcondition = [undefined, null, "undefined", "null", ""];
        $("#_skuId").val(
          valcondition.includes(response.po_DETAIL[0].SKU_ID)
            ? ""
            : response.po_DETAIL[0].SKU_ID
        );
        $("#_productName").val(
          valcondition.includes(response.po_DETAIL[0].PRODUCT_NAME)
            ? ""
            : response.po_DETAIL[0].PRODUCT_NAME
        );
        $("#_desc").val(
          valcondition.includes(response.po_DETAIL[0].PRODUCT_DESC)
            ? ""
            : response.po_DETAIL[0].PRODUCT_DESC
        );
        $("#_newPrice").val(
          valcondition.includes(response.po_DETAIL[0].CURRENT_AMOUNT)
            ? ""
            : response.po_DETAIL[0].CURRENT_AMOUNT
        );
        $("#_stockQty").val(
          valcondition.includes(response.po_DETAIL[0].STOCK_QUANTITY)
            ? ""
            : response.po_DETAIL[0].STOCK_QUANTITY
        );
        $("#hsnCode").val(
          valcondition.includes(response.po_DETAIL[0].HSN_CODE)
            ? ""
            : response.po_DETAIL[0].HSN_CODE
        );
        $("#_mrp").val(
          valcondition.includes(response.po_DETAIL[0].MRP)
            ? ""
            : response.po_DETAIL[0].MRP
        );
        $("#ofrqty").val(
          valcondition.includes(response.po_DETAIL[0].OFFER_QTY) || parseInt(response.po_DETAIL[0].OFFER_QTY) === 0
            ? ""
            : response.po_DETAIL[0].OFFER_QTY
        );
        $("#oframt").val(
          valcondition.includes(response.po_DETAIL[0].OFFER_DISC) || parseInt(response.po_DETAIL[0].OFFER_DISC) === 0
            ? ""
            : response.po_DETAIL[0].OFFER_DISC
        );
        $("#exry").val(
          valcondition.includes(response.po_DETAIL[0].EXPIRY_IN)
            ? ""
            : response.po_DETAIL[0].EXPIRY_IN
        );
        $("#use").val(
          valcondition.includes(response.po_DETAIL[0].HOWTOUSE)
            ? ""
            : response.po_DETAIL[0].HOWTOUSE
        );
        $("#ptype").val(
          valcondition.includes(response.po_DETAIL[0].PRODTYPE)
            ? ""
            : response.po_DETAIL[0].PRODTYPE
        );
        $("#hstype").val(
          valcondition.includes(response.po_DETAIL[0].SKINTYPE)
            ? ""
            : response.po_DETAIL[0].SKINTYPE
        );
        $("#_bulletOne").val(
          valcondition.includes(response.po_DETAIL[0].DESC_1)
            ? ""
            : response.po_DETAIL[0].DESC_1
        );
        $("#_bulletTwo").val(
          valcondition.includes(response.po_DETAIL[0].DESC_2)
            ? ""
            : response.po_DETAIL[0].DESC_2
        );
        $("#_bulletThree").val(
          valcondition.includes(response.po_DETAIL[0].DESC_3)
            ? ""
            : response.po_DETAIL[0].DESC_3
        );
        $("#_bulletFour").val(
          valcondition.includes(response.po_DETAIL[0].DESC_4)
            ? ""
            : response.po_DETAIL[0].DESC_4
        );
        $("#_bulletFive").val(
          valcondition.includes(response.po_DETAIL[0].DESC_5)
            ? ""
            : response.po_DETAIL[0].DESC_5
        );
        $("#inclditm").val(
          valcondition.includes(response.po_DETAIL[0].INCLUDED_COMPONENT)
            ? ""
            : response.po_DETAIL[0].INCLUDED_COMPONENT
        );

        $("#_stockdes").val(
          valcondition.includes(response.po_DETAIL[0].STOCKDESC)
            ? ""
            : response.po_DETAIL[0].STOCKDESC
        );
        $("#purrate").val(
          valcondition.includes(response.po_DETAIL[0].PURCHASERATE)
            ? ""
            : response.po_DETAIL[0].PURCHASERATE
        );
        $("#batchno").val(
          valcondition.includes(response.po_DETAIL[0].BATCHNO)
            ? ""
            : response.po_DETAIL[0].BATCHNO
        );
        $("#purtype").val(
          valcondition.includes(response.po_DETAIL[0].TYPEPURCHASE)
            ? ""
            : response.po_DETAIL[0].TYPEPURCHASE
        );
        $("#salee").val(
          valcondition.includes(response.po_DETAIL[0].SALE)
            ? ""
            : response.po_DETAIL[0].SALE
        );
        $("#damagee").val(
          valcondition.includes(response.po_DETAIL[0].DAMAGE)
            ? ""
            : response.po_DETAIL[0].DAMAGE
        );
        $("#return").val(
          valcondition.includes(response.po_DETAIL[0].NO_OF_DAYS_TO_RETURN)
            ? ""
            : response.po_DETAIL[0].NO_OF_DAYS_TO_RETURN
        );
try{
  if(parseInt(response.po_DETAIL[0].SIZE_TYPE) === 1){
    $("#size1").val(
      valcondition.includes(response.po_DETAIL[0].SIZE)
        ? ""
        : (response.po_DETAIL[0].SIZE).split(" ")[0]
    );

    $("#unit1").val(valcondition.includes(response.po_DETAIL[0].SIZE)
    ? ""
    : (response.po_DETAIL[0].SIZE).split(" ")[1]);
  }else if(parseInt(response.po_DETAIL[0].SIZE_TYPE) === 2){
$("#clothSize").val(valcondition.includes(response.po_DETAIL[0].SIZE)
? ""
: response.po_DETAIL[0].SIZE)
  }else if(parseInt(response.po_DETAIL[0].SIZE_TYPE) === 3){
    $("#sizeChartSelect").val(valcondition.includes(response.po_DETAIL[0].SIZE)
    ? ""
    : response.po_DETAIL[0].SIZE)
  }
}catch(err){}
       
        

        $("#flavour").val(
          valcondition.includes(response.po_DETAIL[0].FLAVOUR)
            ? ""
            : response.po_DETAIL[0].FLAVOUR
        );
        $("#_height").val(
          valcondition.includes(response.po_DETAIL[0].DEMENSION_HEIGHT)
            ? ""
            : response.po_DETAIL[0].DEMENSION_HEIGHT
        );
        $("#_width").val(
          valcondition.includes(response.po_DETAIL[0].DIMENSION_WIDTH)
            ? ""
            : response.po_DETAIL[0].DIMENSION_WIDTH
        );
        $("#_length").val(
          valcondition.includes(response.po_DETAIL[0].DIMMESION_LENGTH)
            ? ""
            : response.po_DETAIL[0].DIMMESION_LENGTH
        );
        $("#volume").val(
          valcondition.includes(response.po_DETAIL[0].VOLUME)
            ? ""
            : (response.po_DETAIL[0].VOLUME).split(" ")[0]
        );

        $("#volumeunit").val(valcondition.includes(response.po_DETAIL[0].VOLUME)
        ? ""
        : (response.po_DETAIL[0].VOLUME).split(" ")[1]);

        $("#brandname").val(
          valcondition.includes(response.po_DETAIL[0].BRANDNAME)
            ? ""
            : response.po_DETAIL[0].BRANDNAME
        );
        $("#gear").val(
          valcondition.includes(response.po_DETAIL[0].GEAR)
            ? ""
            : response.po_DETAIL[0].GEAR
        );



        $("#framesize").val(
          valcondition.includes(response.po_DETAIL[0].FRAMESIZE)
            ? ""
            : (response.po_DETAIL[0].FRAMESIZE).split(" ")[0]
        );

         $("#frmunit").val(valcondition.includes(response.po_DETAIL[0].FRAMESIZE)
         ? ""
         : (response.po_DETAIL[0].FRAMESIZE).split(" ")[1]);

        $("#itmwt").val(
          valcondition.includes(response.po_DETAIL[0].WEIGHT)
            ? ""
            : response.po_DETAIL[0].WEIGHT
        );
        $("#ramsize").val(
          valcondition.includes(response.po_DETAIL[0].RAM)
            ? ""
            : response.po_DETAIL[0].RAM
        );
        $("#asin").val(
          valcondition.includes(response.po_DETAIL[0].ASINNO)
            ? ""
            : response.po_DETAIL[0].ASINNO
        );
        $("#_manufacture").val(
          valcondition.includes(response.po_DETAIL[0].MANUFACTURERDTL)
            ? ""
            : response.po_DETAIL[0].MANUFACTURERDTL
        );
        $("#importer").val(
          valcondition.includes(response.po_DETAIL[0].IMPORTERDTL)
            ? ""
            : response.po_DETAIL[0].IMPORTERDTL
        );
        $("#packer").val(
          valcondition.includes(response.po_DETAIL[0].PACKERDTL)
            ? ""
            : response.po_DETAIL[0].PACKERDTL
        );
        $("#country").val(
          valcondition.includes(response.po_DETAIL[0].NATION)
            ? ""
            : response.po_DETAIL[0].NATION
        );

        $("#shwinvtry").val(
          valcondition.includes(response.po_DETAIL[0].LEFTSTOCK)
            ? ""
            : response.po_DETAIL[0].LEFTSTOCK
        );

        $("#odrshow").val(
          valcondition.includes(response.po_DETAIL[0].SALE_MADE_IN_ORIGIN)
            ? ""
            : response.po_DETAIL[0].SALE_MADE_IN_ORIGIN
        );

        $("#modelyear_").val(
          valcondition.includes(response.po_DETAIL[0].MODELYEAR)
            ? ""
            : response.po_DETAIL[0].MODELYEAR
        );

$("#dissize").val(
          valcondition.includes(response.po_DETAIL[0].DISPLAYSIZE)
            ? ""
            : (response.po_DETAIL[0].DISPLAYSIZE).split(" ")[0]
        );

        $("#disunit").val(valcondition.includes(response.po_DETAIL[0].DISPLAYSIZE)
        ? ""
        : (response.po_DETAIL[0].DISPLAYSIZE).split(" ")[1]);

        $("#shadename").val(
          valcondition.includes(response.po_DETAIL[0].SHADES)
            ? ""
            : response.po_DETAIL[0].SHADES
        );
////
$("#ofrqtySecond").val(
  valcondition.includes(response.po_DETAIL[0].OFFER_QTY_SNT) || parseInt(response.po_DETAIL[0].OFFER_QTY_SNT) === 0
    ? ""
    : response.po_DETAIL[0].OFFER_QTY_SNT
);
$("#oframtSecond").val(
  valcondition.includes(response.po_DETAIL[0].OFFER_DISC_SNT) || parseInt(response.po_DETAIL[0].OFFER_DISC_SNT) === 0
    ? ""
    : response.po_DETAIL[0].OFFER_DISC_SNT
);
$("#ofrqtythird").val(
  valcondition.includes(response.po_DETAIL[0].OFFER_QTY_THRD) || parseInt(response.po_DETAIL[0].OFFER_QTY_THRD) === 0
    ? ""
    : response.po_DETAIL[0].OFFER_QTY_THRD
);
$("#oframtthird").val(
  valcondition.includes(response.po_DETAIL[0].OFFER_DISC_THRD) || parseInt(response.po_DETAIL[0].OFFER_DISC_THRD) === 0
    ? ""
    : response.po_DETAIL[0].OFFER_DISC_THRD
);
/////checkbox 

if(!valcondition.includes(response.po_DETAIL[0].RAM)){
  $("#ramDisid").prop("checked", true);
  $(".ramDis" ).prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].COLOR)){
  $("#colorDisid").prop("checked", true);
  $(".colorDis").prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].DEMENSION_HEIGHT)){
  $("#dimensionDisid").prop("checked", true);
  $(".dimensionDis" ).prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].DISPLAYSIZE)){
  $("#displaySizeDisid").prop("checked", true);
  $(".displaySizeDis").prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].FLAVOUR)){
  $("#flavourDisid").prop("checked", true);
  $(".flavourDis" ).prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].FRAMESIZE)){
  $("#frameDisid").prop("checked", true);
  $(".frameDis" ).prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].GEAR)){
  $("#gearDisid").prop("checked", true);
  $(".gearDis" ).prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].GENDERTYPE)){
  $("#unitSizeId").prop("checked", true);
  $(".unitSize" ).prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].MODELYEAR)){
  $("#modelyrDisid").prop("checked", true);
  $(".modelyrDis").prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].SHADES)){
  $("#shadeDisid").prop("checked", true);
  $(".shadeDis" ).prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].VOLUME)){
  $("#volumeDisid").prop("checked", true);
  $(".volumeDis").prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].WEIGHT)){
  $("#itemWgDisid").prop("checked", true);
  $(".itemWgDis").prop("disabled", false);
}
if(!valcondition.includes(response.po_DETAIL[0].BRANDNAME)){
  $("#brandDisid").prop("checked", true);
  $(".brandDis").prop("disabled", false);
}

        $.each(response.po_DETAIL_OTHER, function (key, value) {
          mainStr =
            mainStr +
            "<tr><td>" +
            this.ATTRIBUTE_KEY +
            "</td><td>" +
            this.ATTRIBUTE_VALUE +
            '</td><td><button class="removeRow">Remove</button></td></tr>';
        });
        $("#myTable tbody").append(mainStr);
      }
    });
  };

  const nextOffer = () => {
    if (validateField("requiredtxtoffer") && validateOnButtonClick()) {
      $("#tab-2").click();
    }
  };

  const nextprodInfo = () => {
    console.log("retArrg++ " + retArrg);
    $("#tab-4").click();
  };

  const nextInventory = () => {
    if (validateField("requiredtxtinventory")) {
      $("#tab-5").click();
    }
  };

  const nextAttr = () => {
    if (validateField("requiredtxtattribute")) {
      $("#tab-6").click();
    }
  };
  const nextMoreInfo = () => {
    if (validateField("requiredtxtmoreinfo")) {
      $("#tab-7").click();
    }
  };

  const prevprodInfo = () => {
    $("#tab-2").click();
  };
  const prevInventory = () => {
    $("#tab-3").click();
  };
  const prevAttr = () => {
    $("#tab-4").click();
  };

  const prevMoreinfo = () => {
    $("#tab-5").click();
  };

  const prevOtherdtl = () => {
    $("#tab-6").click();
  };

  const enableOnChecked = (event) => {
    console.log("" + event.target.value);
    if (event.target.checked) {
      $("." + event.target.value).prop("disabled", false);
    } else {
      $("." + event.target.value).prop("disabled", true);
    }
  };


  ///

  function validateOnButtonClick() {
    const ofrqty = document.getElementById('ofrqty').value.trim();
  const oframt = document.getElementById('oframt').value.trim();
  const ofrqtySecond = document.getElementById('ofrqtySecond').value.trim();
  const oframtSecond = document.getElementById('oframtSecond').value.trim();
  const ofrqtythird = document.getElementById('ofrqtythird').value.trim();
  const oframtthird = document.getElementById('oframtthird').value.trim();
  
    if (ofrqty === '' && oframt === '' &&
      ofrqtySecond === '' && oframtSecond === '' &&
      ofrqtythird === '' && oframtthird === '') {
    return true;
  } else {
    if (ofrqty === '' || oframt === '' ||
        ofrqtySecond === '' || oframtSecond === '' ||
        ofrqtythird === '' || oframtthird === '') {
      toast.error('Please fill all six fields.');
      return false;
    }
  }

  
    if (
      (parseInt(ofrqty) <= 0 && oframt !== '') ||
      (parseInt(ofrqtySecond) <= 0 && oframtSecond !== '') ||
      (parseInt(ofrqtythird) <= 0 && oframtthird !== '')
    ) {
      toast.error('Quantity should be greater than 0 if a discount is specified.');
      return false;
    }
  
    if (
      (parseInt(ofrqtySecond) <= parseInt(ofrqty)) ||
      (parseInt(ofrqtythird) <= parseInt(ofrqtySecond))
    ) {
      toast.error('Quantity should be greater than the previous one.');
      return false;
    }
  
    return true;
  }

  const handleNew = () => {
    $("#_existlist").empty()
  };

  return (
    <>
      <ToastContainer />
      <section style={{ backgroundColor: "#f6f9fa" }}>
        <div className="container">
          <div className=" vhdiv1 pt-3 pb-0">
            <p className="vhp2">Add Product </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="wrapper">
            <div className="tabs">
              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-1"
                  defaultChecked
                  className="tab-switch"
                />
                <label htmlFor="tab-1" className="tab-label">
                  Offer
                </label>
                <div className="tab-content">
                  <div className="row rwhvr1 aic1 pt-2 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required	">Main Category</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <select
                        type="text"
                        id="_mainCat"
                        className="inputform requiredtxtoffer"
                        onChange={loadSubcat}
                        errortxt="Main Category"
                      ></select>
                      <span className="vhsp1">
                        Select the Main Category of product.
                      </span>
                    </div>
                  </div>
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Sub Category </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <select
                        type="text "
                        id="_subCat"
                        className="inputform requiredtxtoffer"
                        errortxt="Sub Category"
                      ></select>
                      <span className="vhsp1">
                        Select the Sub Category of product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Listing Type</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <div className="col-md-3 mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="listingNew"
                          onClick={handleNew}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="listingNew"
                        >
                          New
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="listingexist"
                            onClick={existingprodlst}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="listingexist"
                          >
                            Existing Product
                          </label>
                        </div>
                        <div className="col-md-9">
                          <select
                            type="text"
                            className="inputform"
                            id="_existlist"
                            onChange={()=> fillseleted('')}
                          ></select>
                        </div>
                      </div>

                      <span className="vhsp1">
                        Select the Listing type of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Product SKU</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="20"
                        className="inputform requiredtxtoffer alphaNumeric"
                        errortxt="SKU"
                        id="_skuId"
                      />
                      <span className="vhsp1">
                        Provide Unique SKU ID for Product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Product Name </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="200"
                        className="inputform valoffer requiredtxtoffer"
                        errortxt="Product Name"
                        id="_productName"
                        placeholder="nelco 1.5 ltr pressure cooker inner lid 1.5ltr isi marked branded cooker aluminium high good quality 1.5liter cookers small cooker ideal for 1 or 2 persons"
                      />
                      <span className="vhsp1">
                        Search terms that describe your product: no repetition,
                        no competitor brand names or ASINs.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 ">Product Description </p>
                    </div>
                    <div className="col-md-9  col-12">
                      <textarea
                        type="text"
                        className="inputform "
                        maxlength="2000"
                        errortxt="Product Description"
                        id="_desc"
                        rows={5}
                      ></textarea>
                      <span className="vhsp1">
                        Text - maximum 2000 characters. HTML tags and special
                        characters not on a standard keyboard (eg. ®, ©, ™ or
                        other Type 1 High ASCII characters) are not supported.
                        Do not use email IDs, phone numbers, external URLs or
                        promotional messages such as 'sale', 'price', 'offer'
                        etc
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 ">HSN Code </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="20"
                        className="inputform  alphaNumeric"
                        errortxt="HSN Code"
                        id="hsnCode"
                      />
                      <span className="vhsp1">
                        Provide the applicable 6/8 digit HSN tax code for your
                        product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">List Price </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        value="INR&#8377;"
                        className="inpstat number"
                        disabled
                      />
                      <input
                        type="text"
                        maxlength="10"
                        className="inpstat2 requiredtxtoffer number"
                        errortxt="List Price"
                        id="_newPrice"
                      />
                      <div>
                        <span className="vhsp1">
                          The Listing of the product, in local currency.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Maximum Retail Price </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        value="INR&#8377;"
                        className="inpstat"
                        disabled
                      />
                      <input
                        type="text"
                        maxlength="10"
                        className="inpstat2 requiredtxtoffer number"
                        errortxt="MRP"
                        id="_mrp"
                      />
                      <div>
                        <span className="vhsp1">
                          Maximum Retail Price (MRP) indicates the price
                          mentioned on the product or package. This is the
                          highest price at which the product can be sold to the
                          customer. Please do not use commas or currency signs
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Maximum Shelf Life/ Expiry In </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="10"
                        className="inpstat3 onlynumber"
                        id="exry"
                        name=""
                      />
                      <select type="text" className="inpstat3" id="exryunt">
                        <option value="1">Select</option>
                        <option value="1" className="option">
                          Day
                        </option>
                        <option value="7">Week</option>
                        <option value="30">Month</option>
                        <option value="365">Year</option>
                      </select>
                      <div>
                        <span className="vhsp1">
                          A number with up to 10 digits to the left of the
                          decimal point and 2 digits to the right of the decimal
                          point. Please do not use commas.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Offer &amp; Discount</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">Quantity (Nos)</div>
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform onlynumber"
                            placeholder="2"
                            id="ofrqty"
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">
                            Discount Percent On MRP (%)
                          </div>
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform number"
                            placeholder="5%"
                            id="oframt"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="vhsp1">
                          {" "}
                          Please do not use commas or Percentage signs
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* disc added */}
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 "></div>
                    <div className="col-md-9 col-12">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">Quantity (Nos)</div>
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform onlynumber"
                            placeholder="2"
                            id="ofrqtySecond"
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">
                            Discount Percent On MRP (%)
                          </div>
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform number"
                            placeholder="5%"
                            id="oframtSecond"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="vhsp1">
                          {" "}
                          Please do not use commas or Percentage signs
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 "></div>
                    <div className="col-md-9 col-12">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">Quantity (Nos)</div>
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform onlynumber"
                            placeholder="2"
                            id="ofrqtythird"
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">
                            Discount Percent On MRP (%)
                          </div>
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform number"
                            placeholder="5%"
                            id="oframtthird"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="vhsp1">
                          {" "}
                          Please do not use commas or Percentage signs
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* end */}
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-12 col-12">
                      <div className="row justify-content-end">
                        <div className="col-md-3 col-6">
                          <button
                            className="btnvd add-cart-btn"
                            onClick={nextOffer}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-2"
                  className="tab-switch"
                />
                <label htmlFor="tab-2" className="tab-label">
                  Images
                </label>
                <ImageUpload  
                reqType = {requestType}
  onFileDataChange={handleFileDataChange} 
  defaultImage={defauldimg} />

{/* */}
              </div>

              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-3"
                  className="tab-switch"
                />
                <label htmlFor="tab-3" className="tab-label">
                  Product Info
                </label>
                <div className="tab-content">
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">How to Use</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="20000"
                        className="inputform alphaNumeric"
                        id="use"
                      />
                      <span className="vhsp1">
                        Provide the use of listed product.
                      </span>
                    </div>
                  </div>
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Product Type</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="1000"
                        className="inputform alphaNumeric"
                        id="ptype"
                      />
                      <span className="vhsp1">
                        Provide the type of product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Hair/Skin Type</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="1000"
                        className="inputform alphaNumeric"
                        id="hstype"
                      />
                      <span className="vhsp1">Provide the Hair/Skin type.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Bullet Point </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="10000"
                        className="inputform mb-2 alphaNumeric"
                        id="_bulletOne"
                      />
                      <input
                        type="text"
                        maxlength="10000"
                        className="inputform mb-2 alphaNumeric"
                        id="_bulletTwo"
                      />
                      <input
                        type="text"
                        maxlength="10000"
                        className="inputform mb-2 alphaNumeric"
                        id="_bulletThree"
                      />
                      <input
                        type="text"
                        maxlength="10000"
                        className="inputform mb-2 alphaNumeric"
                        id="_bulletFour"
                      />
                      <input
                        type="text"
                        maxlength="10000"
                        className="inputform mb-2 alphaNumeric"
                        id="_bulletFive"
                      />
                      <span className="vhsp1">
                        Text - maximum 100 characters. HTML tags and special
                        characters not on a standard keyboard (eg. ®, ©, ™ or
                        other Type 1 High ASCII characters) are not supported.
                        Do not use email IDs, phone numbers, external URLs or
                        promotional messages such as 'sale', 'price', 'offer'
                        etc. Do not include any formatting - TejBazar will add
                        the bullet points
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Included Components</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            maxlength="10000"
                            className="inputform"
                            placeholder="Laptop, Power adaptor"
                            id="inclditm"
                          />
                          <span className="vhsp1">
                            Enter Included Components.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-end">
                    <div className="p-3 col-md-3 text-end">
                      <button
                        className="btnvd add-cart-btn"
                        onClick={prevprodInfo}
                      >
                        Previous
                      </button>
                    </div>

                    <div className="p-3 col-md-3 text-end">
                      <button
                        className="btnvd add-cart-btn"
                        onClick={nextprodInfo}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-4"
                  className="tab-switch"
                />
                <label htmlFor="tab-4" className="tab-label">
                  Inventory
                </label>
                <div className="tab-content">
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Quantity/Stock </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="10"
                        className="inputform requiredtxtinventory onlynumber"
                        errortxt="Quantity/Stock"
                        id="_stockQty"
                      />
                      <span className="vhsp1">
                        Stock available for online sales
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Stock Description</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <textarea
                        type="text"
                        maxlength="2000"
                        className="inputform alphaNumeric"
                        id="_stockdes"
                        rows={5}
                      ></textarea>
                      <span className="vhsp1">
                        Text - maximum 2000 characters. HTML tags and special
                        characters not on a standard keyboard (eg. ®, ©, ™ or
                        other Type 1 High ASCII characters) are not supported.
                        Do not use email IDs, phone numbers, external URLs or
                        promotional messages such as 'sale', 'price', 'offer'
                        etc
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Purchase Rate</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        value="INR&#8377;"
                        className="inpstat number"
                        disabled
                      />
                      <input
                        type="text"
                        maxlength="10"
                        className="inpstat2"
                        id="purrate"
                      />
                      <div>
                        <span className="vhsp1">
                          The Purchase Price of the product, in local currency.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Batch Number</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="20"
                        className="inputform alphaNumeric"
                        id="batchno"
                      />
                      <span className="vhsp1">Batch Number of the stock.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Type Purchase</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="2000"
                        className="inputform alphaNumeric"
                        id="purtype"
                      />
                      <span className="vhsp1">purchase type of the stock.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Sale</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="2000"
                        className="inputform alphaNumeric"
                        id="salee"
                      />
                      <span className="vhsp1">Batch Number of the stock.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Damage</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="2000"
                        className="inputform"
                        id="damagee"
                      />
                      <span className="vhsp1">Batch Number of the stock.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Return</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="2000"
                        className="inputform onlynumber"
                        id="return"
                      />
                      <span className="vhsp1">Batch Number of the stock.</span>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-end">
                    <div className="p-3 col-md-3 text-end">
                      <button
                        className="btnvd add-cart-btn"
                        onClick={prevInventory}
                      >
                        Previous
                      </button>
                    </div>

                    <div className="p-3 col-md-3 text-end">
                      <button
                        className="btnvd add-cart-btn"
                        onClick={nextInventory}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-5"
                  className="tab-switch"
                />
                <label htmlFor="tab-5" className="tab-label">
                  Attribute
                </label>
                <div className="tab-content" style={{ width: "100%" }}>
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="sizeDis"
                          id="unitSizeIdOne"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Size</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">Size</div>
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform alphaNumeric sizeDis"
                            placeholder="100"
                            id="size1"
                            disabled
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="vhlab2 mb-1">Unit</div>
                          {/* <input
                            type="text" maxlength="10"
                            className="inputform sizeDis"
                            placeholder="ML"
                            id="unit1"
                            disabled
                          /> */}

                          <select className="inputform sizeDis" id="unit1">
                            <option value="">Please Select</option>
                            <option value="MM">MM</option>
                            <option value="CM">CM</option>
                            <option value="M">M</option>
                          </select>
                        </div>
                      </div>
                      <span className="vhsp1">
                        Enter Size (Ideal for solid product).
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="flavourDis"
                          id="flavourDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Flavour</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform flavourDis"
                            placeholder="Orange"
                            id="flavour"
                            disabled
                          />
                          <span className="vhsp1">Enter Flavour.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="shadeDis"
                          id="shadeDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Shades</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <input
                            type="text"
                            maxlength="10"
                            className="form-control shadeDis"
                            placeholder="Enter Shade Name"
                            id="shadename"
                            disabled
                          ></input>
                        </div>
                      </div>
                      <span className="vhsp1">Provide Text.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="colorDis"
                          id="colorDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Color</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <input
                            type="color"
                            maxLength="10"
                            className="form-control colorDis"
                            placeholder=""
                            id="colorId"
                            disabled
                          ></input>
                        </div>

                        <div className="col-md-6 col-12">
                          <input
                            type="text"
                            maxlength="10"
                            className="form-control colorDis"
                            placeholder="Enter Color Name"
                            id="colorname"
                            disabled
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="sizetypetwoDis"
                          id="unitSizeIdTwo"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Size</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <select
                        type="text"
                        id="clothSize"
                        disabled
                        className="inputform sizetypetwoDis"
                        errortxt="Main Category"
                      >
                        <option value="">Select</option>
                        <option value="XS">XS</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XXL">XXL</option>
                        <option value="XXXL">XXXL</option>
                      </select>
                      <span className="vhsp1">Select the Size of product.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="gendertype"
                          id="unitSizeId"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Product For</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <select
                        type="text"
                        id="_gender"
                        disabled
                        className="inputform gendertype"
                        errortxt="Main Category"
                      >
                        <option value="">Select Product for</option>
                        <option value="Men">Men</option>
                        <option value="Women">Women</option>
                        <option value="Kids">Kids</option>
                      </select>
                      <span className="vhsp1">
                        Select the Product Best Suited for Men/Women/Kids.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="dimensionDis"
                          id="dimensionDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Dimension</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-4 col-12 mb-2">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform number dimensionDis"
                            placeholder="Height"
                            id="_height"
                            disabled
                          />
                        </div>
                        <div className="col-md-4 col-12 mb-2">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform number dimensionDis"
                            placeholder=" Length"
                            id="_length"
                            disabled
                          />
                        </div>
                        <div className="col-md-4 col-12 mb-2">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform number dimensionDis"
                            placeholder="Width"
                            id="_width"
                            disabled
                          />
                        </div>
                      </div>
                      <span className="vhsp1">
                        Provide Dimension of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="sizechartDis"
                          id="unitSizeIdThree"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Sizechart (UK/INDIA)</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <select
                        type="text"
                        id="sizeChartSelect"
                        disabled
                        className="inputform sizechartDis"
                        errortxt="Main Category"
                      >
                        <option value="">Select</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <span className="vhsp1">Select the Size of product.</span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="volumeDis"
                          id="volumeDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Capacity/Volume</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform onlynumber volumeDis"
                            placeholder="100"
                            id="volume"
                            disabled
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          {/* <input
                            type="text" maxlength="10"
                            className="inputform volumeDis"
                            placeholder="ML"
                            id="volumeunit"
                            disabled
                          /> */}

                          <select
                            className="inputform volumeDis"
                            id="volumeunit"
                            disabled
                          >
                            <option value="">Please Select</option>
                            <option value="ML">ML</option>
                            <option value="L">L</option>
                          </select>

                          <div className="">ML</div>
                        </div>
                      </div>
                      <span className="vhsp1">
                        Provide Capacity/Volume of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="displaySizeDis"
                          id="displaySizeDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Display Size</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform number displaySizeDis"
                            placeholder="100"
                            id="dissize"
                            disabled
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          {/* <input
                            type="text" maxlength="10"
                            className="inputform displaySizeDis"
                            placeholder="Inc"
                            id="disunit"
                            disabled
                          /> */}

                          <select
                            className="inputform displaySizeDis"
                            id="disunit"
                            disabled
                          >
                            <option value="">Please Select</option>
                            <option value="Inches">Inches</option>
                          </select>

                          <div className="">Inches</div>
                        </div>
                      </div>
                      <span className="vhsp1">
                        Provide display size of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="brandDis"
                          id="brandDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Brand Name</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            maxlength="100"
                            className="inputform requiredtxtattribute alphaNumeric brandDis"
                            errortxt="Brand Name"
                            placeholder="Sony Bravia"
                            id="brandname"
                            disabled
                          />
                          <span className="vhsp1">
                            Provide brand name of the product (max 100 letters).
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="modelyrDis"
                          id="modelyrDisid"
                          onClick={enableOnChecked}

                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Model Year</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform onlynumber modelyrDis"
                            placeholder="2023"
                            name=""
                            id="modelyear_"
                            disabled
                          />
                          <span className="vhsp1">
                            Provide model Year of the product.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="gearDis"
                          id="gearDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Gear</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform alphaNumeric gearDis"
                            placeholder=""
                            id="gear"
                            disabled
                          />
                          <span className="vhsp1">Provide the Gear.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="frameDis"
                          id="frameDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Frame Size</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <input
                            type="text"
                            maxlength="10"
                            className="inputform onlynumber frameDis"
                            placeholder="100"
                            id="framesize"
                            disabled
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          <select
                            className="inputform frameDis"
                            id="frmunit"
                            disabled
                          >
                            <option value="">Please Select</option>
                            <option value="Inches">Inches</option>
                          </select>
                        </div>
                      </div>
                      <span className="vhsp1">
                        Provide frame size of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="itemWgDis"
                          id="itemWgDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Item Weight</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            maxlength="20"
                            className="inputform itemWgDis"
                            placeholder="2 kg 480 g"
                            id="itmwt"
                            disabled
                          />
                          <span className="vhsp1">Enter Item Weight.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-1 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="ramDis"
                          id="ramDisid"
                          onClick={enableOnChecked}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">RAM Size</p>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            maxlength="20"
                            className="inputform ramDis"
                            placeholder="16 GB"
                            id="ramsize"
                            disabled
                          />
                          <span className="vhsp1">Enter RAM Size.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-2 info1 vhbs1 mt-2">
                    <p>
                      Here you can add any number of additional Product Details.
                      Click On Add New for new field.
                    </p>
                  </div>

                  <div className="row rwhvr1 aic1 pt-2 pb-1 mb-2 mt-2">
                    <div className="col-md-3 col-12 ">
                      <input
                        type="text"
                        className="inputform"
                        placeholder=""
                        id="attrkey"
                      />
                      <span className="vhsp1"></span>
                    </div>
                    <div className="col-md-9 col-12">
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <input
                            type="text"
                            className="inputform"
                            placeholder=""
                            id="attrvalue"
                          />
                          <span className="vhsp2 addNewRow">
                            <a className="pnterr1">Add New</a>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row d-flex ">
                      <table
                        id="myTable"
                        className="table table-bordered table-hover table-stripped mt-3"
                      >
                        <thead className="bg-dark text-white">
                          <tr>
                            <th>Attribute Key</th>
                            <th>Attribute Value</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>

                    <div className="row d-flex justify-content-end">
                      <div className="p-3 col-md-3 text-end">
                        <button
                          className="btnvd add-cart-btn"
                          onClick={prevAttr}
                        >
                          Previous
                        </button>
                      </div>

                      <div className="p-3 col-md-3 text-end">
                        <button
                          className="btnvd add-cart-btn"
                          onClick={nextAttr}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-6"
                  className="tab-switch"
                />
                <label htmlFor="tab-6" className="tab-label">
                  More Info
                </label>
                <div className="tab-content" style={{ width: "100%" }}>
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">ASIN</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="20"
                        className="inputform alphaNumeric requiredtxtmoreinfo"
                        errortxt="ASIN No"
                        placeholder="B0BYN1GM6V"
                        id="asin"
                        disabled
                      />
                      <span className="vhsp1">
                        Specify the ASIN Code of the product or package.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Manufacturer Details </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="100"
                        className="inputform requiredtxtmoreinfo"
                        errortxt="Manufacturer Details"
                        placeholder="XYZ Pvt Ltd"
                        id="_manufacture"
                      />
                      <span className="vhsp1">
                        Specify the manufacturer of the product as mentioned on
                        the product or package.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Importer Details </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="100"
                        className="inputform"
                        placeholder=""
                        id="importer"
                      />
                      <span className="vhsp1">
                        Specify the Importer of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Packer Details </p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="100"
                        className="inputform"
                        placeholder=""
                        id="packer"
                      />
                      <span className="vhsp1">
                        Specify the packer of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1 required">Country of Origin</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="100"
                        className="inputform requiredmoreinfo"
                        errortxt="Country Of Origin"
                        placeholder="India"
                        id="country"
                      />
                      <span className="vhsp1">
                        Specify the Country of Origin of the product.
                      </span>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-end">
                    <div className="p-3 col-md-3 text-end">
                      <button
                        className="btnvd add-cart-btn"
                        onClick={prevMoreinfo}
                      >
                        Previous
                      </button>
                    </div>

                    <div className="p-3 col-md-3 text-end">
                      <button
                        className="btnvd add-cart-btn"
                        onClick={nextMoreInfo}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-7"
                  className="tab-switch"
                />
                <label htmlFor="tab-7" className="tab-label">
                  Other Details
                </label>
                <div className="tab-content" style={{ width: "100%" }}>
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Show Inventory Left</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <input
                        type="text"
                        maxlength="100"
                        className="inputform"
                        placeholder="Only 2 Left"
                        id="shwinvtry"
                      />
                      <span className="vhsp1">
                        This section reflect on single product page.
                      </span>
                    </div>
                  </div>
                  <div className="row rwhvr1 aic1 pt-1 pb-1 mb-2">
                    <div className="col-md-3 col-12 ">
                      <p className="vhlab1">Order's Made in Your region</p>
                    </div>
                    <div className="col-md-9 col-12">
                      <div>
                        <input
                          type="text"
                          maxlength="100"
                          className="inptpc-sgp"
                          placeholder="15K+"
                          id="odrshow"
                        />
                        Plus People Ordered this product in your city.
                      </div>

                      <span className="vhsp1">
                        This section reflect on single product page.
                      </span>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-end">
                    <div className="p-3 col-md-3 text-end">
                      {requestType === "listing" ? (
                        <button
                          className="btnvd add-cart-btn"
                          onClick={() => saveContinue('M')}
                        >
                          Update Product
                        </button>
                      ) : (
                        <button
                          className="btnvd add-cart-btn"
                          onClick={() => saveContinue('N')}
                        >
                          Add Product
                        </button>
                      )}
                      {/* <button
                        className="btnvd add-cart-btn"
                        onClick={saveContinue}
                      >
                        Add Product
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input
                  type="radio"
                  name="css-tabs"
                  id="tab-8"
                  className="tab-switch"
                />
                <label htmlFor="tab-8" className="tab-label">
                  Availibility
                </label>
                <div className="tab-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <Pincode />
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adproduct;
