import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Featured.css";
import "./Common.css";

import img3 from "../media/pro3.jpg";

import queryString from "query-string";
import { Link } from "react-router-dom";

function Featured({pindex, subCatId, catId, catName}) {
  const [features, setFeatures] = useState([]);
  const [viewAllQueryParams, setViewAllQueryParams] = useState("");

  
  useEffect(() => {
    var valdata = {
      pindex : pindex,
      subCatId : subCatId,
      catId : catId
  };
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getFeatured`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata)
    };

    $.ajax(settings).done(function (response) {
      setFeatures(response);
    });
  }, []);

  useEffect(() => {
    if (features.length > 0) {
      const randomFeature = features[Math.floor(Math.random() * features.length)];
      const params = {
        q: "search",
        requestType: "feature",
        subCategory: randomFeature.SUB_CATEGORY_ID,
      };
      const queryStringParams = queryString.stringify(params);
      setViewAllQueryParams(queryStringParams);
    }
  }, [features]);

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }


  return (
    <>
      <section>
        <div className="container-fluid pt-3">
          <div className={`row ${features.length > 0 ? '' :'d-none'}`}>
            <div className="col-12 dnop text-center">
              <h2 className="feth"> <span className="vbgsew">{`Best Of ${catName}`}</span> </h2>
            </div>
            {/* <div className="col-6 d-flex justify-content-end ">
              
              <Link to={`/Allproduct?${viewAllQueryParams}`} className="td-0 txtst">
                <h2 className="fetview">VIEW ALL</h2>
              </Link>
            </div> */}

<div className="container p-2" style={{ backgroundColor: "#e2e2e2" }}>
          <div className="row">
          {shuffleArray(features).slice(0, 12).map((feature) => {
                const params = {
                  q: "search",
                  requestType: "feature",
                  subCategory: feature.SUB_CATEGORY_ID,
                };
                // Allproduct?q=search&requestType=banner&subCategory=9
//  const params = {
//                   q: "search",
//                   requestType: "product",
//                   subCategory: feature.SUB_CATEGORY_ID,
//                   catId : feature.CATEGORY_ID,
//                   productId : feature.ID
//                 };
                const queryStringParams = queryString.stringify(params);

                return (
                  <Link
                    to={`/Allproduct?${queryStringParams}`}
                    className="col-md-2 col-6 td-0 txt-cl-11 trans"
                    key={`feat${feature.ID}`}
                  >


{/* {feature.PRODUCT_NAME.length > 50
    ? `${feature.PRODUCT_NAME.slice(0, 50)}...`
    : feature.PRODUCT_NAME} */}

<div className=" my-1">
              <div className="hyeus1 text-center kaju99">
              <img src={`${Baseurl.getImgUrl}prodImages/${feature.IMAGES_NAME_1}`} className="img-fluid igmxht inhgws"/>
              <div className="crtitmhd">{feature.SUB_CATEGORY_NAME} Starting From {feature.CURRENT_AMOUNT}</div>
              <div>
                
                {/* <span className="dashwjw"> &#8377;{feature.CURRENT_AMOUNT}{" "} </span><span className="gdtasge">{Math.round(100 - 
                              ((parseFloat(feature.CURRENT_AMOUNT) * 100) /
                                parseFloat(feature.MRP))
                            )}{" "} Off</span> */}
                            
                            
                            </div>
              </div>
            </div>








                  </Link>
                );
              })}

            

            
          </div>
        </div>

            {/* <div className="d-flex pt-4 pb-4">
              {shuffleArray(features).slice(0, 6).map((feature) => {
                const params = {
                  q: "search",
                  requestType: "feature",
                  subCategory: feature.SUB_CATEGORY_ID,
                };

                const queryStringParams = queryString.stringify(params);

                return (
                  <Link
                    to={`/Allproduct?${queryStringParams}`}
                    className="col-md-2 col-6 td-0 txt-cl-11 trans"
                    key={`feat${feature.ID}`}
                  >
                    <div
                      className=" crd-bd"
                      style={{ width: "100%", border: "0px" }}
                    >
                      <img
                        src={`${Baseurl.getImgUrl}prodImages/${feature.IMAGES_NAME_1}`}
                        className="-img-top img-crd-2 img-fluid"
                        alt="..."
                      />
                      <div className="pt-1 pb-1 ">
                        <h1 className="crtitmhd">{feature.PRODUCT_NAME}</h1>
                        <p className="fetr-pr-1 dis-unset">
                          &#8377;{feature.CURRENT_AMOUNT}{" "}
                          <s className="strk-2">{feature.OLD_AMOUT}</s>{" "}
                        </p>
                        <p className="m-0">
                          <span className="spfea-2">
                            {Math.round(100 - 
                              ((parseFloat(feature.CURRENT_AMOUNT) * 100) /
                                parseFloat(feature.MRP))
                            )}{" "}
                            % Off
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div> */}
          </div>
        </div>
      </section>







      <section>
        
      </section>



    </>
  );
}

export default Featured;
