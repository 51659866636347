import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";


function Addadmin() {
  const [isupdate, setIsUpdates] = useState(false);
  const { pathname } = useLocation();
  const [report, setReport] = useState([]);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const adminId = queryParams.fetchd;
  const requestType = queryParams.requestType;
  const q = queryParams.q;

  useEffect(() => {
    loadState();
    fetchAdminlst();
    $('#admbno').on('blur', function() {
	    var mobileNumber = $(this).val();
	    var mobileNumberPattern = /^\d{10}$/; 

	    if (mobileNumberPattern.test(mobileNumber)) {
	      
	    } else {
	      toast.error("Please enter a valid 10-digit mobile number.");
	    }
	  });
	  
	  $('#admbno').on('keypress', function(event) {
		    var key = String.fromCharCode(event.keyCode);
		    var regex = /[0-9]/; 

		    if (!regex.test(key)) {
		      event.preventDefault();
		    }
		  });

     
      $('#adfname,#adlname,#adcity').on('input', function(event) {
        var inputValue = $(this).val();
        var regex = /^[A-Za-z]+$/;
  
        if (!regex.test(inputValue)) {
          $(this).val(inputValue.replace(/[^A-Za-z]/g, ''));
        }
      });

      // $('#ademail').on('keydown', function(event) {
      //   var key = event.key;
      //   var alphabeticRegex = /^[A-Za-z]+$/; 
      //   var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; // Regular expression for email validation
  
      //   if (event.which === 8 || event.which === 46 || event.which >= 37 && event.which <= 40 || event.which === 9 || event.which === 13) {
      //     return;
      //   }
  
      //   if (!alphabeticRegex.test(key) && !emailRegex.test($('#ademail').val() + key)) {
      //     toast.error("Enter Valid Email Address.")
      //     $('#ademail').val('')
      //   }
      // });

      $('#ademail').on('blur', function() {
        var email = $('#ademail').val();
        
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (email.trim() === '') {
          toast.error("Enter Valid Email Address.")
        } else if (!emailPattern.test(email)) {
          toast.error("Enter Valid Email Address.")
        }
    });
  
      $('#ademail').on('paste', function(event) {
        var pastedText = event.originalEvent.clipboardData.getData('text');
        var alphabeticRegex = /^[A-Za-z]+$/; 
        var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; 
  
        if (!alphabeticRegex.test(pastedText) && !emailRegex.test(pastedText)) {
          event.preventDefault();
        }
      });


if(requestType === "viewAdmin"){
  fetchAdminlstset();
  setIsUpdates(true);
}



  }, []);

  function getCheckedValues(name) {
	  return Array.from(document.querySelectorAll('input[name="'+name+'"]'))
	  .filter((checkbox) => checkbox.checked)
	  .map((checkbox) => checkbox.value);
	}

  function loadState() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getStateid`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        $("#adstate").empty();
        $("#adstate").append('<option value="">Select</option>');
        $.each(response.po_DETAIL, function (key, value) {
          $("#adstate").append(
            '<option value="' +
              response.po_DETAIL[key].ID +
              '" >' +
              response.po_DETAIL[key].STATE_NAME +
              ""
          );
        });
      }
    });
  }

  const saveData = () => {
    console.log("in")
  if (!$("#adfname").val().trim()) {
    toast.error("FirstName is required.");
    return false;
  }
  if (!$("#adlname").val().trim()) {
    toast.error("LastName is required.");
    return false;
  }
  if (!checkboxvalueretrival('inlineRadioOptions')) {
    toast.error("Gender is required.");
    return false;
  }
  if (!$("#ademail").val().trim()) {
    toast.error("Email is required.");
    return false;
  }
  if (!$("#admbno").val().trim()) {
    toast.error("Mobile No is required.");
    return false;
  }
  if (!$("#adadress").val().trim()) {
    toast.error("Address is required.");
    return false;
  }

  if (!$("#adcity").val().trim()) {
    toast.error("City is required.");
    return false;
  }


  if (!$("#adstate option:selected").val().trim()) {
    toast.error("State Name is required.");
    return false;
  }
  var passwordv = $("#admpws").val().trim()
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;
  if (!passwordPattern.test(passwordv)) {
    toast.error("Please Enter Valid Password. Password should have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 special character, and at most 25 characters.");
    return false;
  }
  
  var emailv = $("#ademail").val().trim()
  const emailpattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailpattern.test(emailv)) {
    toast.error("Please Enter Valid Email Id.");
    return false;
  }
   
  var addroles = getCheckedValues('rolepd');
  if(addroles.length == 0){
    toast.error("Please Specify Role.");
    return false;
  }
  var valdata = {
      adminId: $("#adminId").val().trim(),
    fname: $("#adfname").val().trim(),
    lname: $("#adlname").val().trim(),
    gender: checkboxvalueretrival('inlineRadioOptions'),
passwordvalue : $("#admpws").val().trim(),
    email: $("#ademail").val().trim(),
    mobileno: $("#admbno").val().trim(),
    address: $("#adadress").val().trim(),
    cityname : $("#adcity").val().trim(),
    stateName : $("#adstate option:selected").val().trim() ,
roleaddmin : addroles.toString()
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}addAdminDtls`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) === 1) {
        toast.success(response.po_MSG);
        $("#adfname,#adlname,#ademail,#admbno,#adcity,#adadress").val('');
       $("#adstate").val('')
    } else {
      toast.error(response.po_MSG);
    }
  });
};


const fetchAdminlst = () => {
  var valdata = {
    filterBy: "A",
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}fetchAdminlstDtls`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) === 1) {
      setReport(response.po_DETAIL);
    } else {
      setReport(response.po_DETAIL);
      
    }
  });
};



function checkboxvalueretrival(cls)
{
	const rbs = document.querySelectorAll('input[name="'+cls+'"]');
    let selectedValue;
    for (var rb of rbs) {
        if (rb.checked) {
            selectedValue = rb.value;
            break;
        }
    }
    return selectedValue;
}

const fetchAdminlstset = () => {
  var valdata = {
    filterBy: adminId,
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}fetchAdminlstDtlsbyid`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    if (parseInt(response.po_STATUS) === 1) {
      $("#adfname").val(response.po_DETAIL[0].FIRSTNAME);
      $("#adlname").val(response.po_DETAIL[0].LASTNAME);
      //adgnder
      if(response.po_DETAIL[0].GENDER === "M"){
        $("#adgnder").prop("checked", true)
      }else{
        $("#adfgnder").prop("checked", true)

      }
      $("#ademail").val(response.po_DETAIL[0].EMAILID);
      $("#admbno").val(response.po_DETAIL[0].MOBILENO);
      $("#adadress").val(response.po_DETAIL[0].ADDRESS);
      $("#adcity").val(response.po_DETAIL[0].CITY);

      $("#adstate").val(response.po_DETAIL[0].STATE);
    
    }
  });
};


const updateData = () => {
if (!$("#adfname").val().trim()) {
  toast.error("FirstName is required.");
  return false;
}
if (!$("#adlname").val().trim()) {
  toast.error("LastName is required.");
  return false;
}
if (!checkboxvalueretrival('inlineRadioOptions')) {
  toast.error("Gender is required.");
  return false;
}
if (!$("#ademail").val().trim()) {
  toast.error("Email is required.");
  return false;
}
if (!$("#admbno").val().trim()) {
  toast.error("Mobile No is required.");
  return false;
}
if (!$("#adadress").val().trim()) {
  toast.error("Address is required.");
  return false;
}

if (!$("#adcity").val().trim()) {
  toast.error("City is required.");
  return false;
}

var passwordv = $("#admpws").val().trim()
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;
  if (!passwordPattern.test(passwordv)) {
    toast.error("Please Enter Valid Password. Password should have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 special character, and at most 25 characters.");
    return false;
  }

if (!$("#adstate option:selected").val().trim()) {
  toast.error("State Name is required.");
  return false;
}

var emailv = $("#ademail").val().trim()
const emailpattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
if (!emailpattern.test(emailv)) {
  toast.error("Please Enter Valid Email Id.");
  return false;
}
 


var addroles = getCheckedValues('rolepd');
if(addroles.length == 0){
  toast.error("Please Specify Role.");
  return false;
}

var valdata = {
    adminId: $("#adminId").val().trim(),
  fname: $("#adfname").val().trim(),
  lname: $("#adlname").val().trim(),
  gender: checkboxvalueretrival('inlineRadioOptions'),
  passwordvalue : $("#admpws").val().trim(),
  email: $("#ademail").val().trim(),
  mobileno: $("#admbno").val().trim(),
  address: $("#adadress").val().trim(),
  cityname : $("#adcity").val().trim(),
  stateName : $("#adstate option:selected").val().trim() ,
  updateId : adminId,
 roleaddmin : addroles.toString()
};

const settings = {
  async: true,
  crossDomain: true,
  url: `${Baseurl.baseUrl}updateAdminDtls`,
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  data: JSON.stringify(valdata),
};

$.ajax(settings).done(function (response) {
  if (parseInt(response.po_STATUS) === 1) {
      toast.success(response.po_MSG);
      $("#adfname,#adlname,#ademail,#admbno,#adcity,#adadress").val('');
     $("#adstate").val('')
  } else {
    toast.error(response.po_MSG);
  }
});
};



  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Add Admin</p>
            <span class="hdspn1"></span>
          </div>
        </div>
      </section>

      <section className="bgsec1 p-3">
        <div className="container-fluid">
          <div className="row  mt-3 mb-3">
            <div className="col-md-3 mb-2 bdradis">
              <div className="hyeus1  bdradis">
                <div className="p-2 ">
                  <span className="vhp1 pt-0">All Admin</span>
                </div>
                <hr />
                <div className="p-2  ">

                {report.length === 0 ? (
                    <><span className="mkjdu1">No Data Found</span><br /></>
                  ) : (
                    report.map((rpt) => (
                      <><span className="mkjdu1" key={`admlst${rpt.FormattedID}`}>{rpt.FULLNAME} [{rpt.FORMATTED_DATE}]</span><br /></>
                    ))
                  )}


                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="hyeus1  p-3 ">
               
                  <div className="row">
                    <div className="col-6">
                      <p className="vhp1 pt-0">Add Admin Information</p>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <p>
                        <a href="" className="share-spn">
                          Edit/Change
                        </a>
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div className="row g-3">
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control inpt1"
                        id="adfname"
                        placeholder=""
                        maxLength={255}
                        required
                      />
                      
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control inpt1"
                        id="adlname"
                        placeholder=""
                        maxLength={255}
                        required
                      />
                      
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        Your Gender
                      </label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="M"
                            name="inlineRadioOptions"
                            id="adgnder"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Male
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="adfgnder"
                            value="F"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ademail"
                        placeholder=""
                        maxLength={255}
                        required=""
                      />
                      
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="admbno"
                        placeholder=""
                        required=""
                        maxLength={10}
                      />
                      
                    </div>

                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        Password
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="admpws"
                        placeholder=""
                        required=""
                        maxLength={16}
                      />
                      
                    </div>


                    <div className="col-sm-12">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        Address
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="adadress"
                        placeholder=""
                        required=""
                        maxLength={500}
                      ></textarea>
                      
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        City/Town
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="adcity"
                        placeholder=""
                        maxLength={255}
                      />
                      
                    </div>
                    <div className="col-sm-6">
                      <label
                        htmlFor="firstName"
                        className="form-label crtitmhd"
                      >
                        State
                      </label>
                      <select
                        className="form-control"
                        id="adstate"
                        name="country-state"
                      ></select>
                      
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label
                      htmlFor="firstName"
                      className="form-label crtitmhd"
                    >
                      Asign Role
                    </label>
                    <div>

                      <div className="hyeus1 p-2">
                      <div class="form-check ">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          id="flexCheckDefault"
                          name="rolepd"
                        />
                        <label class="form-check-label juehys1" for="flexCheckDefault">
                          Abondon Cart
                        </label>
                      </div>
                      </div>
                      
                      <div className="hyeus1 p-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="2"
                          id="flexCheckDefault1"
                          name="rolepd"
                        />
                        <label class="form-check-label juehys1" for="flexCheckDefault1">
                          All SKUs
                        </label>
                      </div>
                      </div>
                      


                      <div className="hyeus1 p-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="3"
                          id="flexCheckDefault3"
                          name="rolepd"
                        />
                        <label class="form-check-label juehys1" for="flexCheckDefault3">
                          Orders
                        </label>
                      </div>
                      </div>
                      


                      <div className="hyeus1 p-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="4"
                          id="flexCheckDefault4"
                          name="rolepd"
                        />
                        <label class="form-check-label juehys1" for="flexCheckDefault4">
                        Payments
                        </label>
                      </div>
                      </div>
                      

                      <div className="hyeus1 p-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="5"
                          id="flexCheckDefault5"
                          name="rolepd"
                        />
                        <label class="form-check-label juehys1" for="flexCheckDefault5">
                          Reports
                        </label>
                      </div>
                      </div>
                      

                      <div className="hyeus1 p-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="6"
                          id="flexCheckDefault6"
                          name="rolepd"
                        />
                        <label class="form-check-label juehys1" for="flexCheckDefault6">
                          Tickets
                        </label>
                      </div>
                      </div>
                      

                     
                      
                    </div>
                  </div>

                  <div className={`pt-3 col-md-4 ${isupdate? "d-none" : ""}`}>
                    <button className="btnvd add-cart-btn" onClick={saveData}>Add Admin</button>
                  </div>
                  <div className={`pt-3 col-md-4 ${!isupdate? "d-none" : ""}`}>
                    <button className="btnvd add-cart-btn" onClick={updateData}>Update Admin</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Addadmin;
