import React from 'react'

const AppContext = React.createContext();

export const MyContextProvider = ({ children }) => {
    const [variable1Value, setVariable1Value] = React.useState(0);
    const [login, setLogin] = React.useState('');
  
    const updateVariable1Value = (newValue) => {
      setVariable1Value(newValue);
    };

    const updateLogin = (newValue) => {
      setLogin(newValue);
    };
  
    return (
      <AppContext.Provider value={{ variable1: variable1Value,  variable2: login,updateVariable1Value, updateLogin }}>
        {children}
      </AppContext.Provider>
    );
  };

export default AppContext