import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { Bar } from "react-chartjs-2";
import { BarElement,  CategoryScale,Chart as ChartJS,Legend, LinearScale,Title, Tooltip } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement,Title,Tooltip,Legend);




function Vdhome() {
  const [dashboard, setDashboard] = useState({
    monthlySale: "",
    monthlyOrder: "",
    customerFeedback: "",
    marketPlace: "",
    totalBalance: "",
    totalProduct: "",
  });

  useEffect(() => {
    loadPayouts();
    chartPerday();
    chartMonth();
  }, []);

  function loadPayouts() {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getDashboardPanel`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      setDashboard({
        monthlySale: response.po_DETAIL[0]?.TotalPrice || 0,
        monthlyOrder: response.po_DETAIL[0]?.TotalOrdersCount || 0,
        customerFeedback: response.hsmp?.customerFeedback || 0,
        marketPlace: response.hsmp?.marketPlace || 0,
        totalBalance: response.hsmp?.totalBalance || 0,
        totalProduct: response.hsmp?.totalProduct || 0,
      });
    });
  }
////////////////////////////


const option = {
  responsive: true,
  plugins: {
    legend: { position: "chartArea" },
    title: {
      display: false,
      // text: "Modular Bar Chart",
    },
  },
};

const [perdaylabel, setPerDayLabel] = useState([]);
const [perdayprice, setPerDayPrice] = useState([]);

const [monthlabel, setMonthLabel] = useState([]);
const [monthprice, setMonthPrice] = useState([]);

var data = {
  labels : perdaylabel,
  datasets: [
    {
      label: "Total Sale",
      data: perdayprice,
      backgroundColor: 'rgb(75, 192, 192)',
      barThickness: 25, 
    }

  ]

};

var monthdata = {
  labels : monthlabel,
  datasets: [
    {
      label: "Total Sale",
      data: monthprice,
      backgroundColor: 'rgb(75, 192, 192)',
      barThickness: 25, 
    }

  ]

};


function chartPerday() {
  var valdata = {
    vendorId: localStorage.getItem('vendorId'),
   
  };

  const settings = {
    async: false,
    crossDomain: true,
    url: `${Baseurl.baseUrl}getVendorSaleChart`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    const orderDates = response.po_DETAIL.map(item => item.order_date);
    const totalPrices = response.po_DETAIL.map(item => item.total_price);

    setPerDayLabel(orderDates);
    setPerDayPrice(totalPrices);
  });
}




function chartMonth() {
  var valdata = {
    vendorId: localStorage.getItem('vendorId'),
   
  };

  const settings = {
    async: false,
    crossDomain: true,
    url: `${Baseurl.baseUrl}getVendorMonthChart`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    
    const orderDates = response.po_DETAIL.map(item => item.order_month);
    const totalPrices = response.po_DETAIL.map(item => item.total_price);

    setMonthLabel(orderDates);
    setMonthPrice(totalPrices);
  });
}

  return (
    <>
      <section className=" bg-wt2">
        <div className="container-fluid p-3">
          <div className=" vhdiv1">
            <p className="vhp2">Welcome, Vendor Name/ Store Name </p>
          </div>

          <div className="card mt-2 " style={{ backgroundColor: "#f6f9fa" }}>
            <div className="row mt-2 m-2">
              <div className="col-md-2 col-6 p-0 ">
                <div className="card p-2 pb-0 m-1">
                  <div className="text-uppercase vhdiv2">Today's Sale</div>
                  <p className="vhp1">&#8377; {dashboard.monthlySale}</p>
                </div>
              </div>

              <div className="col-md-2 col-6 p-0 ">
                <div className="card p-2 pb-0 m-1">
                  <div className="text-uppercase vhdiv2">Total Orders</div>
                  <p className="vhp1">{dashboard.monthlyOrder}</p>
                </div>
              </div>

              <div className="col-md-2 col-6 p-0 ">
                <div className="card p-2 pb-0 m-1">
                  <div className="text-uppercase vhdiv2">Market Places</div>
                  <p className="vhp1">{dashboard.marketPlace}</p>
                </div>
              </div>

              <div className="col-md-2 col-6 p-0 ">
                <div className="card p-2 pb-0 m-1">
                  <div className="text-uppercase vhdiv2">Total Balance</div>
                  <p className="vhp1">&#8377; {dashboard.totalBalance}</p>
                </div>
              </div>

              <div className="col-md-2 col-6 p-0 ">
                <div className="card p-2 pb-0 m-1">
                  <div className="text-uppercase vhdiv2">Customer Feedback</div>
                  <p className="vhp1">{dashboard.customerFeedback}</p>
                </div>
              </div>
              <div className="col-md-2 col-6 p-0 ">
                <div className="card p-2 pb-0 m-1">
                  <div className="text-uppercase vhdiv2">Total Products</div>
                  <p className="vhp1">{dashboard.totalProduct}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-wt2">
        <div className="container-fluid ">
          <div className="card bod1 " style={{ backgroundColor: "#f6f9fa" }}>
            <div className="row justify-content-center p-3">
              <div className="col-md-6 col-12">
                <div className="pt-2 pb-2  ">
                  <div className="vhp1 pt-0">Sales Chart</div>
                </div>
                {/* <div className="card p-3  mb-3 vhbs1">
                  <canvas id="myChart" className="mt-3 w-100 mw-600"></canvas>
                </div> */}
				<Bar options={option} data={data} />
        
				
              </div>

              <div className="col-md-6 col-12">
                <div className="pt-2 pb-2  ">
                  <div className="vhp1 pt-0">Bar Chart</div>
                </div>
                <div className="card p-3  mb-3 vhbs1">
                
                <Bar options={option} data={monthdata} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdhome;
