import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from "react-toastify";
import icon1 from "../media/icon1.svg";
import icon2 from "../media/icon2.svg";
import icon3 from "../media/icon3.svg";
import icon4 from "../media/icon4.svg";
import icon5 from "../media/icon5.svg";
import icon6 from "../media/icon6.svg";
import img2 from "../media/img6.jpg";
import logo from '../media/logo6.png';
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Sellerregister() {

  const navigate = useNavigate();
  const location = useLocation();

  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [gstin, setGstin] = useState("");
  const [panNo, setPanNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [storeCity, setStoreCity] = useState("");
  const [storePassword, setStorePassword] = useState("");

  const [storeAsin, setStoreAsin] = useState("");



  const [isValidMobile, setIsValidMobile] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidGstin, setIsValidGstin] = useState(true);
  const [isValidPan, setIsValidPan] = useState(true);
  const [isValidPincode, setIsValidPincode] = useState(true);
  const [isValidStoreName, setIsValidStoreName] = useState(true);
  const [isValidStoreAddress, setIsValidStoreAddress] = useState(true);
  const [isValidStoreCity, setIsValidStoreCity] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [isValidAsin, setIsValidAsin] = useState(true);


  useEffect(() => {
  
    if (location.pathname === '/Sellerregister') {
      const userId = localStorage.getItem('userId');
      // if (userId) {
      //   navigate("/Vdhome");
      // }
    }
    loadState();
  }, []);

  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    const numericPattern = /^\d*$/;

    const startsWith6789 = /^[6-9]/;

    if (
      (numericPattern.test(value) && startsWith6789.test(value)) ||
      value === ""
    ) {
      setIsValidMobile(true);
      setMobileNumber(value);
    } else {
      setIsValidMobile(false);
    }
  };


  const handleLoginButtonClick = (event) => {
    event.preventDefault();

    if (
      mobileNumber === "" ||
      storePassword === ""
    ) {
      toast.error("All fields are required.");
    } else if (
      !isValidMobile ||
      !isValidPassword
    ) {
      toast.error("Please fill all fields correctly.");
    } else {
      getlogin();
     
      
      
      
    }
  };



  const getlogin = () => {
    var valdata = {
      mobileno: $("#vdloginphn").val().trim(),
      _passwrdvd : $("#vdloginpw").val().trim()
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}vendorLoginchk`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        toast.success(response.po_MSG);
        document.getElementById('_close').click();
        $("#_mobileno").val('');
        $("#_emailAddrs").val('');
        localStorage.setItem("authenticated", true);
        localStorage.setItem("vendorId", response.po_DETAIL[0].USERID);
        localStorage.setItem("MOBILENO", response.po_DETAIL[0].MOBILENO);
        navigate("/Vdhome");
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  




  const handleEmailChange = (event) => {
    const value = event.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailPattern.test(value) || value === "");
    setEmailAddress(value);
  };

  const handleGstinChange = (event) => {
    const value = event.target.value;
    const gstinPattern =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})?$/;
    setIsValidGstin(gstinPattern.test(value) || value === "");
    setGstin(value);
  };

  const isValidPanck = (pan) => {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panPattern.test(pan);
  };

  const isValidPincodeck = (pincode) => {
    const pincodePattern = /^[1-9][0-9]{5}$/;
    return pincodePattern.test(pincode);
  };

  const handlePanNoChange = (event) => {
    const value = event.target.value;
    setIsValidPan(isValidPanck(value) || value === "");
    setPanNo(value);
  };

  const handlePincodeChange = (event) => {
    const value = event.target.value;
    setIsValidPincode(isValidPincodeck(value) || value === "");
    setPincode(value);
  };

  const handleStoreNameChange = (event) => {
    const value = event.target.value;
    const namePattern = /^[A-Za-z\s]+$/;
    setIsValidStoreName(namePattern.test(value) || value === "");
    setStoreName(value);
  };

  const handleAsinChange = (event) => {
    const value = event.target.value;
    const namePattern = /^[A-Za-z0-9\s]+$/;
    setIsValidAsin(namePattern.test(value) || value === "");
    setStoreAsin(value);
  };

  const handleStoreAddressChange = (event) => {
    const value = event.target.value;
    const addressPattern = /^[A-Za-z0-9\s]+$/;
    setIsValidStoreAddress(addressPattern.test(value) || value === "");
    setStoreAddress(value);
  };

  const handleStoreCityChange = (event) => {
    const value = event.target.value;
    const cityPattern = /^[A-Za-z\s]+$/;
    setIsValidStoreCity(cityPattern.test(value) || value === "");
    setStoreCity(value);
  };

  const validatePassword = (inputPassword) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;
    return passwordPattern.test(inputPassword);
  };
  
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setIsValidPassword(validatePassword(value));
    setStorePassword(value);
  };

  function loadState() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getStateid`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        $("#floatingSelect").empty();
        $("#floatingSelect").append('<option value="">Select</option>');
        $.each(response.po_DETAIL, function (key, value) {
          $("#floatingSelect").append(
            '<option value="' +
              response.po_DETAIL[key].ID +
              '" >' +
              response.po_DETAIL[key].STATE_NAME +
              ""
          );
        });
      }
    });
  }

  const [showModal, setShowModal] = useState(false);

  const isFormValid = () => {
    return (
      isValidMobile &&
      isValidEmail &&
      isValidGstin &&
      isValidPan &&
      isValidPincode &&
      isValidStoreName &&
      isValidStoreAddress &&
      isValidStoreCity &&
      isValidPassword
    );
  };

  const handleRegisterButtonClick = (event) => {
    event.preventDefault();

    if (
      mobileNumber === "" ||
      emailAddress === "" ||
      gstin === "" ||
      panNo === "" ||
      storeName === "" ||
      storeAddress === "" ||
      storeCity === "" ||
      pincode === "" ||
      storePassword === ""
    ) {
      toast.error("All fields are required.");
    } else if (
      !isValidMobile ||
      !isValidEmail ||
      !isValidGstin ||
      !isValidPan ||
      !isValidStoreName ||
      !isValidStoreAddress ||
      !isValidStoreCity ||
      !isValidPincode ||
      !isValidPassword
    ) {
      toast.error("Please fill all fields correctly.");
    } else {
      
      checkUser();
      
      //toast.success("Success");
      // Open the modal using Bootstrap's JavaScript function
    }
  };

  const saveData = () => {
    var valdata = {
      gstinNo: $("#_gstin").val().trim(),
      panNo: $("#_panno").val().trim(),
      storeName: $("#_storeName").val().trim(),
      pincodeNo: $("#_pincode").val().trim(),
      email: $("#_emailAddrs").val().trim(),
      mobileno: $("#_mobileno").val().trim(),
      address: $("#_storeaddress").val().trim(),
      cityname: $("#_storeCity").val().trim(),
      stateName: $("#floatingSelect option:selected").val().trim(),
      _passwrdvd : $("#_passwrdvd").val().trim(),
      _asinNo : $("#_asinrs").val().trim()
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addVendorDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        toast.success(response.po_MSG);
        //document.getElementById('closeModal').click();
        $("#_mobileno").val('');
        $("#_emailAddrs").val('');
        $("#_gstin").val('');
        $("#_panno").val('');
        $("#_storeName").val('');
        $("#_storeaddress").val('');
        $("#_storeCity").val('');
        $("#floatingSelect").val('');
        $("#_pincode").val('');
        
        $("#_asinrs").val('');
      } else {
        toast.error(response.po_MSG);
      }
    });
  };


  const checkUser = () => {
    var valdata = {
      
      email: $("#_emailAddrs").val().trim(),
     
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}isUserexist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
//         sendOtp($("#_mobileno").val().trim(), 'send');
// $("#_getOtp").val('');
saveData();
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  const [otp, setOtp] = useState("");
  const [isValidOtp, setIsValidOtp] = useState(true);

  const handleOtpChange = (event) => {
    const value = event.target.value;
    setIsValidOtp(value === "" || value.length === 6);
    setOtp(value);
  };

  const handleOtpSubmit = () => {
    if (otp.length === 6) {
      // Your OTP validation logic here
      // For simplicity, we'll just print the OTP to the console
      console.log("OTP:", otp);
      
      verifyOtp($("#_mobileno").val().trim());
    } else {
      toast.error("Please Enter Valid OTP.");
    }
  };

  const sendOtp = (mobileno, typesend) => {
    var valdata = {
      typed: typesend,
      mobileNo: mobileno,
      
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}sendOtp`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        toast.success("Kindly Verify OTP Send To Mobile No "+mobileNumber);
        const button = document.querySelector('.modelToggleBtn');
      button.click();
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  const verifyOtp = (mobileno) => {
    var valdata = {
      otp: $("#_getOtp").val().trim(),
      mobileNo: mobileno,
      
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}verifyOtp`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        saveData();
      } else {
        toast.error("Please Enter Valid OTP.");
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <section className=" p-3">
        <div className="container-fluid">
          <div className="row  mt-3 mb-3">
            <div className="col-md-6 col-12 mb-2">
              <div
                className="card p-4 pt-2 pb-2"
                style={{ backgroundColor: "#ebf3fb" }}
              >
                
                  <div className="row">
                    <div className="col-6">
                      <p className="crtitmhd">
                        <img src={img2} className="imgcrt1" />
                        Seller Registration{" "}
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div className="row g-3">
                    <div className="col-md-12">
                      <div className="form-floating mb-2">
                        <input
                          type="tel"
                          className={`form-control ${
                            isValidMobile ? "" : "is-invalid"
                          }`}
                          id="_mobileno"
                          maxLength={10}
                          placeholder="9878657895"
                          required
                          value={mobileNumber}
                          onChange={handleMobileNumberChange}
                        />
                        <label htmlFor="_mobileno">Enter Mobile Number*</label>
                        {!isValidMobile && (
                          <div className="invalid-feedback">
                            Please enter a valid 10-digit mobile number.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating mb-2">
                        <input
                          type="email"
                          className={`form-control ${
                            isValidEmail ? "" : "is-invalid"
                          }`}
                          id="_emailAddrs"
                          placeholder="xyz@example.com"
                          value={emailAddress}
                          onChange={handleEmailChange}
                        />
                        <label htmlFor="floatingInput">Email ID*</label>
                        {!isValidEmail && (
                          <div className="invalid-feedback">
                            Please enter a valid email address.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating mb-2">
                        <input
                          type="email"
                          className={`form-control ${
                            isValidPassword ? "" : "is-invalid"
                          }`}
                          id="_passwrdvd"
                          placeholder=""
                          value={storePassword}
                          onChange={handlePasswordChange}
                         
                        />
                        <label htmlFor="floatingInput">Password*</label>
                        {!isValidPassword && (
                          <div className="invalid-feedback">
                            Password should have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 special character, and at most 25 characters.
                          </div>
                        )}
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className={`form-control ${
                            isValidGstin ? "" : "is-invalid"
                          }`}
                          id="_gstin"
                          placeholder="GSTIN"
                          value={gstin}
                          onChange={handleGstinChange}
                        />
                        <label htmlFor="floatingInput">GSTIN*</label>
                        {!isValidGstin && (
                          <div className="invalid-feedback">
                            Please enter a valid GSTIN.
                          </div>
                        )}
                      </div>
                      <div className="gsttxt">
                        GSTIN is required to sell products on TejBazar.
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className={`form-control ${
                            isValidAsin ? "" : "is-invalid"
                          }`}
                          id="_asinrs"
                          placeholder="asin"
                          value={storeAsin}
                          onChange={handleAsinChange}
                          maxLength={50}
                        />
                        <label htmlFor="floatingInput">ASIN*</label>
                        {!isValidAsin && (
                          <div className="invalid-feedback">
                            Please enter a valid ASIN.
                          </div>
                        )}
                      </div>
                      <div className="gsttxt">
                        Enter ASIN to sell products on TejBazar.
                      </div>
                    </div>





                    <div className="col-md-12">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className={`form-control  ${
                            isValidPan ? "" : "is-invalid"
                          }`}
                          id="_panno"
                          placeholder="PAN"
                          value={panNo}
                          onChange={handlePanNoChange}
                        />
                        <label htmlFor="floatingInput">PAN*</label>
                        {!isValidPan && (
                          <div className="invalid-feedback">
                            Please enter a valid PAN In Block Letter.
                          </div>
                        )}
                      </div>
                      <div className="gsttxt">PAN is Mandatory.</div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className={`form-control ${
                            isValidStoreName ? "" : "is-invalid"
                          }`}
                          id="_storeName"
                          placeholder="Your Store Name"
                          value={storeName}
                          onChange={handleStoreNameChange}
                        />
                        <label htmlFor="floatingInput">Store Name*</label>
                        {!isValidStoreName && (
                          <div className="invalid-feedback">
                            Please enter a valid store name (alphabets only).
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <textarea
                          className={`form-control ${
                            isValidStoreAddress ? "" : "is-invalid"
                          }`}
                          placeholder="Leave a comment here"
                          id="_storeaddress"
                          value={storeAddress}
                          onChange={handleStoreAddressChange}
                        ></textarea>
                        <label htmlFor="floatingTextarea">Store Address</label>
                        {!isValidStoreAddress && (
                          <div className="invalid-feedback">
                            Please enter a valid store address (alphabets only).
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className={`form-control ${
                            isValidStoreCity ? "" : "is-invalid"
                          }`}
                          id="_storeCity"
                          placeholder="Your City/Town"
                          value={storeCity}
                          onChange={handleStoreCityChange}
                        />
                        <label htmlFor="floatingInput">City/Town*</label>
                        {!isValidStoreCity && (
                          <div className="invalid-feedback">
                            Please enter a valid city/town (alphabets only).
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        ></select>
                        <label for="floatingSelect">State</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className={`form-control ${
                          isValidPincode ? "" : "is-invalid"
                        }`}
                        id="_pincode"
                        placeholder="PIN Code"
                        maxLength={6}
                        value={pincode}
                        onChange={handlePincodeChange}
                      />
                      <label htmlFor="floatingInput">PIN Code*</label>
                      {!isValidPincode && (
                        <div className="invalid-feedback">
                          Please enter a valid 6-digit PIN code.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="">
                    By continuing, I agree to TejBazar’s{" "}
                    <Link
                      to=""
                      data-bs-toggle="modal"
                      data-bs-target="#termsofuse"
                    >
                      Terms of Use
                    </Link>{" "}
                    &{" "}
                    <Link
                      to=""
                      data-bs-toggle="modal"
                      data-bs-target="#privacypolicy"
                    >
                      Privacy Policy
                    </Link>{" "}
                    .
                  </div>

                  <div className="pt-3 col-md-4 mb-3">
                    <button
                      className="btn btn-primary btclr w-100"
                      onClick={handleRegisterButtonClick}
                    >
                      Register & Continue
                    </button>
                  </div>
                
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="card p-2" style={{ backgroundColor: "#ebf3fb" }}>
                <div className="wsot">Why sell on TejBazar?</div>
                <hr />

                <div className="row p-2">
                  <div className="col-md-6 p-2">
                    <div className="mb-1 imgcls1">
                      <img src={icon1} className="" />
                    </div>
                    <div className="wsotdv1">Sell Across India</div>
                    <div className="wsotdv2">
                      Reach over 50 crore+ customers across 27000+ pincodes
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="mb-1 imgcls1">
                      <img src={icon2} className="" />
                    </div>
                    <div className="wsotdv1">Higher Profits</div>
                    <div className="wsotdv2">
                      With 0% commission* , you take 100% profits with you
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="mb-1 imgcls1">
                      <img src={icon3} className="" />
                    </div>
                    <div className="wsotdv1">Account Management</div>
                    <div className="wsotdv2">
                      Our Dedicated managers will help your business on TejBazar
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="mb-1 imgcls1">
                      <img src={icon4} className="" />
                    </div>
                    <div className="wsotdv1">Lower Return Charges</div>
                    <div className="wsotdv2">
                      With our flat and low return charges, ship your products
                      stress-free
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="mb-1 imgcls1">
                      <img src={icon5} className="" />
                    </div>
                    <div className="wsotdv1">Simple Pricing Calculator</div>
                    <div className="wsotdv2">
                      Use our simple pricing calculator to decide the best and
                      competitive selling price for your product
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="mb-1 imgcls1">
                      <img src={icon6} className="" />
                    </div>
                    <div className="wsotdv1">24x7 Seller Support</div>
                    <div className="wsotdv2">
                      All your queries and issues are answered by our dedicated
                      Seller Support Team
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade m-0"
        id="termsofuse"
        aria-labelledby="termsofuse"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl p-0 w-100 modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header mx-0">
              <h5 className="modal-title" id="termsofuse">
                Terms Of Use
              </h5>
            </div>
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Accept Terms of use
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade m-0"
        id="privacypolicy"
        aria-labelledby="privacypolicy"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl p-0 w-100 modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header mx-0">
              <h5 className="modal-title" id="privacypolicy">
                Privacy Policy
              </h5>
            </div>
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Accept Privacy Policy
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade`}
        id="otpdigitseller"
        tabindex="-1" aria-labelledby="otpdigitseller" aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="row m-0">
                <div className="col-md-12 col-12 p-4">
                  <div className="lgnfmdv1">
                    <div>
                      OTP send to {mobileNumber}
                      <span 
                      >
                        &nbsp;&nbsp;[Edit]
                      </span>
                    </div>
                    <input
                      type="text"
                      className={`inptpc-sgp wd-100 ${
                        isValidOtp ? "" : "is-invalid"
                      }`}
                      id="_getOtp"
                      placeholder="Enter OTP"
                      value={otp}
                      maxLength={6}
                      onChange={handleOtpChange}
                    />
                  </div>
                  {!isValidOtp && (
                    <div className="invalid-feedback">
                      Please enter a valid 6-digit OTP.
                    </div>
                  )}
                  <div>
                    <p className="pc1"></p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="adtcrtbtn add-cart-btn"
                      onClick={handleOtpSubmit}
                    >
                      Submit OTP
                    </button>
                  </div>
                  <div className="mt-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="d-none modelToggleBtn" data-bs-toggle="modal" data-bs-target="#otpdigitseller"></button>
      





      <div
        class="modal fade"
        id="sellerlogin"
        tabIndex="-1"
        aria-labelledby="sellerlogin"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header m-0 w-100">
              <h5 class="" id="sellerlogin">
                Log In
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="_close"
              ></button>
            </div>
            <div className="modal-body">
        <form>
          <div className="col-md-12">
            <div className="form-floating mb-2">
              <input
                type="text"
                className={`form-control ${!isValidMobile ? 'is-invalid' : ''}`}
                id="vdloginphn"
                maxLength={50}
                placeholder="Enter Email"
                required
                value={mobileNumber}
                onChange={handleMobileNumberChange}
              />
              <label htmlFor="floatingInput">Enter Email *</label>
              {!isValidMobile && (
                <div className="invalid-feedback">
                  Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9.
                </div>
              )}
            </div>

            <div className="form-floating mb-2">
              <input
                type="password"
                className={`form-control ${!isValidPassword ? 'is-invalid' : ''}`}
                id="vdloginpw"
                placeholder=""
                required
                value={storePassword}
                onChange={handlePasswordChange}
              />
              <label htmlFor="floatingInput">Enter Password</label>
              {!isValidPassword && (
                <div className="invalid-feedback">
                  Please enter a valid password (minimum 7 characters).
                </div>
              )}
            </div>
          </div>
          <div className="row d-flex mt-4">
            <div className="col-md-12 text-end my-3">
              <button
                type="button"
                className="btnvd add-cart-btn"
                onClick={handleLoginButtonClick}
              >
                Login Now
              </button>
            </div>
          </div>
        </form>
      </div>
          </div>
        </div>
      </div>




    </>
  );
}

export default Sellerregister;
