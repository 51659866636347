import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import img2 from "../media/prod12.jpg";
import img3 from "../media/male.jpg";
import img4 from "../media/prod14.jpg";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaMailBulk,
  FaPinterest,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

function Myaccount() {

  useEffect(() => {
    loadState();
    fetchAddress("Alldata", "");
  }, []);
  const [isFormValid, setIsFormValid] = useState(true);

  const [address, setAddress] = useState([]);

  const [rowId, setRowId] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);



  const validateForm = () => {
    // Validate the form fields
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const mobileNumber = document.getElementById("mobileNumber").value;
    const address = document.getElementById("address").value;
    const city = document.getElementById("city").value;
    const state = document.getElementById("country-state").value;
    const pincode = document.getElementById("pincode").value;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isEmailValid = emailRegex.test(email);

    const isValid =
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      isEmailValid &&
      mobileNumber.trim() !== "" &&
      address.trim() !== "" &&
      city.trim() !== "" &&
      pincode.trim() !== "" &&
      state.trim() !== "Select";

    setIsFormValid(isValid);

    return isValid;
  };

  const handleSubmit = (flag) => {
    const isFormValid = validateForm();

    if(flag === "N"){
      if(address.length >= 5){
        toast.error("You Have Reached Your Maximum Address Limit.");
        return false;
      }
      
    }
    if (isFormValid) {
      saveAddress(flag);
    } else {
      // Display error messages or prevent submission
      toast.error("Form is not valid. Please check your inputs.");
    }
  };

  function loadState() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getStateid`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        $("#country-state").empty();
        $("#country-state").append('<option value="">Select</option>');
        $.each(response.po_DETAIL, function (key, value) {
          $("#country-state").append(
            '<option value="' +
              response.po_DETAIL[key].ID +
              '" >' +
              response.po_DETAIL[key].STATE_NAME +
              ""
          );
        });
      }
    });
  }

  const saveAddress = (flag) => {
    if(!$("#country-state option:selected").val()){
      toast.error('Please Select State');
    }


    var valdata = {
      userId: $("#userId").val(),
      pincode: $("#pincode").val(),
      locality: $("#city").val(),
      stateId: $("#country-state option:selected").val(),
      //landmark: $("#_landmark").val(),
      address: $("#address").val().trim(),
      //town: $("#_town").val().trim(),
      //id: $("#addressId").val() == "" ? "" : $("#addressId").val(),
      customerName : $("#firstName").val() + " " + $("#lastName").val(),
      customerPhone : $("#mobileNumber").val(),
      customerEmail : $("#email").val(),
      customerLandmark : $("#_landmark").val(),
      customerAlterPhoneno : $("#_alternatePhone").val(),
rowId : rowId,
flag : flag
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addAddress`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 0) {
        toast.error("Failed To save Address");
      }else{
        
        toast.success("Address Saved Successfully.");

      }
    });
  
  };



  function fetchAddress(searchType, rowId) {
    var valdata = {
      userId: $("#userId").val(),
      searchType : searchType,
      rowId : rowId
    };
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchUserShippingAddress`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        
        if(response.po_DETAIL.length > 0){
          var key = response.po_DETAIL.length - 1;
          var fName = "";
          var lName = "";
          try{
            fName = (response.po_DETAIL[key].NAME).split(" ")[0];
            lName = (response.po_DETAIL[key].NAME).split(" ")[1];
          }catch(err){lName=""}
          $("#firstName").val(fName);
          $("#lastName").val(lName);
          $("#email").val(response.po_DETAIL[key].EMAILID);
          $("#mobileNumber").val(response.po_DETAIL[key].PHONENO);
          $("#pincode").val(response.po_DETAIL[key].PI_PINCODE);
          $("#address").val(response.po_DETAIL[key].PI_ADDRESS);
          $("#city").val(response.po_DETAIL[key].LOCALITY);
          $("#country-state").val(response.po_DETAIL[key].STATE_ID);
        }
        setAddress(response.po_DETAIL);
      }
    });
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength)}...`;
  };
  
  
  function oneDataAddress(rowId) {
    setRowId(rowId);
    var valdata = {
      userId: $("#userId").val(),
      searchType : "oneData",
      rowId : rowId
    };
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchUserShippingAddress`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        
        if(response.po_DETAIL.length > 0){
          var key = response.po_DETAIL.length - 1;
          var fName = "";
          var lName = "";
          try{
            fName = (response.po_DETAIL[key].NAME).split(" ")[0];
            lName = (response.po_DETAIL[key].NAME).split(" ")[1];
          }catch(err){lName=""}
          $("#firstName").val(fName);
          $("#lastName").val(lName);
          $("#email").val(response.po_DETAIL[key].EMAILID);
          $("#mobileNumber").val(response.po_DETAIL[key].PHONENO);
          $("#pincode").val(response.po_DETAIL[key].PI_PINCODE);
          $("#address").val(response.po_DETAIL[key].PI_ADDRESS);
          $("#city").val(response.po_DETAIL[key].LOCALITY);
          $("#country-state").val(response.po_DETAIL[key].STATE_ID);
          setIsUpdate(true);
        }
        
      }
    });
  }


  return (
    <>
    <ToastContainer />
      <section className="bgsec1 p-3">
        <div className="container">
          <div className="row  mt-3 mb-3">
            <div className="col-md-3 mb-2 bdradis">

              <div className=" hyeus1">
                <div className="row mb-2">
                  <div className="col-4">
                    <img src={img3} className="p-1 rounded-circle igmcls1" />
                  </div>
                  <div className="col-8 align-self-lg-center">
                    <div className="jswnds">Hello,</div>
                    <div className="juehys1">{localStorage.getItem("userName")}</div>
                  </div>
                </div>
              </div>

              <div className="hyeus1 d-none d-xl-flex">
                <div className="p-2 ">


                <div className="mkjdu1">ACCOUNT SETTINGS</div>
                <hr/>
                <div className="kjiu2">Manage Addresses</div>


               
                  {address.length === 0 ? (
                    <>
                   
                    <span className="prflisp">No Data Found</span><br /></>
                  ) : (
                    address.map((rpt) => (
                      <>
                      
                      <span className="kiujy2" key={`add${rpt.ID}`}  onClick={() => oneDataAddress(rpt.ID)}>{rpt.NAME } [{truncateText(rpt.PI_ADDRESS, 20)}]</span><br /></>
                    ))
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="hyeus1 p-3 ">
                
                  <div className="row">
                    <div className="col-6">
                      <p className="crtitmhd">Personal Information</p>
                    </div>
                    
                  </div>
                  <hr />

                  <div className="row g-3">
        <div className="col-sm-6">
          <label htmlFor="firstName" className="form-label crtitmhd">
            First Name
          </label>
          <input
            type="text"
            className={`form-control inpt1 ${isFormValid ? "" : "is-invalid"}`}
            id="firstName"
            placeholder=""
            required
          />
          {!isFormValid && <div className="invalid-feedback">Valid first name is required.</div>}
        </div>
        <div className="col-sm-6">
          <label htmlFor="lastName" className="form-label crtitmhd">
            Last Name
          </label>
          <input
            type="text"
            className={`form-control inpt1 ${isFormValid ? "" : "is-invalid"}`}
            id="lastName"
            placeholder=""
            required
          />
          {!isFormValid && <div className="invalid-feedback">Valid last name is required.</div>}
        </div>
        <div className="col-sm-12">
          <label htmlFor="email" className="form-label crtitmhd">
            Email
          </label>
          <input
            type="email"
            className={`form-control ${isFormValid ? "" : "is-invalid"}`}
            id="email"
            placeholder=""
            required
          />
          {!isFormValid && <div className="invalid-feedback">Valid email is required.</div>}
        </div>
        <div className="col-sm-6">
          <label htmlFor="mobileNumber" className="form-label crtitmhd">
            Mobile Number
          </label>
          <input
            type="tel"
            className={`form-control ${isFormValid ? "" : "is-invalid"}`}
            id="mobileNumber"
            placeholder=""
            required
            maxLength={10}
          />
          {!isFormValid && <div className="invalid-feedback">Valid mobile number is required.</div>}
        </div>

        <div className="col-sm-6">
          <label htmlFor="mobileNumber" className="form-label crtitmhd">
            Pincode
          </label>
          <input
            type="tel"
            className={`form-control ${isFormValid ? "" : "is-invalid"}`}
            id="pincode"
            placeholder=""
            required
            maxLength={6}
          />
          {!isFormValid && <div className="invalid-feedback">Valid pincode is required.</div>}
        </div>

        <div className="col-sm-12">
          <label htmlFor="address" className="form-label crtitmhd">
            Address
          </label>
          <textarea
            className={`form-control ${isFormValid ? "" : "is-invalid"}`}
            id="address"
            placeholder=""
            required
          ></textarea>
          {!isFormValid && <div className="invalid-feedback">Valid address is required.</div>}
        </div>
        <div className="col-sm-6">
          <label htmlFor="city" className="form-label crtitmhd">
            City/Town
          </label>
          <input
            type="text"
            className={`form-control ${isFormValid ? "" : "is-invalid"}`}
            id="city"
            placeholder=""
            required
          />
          {!isFormValid && <div className="invalid-feedback">Valid city/town is required.</div>}
        </div>
        <div className="col-sm-6">
          <label htmlFor="country-state" className="form-label crtitmhd">
            State
          </label>
          <select
            id="country-state"
            className={`form-select ${isFormValid ? "" : "is-invalid"}`}
            name="country-state"
            required
          >
            <option>Select</option>
          </select>
          {!isFormValid && <div className="invalid-feedback">Valid state is required.</div>}
        </div>
      </div>
      <div className="col-md-4 mt-3">
        <button
          className={`btn w-100 ${!isUpdate ? 'd-block' : 'd-none'}`}
          style={{ backgroundColor: "#fff600" }}
          onClick={() => handleSubmit("N")}
          
        >
          Submit
        </button>
        <button
          className={`btn w-100 ${isUpdate ? 'd-block' : 'd-none'}`}
          style={{ backgroundColor: "#fff600" }}
          onClick={() => handleSubmit("M")}
          
        >
          Update
        </button>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Myaccount;
