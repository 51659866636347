import React from 'react';
import './Common.css'


function LoaderFallback() {
  return (
    <div className="cool-spinner-container">
      <div className="cool-spinner"></div>
    </div>
  )
}

export default LoaderFallback