import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";

import { Link, useLocation } from "react-router-dom";

import { FaFacebook, FaShare } from "react-icons/fa";
import queryString from "query-string";
import { toast, ToastContainer } from "react-toastify";



function Review() {
  const [ratings, setRatings] = useState(0);
  const [products, setProducts] = useState([]);

  const { pathname } = useLocation();

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const subCategoryId = queryParams.subCategory;
  const requestType = queryParams.requestType;
  const q = queryParams.q;
  const productId = queryParams.productId;
  const catId = queryParams.catId;


  let base64File = '';
  let fileName = '';

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fullBase64Data = e.target.result;
        const [, base64Data] = fullBase64Data.split(',');
        base64File = base64Data;
        fileName = file.name;
        
      };
      reader.readAsDataURL(file);
    }
  };
  
  const saveRating = () => {
    debugger
    if (selectedRating == 0) {
      toast.error("Please Select Rating Star.");
      return false;
    }
    var valdata = {
      userId: $("#userId").val(),
      prodId: productId,
      rating: selectedRating,
      comment: $("#ratingComment").val().trim(),
      fileBase : base64File,
      fileName : fileName
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addReviews`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (
        parseInt(response.po_STATUS) === 1 &&
        parseInt(response.po_DETAIL[0].INDEX) === 1
      ) {
        toast.success("Review Save Successfully.");
        const url = `Order`;
        window.location.href = url;
      } else {
        toast.error("Failed To Save review.");
      }
    });
  };

  useEffect(() => {
    loadData();
    const radios = document.querySelectorAll('input[name="stars"]');
    radios.forEach((radio) => {
      radio.addEventListener("click", function () {
        debugger
        if (this.checked) {
          setRatings(this.value);
        }
      });
    });
  }, []);

function loadData(){
	var valdata = {
    subCatId: subCategoryId,
    prodId: productId,
    q: q,
    catId: catId,
    requestType: requestType,
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}getProductDtls`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
   setProducts(response.po_DETAIL[0]);
  });
}



///////////
const [selectedRating, setSelectedRating] = useState(null);

  const handleRatingClick = (ratingValue) => {
    debugger
    setSelectedRating(ratingValue);
  };

  return (
    <>
    <ToastContainer />
      <section className="bgcrt1">
        <div className="container p-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-9 col-lg-9">
              <div className="hyeus1">
                <div className="bdbt-1">
                  <h3 className="p-3 pt-2 pb-1">Ratings & Reviews</h3>
                </div>

                <div className=" m-3 mb-2 ">
                  <div className="row align-items-start p-1">
                    <div className="col-md-2 col-3 col-lg-2">
                      <img src={`${Baseurl.getImgUrl}prodImages/${products.IMAGES_NAME_1}`} className="wid-100" />
                    </div>
                    <div className="col-md-8 col-9 col-lg-8">
                      <Link className="text-decoration-none text-bg-info" to={`/Product?catId=${products.CATEGORY_ID}&productId=${products.ID}&q=search&requestType=product&subCategory=${products.SUB_CATEGORY_ID}`}><h1 className="crtitmhd2">
                        {products.PRODUCT_NAME}
                      </h1></Link>
                      <p className="order-prc">&#8377;{products.CURRENT_AMOUNT}</p>
                    </div>
                    <div className="col-md-2 col-lg-2">
                      <div className="col-md-12 text-end">
                        <span className="share-spn">
                          <FaShare />
                          Share
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hyeus1 mt-2">
                
               {Array.from({ length: 5 }, (_, index) => (
          <span
            key={index}
            className={`icon ${selectedRating >= index + 1 ? 'selected' : ''}`}
            onClick={() => handleRatingClick(index + 1)}
            style={{ cursor: 'pointer' }}
          >
            ★
          </span>
        ))}

                <div className="p-3 pt-2 bdtp-1">
                  <p className="rt-p1">Review this product</p>
                  <textarea
                    className="form-control"
                    placeholder="Description..."
                    id="ratingComment"
                    rows="3"
                  ></textarea>
                </div>
                <div className="p-3 pt-0 ">
                  <p className="rt-p1">Uplode</p>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    onChange={handleFileUpload}
                  />
                  <output></output>
                </div>

                <div className="p-3 pt-0 d-flex justify-content-end">
                  <div className="col-md-2 col-12">
                    <button
                      onClick={() => saveRating()}
                      className="btn w-100"
                      style={{ backgroundColor: "rgb(255, 246, 0)" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>


              



            </div>
          </div>
        </div>


        <div className="container-fluid">
        <div className="hyeus1 mt-2">
        <div class="bdbt-1"><h3 class="p-3 pt-2 pb-1">My Order</h3></div>
        </div>
        </div>
      </section>
    </>
  );
}

export default Review;
