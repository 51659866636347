import React from 'react'
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook, FaArrowLeft } from 'react-icons/fa'


function Shopbycategory() {
  return (
    <>

<section>
    <div className="container-fluid  p-2">

        <div className="hyeus1 w-100 my-3">
            <div className="juehys1 p-2 bgyl1"><FaArrowLeft/> &nbsp; All Categories</div>
        </div>

        <div className="row">
            <div className="col-4 mb-3">
                <div className="hyeus1">
                <div className="text-center">
                    <img src={img1} className="img-fluid ijha"/>
                </div>
                <div className="text-center nhgtr">Fashion</div>
                </div>
            </div>

            <div className="col-4 mb-3">
                <div className="hyeus1">
                <div className="text-center">
                    <img src={img1} className="img-fluid ijha"/>
                </div>
                <div className="text-center nhgtr">Fashion</div>
                </div>
            </div>

            <div className="col-4 mb-3">
                <div className="hyeus1">
                <div className="text-center">
                    <img src={img1} className="img-fluid ijha"/>
                </div>
                <div className="text-center nhgtr">Fashion</div>
                </div>
            </div>

            <div className="col-4 mb-3">
                <div className="hyeus1">
                <div className="text-center">
                    <img src={img1} className="img-fluid ijha"/>
                </div>
                <div className="text-center nhgtr">Fashion</div>
                </div>
            </div>
        </div>

    </div>
</section>

    
    </>
	
  )
}

export default Shopbycategory
