import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";

function Coupons() {
  const [coupon, setCoupon] = useState([]);
  useEffect(() => {
    fetchCoupon();
  }, []);

  const saveCoupon = () => {
    var valdata = {
      adminId: $("#adminId").val().trim(),
      couponId: $("#_couponCode").val().trim(),
      startDt: $("#_startDate").val().trim(),
      endDt: $("#_endDate").val().trim(),
      amount: $("#_discountAmt").val().trim(),
    };
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addCoupon`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        toast.success("Coupon Code Saves Successfully.");
      } else {
        toast.error(response.po_MSG);
      }
    });
  };


  const fetchCoupon = () => {
    var valdata = {
      adminId: $("#adminId").val().trim(),
      
    };
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchCouponDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        setCoupon(response.po_DETAIL);
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  const updateStatus = (flag, rowId) => {
    var valdata = {
      adminId: $("#adminId").val().trim(),
      flagStatus : flag,
      rowId : rowId
    };
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}updateCoupon`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        setCoupon(response.po_DETAIL);
        fetchCoupon();
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Manage Discount Coupon(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div className="">
              <div className="row p-3 bgcldiv1 mb-2 bdrtb1">
                <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
                  <div className="d-flex justify-content-end">
                    <div>
                      {" "}
                      <p className="mgrt2">Find a Coupon</p>
                    </div>
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Enter Coupon Code"
                      aria-label="Search"
                    />
                    <button className="button1 mxhtsh m-0" type="submit">
                      Search
                    </button>{" "}
                    &nbsp;
                    <div>
                      {" "}
                      <p className="mgrt2"> 2 Coupon(s)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-12 pt-2 pb-2  ">
                <div class="vhp1 pt-0">Add Coupon(s)</div>
              </div>
            </div>

            <div className="">
              <div className="">
                <table class="table table-hover ft13">
                  <thead className="tbhd1">
                    <tr>
                      <td>Coupon Code</td>
                      <td>Discount Amount</td>
                      <td>Start Date</td>
                      <td>End Date</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td>
                        <input
                          type="text"
                          id="_couponCode"
                          className="form-control"
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          id="_discountAmt"
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          id="_startDate"
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          id="_endDate"
                          className="form-control"
                        />
                      </td>

                      <td>
                        <button class="btnvd add-cart-btn" onClick={saveCoupon}>
                          Add Coupon
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-12 pt-2 pb-2  ">
                <div class="vhp1 pt-0">All Coupon(s)</div>
              </div>
            </div>

            <table class="table table-hover ft13">
              <thead className="tbhd1">
                <tr>
                  <td>Sr. No</td>
                  <td>Coupon Code</td>
                  <td>Discount Amount</td>
                  <td>Start Date</td>
                  <td>End Date</td>
                  <td>Status</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
              {coupon.map((coupn, index)=>  (
          <tr  key={`coupn${coupn.ID}`}>
          
         <td>{index + 1}</td>
          

          <td>{coupn.PROMO_CODE}</td>
          <td>&#8377;{coupn.AMOUNT}</td>
          <td>{coupn.START_DATE}</td>
          <td>{coupn.END_DATE} </td>
          
          
                  <td>
                    {coupn.STATUS === 'A' ? 'Active' : 'InActive' }
                  </td>
                  
                  <td>{coupn.STATUS === 'A' ? <button class="btnvd add-cart-btn"  onClick={() => updateStatus('D', coupn.ID)}>De-activate</button> : <button class="btnvd add-cart-btn" onClick={() => updateStatus('A', coupn.ID)}>Activate</button>}</td>
        </tr>
			))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Coupons;
