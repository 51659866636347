import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";

import { FaFacebook,FaEye } from "react-icons/fa";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';


function Advendor() {
  const [report, setReport] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      filterBy: pytype,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getVendordtlsAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setReport(response.po_DETAIL);
      } else {
        setReport(response.po_DETAIL);
        toast.error(response.po_MSG);
      }
    });
  };

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "VendorRpt.xlsx");
    toast.success("Excel file downloaded successfully!");
  };

  const downloadExcel = () => {
    var modifiedData = report.map(function (obj) {
      return {
        "VENDOR ID": obj.ID,
        "REGISTRATION DATE": obj.TIME_STAMP,
        "VENDOR NAME": obj.FULL_NAME,
        "MOBILE NO": obj.PHONE_NUMBER,
        "PRODUCT(S)": obj.PRODUCT_COUNT,
        CITY: obj.CITY,
        "VENDOR STATUS": obj.PI_STATUS === "A" ? "ACTIVE" : "INACTIVE",
      };
    });
    downloadExcelFile(modifiedData);
  };

  const filterListing = () => {
    let filter = $("#_filterVendor").val();
    fetchData(filter);
  };

  const changeSts = (param, dltId) => {
    if (
      window.confirm(
        "Are You Sure To " +
          (param === "A" ? "Inactive" : "Active") +
          " The Vendor ?"
      )
    ) {
      var valdata = {
        userId: $("#adminId").val(),
        statusPi: param === "A" ? "I" : "A",
        did: dltId,
      };

      const settings = {
        async: true,
        crossDomain: true,
        url: `${Baseurl.baseUrl}chgVendorSts`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(valdata),
      };

      $.ajax(settings).done(function (response) {
        if (parseInt(response.po_STATUS) === 1) {
          toast.success(response.po_MSG);
        } else {
          toast.error(response.po_MSG);
        }
        fetchData("");
      });
    }
  };

  const handleVisit = (emailId, password) => {
    debugger
    console.log("In con")
    if (!emailId) {
      toast.error("Invalid User Id.");
      return false;
    }
    if (!password) {
      toast.error("Invalid User Detail.");
      return false;
    }

    var valdata = {
      mobileno: emailId,
      _passwrdvd: decryptData(password),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}vendorLoginchk`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        toast.success(response.po_MSG);
        localStorage.setItem("authenticated", true);
        localStorage.setItem("vendorId", response.po_DETAIL[0].USERID);
        localStorage.setItem("MOBILENO", response.po_DETAIL[0].MOBILENO);
        navigate("/Vdhome");
      } else {
        toast.error(response.po_MSG);
      }
    });
  };


  const decryptData = (valdata) => {
    const encryptedBase64Key = 'MTZSSzV6WD9kTUFUaGtRdQ==';
    const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    let encryptedData = null;

    encryptedData = CryptoJS.AES.decrypt(valdata, parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    const encdata = encryptedData.toString(CryptoJS.enc.Utf8);

    return CryptoJS.enc.Utf8.stringify(encryptedData);
  };

  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">All Vendor(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search Vendors:</p>
                </div>
                <form class="d-flex" style={{ height: "30px" }}>
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search Vendor ID"
                    aria-label="Search"
                  />
                  <button class="button1 mxhtsh m-0 " type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2"> &nbsp; {report.length} Vendor(s)</p>
                </div>
              </div>
            </div>

            <div class="container ">
              <div class="  pt-1 pb-0">
                <div class="d-flex">
                  <div>
                    {" "}
                    <p className="mgrt2">Filters:</p>
                  </div>
                  <div class="p-3 pt-0 pb-0">
                    <label>Vendor Type : &nbsp;</label>
                    <select
                      className="selectoption"
                      id="_filterVendor"
                      onChange={filterListing}
                    >
                      <option value="">All Vendor Type</option>
                      <option value="A">Active Vendor</option>
                      <option value="I">Inactive Vendor</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-6 pt-2 pb-2  ">
                <div class="vhp1 pt-0">All Vendor(s)</div>
              </div>
              <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                <div class="vhp1 pt-0">
                  <button
                    class="button1"
                    disabled={report.length === 0}
                    onClick={downloadExcel}
                  >
                    Download{" "}
                  </button>
                </div>
              </div>
            </div>

            <div class=" hyeus1 mt-2 p-2">
              <table class="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>Vendor ID</td>
                    <td>Registration Date</td>
                    <td>Vendor Name</td>
                    <td>Mobile</td>
                    <td>Product(s) Nos.</td>
                    <td>City</td>
                    <td>Vendor Status</td>
                    <td>Change Status</td>
                    <td>Action</td>
                    <td>Visit Account</td>
                  </tr>
                </thead>
                <tbody>
                  {report.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={8}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    report.map((rpt) => {
                      const params = {
                        q: "search",
                        requestType: "vendor",
                        getfilter: rpt.ID,
                      };

                      const queryStringParams = queryString.stringify(params);

                      return (
                        <tr key={`vendor${rpt.ID}`}>
                          <td>{rpt.ID}</td>
                          <td>{rpt.TIME_STAMP}</td>
                          <td>{rpt.NAME}</td>

                          <td>{rpt.PHONE_NUMBER}</td>
                          <td>{rpt.PRODUCT_COUNT}</td>
                          <td>{rpt.CITY}</td>
                          <td>
                            {rpt.PI_STATUS === "A" ? "Active" : "In-active"}
                          </td>
                          <td>
                            <button
                              class="btnvd add-cart-btn bdrradius1"
                              onClick={() => changeSts(rpt.PI_STATUS, rpt.ID)}
                            >
                              {rpt.PI_STATUS === "A" ? "In-Active" : "Active"}
                            </button>
                          </td>
                          <td>
                            <Link to={`/Vendordetails?${queryStringParams}`}>
                              <button class="btnvd add-cart-btn bdrradius1">
                                {" "}
                                View
                              </button>
                            </Link>
                          </td>
                          <td>
                            <button
                              class="btnvd add-cart-btn bdrradius1"
                              onClick={() =>
                                handleVisit(rpt.EMAIL_ID, rpt.PIPASSWORD)
                              }
                            >
                              {" "}
                              <FaEye/>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Advendor;
