import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";

import { Link, useLocation } from "react-router-dom";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { FaFacebook } from "react-icons/fa";
import { toast } from "react-toastify";

function Vdorderreport() {

  
  const [listings, setListings] = useState([]);

  useEffect(() => {
    fetchData();
    console.log($('input[name=inlineRadioOptions]:checked').val())
    $('input[name=inlineRadioOptions]:first').prop('checked', true);
  }, []);


  
  const fetchData = (pytype) => {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
      filterBy : pytype
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchVendororderDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setListings(response.po_DETAIL);
    });
  };


  const updateOrderSts = (sts, rowId, orderId) => {
    var valdata = {
      statusName: sts,
      rowId : rowId,
      orderId : orderId,
      userId : localStorage.getItem("userId")
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}updateOrderSts`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if(parseInt(response.po_STATUS) === 1){
toast.success("Status Updated.");
fetchData("");
      }else{
        toast.error("Failed To Update Status.");
      }
    });
  };

  
  const handleChange = () => {
    console.log($('input[name=inlineRadioOptions]:checked').val())
    fetchData($('input[name=inlineRadioOptions]:checked').val());
      };

      
  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "0rderReport.xlsx");
    toast.success('Excel file downloaded successfully!');
  };

  const downloadExcel = () => {
    var modifiedData = listings.map(function (obj) {
      return {
        "PRODUCT NAME": obj.PRODUCT_NAME,
        "ORDER DATE": obj.ORDER_DATE,
        "LISTING PRICE": obj.PRICE + " " + obj.DELIVERY_CHARGE,
        "PAYMENT MODE" : obj.PAYMENT_TYPE,
        "Address" : obj.LOCALITY +" "+ obj.PI_ADDRESS,
        "STATUS": obj.ORDER_STATUS,
      };
    });
    downloadExcelFile(modifiedData);
  };

	
  return (
    <>
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">Order Report</p>
            <span className="hdspn1">Learn More</span>
          </div>

          <div>
            <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search orders:</p>
                </div>
                <form className="d-flex" style={{ height: "30px" }}>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>  &nbsp;
                </form>

                <div>
                  {" "}
                  <p className="mgrt2"> &nbsp; {listings.length}  Order(s)</p>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="  pt-1 pb-0">
                <div className="d-flex">
                  <div>
                    {" "}
                    <p className="mgrt2">Filters:</p>
                  </div>
                  <div className="p-3 pt-0 pb-0">
                    <label>Order Type : &nbsp;</label>
                    <select className="selectoption">
                      <option>All Order Type</option>
                      <option>Completed Order</option>
                      <option>Cancelled Order</option>
                      <option>Return Order</option>
                    </select>
                  </div>

                  <div className="p-3 pt-0 pb-0">
                    <label>Date : &nbsp;</label>
                    <input type="date" className="selectoption" name="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-6 pt-2 pb-2  ">
                <div className="vhp1 pt-0">Order Report </div>
              </div>
              <div className="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                
                <div className="vhp1 pt-0">
                  <button className="button1"
                  disabled={listings.length === 0}
                  onClick={downloadExcel}
                  >Download </button>
                </div>
              </div>
            </div>

            <table className="table table-bordered ft13">
              <thead className="tbhd1">
                <tr>
                  <td>Product Details</td>

                  <td>Order Date</td>
                  <td>Price & Shipping Cost</td>
                  <td>Payment Type</td>
                  <td>Address</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
              {listings.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    listings.map((listing) => (
                      <tr key={`listing${listing.ID}`}>
                      <td>
                    <div className="d-flex ">
                    <img src={`${Baseurl.getImgUrl}prodImages/${listing.IMAGES_NAME_1}`} style={{ height: "40px" }} />
                     
                      <p>{listing.PRODUCT_NAME}</p>
                    </div>
                  </td>
                  <td>{listing.ORDER_DATE}</td>
          <td>&#8377;{listing.PRICE} + &#8377;{listing.DELIVERY_CHARGE}</td>
          <td>{listing.PAYMENT_TYPE}</td>
          <td>{listing.LOCALITY} , {listing.PI_ADDRESS}</td>
          <td>{listing.ORDER_STATUS}</td>




                      
                    </tr>
                    ))
                  )}

                {/* <tr>
                  <td>
                    <div className="d-flex ">
                      <img src={img1} style={{ height: "40px" }} />
                      <p>ThriveCo Hair Grow & Capilia Longa & Women | 50ml</p>
                    </div>
                  </td>
                  <td>20/09/2020 14:27:15</td>
                  <td>₹234 + ₹40</td>
                  <td>Online</td>
                  <td>Nagpur, Maharashtra</td>
                  <td>Active</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdorderreport;
