

import React from 'react'
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa'


function Filtersetup() {
  return (
    <>


    <section >

        <div></div>

    </section>

    <div className="hyeus1 p-2">
        <div>
            
            <div className="col-md-2">
                    <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label class="form-check-label crtitmhd " for="flexCheckDefault">
                    Maharashta
                    </label>
                    </div>
                </div>
            <hr className="my-2"/>

            <div className="col-md-2">
                    <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label class="form-check-label crtitmhd " for="flexCheckDefault">
                    Nagpur
                    </label>
                    </div>
                </div>
            
            <div className="row p-2">
                <div className="col-md-2">
                    <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label class="form-check-label hstd1" for="flexCheckDefault">
                        440016
                    </label>
                    </div>
                </div>

                <div className="col-md-2">
                    <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label class="form-check-label hstd1" for="flexCheckDefault">
                        440016
                    </label>
                    </div>
                </div>

                <div className="col-md-2">
                    <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label class="form-check-label hstd1" for="flexCheckDefault">
                        440016
                    </label>
                    </div>
                </div>

            </div>
            
        </div>
    </div>

    
    </>
	
  )
}

export default Filtersetup
