import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { FaFacebook,FaStar } from 'react-icons/fa'
import queryString from "query-string";

function Addreviews() {
    const [products, setProducts] = useState([]);

    const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const requestType = queryParams.requestType;
  const q = queryParams.q;
  const productId = queryParams.productId;

  useEffect(() => {
    loadData();
}, []);

  function loadData(){
	var valdata = {
    
        prodId: productId,
        userId : "0"
  };

  const settings = {
    async: true,
    crossDomain: true,
    url: `${Baseurl.baseUrl}getProductByid`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
   setProducts(response.po_DETAIL[0]);
  });
}



const saveRating = () => {
    if ($("#usrname").val().trim() == 0) {
      toast.error("Please Enter UserName .");
      return false;
    }
    if ($("#userstar option:selected").val().trim() == 0) {
        toast.error("Please Select Rating Star.");
        return false;
      }
      if ($("#usrtextrating").val().trim() == 0) {
        toast.error("Please Enter Review .");
        return false;
      }
    var valdata = {
        userName: $("#usrname").val(),
      prodId: productId,
      rating: $("#userstar option:selected").val().trim(),
      comment: $("#usrtextrating").val().trim(),
      
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addReviewsByAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (
        parseInt(response.po_STATUS) === 1 &&
        parseInt(response.po_DETAIL[0].INDEX) === 1
      ) {
        toast.success("Review Save Successfully.");
        $("#usrname").val('');
        $("#userstar").val('')
        $("#usrtextrating").val('')
      } else {
        toast.error("Failed To Save review.");
      }
    });
  };

  return (
    <>
<ToastContainer/>
<section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Add Review(s)</p>
            <span class="hdspn1"></span>
          </div>
        </div>
</section>

<section className="bgsec1 p-3">
    <div className="container-fluid">
        <div className="row  mt-3 mb-3">
            <div className="col-md-3 mb-2 bdradis">
            <div className="hyeus1  bdradis">
                <div className="p-2 ">
                  <span className="vhp1 pt-0">Product Details</span>
                </div>
                <hr />
                <div className="p-2  ">
                    <div className="row mb-2">
                        <div className="col-4 ">
                            <div className="crtitmhd">Product</div>
                        </div>
                        <div className="col-8 hstd1">{products.PRODUCT_NAME}</div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 ">
                            <div className="crtitmhd">SKU</div>
                        </div>
                        <div className="col-8 hstd1">{products.SKU_ID}</div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 ">
                            <div className="crtitmhd">ASIN</div>
                        </div>
                        <div className="col-8 hstd1">{products.ASINNO}</div>
                    </div>
                </div>
              </div>
            </div>


            <div className="col-md-9 mb-2 bdradis" >
            <div className="hyeus1  bdradis">
                <div className="p-2 ">
                  <span className="vhp1 pt-0">Add Review</span>
                </div>
                <hr />
                <div className="p-2  ">
                    <div className="row mb-2">
                        <div className="col-4 ">
                            <div className="crtitmhd">User Name</div>
                        </div>
                        <div className="col-8 hstd1">
                        <input type="text" class="form-control inpt1" id="usrname" placeholder="Enter Name" maxlength="255" required=""/>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 ">
                            <div className="crtitmhd">Rating</div>
                        </div>
                        <div className="col-8 hstd1">
                        <select id="userstar" class="form-select " name="country-state" required="">
                            <option value="">Select</option>
                            <option value="1">1 Stars</option>
                            <option value="2">2 Stars</option>
                            <option value="3">3 Stars</option>
                            <option value="4">4 Stars</option>
                            <option value="5">5 Stars</option>
                        </select>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4 ">
                            <div className="crtitmhd">User Name</div>
                        </div>
                        <div className="col-8 hstd1">
                            <textarea class="form-control inpt1" id="usrtextrating" placeholder="Enter Review" maxlength="255" required=""></textarea>
                       
                        </div>
                    </div>

                    <div className="row mb-2 justify-content-end d-flex">
                        <div className="col-4 ">
                        <button class="btnvd add-cart-btn" onClick={saveRating}>Add Review</button>
                        </div>
                        
                    </div>
                </div>
              </div>
            </div>

        </div>
    </div>
</section>

    
    </>
	
  )
}

export default Addreviews
