import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from "react-toastify";

import img1 from "../media/ic1.svg";
import img2 from "../media/ic2.svg";
import img3 from "../media/ic3.svg";
import img4 from "../media/ic4.svg";
import img5 from "../media/img1.jpg";

import logo from "../media/logo6.png";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


function Seller() {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileNumber, setMobileNumber] = useState('');
  const [storePassword, setStorePassword] = useState('');
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  useEffect(() => {
    if (location.pathname === '/Seller') {
      const userId = localStorage.getItem('vendorId');
      if (userId) {
        navigate("/Vdhome");
      }
    }
  }, [location, navigate]);

  function handleMobileNumberChange(event) {
    const inputMobileNumber = event.target.value;
    setMobileNumber(inputMobileNumber);

    setIsValidMobile(/^[6-9]\d{9}$/.test(inputMobileNumber));
  }

  function handlePasswordChange(event) {
    const inputPassword = event.target.value;
    setStorePassword(inputPassword);

    setIsValidPassword(inputPassword.length >= 7);
  }

  const isFormValid = () => {
    return (
      isValidMobile &&
      isValidPassword
    );
  };


  const handleLoginButtonClick = (event) => {
    event.preventDefault();

    if (
      mobileNumber === "" ||
      storePassword === ""
    ) {
      toast.error("All fields are required.");
    } else if (
      !isValidMobile ||
      !isValidPassword
    ) {
      toast.error("Please fill all fields correctly.");
    } else {
      getlogin();
 
    }
  };


  const getlogin = () => {
    var valdata = {
      mobileno: $("#vdloginphn").val().trim(),
      _passwrdvd : $("#vdloginpw").val().trim()
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}vendorLoginchk`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        toast.success(response.po_MSG);
        document.getElementById('_close').click();
        $("#_mobileno").val('');
        $("#_emailAddrs").val('');
        localStorage.setItem("authenticated", true);
        localStorage.setItem("vendorId", response.po_DETAIL[0].USERID);
        localStorage.setItem("MOBILENO", response.po_DETAIL[0].MOBILENO);
        navigate("/Vdhome");
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <section className=" pb-3" style={{ backgroundColor: "#f7f7f7" }}>
        

        <div className="container">
          <div className="divhed1 mt-2">Sell With Tej-Bazar</div>

          <div className="card crdbdrnone mt-2 mb-3" style={{ border: "0px" }}>
            <div className="row ">
              <div className="col-md-3 col-6 justify-content-center d-grid bdrsellrt mt-2">
                <img src={img1} className="" />
                <p className="pseller">Secure & Weekly Regular Payments</p>
              </div>

              <div className="col-md-3 col-6 justify-content-center d-grid bdrsellrt mt-2">
                <img src={img2} className="" />
                <p className="pseller">24*7 Seller Support</p>
              </div>

              <div className="col-md-3 col-6 justify-content-center d-grid bdrsellrt mt-2">
                <img src={img3} className="" />
                <p className="pseller">Low cost of doing business</p>
              </div>

              <div className="col-md-3 col-6 justify-content-center d-grid mt-2">
                <img src={img4} className="" />
                <p className="pseller"> Crore+ Tej-Bazar Customers</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center d-flex ">
            <div className="col-md-8">
              <div className=" p-2 bgclkjh">
                <div className="text-center ndskas">Learn About Selling</div>
                <div>
                  <iframe
                    width="100%"
                    style={{ minHeight: "500px" }}
                    className="p-2"
                    src="https://www.youtube.com/embed/RvcSNHJkW5I"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="">
            <p>
              <div className="row px-3">
                <div className="col-md-8">
                  <Link
                    className="lerncpl"
                    data-bs-toggle="collapse"
                    to="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Learn more about Tez-Bazar fees
                  </Link>
                </div>
                <div className="col-md-4 text-end">
                  <Link
                    className="lerncpl"
                    data-bs-toggle="collapse"
                    to="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    {" "}
                    <FaPlus />
                  </Link>
                </div>
              </div>
            </p>
            <div class="collapse" id="collapseExample">
              <div
                class="card card-body crdbdrnone borderlft"
                style={{ borderLeft: " 5px solid #fff600" }}
              >
                <div className="p-3">
                  <div className="exdsa1 mb-3">Example</div>
                  <div className="exdsad28 mb-3 p-2 bldgf">
                    Standard item: Talking Tom Toy
                  </div>
                  <div className="row">
                    <div className="col-md-8 ">
                      <p className="bldgf">
                        Product dimensions: 20 x 11 x 10 Centimeters
                      </p>
                      <p className="bldgf">Unit weight: 220 g</p>
                      <p className="bldgf">Listing price: ₹525</p>
                    </div>
                    <div className="col-md-4 ">
                      <img src={img5} className="" />
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p>
                      <span className="bldgf">Step 1:</span> Calculate your
                      referral fees{" "}
                      <span className="bldgf">9.5% (₹525) = ₹49.8</span>{" "}
                    </p>
                    <p>
                      <span className="bldgf">Step 2:</span> Find your your
                      closing fees: <span className="bldgf">₹30</span>{" "}
                    </p>
                    <p>
                      <span className="bldgf">Step 3:</span> Calculate the
                      shipping fees, or if you are using self-ship, check the
                      cost of shipping: <span className="bldgf">₹51</span>{" "}
                    </p>
                    <p>
                      <span className="bldgf">Step 4:</span> Calculate Total
                      Fees = Referral Fees + Closing Fees + Shipping Fees/Cost
                      (Excl. GST): <span className="bldgf">₹130.8</span>{" "}
                    </p>
                    <p>GST @ 18%: ₹23.5</p>
                    <p>
                      <span className="bldgf">
                        Total fee (Incl. GST): ₹154.3
                      </span>{" "}
                    </p>
                    <p>
                      <span className="bldgf">
                        Step 5: Calculate Profit: Listing price - Total fee
                      </span>{" "}
                      (₹525-₹154.3) <span className="bldgf">= ₹370.7</span>{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="card card-body crdbdrnone borderlft"
                style={{ borderLeft: " 5px solid #fff600" }}
              >
                <div className="p-3">
                  <div>
                    <p className="mkjnh">
                      Please note that the fees mentioned are indicative. The
                      final fee you will be subject to will be dependent on
                      multiple factors such as product category, size, weight,
                      volumetric weight, additional services availed, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>

        <div className="container">
          <div className="">
            <p>
              <hr />
              <div className="row px-3">
                <div className="col-md-8">
                  <Link
                    className="lerncpl"
                    data-bs-toggle="collapse"
                    to="#termseller"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Terms And Conditions
                  </Link>
                </div>
                <div className="col-md-4 text-end">
                  <Link
                    className="lerncpl"
                    data-bs-toggle="collapse"
                    to="#termseller"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    {" "}
                    <FaPlus />
                  </Link>
                </div>
              </div>
            </p>
            <div class="collapse" id="termseller">
              <div
                class="card card-body crdbdrnone borderlft"
                style={{ borderLeft: " 5px solid #fff600" }}
              >
                <div className="p-3">
                  <div className="exdsa1 mb-3">Seller's Term & Conditions</div>

                  <p>
                    {" "}
                    1. This Offer is valid for referral fee payable from May 10,
                    2023 to July 9, 2023 (both days inclusive) ("Offer Period"),
                    unless extended or revoked by Amazon in its sole discretion.
                  </p>
                  <p>
                    {" "}
                    1. This Offer is valid for referral fee payable from May 10,
                    2023 to July 9, 2023 (both days inclusive) ("Offer Period"),
                    unless extended or revoked by Amazon in its sole discretion.
                  </p>
                  <p>
                    {" "}
                    1. This Offer is valid for referral fee payable from May 10,
                    2023 to July 9, 2023 (both days inclusive) ("Offer Period"),
                    unless extended or revoked by Amazon in its sole discretion.
                  </p>
                  <p>
                    {" "}
                    1. This Offer is valid for referral fee payable from May 10,
                    2023 to July 9, 2023 (both days inclusive) ("Offer Period"),
                    unless extended or revoked by Amazon in its sole discretion.
                  </p>
                  <p>
                    {" "}
                    1. This Offer is valid for referral fee payable from May 10,
                    2023 to July 9, 2023 (both days inclusive) ("Offer Period"),
                    unless extended or revoked by Amazon in its sole discretion.
                  </p>
                  <p>
                    {" "}
                    1. This Offer is valid for referral fee payable from May 10,
                    2023 to July 9, 2023 (both days inclusive) ("Offer Period"),
                    unless extended or revoked by Amazon in its sole discretion.
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>

        <div></div>
      </section>

      <div
        class="modal fade"
        id="sellerlogin"
        tabIndex="-1"
        aria-labelledby="sellerlogin"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header m-0 w-100">
              <h5 class="" id="sellerlogin">
                Log In
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="_close"
              ></button>
            </div>
            <div className="modal-body">
        <form>
          <div className="col-md-12">
            <div className="form-floating mb-2">
              <input
                type="text"
                className={`form-control ${!isValidMobile ? 'is-invalid' : ''}`}
                id="vdloginphn"
                maxLength={10}
                placeholder="9878657895"
                required
                value={mobileNumber}
                onChange={handleMobileNumberChange}
              />
              <label htmlFor="floatingInput">10 digit phone number*</label>
              {!isValidMobile && (
                <div className="invalid-feedback">
                  Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9.
                </div>
              )}
            </div>

            <div className="form-floating mb-2">
              <input
                type="password"
                className={`form-control ${!isValidPassword ? 'is-invalid' : ''}`}
                id="vdloginpw"
                placeholder=""
                required
                value={storePassword}
                onChange={handlePasswordChange}
              />
              <label htmlFor="floatingInput">Enter Password</label>
              {!isValidPassword && (
                <div className="invalid-feedback">
                  Please enter a valid password (minimum 7 characters).
                </div>
              )}
            </div>
          </div>
          <div className="row d-flex mt-4">
            <div className="col-md-12 text-end my-3">
              <button
                type="button"
                className="btnvd add-cart-btn"
                onClick={handleLoginButtonClick}
              >
                Login Now
              </button>
            </div>
          </div>
        </form>
      </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Seller;
