import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/pay1.jpg";
import img2 from "../media/cod.png";
import img3 from "../media/prod13.jpg";
import img4 from "../media/prod14.jpg";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import Counter from "./Counter.js";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaGoogle, FaShippingFast } from "react-icons/fa";

function Checkout() {
  const [itemcount, setItemCount] = useState(true);
  const [shipaddress, setShipAddress] = useState(false);
  const [payment, setPayment] = useState(false);
  const navigate = useNavigate();
  const [selectedQty, setSelectedQty] = useState(1);

  const [isAddressInput, setIsAddressInput] = useState(false);

  const [isShippingAddressValid, setIsShippingAddressValid] = useState(true);

  const [getshipAddress, setGetShipAddress] = useState([]);

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);

  const [promoCodeList, setPromoCodeList] = useState([]);
  const [appliedPromoCode, setAppliedPromoCode] = useState("");

  const handleAddressClick = (index) => {
    setSelectedAddressIndex(index);
  };

  const [selectedAddress, SetSelectedAddress] = useState({
    name: "",
    address: "",
    email: "",
    phoneNo: "",
  });

  const validateShippingAddress = () => {
    const name = document.getElementById("_name").value;
    const phone = document.getElementById("_phone").value;
    const pincode = document.getElementById("_pincode").value;
    const locality = document.getElementById("_locality").value;
    const address = document.getElementById("_address").value;
    const town = document.getElementById("_town").value;
    const state = document.getElementById("_state").value;

    const isValid =
      name.trim() !== "" &&
      phone.trim() !== "" &&
      pincode.trim() !== "" &&
      locality.trim() !== "" &&
      address.trim() !== "" &&
      town.trim() !== "" &&
      state.trim() !== "";

    setIsShippingAddressValid(isValid);

    return isValid;
  };

  const [totals, SetTotals] = useState({
    prodPrice: 0,
    discPrice: 0,
    deliveryPrice: 0,
    netTotal: 0,
    discPer: 0,
  });

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const requestType = queryParams.requestType;
  const productId = queryParams.productDtls;
  const quantity = queryParams.prodqty;

  useEffect(() => {
    console.log(productId);
    // ;
    loadState();
    getPromocodeList();
    if (requestType == "singleProduct") {
      //getSingleProductTotal()
      getSingleProductdetails(productId);
      getSingleProductTotal(productId, quantity);
    } else {
      setItemCount(false);
      setShipAddress(true);
      setPayment(false);
      getCartProductTotal();
    }
    fetchAddress();
  }, []);

  function loadState() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getStateid`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        $("#_state").empty();
        $("#_state").append('<option value="">Select</option>');
        $.each(response.po_DETAIL, function (key, value) {
          $("#_state").append(
            '<option value="' +
              response.po_DETAIL[key].ID +
              '" >' +
              response.po_DETAIL[key].STATE_NAME +
              ""
          );
        });
      }
    });
  }

  // const saveAddress = () => {
  //   const isFormValid = validateShippingAddress();

  //   if (isFormValid) {
  //   var valdata = {
  //     userId: $("#userId").val(),
  //     pincode: $("#_pincode").val(),
  //     locality: $("#_locality").val(),
  //     stateId: $("#_state").val(),
  //     landmark: $("#_landmark").val(),
  //     address: $("#_address").val().trim(),
  //     town: $("#_town").val().trim(),
  //     id: $("#addressId").val() == "" ? "" : $("#addressId").val(),
  //     customerName : $("#_name").val(),
  //     customerPhone : $("#_phone").val(),
  //     customerLandmark : $("#_landmark").val(),
  //     customerAlterPhoneno : $("#_alternatePhone").val(),

  //   };

  //   const settings = {
  //     async: false,
  //     crossDomain: true,
  //     url: `${Baseurl.baseUrl}addAddress`,
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: JSON.stringify(valdata),
  //   };

  //   $.ajax(settings).done(function (response) {
  //     if (parseInt(response.po_STATUS) === 0) {
  //       toast.error("Failed To save Address");
  //     }else{
  //       $("#addressId").val(response.rowId);
  //       setPayment(true);
  //       toast.success("Record Saved Successfully. kindly Select Payment Mode;");

  //     }
  //   });
  // }
  // else{
  //   toast.error("Shipping address is not valid. Please check your inputs.");
  // }
  // };

  const fetchAddress = () => {
    var valdata = {
      userId: localStorage.getItem("userId"),
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchShipAddress`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (
        parseInt(response.po_STATUS) === 0 ||
        response.po_DETAIL.length === 0
      ) {
        setIsAddressInput(true);
      } else {
        setIsAddressInput(false);
        setGetShipAddress(response.po_DETAIL);
        if (response.po_DETAIL.length > 0) {
          SetSelectedAddress({
            name: response.po_DETAIL[response.po_DETAIL.length - 1].NAME,
            address:
              response.po_DETAIL[response.po_DETAIL.length - 1].PI_ADDRESS +
              response.po_DETAIL[response.po_DETAIL.length - 1].LOCALITY +
              response.po_DETAIL[response.po_DETAIL.length - 1].STATE_ID +
              response.po_DETAIL[response.po_DETAIL.length - 1].PI_PINCODE,
            email: response.po_DETAIL[response.po_DETAIL.length - 1].EMAILID,
            phoneNo: response.po_DETAIL[response.po_DETAIL.length - 1].PHONENO,
          });
        }
      }
    });
  };

  const bindAddress = () => {
    if (!localStorage.getItem("userId")) {
      //handleShow();
      return false;
    }

    setShipAddress(false);
    setPayment(true);
  };

  const setaddressonchange = (
    addressId,
    name,
    email,
    PI_ADDRESS,
    LOCALITY,
    STATE_ID,
    PI_PINCODE
  ) => {
    $("#addressId").val(addressId);
    SetSelectedAddress({
      name: name,
      address: PI_ADDRESS + LOCALITY + STATE_ID + PI_PINCODE,
      email: email,
    });
  };

  function getSingleProductTotal(productId, quantity) {
    var valdata = {
      userId: localStorage.getItem("userId"),
      prodId: productId,
      quantity: quantity,
    };
    console.log(valdata);
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getdiscorchrges`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      SetTotals({
        deliveryPrice: response.devileryCharges,
        discPrice: response.discCharges,
        prodPrice: response.currentPrice,

        netTotal:
          parseFloat(response.netTotal) -
          parseFloat(response.discCharges) +
          parseFloat(response.devileryCharges),
        discPer:
          (parseFloat(response.discCharges) * 100) /
          parseFloat(response.netTotal),
      });
    });
  }

  function getCartProductTotal() {
    var valdata = {
      userId: localStorage.getItem("userId"),
      requestType: requestType,
    };
    console.log(valdata);
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getcartTotals`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      SetTotals({
        deliveryPrice: response.hsmp.devileryCharges,
        discPrice: response.hsmp.totalDiscount,
        prodPrice: response.hsmp.totalAmount,
        netTotal:
          parseFloat(response.hsmp.totalAmount) -
          parseFloat(response.hsmp.totalDiscount) +
          parseFloat(response.hsmp.devileryCharges),
      });
    });
  }

  function getSingleProductdetails(productId) {
    var valdata = {
      userId: localStorage.getItem("userId"),
      prodId: productId,
    };
    console.log(valdata);
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchSingleProDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      $("#_singleProd").text(response.po_DETAIL[0].PRODUCT_NAME);
      $("#_singlePrice").text(response.po_DETAIL[0].CURRENT_AMOUNT);

      var img =
        Baseurl.getImgUrl + "prodImages/" + response.po_DETAIL[0].IMAGES_NAME_1;
      $("#_singleImg").attr("src", img);
    });
  }

  function placeOrder() {
    var valdata = {
      userId: localStorage.getItem("userId"),
      prodId: productId,
      quantity: selectedQty,
      requestType: requestType,
      addressId: $("#addressId").val(),
      paymentType: $("input[name=paymentMethod]:checked").val(),
      promoCode: appliedPromoCode,
    };
    console.log(valdata);
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}placeOrder`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) == 1) {
        const params = {
          q: "search",
          requestType: response.other_DETAILS,
        };
        if ($("input[name=paymentMethod]:checked").val() === "PAYNOW") {
          makePayment(response.hsmp);
        } else {
          const queryStringParams = queryString.stringify(params);
          const url = `Thankyou?${queryStringParams}`;
          window.location.href = url;

          // navigate(url);
        }
      } else {
        toast.error("Failed To Place Order.");
      }
    });
  }

  function getPromocodeList() {
    var valdata = {
      userId: localStorage.getItem("userId"),
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getPromoCode`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setPromoCodeList(response.po_DETAIL);
    });
  }

  const sendDataToParent = (qty, rate) => {
    console.log(qty + " ==" + rate);
    setSelectedQty(qty);
    getSingleProductTotal(productId, qty);
  };

  const handlepromocode = (toaddVal, promoCode) => {
    if (appliedPromoCode !== "") {
      toast.error("PromoCode Already Applied.");
      return false;
    }
    setAppliedPromoCode(promoCode);

    const updatedDiscPrice =
      parseFloat(totals.discPrice) + parseFloat(toaddVal);

    SetTotals({
      deliveryPrice: totals.deliveryPrice,
      discPrice: updatedDiscPrice,
      prodPrice: totals.prodPrice,
      netTotal:
        parseFloat(totals.netTotal) -
        parseFloat(updatedDiscPrice) +
        parseFloat(totals.deliveryPrice),
    });

    $(".promoCode").prop("disabled", true);
  };

  function isApplyPromocode() {
    var valdata = {
      userId: localStorage.getItem("userId"),
      promoCode: $("#_promoCodeApply").val().trim(),
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getPromoCodeByCode`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (response.po_DETAIL.length > 0) {
        handlepromocode(
          response.po_DETAIL[0].AMOUNT,
          response.po_DETAIL[0].PROMO_CODE
        );
      } else {
        toast.error("No PromoCode Available.");
      }
    });
  }

  const makePayment = (hsmp) => {
    console.log(hsmp);
    // $.ajax({
    //   'url' :"https://test.payu.in/_payment",
    //   'data': {
    //     key:"0LP0Sr"
    //        ,txnid:hsmp.transactionId
    //        ,productinfo:"tez"
    //        ,amount:hsmp.payment
    //        ,email:hsmp.emailId
    //        ,firstname:hsmp.firstName
    //        ,lastname:""
    //        ,surl:"https://google.com/"
    //        ,furl:"https://google.com/"
    //        ,phone:""
    //        ,hash:hsmp.hashCode
    // },
    //    'type' : 'post',
    //    'async' : false ,

    //    success : function(resp) {

    //    }})

    var url = "https://test.payu.in/_payment";

    // Create a form dynamically
    var form = $("<form>")
      .attr("method", "post")
      .attr("action", url)
      .attr("target", "_blank"); // Open in a new window or tab

    var urlSts = Baseurl.baseUrl + "getPaymentStatus";
    // Append hidden input fields for each parameter
    form.append(
      $("<input>").attr("type", "hidden").attr("name", "key").val("0LP0Sr"),
      $("<input>")
        .attr("type", "hidden")
        .attr("name", "txnid")
        .val(hsmp.transactionId),
      $("<input>")
        .attr("type", "hidden")
        .attr("name", "productinfo")
        .val(hsmp.orderNo),
      $("<input>")
        .attr("type", "hidden")
        .attr("name", "amount")
        .val(hsmp.payment),
      $("<input>")
        .attr("type", "hidden")
        .attr("name", "email")
        .val(hsmp.emailId),
      $("<input>")
        .attr("type", "hidden")
        .attr("name", "firstname")
        .val(hsmp.firstName),
      $("<input>").attr("type", "hidden").attr("name", "lastname").val(""),
      $("<input>").attr("type", "hidden").attr("name", "surl").val(urlSts),
      $("<input>").attr("type", "hidden").attr("name", "furl").val(urlSts),
      $("<input>")
        .attr("type", "hidden")
        .attr("name", "phone")
        .val("9988776655"),
      $("<input>")
        .attr("type", "hidden")
        .attr("name", "hash")
        .val(hsmp.hashCode)
    );

    // Append the form to the body and submit it
    form.appendTo("body").submit();
  };

  return (
    <>
      <ToastContainer />
      <input type="hidden" id="addressId" />
      <section className="bgcrt1 p-3">
        <div className="container">
          <div className="row d-flex justify-content-center  mt-3 mb-3">
            <div className="col-md-7">
              <div className="hyeus1 p-2 m-1">
                <h1 className="crthd m-0">Checkout</h1>
              </div>

              <button type="" class="collapsible mb-2 crtitmhd1 ">
                Check Out Item
              </button>
              <div class={`content hyeus1 ${itemcount ? "d-block" : ""}`}>
                <div className="row ">
                  <div class="row align-items-start p-1 pt-3">
                    <div class="col-md-2 col-12 col-lg-2">
                      <img id="_singleImg" class="wid-100" />
                    </div>
                    <div class="col-md-10 col-12 col-lg-10">
                      <h1 class="crtitmhd2" id="_singleProd"></h1>
                      <p class="order-prc">
                        ₹ <span id="_singlePrice"></span>
                        <span id="_appliedDisc">
                          {" "}
                          {totals.discPer > 0
                            ? totals.discPer + " % Discount"
                            : ""}{" "}
                        </span>
                      </p>

                      <div className="p-3 col-md-5">
                        {1 > 0 ? (
                          <Counter
                            id={1}
                            min={1}
                            max={20}
                            qty={1}
                            rate={20}
                            sendDataToParent={sendDataToParent}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="p-3 col-md-12 text-end">
                    <button
                      className="btn w-50"
                      style={{ backgroundColor: "#fff600" }}
                      onClick={() => {
                        setShipAddress(true);
                        setItemCount(false);
                      }}
                    >
                      {" "}
                      Buy Now{" "}
                    </button>
                  </div>
                </div>
              </div>

              <button type="button" class="collapsible mb-2 crtitmhd1">
                {" "}
                Shipping Address
              </button>
              <div class={`content hyeus1 ${shipaddress ? "d-block" : ""}`}>
                <div className="row">
                  <div className="col-6">
                    <p className="mkjdu1 mt-2">Shipping Address</p>
                  </div>
                  <div className="col-6 d-flex justify-content-end"></div>
                </div>
                <hr />
                <span>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row   ">
                        <div className="col-12 jswnds">
                          <b className="mkjdu1">
                            {selectedAddress.name
                              ? selectedAddress.name
                              : "Hello User"}
                          </b>
                        </div>
                      </div>
                      <div className="row   ">
                        <div className="col-12 ft12t">
                          {selectedAddress.address}
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-12 jswnds">
                          <b className="mkjdu1">Phone:- </b>
                          {selectedAddress.phoneNo
                            ? selectedAddress.phoneNo
                            : "NA"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 jswnds">
                          <b className="mkjdu1">Email Id:- </b>
                          {selectedAddress.email ? selectedAddress.email : "NA"}
                        </div>
                      </div>

                      <div className="row my-1 ">
                        <div className="col-12">
                          <b className="mkjdu1">
                            {" "}
                            <Link to="/Myaccount">
                              Edit - Add New Address
                            </Link>{" "}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 rwhvr1 text-start">
                      <div className="my-1 p-1 ">
                        {" "}
                        <b className="mkjdu1">Change Shipping Address</b>{" "}
                      </div>
                      {getshipAddress.length === 0 ? (
                        <div>
                          No Address Found. Please Enter Shipping Address
                        </div>
                      ) : (
                        getshipAddress.map((shipadd, index) => (
                          <div
                            className="hyeus1 p-1 "
                            key={`addrCht${shipadd.ID}`}
                          >
                            {index !== selectedAddressIndex ? (
                              <button
                                className="jiudy text-start"
                                onClick={() => {
                                  handleAddressClick(index);
                                  setaddressonchange(
                                    shipadd.ID,
                                    shipadd.NAME,
                                    shipadd.PHONENO,
                                    shipadd.PI_ADDRESS,
                                    shipadd.LOCALITY,
                                    shipadd.STATE_ID,
                                    shipadd.PI_PINCODE
                                  );
                                }}
                              >
                                {shipadd.PI_ADDRESS}, {shipadd.LOCALITY},{" "}
                                {shipadd.STATE_ID}, {shipadd.PI_PINCODE}
                              </button>
                            ) : null}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </span>

                <div className="row">
                  {getshipAddress.length === 0 ? (
                    <span className="m-2"></span>
                  ) : (
                    <div className="p-3 col-md-12 text-end">
                      <button
                        className="btn w-50"
                        style={{ backgroundColor: "#fff600" }}
                        onClick={() => bindAddress()}
                      >
                        Save Changes
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <button type="button" class="collapsible mb-2 crtitmhd1">
                Payment
              </button>
              <div class={`content hyeus1 ${payment ? "d-block" : ""}`}>
                <div className="my-3">
                  <div className="card p-2 mb-2">
                    <div className="row">
                      <div className="col-8">
                        <div className="form-check">
                          <input
                            id="credit"
                            name="paymentMethod"
                            type="radio"
                            value="PAYNOW"
                            className="form-check-input"
                            required=""
                          />
                          <label
                            className="form-check-label mkjdu1"
                            htmlFor="credit"
                          >
                            Credit / Debit Card
                          </label>
                          <div className="jswnds">
                            Safe Money transfer using your bank account. We
                            support Mastercard, Visa, Discover.
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <img src={img1} className="img-fluid" />
                      </div>

                      {/* <div className="row mt-3">
                          <div class="col-md-12">
                            <label for="firstName" class="form-label crtitmhd">Card Number</label>
                          <input type="text" class="form-control inpt1 " id="firstName" placeholder="4242 4242 4242 4242" required="" fdprocessedid="6n576w"/>
                          </div>

                          <div class="col-md-6">
                            <label for="firstName" class="form-label crtitmhd">Name On Card</label>
                          <input type="text" class="form-control inpt1 " id="firstName" placeholder="Master Shreya" required="" fdprocessedid="6n576w"/>
                          </div>

                          <div class="col-md-3">
                            <label for="firstName" class="form-label crtitmhd">Expiry Date</label>
                          <input type="text" class="form-control inpt1 " id="firstName" placeholder="MM/YY" required="" fdprocessedid="6n576w"/>
                          </div>
                          <div class="col-md-3">
                            <label for="firstName" class="form-label crtitmhd">CVV</label>
                          <input type="text" class="form-control inpt1 " id="firstName" placeholder="012" required="" fdprocessedid="6n576w"/>
                          </div>

                        </div> */}
                    </div>
                  </div>

                  <div className="card p-2">
                    <div className="row">
                      <div className="col-8">
                        <div className="form-check">
                          <input
                            id="cod"
                            name="paymentMethod"
                            type="radio"
                            value="COD"
                            className="form-check-input"
                            required=""
                          />
                          <label
                            className="form-check-label mkjdu1"
                            htmlFor="credit1"
                          >
                            Cash on Delivery
                          </label>
                          <div className="jswnds">
                            Pay with cash when your order is delivered.
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <img src={img2} className="img-fluid" />
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-check">
              <input id="debit" name="paymentMethod" type="radio" className="form-check-input" required=""/>
              <label className="form-check-label" htmlFor="debit">Net Banking</label>
            </div> */}

                  <div className="p-3">
                    <button
                      className="btn w-100"
                      style={{ backgroundColor: "#fff600" }}
                      onClick={() => placeOrder()}
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hyeus1">
                <div className="p-0 d-flex justify-content-center">
                  <button type="button" class="collapsible  crtitmhd1 ">
                    Order Summery
                  </button>
                </div>

                <div className="py-2">
                  <ul className="smrycrt1">
                    <li className="loio">
                      <h5 className="crtitmhd">Price Details</h5>
                    </li>
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">Total Price:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt2">
                            &#8377; {totals.prodPrice}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-8 col-lg-6">
                          <span className="spnsbt2">Delivery Charges:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-4 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt2">
                            &#8377; {totals.deliveryPrice}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">Discount:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt2">
                            &#8377;- {totals.discPrice}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr />
                  <ul className="smrycrt1">
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-8 col-lg-8 col-lg-8">
                          <span className="crtitmhd">Amount Payable</span>
                          <br />
                          {appliedPromoCode !== "" ? (
                            <small className="text-danger">
                              * PromoCode Applied
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-4 col-lg-4 col-lg-4 d-flex justify-content-end">
                          <span className="crtitmhd">
                            &#8377; {totals.netTotal}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="hyeus1 mt-2 p-2">
                <div className="row">
                  <div className="col-8">
                    <input
                      type="text"
                      className="inptpc-sgp"
                      placeholder="Promo Code"
                      name=""
                      data-last-active-input=""
                      id="_promoCodeApply"
                    />
                  </div>
                  <div className="col-4 text-center">
                    <button
                      className="sngl-prodt-pinchk promoCode"
                      onClick={isApplyPromocode}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div className="hyeus1 mt-2 p-2 hgtg">
                <div className="crtitmhd">Available Coupon</div>
                <hr />

                {promoCodeList.length === 0 ? (
                  <div className="row mb-2">
                    <div className="col-12">
                      <div className="inptpc-sgp ft12t">
                        No Promo Code Available.
                      </div>
                    </div>
                  </div>
                ) : (
                  promoCodeList.map((promolist) => (
                    <div className="row mb-2">
                      <div className="col-8">
                        <div className="inptpc-sgp">{promolist.PROMO_CODE}</div>
                      </div>
                      <div className="col-4">
                        <button
                          className="sngl-prodt-pinchk promoCode"
                          onClick={() =>
                            handlepromocode(
                              promolist.AMOUNT,
                              promolist.PROMO_CODE
                            )
                          }
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Checkout;
