import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import { toast, ToastContainer } from 'react-toastify';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';




function Vdpayoutreport() {

    const [payout, setPayout] = useState([]);

  useEffect(() => {
    loadPayouts();
  }, []);

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(excelData, 'payoutRpt.xlsx');
    toast.success('Excel file downloaded successfully!');
    };

  function loadPayouts() {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getVendorPayout`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      
        setPayout(response.po_DETAIL);
      
    });
  }

  const downloadExcel = () => {
    var modifiedData = payout.map(function(obj) {
       return {
         						
           "Payout Date" : obj.StartDateOfWeek,
           "Payout Mode" : obj.PAYMENTMODE,
           "Scheduled payout":obj.StartDateOfWeek,
           "Processing" :	obj.PAYOUTPROCESSINGDATE,
           "Sent to bank" :	obj.COMPLETEDDATE,
           "Payout Status" : obj.ISDONE,
           "Payout Amount" :	obj.TotalPayoutAmount,
          
           
       };
       });
      downloadExcelFile(modifiedData);
    };


  return (
    <>
<ToastContainer/>
<section className="" style={{backgroundColor:"#fff"}}>

	

<div className="container-fluid">
    
<div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
        <p className="vhp3">Payout Report</p><span className="hdspn1">Learn More</span>
    </div>


    <div>




        <div className="row">
                    <div className="col-md-6 col-6 pt-2 pb-2  ">
                    <div className="vhp1 pt-0">Payout Report </div>
                </div>
                <div className="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                    
                    <div className="vhp1 pt-0"><button className="button1" disabled={payout.length === 0} onClick={downloadExcel}>Download </button></div>
                </div>
                </div>


        <table className="table table-bordered ft13" >
            <thead className="tbhd1">
                <tr>
                    <td>Payout Date</td>
                    <td>Payout Mode</td>
                    <td>Scheduled payout</td>
                    <td>Processing</td>
                    <td>Sent to bank</td>
                    <td>Payout Status</td>					
                    <td>Payout Amount</td>								
                </tr>
                
            </thead>
            <tbody>

            {payout.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    payout.map((pyout) => (
						<tr key={`payout${pyout.ID}`}>
						<td>{pyout.StartDateOfWeek}</td>
						<td>{pyout.PAYMENTMODE}</td>
						<td>{pyout.StartDateOfWeek}</td>
						<td>{pyout.PAYOUTPROCESSINGDATE}</td>
						<td>{pyout.COMPLETEDDATE}</td>
						<td>
						  {pyout.ISDONE}
						</td>
						<td>
						₹ {pyout.TotalPayoutAmount}
						</td>
						
					  </tr>
                    ))
                  )}


                {/* <tr>
                    <td>1/4/2023</td>
                    <td>Electronic Transactions</td>
                    <td>9/10/2021</td>
                    <td>9/10/2021</td>
                    <td>10/10/2021</td>
                    <td>Done</td>
                    <td>₹134.44</td>
                </tr> */}
{/* 
                <tr>
                    <td>1/4/2023</td>
                    <td>Electronic Transactions</td>
                    <td>9/10/2021</td>
                    <td>9/10/2021</td>
                    <td>10/10/2021</td>
                    <td>Done</td>
                    <td>₹134.44</td>
                </tr>

                <tr>
                    <td>1/4/2023</td>
                    <td>Electronic Transactions</td>
                    <td>9/10/2021</td>
                    <td>9/10/2021</td>
                    <td>10/10/2021</td>
                    <td>Done</td>
                    <td>₹134.44</td>
                </tr>
                <tr>
                    <td>1/4/2023</td>
                    <td>Electronic Transactions</td>
                    <td>9/10/2021</td>
                    <td>9/10/2021</td>
                    <td>10/10/2021</td>
                    <td>Done</td>
                    <td>₹134.44</td>
                </tr>
                <tr>
                    <td>1/4/2023</td>
                    <td>Electronic Transactions</td>
                    <td>9/10/2021</td>
                    <td>9/10/2021</td>
                    <td>10/10/2021</td>
                    <td>Done</td>
                    <td>₹134.44</td>
                </tr>
                <tr>
                    <td>1/4/2023</td>
                    <td>Electronic Transactions</td>
                    <td>9/10/2021</td>
                    <td>9/10/2021</td>
                    <td>10/10/2021</td>
                    <td>Done</td>
                    <td>₹134.44</td>
                </tr>
                <tr>
                    <td>1/4/2023</td>
                    <td>Electronic Transactions</td>
                    <td>9/10/2021</td>
                    <td>9/10/2021</td>
                    <td>10/10/2021</td>
                    <td>Done</td>
                    <td>₹134.44</td>
                </tr>

                 */}

                
            </tbody>
        </table>
    </div>

    
</div>
</section>

    </>
	
  )
}

export default Vdpayoutreport

