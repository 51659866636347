import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import "./Common.css";
import { toast, ToastContainer } from 'react-toastify';
import img1 from "../media/prod11.jpg";
import img2 from "../media/prod12.jpg";
import img3 from "../media/prod13.jpg";

import { Link, useLocation } from "react-router-dom";

function Vdinventoryreport() {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      vendorId: $("#vendorId").val(),
      piType: pytype,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getinventoryDetails`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setListings(response.po_DETAIL);
    });
  };

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "Report.xlsx");
    toast.success('Excel file downloaded successfully!');
  };

  const downloadExcel = () => {
    var modifiedData = listings.map(function (obj) {
      return {
        "PRODUCT NAME": obj.PRODUCT_NAME,
        "SKU ID": obj.SKU_ID,
        "LISTING DATE": obj.TIME_STAMP,

        "AVAILABLE STOCKS": obj.STOCK_QUANTITY,
        "LISTING PRICE": obj.CURRENT_AMOUNT,
        MRP: obj.MRP,
        CATEGORY: obj.SUB_CATEGORY_NAME,

        STATUS:
          obj.PI_STATUS == "A"
            ? "Active"
            : obj.PI_STATUS == "D"
            ? "Removed"
            : "Inactive",
      };
    });
    downloadExcelFile(modifiedData);
  };

  return (
    <>
    	<ToastContainer />
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">Inventory Report</p>
            <span className="hdspn1">Learn More</span>
          </div>

          <div>
            <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Action on 0 Selected:</p>
                </div>
                <form className="d-flex" style={{ height: "30px" }}>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2"> &nbsp; {listings.length} product(s)</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-6 pt-2 pb-2  ">
                <div className="vhp1 pt-0">Inventory Report </div>
              </div>
              <div className="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                
                <div className="vhp1 pt-0">
                  <button
                    className="button1"
                    disabled={listings.length === 0}
                    onClick={downloadExcel}
                  >
                    Download{" "}
                  </button>
                </div>
              </div>
            </div>

            <table className="table table-bordered ft13">
              <thead className="tbhd1">
                <tr>
                  <td>Product Details</td>
                  <td>SKU</td>
                  <td>Listing Date</td>
                  <td>Available Stock</td>
                  <td>Listing Price</td>
                  <td>MRP</td>
                  <td>Sub Category</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {listings.map((listing) => (
                  <tr key={`listing${listing.ID}`}>
                    <td>
                      <div className="d-flex ">
                        <img
                          src={`${Baseurl.getImgUrl}prodImages/${listing.IMAGES_NAME_1}`}
                          style={{ height: "40px" }}
                        />
                        <p>{listing.PRODUCT_NAME}</p>
                      </div>
                    </td>
                    <td>{listing.SKU_ID}</td>
                    <td>{listing.TIME_STAMP}</td>
                    <td>{listing.STOCK_QUANTITY}</td>
                    <td>₹{listing.CURRENT_AMOUNT}</td>
                    <td>₹{listing.MRP}</td>

                    <td>{listing.SUB_CATEGORY_NAME}</td>
                    <td>
                      {listing.PI_STATUS == "A"
                        ? "Active"
                        : listing.PI_STATUS == "D"
                        ? "Removed"
                        : "Inactive"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdinventoryreport;
