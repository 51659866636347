import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Adinventoryreport() {
	const [report, setReport] = useState([]);

  useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = (pytype) => {
    var valdata = {
      filterBy: pytype,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getlistingdtlsAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setReport(response.po_DETAIL);
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "AllInventoryRpt.xlsx");
    toast.success("Excel file downloaded successfully!");
  };

  const downloadExcel = () => {
    var modifiedData = report.map(function (obj) {
      return {
        "VENDO ID": obj.ID,
        "PRODUCT NAME": obj.PRODUCT_NAME,
        "VENDOR NAME": obj.NAME,

        "AVAILABLE STOCK": obj.STOCK_QUANTITY,
        "MRP": obj.MRP,
        "TOTAL STOCK": (parseInt(obj.STOCK_QUANTITY) + parseInt(obj.SOLD_QUANTITY)),
        "CATEGORY" : obj.CATEGORY_NAME
      };
    });
    downloadExcelFile(modifiedData);
  };

  return (
    <>
	  <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">All Inventory(s) Report</p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div>
            <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div class="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search Inventory:</p>
                </div>
                <form class="d-flex" style={{ height: "30px" }}>
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search Inventory "
                    aria-label="Search"
                  />
                  <button class="button1 mxhtsh m-0 " type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2">&nbsp; 67 Products(s)</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-6 pt-2 pb-2  ">
                <div class="vhp1 pt-0">All Inventory(s)</div>
              </div>
              <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
               
                <div class="vhp1 pt-0">
				<button class="button1" disabled={report.length === 0}
				  onClick={downloadExcel}>Download </button>
                </div>
              </div>
            </div>

            <div class="hyeus1 mt-2 p-2">
              <table class="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>Vendor_ID</td>
                    <td>Product_Details</td>
                    <td>Vendor_Name</td>
                    <td>Available_Stock</td>
                    <td>Listing_Price</td>
                    <td>MRP</td>
                    <td>Total_Stock</td>
                    <td>Category</td>
                  </tr>
                </thead>
                <tbody>
				{report.length === 0 ? (
        <tr>
          <td className="text-center" colSpan={8}>No data found</td>
        </tr>
      ) : (
        report.map((rpt) => (
			<tr key={`rptad${rpt.ID}`}>
			  <td>{rpt.ID}</td>
			  <td>{rpt.PRODUCT_NAME}</td>
			  <td>{rpt.NAME}</td>


			  <td>{rpt.STOCK_QUANTITY}</td>
			  <td>{rpt.CURRENT_AMOUNT}</td>
			  <td>{rpt.MRP}</td>
			  <td>{parseInt(rpt.SOLD_QUANTITY) + parseInt(rpt.STOCK_QUANTITY)}</td>
			  <td>{rpt.CATEGORY_NAME}</td>

			  <td></td>
			</tr>
		  )))}

                  {/* <tr>
                    <td>45871254</td>
                    <td>
                      mCaffeine Cocoa Kiss Creamy Matte Lipstick-Mocha Muse
                      (Mocha Muse, 4.2 g)
                    </td>
                    <td>Example Name</td>
                    <td>8574961236</td>
                    <td>52</td>
                    <td>Delhi</td>
                    <td>Active</td>
                    <td>
                      <button class="btn btclr w-100">Edit</button>
                    </td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>8574961236</td>
                    <td>25</td>
                    <td>Delhi</td>
                    <td>Active</td>
                    <td>
                      <button class="btn btclr w-100">Edit</button>
                    </td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>8574961236</td>
                    <td>78</td>
                    <td>Delhi</td>
                    <td>Active</td>
                    <td>
                      <button class="btn btclr w-100">Edit</button>
                    </td>
                  </tr>
                  <tr>
                    <td>45871254</td>
                    <td>20/09/2020 14:27:15</td>
                    <td>Example Name</td>
                    <td>8574961236</td>
                    <td>98</td>
                    <td>Delhi</td>
                    <td>Active</td>
                    <td>
                      <button class="btn btclr w-100">Edit</button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adinventoryreport;
