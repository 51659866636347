import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import "./Common.css";
import { Link } from "react-router-dom";

import img5 from "../media/img4.png";

import img8 from "../media/male.jpg";
import queryString from "query-string";
import AppContext from "../AppContext";
import {
  FaFacebook,
  FaWhatsapp,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaMailBulk,
  FaPinterest,
  FaThumbsDown,
  FaThumbsUp,
  FaShare,
  FaStar,
  FaStarHalfAlt,
  FaHeart,
} from "react-icons/fa";
import { toast } from "react-toastify";
import Featured from "../component/Featured.jsx";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function Singleproduct() {
  const [hoveredImage, setHoveredImage] = useState(null);
  const [isdeliverable, setIsDeliverable] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [prodDtls, setProdDtls] = useState({
    productDetails: [],
    productSpecs: [],
    productVarient: [],
    productColor: [],
  });

  const [reviews, setReviews] = useState({});
  const [reviewbreakdw, setReviewbreakdw] = useState({
    beforedecimal: 0,
    afterdecimal: false,
    productReviews: [],
  });

  const [quantity, SetQuantity] = useState(1);

  const { variable1, updateVariable1Value } = useContext(AppContext);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const subCategoryId = queryParams.subCategory;
  const requestType = queryParams.requestType;
  const q = queryParams.q;
  const productId = queryParams.productId;
  const catId = queryParams.catId;

  const handleClick = () => {
    //updateVariable1Value(variable1 + 1);
    addProductToCart();
  };

  function addProductToCart() {
    var valdata = {
      userId: localStorage.getItem("userId"),
      prodId: productId,
      q: q,
      quantity: "1",
      requestType: "AddCart",
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addCartlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        // toast.success("Product Added.");
        updateVariable1Value(variable1 + 1);
      } else {
        toast.error(response.po_MSG);
      }
    });
  }

  function loadProduct() {
    var valdata = {
      subCatId: subCategoryId,
      prodId: productId,
      q: q,
      catId: catId,
      requestType: requestType,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getProductDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setProdDtls({
        productDetails: response.po_DETAIL[0],
        productSpecs: response.po_SPECIFICATION,
        productVarient: response.po_VARIENT,
        productColor: response.po_COLOR,
      });
      reviewDtls(response.po_DETAIL[0].ID);
      console.log(response.po_DETAIL[0]);
    });
  }

  function reviewDtls(prodId) {
    var valdata = {
      prodId: productId,
      requestType: requestType,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getProductratingdtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setReviews(response.hsmp);
      setReviewbreakdw({
        beforedecimal: Math.floor(
          parseFloat(response.hsmp.AVGRating ? response.hsmp.AVGRating : 0)
        ),
        afterdecimal:
          parseFloat(response.hsmp.AVGRating) % 1 > 0 ? true : false,
        productReviews: response.po_DETAIL_OTHER,
      });
      console.log(Math.floor(parseFloat(reviews.AVGRating)));
    });
  }

  const moveWishList = () => {
    var valdata = {
      userId: $("#userId").val(),
      prodId: productId,
      iD: "",
      typeW: "SIN_PROD",
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addWishlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (
        parseInt(response.po_STATUS) === 1 &&
        parseInt(response.po_DETAIL[0].INDEX) === 1
      ) {
      } else {
        if (![null, undefined, "null", "undefined"].includes(response.po_MSG)) {
          toast.error(response.po_MSG);
        } else {
          toast.error("Failed To Remove From Cart.");
        }
      }
    });
  };

  useEffect(() => {
    // const allHoverImages = document.querySelectorAll(
    //   ".hover-container div img"
    // );
    // const imgContainer = document.querySelector(".img-container");

    // window.addEventListener("DOMContentLoaded", () => {
    //   allHoverImages[0].parentElement.classList.add("active");
    // });

    // allHoverImages.forEach((image) => {
    //   image.addEventListener("mouseover", () => {
    //     imgContainer.querySelector("img").src = image.src;
    //     allHoverImages.forEach((img) => {
    //       img.parentElement.classList.remove("active");
    //     });
    //     image.parentElement.classList.add("active");
    //   });
    // });
    loadProduct();
  }, [queryParams.productId]);

  const handlePincodeChange = (event) => {
    const inputValue = event.target.value;

    const parsedPincode = parseInt(inputValue);
    if (!isNaN(parsedPincode)) {
      if (parsedPincode.toString().length === 6) {
        $("#_deliveryCheck").prop("disabled", false);
      } else {
        $("#_deliveryCheck").prop("disabled", true);
      }

      console.log("in" + parsedPincode.length);
    } else {
      $("#_deliveryCheck").prop("disabled", false);
      console.log("on");
    }
  };

  const checkPincode = () => {
    if (!$("#_pincodeCheck").val().trim()) {
      toast.error("Please Enter Valid Picode.");
      return false;
    }

    if (
      [440024, 440016, 700007].includes(parseInt($("#_pincodeCheck").val()))
    ) {
      toast.success("Delivery Applicable.");
      setIsDeliverable(false);
    } else {
      setIsDeliverable(true);
      toast.error("Not Applicable For Delivery.");
    }
  };

  const handleColorClick = (catId, prod, subCatid) => {
    const url = `Product?catId=${catId}&productId=${prod}&q=search&requestType=product&subCategory=${subCatid}`;
    window.open(url);
  };

  return (
    <>
      <ScrollToTop />
      <section>
        <div className="prodt-wrapper">
          <div className="container-fluid">
            <div className="product-div">
              <div className="row">
                <div className="col-md-5">
                  <div className="row spstk">
                    <div className="col-md-2">
                      <div className="hover-container d-none d-xl-flex">
                        {prodDtls.productDetails.IMAGES_NAME_1 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_1.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_1.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {prodDtls.productDetails.IMAGES_NAME_2 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_2.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_2.trim()
                                )
                              }
                              // onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {prodDtls.productDetails.IMAGES_NAME_3 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_3.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_3.trim()
                                )
                              }
                              // onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {prodDtls.productDetails.IMAGES_NAME_4 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_4.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_4.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {prodDtls.productDetails.IMAGES_NAME_5 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_5.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_5.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {prodDtls.productDetails.IMAGES_NAME_6 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_6.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_6.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {prodDtls.productDetails.IMAGES_NAME_7 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_7.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_7.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {prodDtls.productDetails.IMAGES_NAME_8 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_8.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_8.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {prodDtls.productDetails.IMAGES_NAME_9 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_9.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_9.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {prodDtls.productDetails.IMAGES_NAME_10 ? (
                          <div className="div-hvr-1">
                            <img
                              src={`${
                                Baseurl.getImgUrl
                              }prodImages/${prodDtls.productDetails.IMAGES_NAME_10.trim()}`}
                              className="sngl-prodt-img"
                              onMouseEnter={() =>
                                setHoveredImage(
                                  prodDtls.productDetails.IMAGES_NAME_10.trim()
                                )
                              }
                              //onMouseLeave={() => setHoveredImage(null)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div
                        className="img-container"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <img
                          // src={`${Baseurl.getImgUrl}prodImages/${prodDtls.productDetails.IMAGES_NAME_1}`}
                          src={`${Baseurl.getImgUrl}prodImages/${
                            hoveredImage ||
                            prodDtls.productDetails.IMAGES_NAME_1
                          }`}
                          className={`sngl-prodt-img ${
                            isHovered ? "zoomed" : ""
                          }`}
                          alt="Product Img"
                        />
                      </div>
                    </div>
                    <div className="hover-container d-lg-none">
                      {prodDtls.productDetails.IMAGES_NAME_1 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_1.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_1.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {prodDtls.productDetails.IMAGES_NAME_2 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_2.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_2.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {prodDtls.productDetails.IMAGES_NAME_3 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_3.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_3.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {prodDtls.productDetails.IMAGES_NAME_4 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_4.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_4.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {prodDtls.productDetails.IMAGES_NAME_5 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_5.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_5.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {prodDtls.productDetails.IMAGES_NAME_6 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_6.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_6.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {prodDtls.productDetails.IMAGES_NAME_7 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_7.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_7.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {prodDtls.productDetails.IMAGES_NAME_8 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_8.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_8.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {prodDtls.productDetails.IMAGES_NAME_9 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_9.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_9.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {prodDtls.productDetails.IMAGES_NAME_10 ? (
                        <div className="div-hvr-1">
                          <img
                            src={`${
                              Baseurl.getImgUrl
                            }prodImages/${prodDtls.productDetails.IMAGES_NAME_10.trim()}`}
                            className="sngl-prodt-img"
                            onMouseEnter={() =>
                              setHoveredImage(
                                prodDtls.productDetails.IMAGES_NAME_10.trim()
                              )
                            }
                            //onMouseLeave={() => setHoveredImage(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-6 ">
                      <span className="hambs">
                        <Link to="/" className="hambss">
                          {" "}
                          Home &gt;
                        </Link>
                        <Link
                          to={`/Allproduct?q=search&requestType=find&subCategory=${prodDtls.productDetails.SUB_CATEGORY_ID}`}
                          className="hambss"
                        >
                          {" "}
                          {prodDtls.productDetails.CATEGORY_NAME} &gt;
                        </Link>
                        <Link
                          to={`/Allproduct?q=search&requestType=find&subCategory=${prodDtls.productDetails.SUB_CATEGORY_ID}`}
                          className="hambss"
                        >
                          {" "}
                          {prodDtls.productDetails.SUB_CATEGORY_NAME}
                        </Link>
                      </span>
                    </div>
                    <div className="col-md-6 text-end">
                      <span className="share-spn pr-1">
                        <FaShare />
                      </span>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="share-link"
                      >
                        <FaFacebook />
                      </a>
                      <a
                        href={`whatsapp://send?text=${encodeURIComponent(
                          window.location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="share-link p-1"
                      >
                        <FaWhatsapp className="whtscl" />
                      </a>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <h1 className="pro-hdng1">
                      {prodDtls.productDetails.PRODUCT_NAME}
                    </h1>
                    <div className="product-rating">
                      {/* {[...Array(reviewbreakdw.beforedecimal)].map((_, index) => (
                        <span key={index}>
                          <FaStar />
                        </span>
                      ))}

{(reviewbreakdw.afterdecimal ? <span >
                          <FaStarHalfAlt />
                        </span> : null )} */}

                      <span>
                        {reviews.AVGRating != null ||
                        parseInt(reviews.reviewsCount) !== 0
                          ? `${reviews.AVGRating} ratings | ${reviews.reviewsCount} Reviews`
                          : ""}
                      </span>
                    </div>
                    <div>
                      <h3 className="p-0 m-0">
                        &#8377;{prodDtls.productDetails.CURRENT_AMOUNT}{" "}
                      </h3>
                      <p className="p-0 m-0 ft-14">
                        M.R.P.: <s>&#8377; {prodDtls.productDetails.MRP}</s>{" "}
                        <span className="spncrt3">
                          {" "}
                          Save&#8377;
                          {parseInt(prodDtls.productDetails.MRP) -
                            parseInt(
                              prodDtls.productDetails.CURRENT_AMOUNT
                            )}{" "}
                          (
                          {Math.round(
                            100 -
                              (parseFloat(
                                prodDtls.productDetails.CURRENT_AMOUNT
                              ) *
                                100) /
                                (parseFloat(prodDtls.productDetails.MRP) == 0
                                  ? 1
                                  : parseFloat(prodDtls.productDetails.MRP))
                          )}
                          % Off)
                        </span>
                      </p>
                    </div>
                    <div className="p-0 m-0">
                      <span className="crtsp1 t-col-succss">
                        Inclusive of all taxes
                      </span>
                    </div>

                    <div className="">
                      <div className="">
                        <input
                          type="text"
                          className="inptpc-sgp"
                          placeholder="Enter Pincode"
                          maxLength={6}
                          id="_pincodeCheck"
                          onChange={handlePincodeChange}
                          name=""
                        />
                        <button
                          className="sngl-prodt-pinchk"
                          id="_deliveryCheck"
                          onClick={checkPincode}
                        >
                          Check
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    {prodDtls.productVarient &&
                    prodDtls.productVarient.length > 0
                      ? prodDtls.productVarient.map((variant, index) => (
                          <div className="row p-2">
                            <div className="col-md-2"></div>
                            <div className="col-md-10">
                              <div className="row">
                                <div className="col">
                                <Link
                                  to={`/Product?catId=${variant.CATEGORY_ID}&productId=${variant.ID}&q=search&requestType=product&subCategory=${variant.SUB_CATEGORY_ID}`}
                                  key={index}
                                >
                                  <div className="col-md-2 text-center">
                                    <div className="kisa">
                                      <img
                                        src={`${
                                          Baseurl.getImgUrl
                                        }prodImages/${variant.IMAGES_NAME_1.trim()}`}
                                        className="img-fluid neimg"
                                      />
                                    </div>
                                    {/* <div className="jswnds">{variant.COLOR}</div> */}
                                    <div className="jswnds">{variant.SIZE}</div>
                                  </div>
                                </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>

                  <div className="my-2">
                    <div className="row">
                      {prodDtls.productDetails.COLOR === "" ? (
                        ""
                      ) : (
                        <div className="mb-2">
                          <span className="nmj009">Color :</span>{" "}
                          <strong>{prodDtls.productDetails.COLOR}</strong>
                        </div>
                      )}
                    </div>
                    {prodDtls.productDetails.COLOR === "" ? (
                      ""
                    ) : (
                      <div class="row">
                        <div className="col-1">
                          <div className="colormain">
                            <div
                              className="color"
                              style={{
                                backgroundColor: prodDtls.productDetails.COLOR,
                              }}
                            ></div>
                          </div>
                        </div>

                        {prodDtls.productColor &&
                        prodDtls.productColor.length > 0
                          ? prodDtls.productColor.map((color, index) => (
                            
                                  <div className="col-1">
                                    <Link
                                  to={`/Product?catId=${color.CATEGORY_ID}&productId=${color.ID}&q=search&requestType=product&subCategory=${color.SUB_CATEGORY_ID}`}
                                  key={index}
                                >
<div className="colormain cursorpointer">
                                  <div
                                    className="color"
                                    style={{ backgroundColor: color.COLORID }}
                                  ></div>
                                </div>
                                </Link>
                                </div>
                                
                              // <div
                              //   className="col-1"
                              //   onClick={() =>
                              //     handleColorClick(
                              //       color.CATEGORY_ID,
                              //       color.ID,
                              //       color.SUB_CATEGORY_ID
                              //     )
                              //   }
                              // >
                                
                              // </div>
                            ))
                          : ""}
                      </div>
                    )}
                  </div>

                  <div className="btn-groups">
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <button
                          onClick={handleClick}
                          type="button"
                          className="adtcrtbtn add-cart-btn"
                          disabled={isdeliverable}
                        >
                          <i className="fas fa-shopping-cart"></i>add to cart
                        </button>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <Link
                          to={`/Checkout?requestType=singleProduct&productDtls=${productId}&prodqty=${quantity}`}
                        >
                          <button
                            type="button"
                            className="adtcrtbtn buy-now-btn"
                            disabled={isdeliverable}
                          >
                            Buy Now
                          </button>
                        </Link>
                      </div>
                    </div>
                    <span className="wishlst-spn w-hvr" onClick={moveWishList}>
                      {" "}
                      <FaHeart /> Add To Wishlist
                    </span>
                  </div>

                  <div className="row p-3">
                    <div className="col-3">
                      <p></p>
                      <p className="sldby-sp1">100% Genuine Products</p>
                    </div>
                    <div className="col-3">
                      <p></p>
                      <p className="sldby-sp1">Easy Return In 15 Days</p>
                    </div>
                    <div className="col-3">
                      <p></p>
                      <p className="sldby-sp1">1000+ Options</p>
                    </div>
                    <div className="col-3">
                      <p></p>
                      <p className="sldby-sp1">Cash On Delivery Available</p>
                    </div>
                  </div>

                  <div className="sold-by p-2">
                    <p className="sldby-p1">
                      Sold By:{" "}
                      <span className="sldby-sp1">
                        {prodDtls.productDetails.STORENAME}
                      </span>
                    </p>
                    {prodDtls.productDetails.ISFULFILLED === 1 ? (
                      <img src={img5} className="img-fulil" />
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="p-0 pt-2">
                    <p className="pro-des-hilt1">From the Manufacturers</p>
                    <img
                      src={`${Baseurl.getImgUrl}prodImages/${prodDtls.productDetails.P_IMAGES_1}`}
                      className="img-pro-des1"
                    />
                    <img
                      src={`${Baseurl.getImgUrl}prodImages/${prodDtls.productDetails.P_IMAGES_2}`}
                      className="img-pro-des1"
                    />
                    <img
                      src={`${Baseurl.getImgUrl}prodImages/${prodDtls.productDetails.P_IMAGES_3}`}
                      className="img-pro-des1"
                    />
                    <img
                      src={`${Baseurl.getImgUrl}prodImages/${prodDtls.productDetails.P_IMAGES_4}`}
                      className="img-pro-des1"
                    />
                    <img
                      src={`${Baseurl.getImgUrl}prodImages/${prodDtls.productDetails.P_IMAGES_5}`}
                      className="img-pro-des1"
                    />
                  </div> */}
                  <div className="p-0 pt-3">
                    <p className="pro-des-hilt1">Description</p>
                    <p>{prodDtls.productDetails.PRODUCT_DESC}</p>

                    <ul>
                      {prodDtls.productDetails.DESC_1 == "" ? (
                        ""
                      ) : (
                        <li className="prodBullet">
                          {prodDtls.productDetails.DESC_1}
                        </li>
                      )}
                      {prodDtls.productDetails.DESC_2 == "" ? (
                        ""
                      ) : (
                        <li className="prodBullet">
                          {prodDtls.productDetails.DESC_2}
                        </li>
                      )}
                      {prodDtls.productDetails.DESC_3 == "" ? (
                        ""
                      ) : (
                        <li className="prodBullet">
                          {prodDtls.productDetails.DESC_3}
                        </li>
                      )}
                      {prodDtls.productDetails.DESC_4 == "" ? (
                        ""
                      ) : (
                        <li className="prodBullet">
                          {prodDtls.productDetails.DESC_4}
                        </li>
                      )}
                      {prodDtls.productDetails.DESC_5 == "" ? (
                        ""
                      ) : (
                        <li className="prodBullet">
                          {prodDtls.productDetails.DESC_5}
                        </li>
                      )}
                    </ul>

                    <p className="pro-des-hilt1">Specification</p>
                    {prodDtls.productSpecs.map((proddtl) => (
                      <div className="row" key={`specs${proddtl.ID}`}>
                        <div className="col-6 col-md-4 col-lg-4 spclspe1">
                          {" "}
                          {proddtl.ATTRIBUTE_KEY}:{" "}
                        </div>
                        <div className="col-6 col-md-8 col-lg-8 spclspe2">
                          {" "}
                          {proddtl.ATTRIBUTE_VALUE}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* review */}

                  <div>
                    {/* <div className="sold-by p-3 mb-2">
                      <div>
                        <img src={img8} className="img-fulil-1" />
                        <p className="sldby-p1 dis-unset">Buyer name here</p>
                        <div className="product-rating">
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStarHalfAlt />
                          </span>
                          <span>On 20/7/2022</span>
                        </div>
                      </div>

                      <p>Good Product Love It.</p>
                      <hr />
                      <p className="p-0 rev-p-1">
                        Is this Review helpful for you?{" "}
                        <Link to="">
                          <FaThumbsUp /> (18)
                        </Link>
                        <Link to="">
                          <FaThumbsDown />
                          (5)
                        </Link>
                      </p>
                    </div> */}

                    {reviewbreakdw.productReviews.length === 0
                      ? ""
                      : reviewbreakdw.productReviews.slice(0, 5).map((rpt) => (
                          <div className="hyeus1 p-3 mb-2">
                            <div>
                              <img src={img8} className="img-fulil-1" />
                              <p className="sldby-p1 dis-unset">
                                {rpt.username}
                              </p>
                              <div className="product-rating">
                                {[...Array(parseInt(rpt.RATING))].map(
                                  (_, index) => (
                                    <span key={index}>
                                      <FaStar />
                                    </span>
                                  )
                                )}

                                <span>On {rpt.TIME_STAMP}</span>
                              </div>
                            </div>
                            <p>{rpt.PI_COMMENT}</p>

                            {/* <p className="p-0 rev-p-1">
            Is this Review helpful for you?{" "}
            <Link to="">
              <FaThumbsUp /> (18)
            </Link>
            <Link to="">
              <FaThumbsDown />
              (5)
            </Link>
          </p> */}
                          </div>
                        ))}

                    {/* <div className="sold-by p-3 mb-2">
                      <div>
                        <img src={img8} className="img-fulil-1" />
                        <p className="sldby-p1 dis-unset">Buyer name here</p>
                        <div className="product-rating">
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStar />
                          </span>
                          <span>
                            <FaStarHalfAlt />
                          </span>
                          <span>On 20/7/2022</span>
                        </div>
                      </div>

                      <p>Good Product Love It.</p>
                      <hr />
                      <p className="p-0 rev-p-1">
                        Is this Review helpful for you?{" "}
                        <Link to="">
                          <FaThumbsUp /> (18)
                        </Link>
                        <Link to="">
                          <FaThumbsDown />
                          (5)
                        </Link>
                      </p>
                    </div> */}

                    {reviewbreakdw.productReviews.length >= 5 ? (
                      <div className="p-0 m-0">
                        <span className="crtsp1 lk1 t-col-succss">
                          <Link
                            to={`/Allreview?catId=${catId}&productId=${productId}&q=search&requestType=allReview&subCategory=${subCategoryId}`}
                          >
                            {" "}
                            View More Review
                          </Link>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <Featured
                  pindex="singleProd"
                  subCatId=""
                  catId={catId}
                  catName={prodDtls.productDetails.CATEGORY_NAME}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Singleproduct;
