import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";

import img1 from "../media/ic1.svg";
import img2 from "../media/ic2.svg";
import img3 from "../media/ic3.svg";
import img4 from "../media/ic4.svg";
import img5 from "../media/img1.jpg";
import { Link, useLocation } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FaPlus } from "react-icons/fa";

function Homesetup() {
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);


  useEffect(() => {
		loadmainmenu();
    loadsubcatmenu();
  },[]);

  function loadmainmenu() {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getMainMenu`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    $.ajax(settings).done(function (response) {
      if(parseInt(response.po_STATUS) == 1){
		$("#_sub_MainCat,#_Hero_MainCat,#_Slider_MainCat,#_HalfWd_MainCat").empty();
			$("#_sub_MainCat,#_Hero_MainCat,#_Slider_MainCat,#_HalfWd_MainCat").append('<option value="">Select</option>');
			$.each(response.po_DETAIL,function(key,value){
				
			  $("#_sub_MainCat,#_Hero_MainCat,#_Slider_MainCat,#_HalfWd_MainCat").append(
						'<option value="'+response.po_DETAIL[key].ID+'" >'
								+ response.po_DETAIL[key].CATEGORY_NAME + '');

                setCategory(response.po_DETAIL);


			});


	  }
    });
  };

  const savecategory = () => {
    if(!$("#_categry_Name").val().trim()){
      toast.error("Please Enter Valid Category Name.");
      return false;
    }
    var valdata = {
        adminId: $("#adminId").val().trim(),
categoryname : $("#_categry_Name").val()
    };
  
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addcategory`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
  
    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
          toast.success("Added");
          loadmainmenu();
         $("#_categry_Name").val('')
      } else {
        toast.error(response.po_MSG);
      }
    });
  };


  const savesubcategory = () => {
    if(!$("#_sub_MainCat").val().trim()){
      toast.error("Please Enter Valid Category Name.");
      return false;
    }
    if(!$("#_SubcatName").val().trim()){
      toast.error("Please Enter Valid Category Name.");
      return false;
    }

    var valdata = {
        adminId: $("#adminId").val().trim(),
        categoryId : $("#_sub_MainCat").val(),
        subcategoryname : $("#_SubcatName").val().trim()
    };
  
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addsubcategory`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
  
    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
          toast.success("Added");
          loadsubcatmenu();
         $("#_categry_Name").val('')
      } else {
        toast.error(response.po_MSG);
      }
    });
  };


  function loadsubcatmenu() {
    var valdata = {
      adminId: $("#adminId").val().trim(),
      categoryId : $("#_sub_MainCat").val(),
      subcategoryname : $("#_SubcatName").val().trim()
  };
    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchsubcategory`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setSubCategory(response.po_DETAIL);
    });
  };

  const Editcategory = (id, CatName) => {
    
  };
  return (
    <>
      <ToastContainer />
      <section className=" pb-3" style={{ backgroundColor: "#f7f7f7" }}>
        <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
          <p class="vhp3">Home Setup(s)</p>
        </div>

        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card p-3  crdbdrnone">
                  
                    <div class="row">
                      <div class="col-md-6 col-6 pt-2 pb-2  ">
                        <div class="vhp1 pt-0">Add Category</div>
                      </div>
                    </div>
                    <hr />

                    <div className="row g-3">
                      <div className="col-12">
                        <label htmlFor="firstName" className="form-label chkf1">
                          Category Name
                        </label>
                        <input
                          type="text"
                          className="form-control inpt1"
                          id="_categry_Name"
                          placeholder="Enter Category Name"
                          
                        />
                      </div>
                    </div>

                    <div className="pt-3 col-md-4">
                      <button className="btnvd add-cart-btn" onClick={savecategory}>Add</button>
                    </div>
                  
                </div>
              </div>
              <div className="col-md-6">
                <div class="card  p-2 crdbdrnone">
                  <div class="row">
                    <div class="col-md-6 col-6 pt-2 pb-2  ">
                      <div class="vhp1 pt-0">Category(s) List</div>
                    </div>
                  </div>
                  <hr />
                  <table class="table table-hover ft13">
                    <thead className="tbhd1">
                      <tr>
                        <td>Category</td>
                        {/* <td>Edit</td> */}
                        
                      </tr>
                    </thead>
                    <tbody>
                    {category.map((categorys) => (
                  <tr key={`categorys${categorys.ID}`}>
                    <td>{categorys.CATEGORY_NAME}</td>
                    {/* <td><button class="btnvd add-cart-btn" onClick={() => Editcategory(categorys.ID, categorys.CATEGORY_NAME)}>Edit</button></td> */}

                  </tr>
                ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card p-3 crdbdrnone">
                  
                    <div class="row">
                      <div class="col-md-6 col-6 pt-2 pb-2  ">
                        <div class="vhp1 pt-0">Add Sub-Category</div>
                      </div>
                    </div>
                    <hr />

                    <div className="row g-3">
                      <div className="col-12">
                        <label htmlFor="firstName" className="form-label chkf1">
                          Select Category
                        </label>
                        <select
                          id="_sub_MainCat"
                          className="form-control"
                          name=""
                        >
                          
                        </select>
                      </div>

                      <div className="col-12">
                        <label htmlFor="firstName" className="form-label chkf1">
                          Sub-Category Name
                        </label>
                        <input
                          type="text"
                          className="form-control inpt1"
                          id="_SubcatName"
                          placeholder="Enter Sub-Category Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="pt-3 col-md-4">
                      <button className="btnvd add-cart-btn" onClick={savesubcategory}>Add</button>
                    </div>
                  
                </div>
              </div>
              <div className="col-md-6">
                <div class="card  p-2 crdbdrnone">
                  <div class="row">
                    <div class="col-md-6 col-6 pt-2 pb-2  ">
                      <div class="vhp1 pt-0">Sub-Category(s) List</div>
                    </div>
                  </div>
                  <hr />
                  <table class="table table-hover ft13">
                    <thead className="tbhd1">
                      <tr>
                        <td>Category</td>
                        <td>Sub-Category</td>
                       
                      </tr>
                    </thead>
                    <tbody>
                    {subcategory.map((subcategorys) => (
                  <tr key={`subcategorys${subcategorys.ID}`}>
                    <td>{subcategorys.CATEGORY_NAME}</td>
                    <td>{subcategorys.SUB_CATEGORY_NAME}</td>

                  </tr>
                ))}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        

        

        
      </section>
    </>
  );
}

export default Homesetup;
