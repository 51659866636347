import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import logo from '../media/logo6.png';

import { Link, useLocation } from "react-router-dom";

import { FaFacebook,FaCaretDown } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

function Vdheader() {
  const navigate = useNavigate();

  const [vendorId, setVendorId] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [vendorMobile, setVendorMobile] = useState('');
  const [vendorMail, setVendorMail] = useState('');

  useEffect(() => {
   
      const userId = localStorage.getItem('vendorId');
      if (!userId) {
        navigate("/Seller");
        return;
      }
    
      setVendorId(localStorage.getItem('vendorId'));
      setVendorMobile(localStorage.getItem('MOBILENO'));
      setVendorName('Test Vendor');
      setVendorMail('test@test.com');
  }, []);

  const logout = () => {
    localStorage.removeItem("vendorId");
    localStorage.removeItem("MOBILENO");
    localStorage.removeItem("authenticated");
    navigate("/Seller");
  };

  const handleLinkClick = (event) => {
    event.preventDefault(); 
  };

  return (
    <>
    <input type="hidden" id="vendorId" value={vendorId} />
    <input id="rowId" type="hidden" value="7" />
    <input id="_vName" type="hidden" value={vendorName} />

    <input id="_vmail" type="hidden" value={vendorMail} />
    <input id="_vMobile" type="hidden" value={vendorMobile} />



      <nav className="navbar navbar-expand-lg navbar-light navvbgcl1">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/Vdhome">
            <img src={logo} style={{ height: "40px" }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

            <li role="menuitem " style={{listStyle: "none"}}>
          <Link
                  className="nav-link navhv1"
                  style={{ fontWeight: "600", color: "#000" }}
                  aria-current="page"
                  to="/Vdhome"
                >
                  Home
                </Link>
    </li>




            <li role="menuitem " style={{listStyle: "none", padding:"10px"}}>
     <Link href="#" className="dmhvr text-dark txtdeco22" style={{backgroundColor: "#fff600",fontWeight: "600"}}>Listing <FaCaretDown size={15} /></Link>
     <div className="mega-menu "  aria-hidden="true" role="menu">
      <div className="nav-column row m-0">
       
       <ul className="p-0">
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Adproduct">
        Add Product
        </Link>
        </li> 

        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdmanagelisting">
        Manage Listing
        </Link>
        </li>   
       </ul>
      </div>
     </div>
    </li>



    <li role="menuitem " style={{listStyle: "none", padding:"10px"}}>
     <Link href="#" className="dmhvr text-dark txtdeco22" style={{backgroundColor: "#fff600",fontWeight: "600"}}>Inventory <FaCaretDown size={15} /></Link>
     <div className="mega-menu "  aria-hidden="true" role="menu">
      <div className="nav-column row m-0">
       
       <ul className="p-0">
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdmanageinventory">
        Manage Inventory
        </Link>
        </li>   
       </ul>
      </div>
     </div>
    </li>



    <li role="menuitem " style={{listStyle: "none", padding:"10px"}}>
     <Link href="#" className="dmhvr text-dark txtdeco22" style={{backgroundColor: "#fff600",fontWeight: "600"}}>Orders <FaCaretDown size={15} /></Link>
     <div className="mega-menu "  aria-hidden="true" role="menu">
      <div className="nav-column row m-0">
       
       <ul className="p-0">
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdmanageorder">
        Manage Orders
        </Link>
        </li>   
       </ul>
      </div>
     </div>
    </li>

    <li role="menuitem " style={{listStyle: "none", padding:"10px"}}>
     <Link href="#" className="dmhvr text-dark txtdeco22" style={{backgroundColor: "#fff600",fontWeight: "600"}}>Payments <FaCaretDown size={15} /></Link>
     <div className="mega-menu "  aria-hidden="true" role="menu">
      <div className="nav-column row m-0">
       
       <ul className="p-0">
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdpayout">
        Payments
        </Link>
        </li>  
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdtransaction">
        All Transactions
        </Link>
        </li> 
       </ul>
      </div>
     </div>
    </li>

    <li role="menuitem " style={{listStyle: "none", padding:"10px"}}>
     <Link href="#" className="dmhvr text-dark txtdeco22" style={{backgroundColor: "#fff600",fontWeight: "600"}}>Reports <FaCaretDown size={15} /></Link>
     <div className="mega-menu " style={{zIndex: "1"}}  aria-hidden="true" role="menu">
      <div className="nav-column row m-0">
       
       <ul className="p-0">
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdlistingreport">
        Listing Report
        </Link>
        </li>  
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdinventoryreport">
        Inventory Report
        </Link>
        </li> 

        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdorderreport">
        Order Report
        </Link>
        </li>

        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdpayoutreport">
        Payout Report
        </Link>
        </li>

        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdinventoryreport">
        Inventory Report
        </Link>
        </li>
       </ul>
      </div>
     </div>
    </li>


    <li role="menuitem " style={{listStyle: "none", padding:"10px"}}>
     <Link href="#" className="dmhvr text-dark txtdeco22" style={{backgroundColor: "#fff600",fontWeight: "600"}}>Tickets <FaCaretDown size={15} /></Link>
     <div className="mega-menu "  aria-hidden="true" role="menu">
      <div className="nav-column row m-0">
       
       <ul className="p-0">
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdticket">
        Open Ticket
        </Link>
        </li>  
       
       </ul>
      </div>
     </div>
    </li>


          
            </ul>
            <form className="d-flex d-none d-sm-none d-md-block d-lg-block">
              

              <li role="menuitem " style={{listStyle: "none", padding:"10px"}}>
     <Link href="#" className="dmhvr text-dark txtdeco22" style={{backgroundColor: "#fff600",fontWeight: "600"}}>My Account  <FaCaretDown size={15} /></Link>
     <div className="mega-menu rtac1"  aria-hidden="true" role="menu">
      <div className="nav-column row m-0">
       
       <ul className="p-0">
        <li role="menuitem" style={{listStyle: "none"}}>
        <Link className="dropdown-item" to="/Vdaccount">
        My Account
                          </Link>
        </li> 
        <li role="menuitem" style={{listStyle: "none"}} onClick={logout}>
        <Link className="dropdown-item" >
        Logout
        </Link>
        </li>    
       </ul>
      </div>
     </div>
    </li>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Vdheader;
