import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import img2 from "../media/prod12.jpg";
import img3 from "../media/prod13.jpg";
import img4 from "../media/prod14.jpg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";


function Order() {
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [orderdtls, setOrderDtls] = useState([]);

  const [prodId, setProdId] = useState("0");
  const [orderId, setOrderId] = useState("0");
  const [reason, setReason] = useState("");


  const handleCheckboxChange = (checkboxValue) => {
    setSelectedCheckbox(checkboxValue);
    loadOrder(checkboxValue);
  };

  useEffect(() => {
    loadOrder("");
  }, []);

  function loadOrder(checkBoxValData) {
    var valdata = {
      userId: $("#userId").val(),
      filterBy: checkBoxValData,
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAllOrdersUser`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setOrderDtls(response.po_DETAIL);
      if(checkBoxValData === ''){
        setSelectedCheckbox('');
      }
    });
  }


  const updateOrderSts = () => {
    
    var valdata = {
      userId: $("#userId").val(),
      orderId: $("#orderId").val(),
      prodId:  $("#prodId").val(),
      reason : $("input[name='flexRadioDefault']:checked").val()
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}updateOrderDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 0) {
        toast.error("Failed To Cancel Order.");
      }else{
        $(".btn-close").click();
        loadOrder("");
        toast.success("Ticket Opened For Cancel/Refund Successfully.");
        
      }
    });
  };


  const setDetails = (orderId, prodId) => {
    $("#prodId").val(prodId);
    $("#orderId").val(orderId);
  };


  return (
    <>
    <ToastContainer />

    <input type="hidden" id="prodId"></input>
    <input type="hidden" id="orderId"></input>


      <section className="bgsec1">
        <div className="container p-3">
          <div className="row">
            <nav className="divider" aria-label="breadcrumb">
              <ol className="breadcrumb ft12 mb-1">
                <li className="breadcrumb-item txthvr-2">Home</li>
                <li
                  className="breadcrumb-item txthvr-2  "
                  aria-current="page"
                >
                  Library
                </li>
              </ol>
            </nav>
            <div className="col-md-3 col-lg-3">
              <div className="hyeus1 bgwt ">
               
                <div className="row p-2">
                  <div className="col-6">
                    <div className="juehys1">Filter</div>
                  </div>
                  <div className="col-6">
                    <div className="jswnds ptrr" onClick={() => loadOrder("")}>All Clear</div>
                  </div>
                </div>


                <div className="p-3 pt-1 pb-1 bdtp-1">
                  <p className="mkjdu1 mb-1">Order Status</p>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={selectedCheckbox === "'PENDING','ACTIVE'"}
                      onChange={() => handleCheckboxChange("'PENDING','ACTIVE'")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      On The Way
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="_checkDeliver"
                      checked={selectedCheckbox === "'DELIVERED'"}
                      onChange={() => handleCheckboxChange("'DELIVERED'")}
                    />
                    <label className="form-check-label" htmlFor="_checkDeliver">
                      Delivered
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="_checkCancle"
                      checked={selectedCheckbox === "'CANCELED'"}
                      onChange={() => handleCheckboxChange("'CANCELED'")}
                    />
                    <label className="form-check-label" htmlFor="_checkCancle">
                      Cancelled
                    </label>
                  </div>
                  {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="_checkReturn"
                      checked={selectedCheckbox === "'RETURNED'"}
                      onChange={() => handleCheckboxChange("'RETURNED'")}
                    />
                    <label className="form-check-label" htmlFor="_checkReturn">
                      Returned
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-9 col-lg-9">
              <div className="hyeus1" style={{ border: "0px" }}>
                <div className="bdbt-1">
                  <h3 className="p-3 pt-2 pb-1 text-capitalize">My Order</h3>
                </div>

                {orderdtls.length === 0 ? (
                  <p>No orders found.</p>
                ) : (
                  orderdtls.map((orderdtl) => (
                    <div
                      key={`order${orderdtl.ID}`}
                      className="card m-3 mb-2 crdhvr2 bdr-0"
                      style={{ border: "0px" }}
                    >
                      <div className="row align-items-center p-1">
                        <div className="col-md-2 col-3 col-lg-2">
                          <img
                            src={`${Baseurl.getImgUrl}prodImages/${orderdtl.IMAGES_NAME_1}`}
                            className="wid-100 img-fluid omhj"
                          />
                        </div>
                        <div className="col-md-5 col-9 col-lg-5">
                         <Link className="text-decoration-none text-bg-info"  to={`/Product?catId=${orderdtl.CATEGORY_ID}&productId=${orderdtl.PRODUCT_ID}&q=search&requestType=product&subCategory=${orderdtl.SUB_CATEGORY_ID}`}> <h1 className="crtitmhd2 hyas">{orderdtl.PRODUCT_NAME}</h1></Link>
                          <p className="order-prc">&#8377;{parseInt(orderdtl.PRICE).toLocaleString('en-IN')}</p>
                          {
                            orderdtl.ORDER_STATUS == "PENDING" ? (
<span onClick={() => setDetails(orderdtl.ORDER_ID, orderdtl.PRODUCT_ID)}>
                            <Link
                              className="excgw1 vgbzx9"
                              data-bs-toggle="modal"
                              data-bs-target="#return"
                            >
                              Exchange/Return window closed on {orderdtl.EXPECTED_DELIVERY_DATE}
                            </Link>
                          </span>
                            ): orderdtl.ORDER_STATUS == 'CANCEL_PENDING' ? (
<p className="lizx56">Customer Support Will Connect In Next 48 Hours For Cancelation/Return.</p>
                            ) : ('')
                          }
                          
                        </div>
                        <div className="col-md-3 col-12 col-lg-3">
                          {orderdtl.ORDER_STATUS === "DELIVERED" ? (
                            <div>
                              <div className="grdt1 grdt2"></div>
                              <span className="ordd1">
                                Delivered On <br />
                                {orderdtl.CANCELDATE}
                              </span>
                            </div>
                          ) : orderdtl.ORDER_STATUS === "PENDING" ? (
                            <div>
                              <div className="grdt1 grdt2"></div>
                              <span className="ordd1">
                                Expected Delivery On <br />
                                {orderdtl.EXPECTED_DELIVERY_DATE}
                              </span>
                            </div>
                          ) : orderdtl.ORDER_STATUS === "CANCELED" ? (
                            <div>
                              <div className="grdt3 grdt4"></div>
                              <span className="ordd1">
                                Cancelled On <br />
                                {orderdtl.CANCELDATE}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <div className="grdt1 grdt2"></div>
                              <span className="ordd1">
                                Expected Delivery On <br />
                                {orderdtl.EXPECTED_DELIVERY_DATE}
                              </span>
                            </div>
                          )}
                          {/* <div>
                          <p className="ordd2">Your item has been delivered</p>
                        </div> */}
                          <div>
                            {orderdtl.ORDER_STATUS === "DELIVERED" ? (
                              <p className="vgbzx9">
                                <Link className="vgbzx9 njh67" to={`/Review?catId=${orderdtl.CATEGORY_ID}&productId=${orderdtl.PRODUCT_ID}&q=search&requestType=review&subCategory=${orderdtl.SUB_CATEGORY_ID}`}>WRITE A REVIEW</Link>
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {orderdtl.ORDER_STATUS === "DELIVERED" ? (
                          <div className="col-md-2 col-12 col-lg-2 ">
                            <div className="text-center">
                              <div>
                                <p className="ordd1">Invoice</p>
                              </div>
                              <div>
                                <p className="invc1">
                                  <Link className="cnlord1" to={`/Invoice?orderId=${orderdtl.ORDER_ID}&productId=${orderdtl.PRODUCT_ID}&q=invoice&requestType=invoice`}>download</Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))
                )}

                {/* <div
                  className="card m-3 mb-2 crdhvr2 bdr-0"
                  style={{ border: "0px" }}
                >
                  <div className="row align-items-center p-1 ">
                    <div className="col-md-2 col-3 col-lg-2">
                      <img src={img1} className="wid-100" />
                    </div>
                    <div className="col-md-5 col-9 col-lg-5">
                      <h1 className="crtitmhd2">
                        ThriveCo Hair Growth Serum | with Redensyl, Anagain,
                        Procapil &amp; Capilia Longa For Hair Fall Control | For
                        Men &amp; Women | 50ml
                      </h1>
                      <p className="order-prc">&#8377;1100</p>
                      <span>
                        <Link
                          className="excgw1"
                          data-bs-toggle="modal"
                          data-bs-target="#return"
                        >
                          Exchange/Return window closed on Tue, 1 June 2023
                        </Link>
                      </span>
                    </div>
                    <div className="col-md-3 col-12 col-lg-3">
                      <div className="grdt1 grdt2"></div>
                      <span className="ordd1">Delivered On Nov 29,2021</span>
                      <div>
                        <p className="ordd2">Your item has been delivered</p>
                      </div>
                      <div>
                        <p>
                          <Link to="">Add Rate & Review Product</Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-2 col-12 col-lg-2 ">
                      <div className="text-center">
                        <div>
                          <p className="ordd1">Invoice</p>
                        </div>
                        <div>
                          <p className="invc1">
                            <Link className="cnlord1">download</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card m-3 mb-2 crdhvr2"
                  style={{ border: "0px" }}
                >
                  <div className="row align-items-center p-1">
                    <div className="col-md-2 col-3 col-lg-2">
                      <img src={img2} className="wid-100" />
                    </div>
                    <div className="col-md-5 col-9 col-lg-5">
                      <h1 className="crtitmhd2">
                        ThriveCo Hair Growth Serum | with Redensyl, Anagain,
                        Procapil &amp; Capilia Longa For Hair Fall Control | For
                        Men &amp; Women | 50ml
                      </h1>
                      <p className="order-prc">&#8377;1100</p>
                    </div>
                    <div className="col-md-3 col-12 col-lg-3">
                      <div className="grdt1 grdt2"></div>
                      <span className="ordd1">
                        Order will be delivered by Nov 20, 2023
                      </span>
                    </div>
                    <div className="col-md-2 col-12 col-lg-2 ">
                      <div className="text-center">
                        <div>
                          <Link
                            className="cnlord1"
                            data-bs-toggle="modal"
                            data-bs-target="#return"
                          >
                            <p className="invc1">Cancel Order</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card m-3 mb-2 crdhvr2"
                  style={{ border: "0px" }}
                >
                  <div className="row align-items-center p-1">
                    <div className="col-md-2 col-3 col-lg-2">
                      <img src={img3} className="wid-100" />
                    </div>
                    <div className="col-md-5 col-9 col-lg-5">
                      <h1 className="crtitmhd2">
                        ThriveCo Hair Growth Serum | with Redensyl, Anagain,
                        Procapil &amp; Capilia Longa For Hair Fall Control | For
                        Men &amp; Women | 50ml
                      </h1>
                      <p className="order-prc">&#8377;1100</p>
                    </div>
                    <div className="col-md-3 col-12 col-lg-3">
                      <div className="grdt3 grdt4"></div>
                      <span className="ordd1">Cancelled On Nov 20,2021</span>
                      <div>
                        <p className="ordd2">Your item has been delivered</p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="return"
        aria-labelledby="return"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header m-0">
              <h5 className="modal-title" id="return">
              Select Reason For Return And Exchange
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pt-0">

              <form>

                <p className="pp1"></p>
              <div className="form-check">
  <input className="form-check-input" type="radio" value="Merchant shipped the wrong product" name="flexRadioDefault" id="flexRadioDefault1"/>
  <label className="form-check-label pp1" for="flexRadioDefault1">
  Merchant shipped the wrong product
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" value="The product arrived too late" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
  <label className="form-check-label pp1" for="flexRadioDefault2">
  The product arrived too late
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" value="No longer needed the product" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
  <label className="form-check-label pp1" for="flexRadioDefault3">
  No longer needed the product
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" value="The product did not match the description" type="radio" name="flexRadioDefault" id="flexRadioDefault6"/>
  <label className="form-check-label pp1" for="flexRadioDefault6">
  The product did not match the description
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" value="Found a better price somewhere else" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
  <label className="form-check-label pp1" for="flexRadioDefault4">
  Found a better price somewhere else
  </label>
</div>

<div className="form-check">
  <input className="form-check-input" value="Other" type="radio" name="flexRadioDefault" id="flexRadioDefault5"/>
  <label className="form-check-label pp1" for="flexRadioDefault5">
  Other
  </label>
</div>

<div className="modal-footer">
              <button type="button" className="btnvd add-cart-btn bdrradius1" onClick={updateOrderSts}>
                Return Now
              </button>
            </div>

            
              </form>
            
            

            

            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Order;
