import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFacebook } from "react-icons/fa";

function Adcart() {
  const [orderdtls, setOrderDtls] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = orderdtls.filter((user) => {
    const userId = user.USERID ? user.USERID.toString() : '';
    const fullName = user.FULL_NAME ? user.FULL_NAME.toLowerCase() : '';
    const mobileNo = user.MOBILENO ? user.MOBILENO.toString() : '';
    const productName = user.PRODUCT_NAME ? user.PRODUCT_NAME.toLowerCase() : '';
    const piCity = user.PI_CITY ? user.PI_CITY.toLowerCase() : '';
    const piStatus = user.PI_STATUS ? user.PI_STATUS.toLowerCase() : '';
  
    return (
      userId.includes(searchQuery) ||
      fullName.includes(searchQuery.toLowerCase()) ||
      mobileNo.includes(searchQuery) ||
      productName.includes(searchQuery.toLowerCase()) ||
      piCity.includes(searchQuery.toLowerCase()) ||
      piStatus.includes(searchQuery.toLowerCase())
    );
  });

  //////////////

  useEffect(() => {
    loadOrder("fetch", "", "");
  }, []);

  function loadOrder(flag, did, status) {
    var valdata = {
      userId: $("#userId").val(),
      flagPi :flag,
      did : did,
      status : status
    };

    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAbondandcart`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setOrderDtls(response.po_DETAIL);
        if(flag === 'M'){
          loadOrder("fetch", "", "");
        }
      } else {
        toast.error(response.po_MSG);
      }
    });
  }

  const downloadExcelFile = (Jsondata) => {
    const worksheet = XLSX.utils.json_to_sheet(Jsondata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(excelData, "AbondonCartRpt.xlsx");
    toast.success("Excel file downloaded successfully!");
  };

  const downloadExcel = () => {
    var modifiedData = orderdtls.map(function (obj) {
      return {
        "USER ID": obj.USERID,
        USERNAME: obj.FULL_NAME,
        "USER CONTACT NO": obj.MOBILENO,

        "PRODUCT NAME": obj.PRODUCT_NAME,
        CITY: obj.PI_CITY,
        "USER STATUS": obj.PI_STATUS == "A" ? "Active" : "Inactive",
      };
    });
    downloadExcelFile(modifiedData);
  };

  return (
    <>
      <ToastContainer />
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">Abondon Cart(s)</p>
            <span className="hdspn1">Learn More</span>
          </div>

          <div>
            <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search User(s):</p>
                </div>
                <form className="d-flex" style={{ height: "30px" }}>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search User ID"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <button className="button1 mxhtsh m-0" type="submit">
                    Search 
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2">&nbsp; {orderdtls.length} Vendor(s)</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-6 pt-2 pb-2  ">
                <div className="vhp1 pt-0">All Abondon Cart(s)</div>
              </div>
              <div className="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                
                <div className="vhp1 pt-0">
                  <button
                    className="button1"
                    disabled={orderdtls.length === 0}
                    onClick={downloadExcel}
                  >
                    Download{" "}
                  </button>
                </div>
              </div>
            </div>

            <div className="card mt-2 p-2">
              <table className="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>User ID</td>
                    <td>User Name</td>
                    <td>Mobile</td>
                    <td>Product(s) in Cart</td>
                    <td>City</td>
                    <td>User Status</td>
                  </tr>
                </thead>
                <tbody>
                {filteredUsers.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    filteredUsers.map((orderlts) => (
                      <tr key={`abbnd${orderlts.ID}`}>
                      <td>{orderlts.USERID}</td>
                      <td>{orderlts.FULL_NAME}</td>
                      <td>{orderlts.MOBILENO}</td>
                      <td>{orderlts.PRODUCT_NAME}</td>
                      <td>{orderlts.PI_CITY}</td>
                      <td>
                        {orderlts.PI_STATUS == "A" ? "Active" : "Inactive"}
                      </td>
                      
                      <td>
                        {orderlts.PI_STATUS == "A" ? <button class="btnvd add-cart-btn" onClick={() => loadOrder("M", orderlts.ID, "D" )}>Delete</button> : <button class="btnvd add-cart-btn" onClick={() => loadOrder("M", orderlts.ID, "A")}>Active</button>}
                      </td>
                    </tr>
                    ))
                  )}



                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adcart;
