import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa'
import { Bar } from "react-chartjs-2";
import { BarElement,  CategoryScale,Chart as ChartJS,Legend, LinearScale,Title, Tooltip } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement,Title,Tooltip,Legend);


function Adhome() {
	const [topselling, setTopSelling] = useState('');
	const [currentMonth, setCurrentMonth] = useState('');
	const [dashboard, setDashboard] = useState({
		monthlySale: "",
  monthlyOrder: "",
  delivered : "",
  pending : "",
  customerFeedback: "",
  marketPlace: "",
  customerFeedback: "",
  totalUsers: "",
  totalVendor : "",
  complain : "",
	  });

  useEffect(() => {
    loadPayouts();
	const date = new Date();
    const month = date.toLocaleString('en-US', { month: 'long' });
    setCurrentMonth(month);
	chartPerday();
    chartMonth();
	topSellingProd();
  }, []);

  function loadPayouts() {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAdminDashboardPanel`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
        setDashboard(
			{
				monthlySale: response.po_DETAIL[0]?.TotalPrice || 0,
  monthlyOrder: response.po_DETAIL[0]?.totalorder || 0,
  delivered : response.po_DETAIL[0]?.delivered,
  pending : response.po_DETAIL[0]?.pending,
  customerFeedback: response.hsmp?.customerFeedback || 0,
  marketPlace: response.hsmp?.marketPlace || 0,
  customerFeedback: response.hsmp?.customerFeedback || 0,
  totalUsers: response.hsmp?.totalUsers || 0,
  totalVendor : response.hsmp?.totalVendor || 0,
  complain : response.hsmp?.complain
			}
		)
    });
  }
//////////////


const option = {
	responsive: true,
	plugins: {
	  legend: { position: "chartArea" },
	  title: {
		display: false,
		// text: "Modular Bar Chart",
	  },
	},
  };

const [perdaylabel, setPerDayLabel] = useState([]);
const [perdayprice, setPerDayPrice] = useState([]);

const [monthlabel, setMonthLabel] = useState([]);
const [monthprice, setMonthPrice] = useState([]);

var data = {
  labels : perdaylabel,
  datasets: [
    {
      label: "Total Sale",
      data: perdayprice,
      backgroundColor: 'rgb(75, 192, 192)',
      barThickness: 25, 
    }

  ]

};

var monthdata = {
  labels : monthlabel,
  datasets: [
    {
      label: "Total Sale",
      data: monthprice,
      backgroundColor: 'rgb(75, 192, 192)',
      barThickness: 25, 
    }

  ]

};


function chartPerday() {
  var valdata = {
    vendorId: localStorage.getItem('vendorId'),
   
  };

  const settings = {
    async: false,
    crossDomain: true,
    url: `${Baseurl.baseUrl}getAdminSaleChart`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    const orderDates = response.po_DETAIL.map(item => item.order_date);
    const totalPrices = response.po_DETAIL.map(item => item.total_price);

    setPerDayLabel(orderDates);
    setPerDayPrice(totalPrices);
  });
}




function chartMonth() {
  var valdata = {
    vendorId: localStorage.getItem('vendorId'),
   
  };

  const settings = {
    async: false,
    crossDomain: true,
    url: `${Baseurl.baseUrl}getAdminMonthChart`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(valdata),
  };

  $.ajax(settings).done(function (response) {
    
    const orderDates = response.po_DETAIL.map(item => item.order_month);
    const totalPrices = response.po_DETAIL.map(item => item.total_price);

    setMonthLabel(orderDates);
    setMonthPrice(totalPrices);
  });
}


function topSellingProd() {
	var valdata = {
	  vendorId: localStorage.getItem('vendorId'),
	 
	};
  
	const settings = {
	  async: false,
	  crossDomain: true,
	  url: `${Baseurl.baseUrl}topSalingProd`,
	  method: "POST",
	  headers: {
		"Content-Type": "application/json",
	  },
	  data: JSON.stringify(valdata),
	};
  
	$.ajax(settings).done(function (response) {
		setTopSelling(response.po_DETAIL);
	 
	});
  }


  return (
    <>

<section className=" bg-wt2" >
	<div class="container-fluid p-3">
 
		<div class=" vhdiv1" >
			<p class="vhp2">Welcome, Admin Name</p>
		</div>

		<p class="vhp4 m-0 m-1">This Month Summary:- {currentMonth}</p>

		<div class="card mt-2 "  style={{backgroundColor:"#f6f9fa"}}>
			<div class="row mt-2 m-2">
			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Total Revenue</div>
					<p class="vhp1">&#8377; {dashboard.monthlySale}</p>
				</div>
			</div>

			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Total Orders</div>
					<p class="vhp1">{dashboard.monthlyOrder}</p>
				</div>
			</div>

			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Total Vendors</div>
					<p class="vhp1">{dashboard.totalVendor}</p>
				</div>
			</div>

			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Total Users</div>
					<p class="vhp1"> {dashboard.totalVendor}</p>
				</div>
			</div>

			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Open Complains</div>
					<p class="vhp1">{dashboard.complain}</p>
				</div>
			</div>
			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Total SKUs</div>
					<p class="vhp1">52</p>
				</div>
			</div>
			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Completed Order(s)</div>
					<p class="vhp1">{dashboard.delivered}</p>
				</div>
			</div>
			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Pending Order(s)</div>
					<p class="vhp1">{dashboard.pending}</p>
				</div>
			</div>
			<div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Cart Abondon Rate</div>
					<p class="vhp1">5.6%</p>
				</div>
			</div>
			{/* <div class="col-md-2 col-6 p-0 ">
				<div class="card p-2 pb-0 m-1">
					<div class="text-uppercase vhdiv2">Total SKUs</div>
					<p class="vhp1">52</p>
				</div>
			</div> */}


		</div>
		</div>





		
	</div>
</section>


<section >
	<div class="container-fluid ">
		<div class="card bod1 "  style={{backgroundColor:"#f6f9fa"}}>

			<div class="row justify-content-center p-3">
				<div class="col-md-6 col-12">
					<div class="pt-2 pb-2  ">
						<div class="vhp1 pt-0">Sales Chart</div>
					</div>
					<div class="card p-3  mb-3 vhbs1">
					<Bar options={option} data={data} />
					</div>
				</div>

				<div class="col-md-6 col-12">
					<div class="pt-2 pb-2  ">
						<div class="vhp1 pt-0">Bar Chart</div>
					</div>
					<div class="card p-3  mb-3 vhbs1">
					<Bar options={option} data={monthdata} />
					</div>
				</div>
			</div>

		</div>

		
	</div>
</section>

{/* <section  class="">
	<div class="container-fluid mt-2 mb-2">
		<div class="card bod1 " >

			<div class="row justify-content-center p-3">
				<div class="col-md-6 col-12">
					<div class="pt-2 pb-2  ">
						<div class="vhp1 pt-0">Active Visitors</div>
					</div>
					<div class="card p-3  mb-3 vhbs1">
						<canvas id="myChart" class="mt-3"></canvas>
					</div>
				</div>

				<div class="col-md-6 col-12">
					<div class="pt-2 pb-2  ">
						<div class="vhp1 pt-0">Top Selling Products</div>
					</div>



					
					{topselling.length === 0 ? (
                    <span>No data</span>
                  ) : (
                    topselling.map((pyout) => (
						<div>

<div class="card  p-1 mt-1">
						<div class="row align-items-center p-1 m-2 ">
							<div class="col-md-2 col-3 col-lg-2">
								<img src={`${Baseurl.getImgUrl}prodImages/${pyout.IMAGES_NAME_1}`} class=" igm1"/>
							</div>
							<div class="col-md-8 col-9 col-lg-8 	">
								<h1 class="crtitmhd2">{pyout.PRODUCT_NAME}</h1>
								
							</div>
							<div class="col-md-2 col-lg-2">
								<div class="col-md-12 text-end">
		                		<span class="share-spn"> <p class="order-prc txtac1">₹{pyout.CURRENT_AMOUNT}</p></span>
		                	</div>
							</div>
						</div>
					</div>
						</div>
                    ))
                  )}


				</div>










				
			</div>

		</div>

		
	</div>
</section> */}
    
    </>
	
  )
}

export default Adhome

