import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import logo from "../media/logo6.png";
import "./Mobilehead2.css";
import { toast, ToastContainer } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {
  FaHeart,
  FaUserAlt,
  FaShoppingCart,
  FaSearch,
  FaBars,
  FaUser,
  FaHome,
  FaList,
  FaCartPlus, FaGift
} from "react-icons/fa";
import AppContext from "../AppContext";
import { HiLocationMarker, HiSearch } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";

function Mobilehead2() {
	const { variable1, updateVariable1Value } = useContext(AppContext);
	const {variable2,updateLogin} = useContext(AppContext);
  
	const [userid, setUserid] = useState("");
	useEffect(() => {
	  setUserid($("#userId").val());
	  loadCartCount();
	 
  
	}, []);
  
  
	function loadCartCount() {
	  var valdata = {
		userId: $("#userId").val(),
	  };
	  const settings = {
		async: false,
		crossDomain: true,
		url: `${Baseurl.baseUrl}getCartlist`,
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		data: JSON.stringify(valdata),
	  };
  
	  $.ajax(settings).done(function (response) {
		
			updateVariable1Value(response.length)
		
	  });
	}
  
  
	
	const handleKeyPress = (event) => {
	  const keyCode = event.keyCode || event.which;
	  const keyValue = String.fromCharCode(keyCode);
	  const regex = /^\d+$/; // Only allow digits
	  const value = event.target.value + keyValue;
  
	  if (!regex.test(keyValue) && keyCode !== 8) {
		event.preventDefault();
	  }
	  if (value.length === 1 && !/[6-9]/.test(keyValue)) {
		event.preventDefault();
	  }
	};
  
	const validateLogin = (event) => {
	  if ($("#_logMob").val().length < 10 || !$("#_logMob").val()) {
		toast.error("Invalid Mobile No!");
		return false;
	  }
  
	  var valdata = {
		  
		mobileNo: $("#_logMob").val().trim()
		};
	  
		const settings = {
		async: true,
		crossDomain: true,
		url: `${Baseurl.baseUrl}validateUser`,
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		data: JSON.stringify(valdata),
		};
	  
		$.ajax(settings).done(function (response) {
		if(parseInt(response.po_STATUS) == 1){
		  sendOtp('send');
		  $(".displayMobile").text($("#_logMob").val())
		  $("#otpmodalopen").click();
		}else{
		  toast.error('Invalid Mobile No.');
		}
		});
	
	};
  
  
	const sendOtp = (typed) => {
	  var valdata = {
		  
		mobileNo: $("#_logMob").val().trim(),
		typed : typed
		};
	  
		const settings = {
		async: true,
		crossDomain: true,
		url: `${Baseurl.baseUrl}sendOtp`,
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		data: JSON.stringify(valdata),
		};
	  
		$.ajax(settings).done(function (response) {
		if(parseInt(response.po_STATUS) == 1){
		  toast.success('OTP Send Successfully..');
  
		}else{
		  toast.error('Failed To Send OTP.');
		}
		});
	}
  
  
  
	
	const verifyOtp = (typed) => {
	  if(!$("#_otplogin").val().trim()){
		toast.error('Please Enter Valid OTP.');
		return false;
	  }
	  var valdata = {
		  
		mobileNo: $("#_logMob").val().trim(),
		otp : $("#_otplogin").val().trim()
		};
	  
		const settings = {
		async: true,
		crossDomain: true,
		url: `${Baseurl.baseUrl}verifyOtp`,
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		data: JSON.stringify(valdata),
		};
	  
		$.ajax(settings).done(function (response) {
		if(parseInt(response.po_STATUS) == 1){
		  //toast.success('Logged In Successfully.');
		  loginuser();
		}else{
		  toast.error('Invalid OTP.');
		}
		});
	}
  
	const loginuser = () => {
	  if(!$("#_otplogin").val().trim()){
		toast.error('Please Enter Valid OTP.');
		return false;
	  }
	  var valdata = {
		  
		mobileNo: $("#_logMob").val().trim(),
		otp : $("#_otplogin").val().trim()
		};
	  
		const settings = {
		async: true,
		crossDomain: true,
		url: `${Baseurl.baseUrl}loginchk`,
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		data: JSON.stringify(valdata),
		};
	  
		$.ajax(settings).done(function (response) {
		if(parseInt(response.Status) == 1){
		  toast.success('Logged In Successfully.');
		  
		}else{
		  toast.error('Invalid OTP.');
		}
		});
	}
  
  
	const [category, setCategory] = useState([]);
	const [subcategory, setSubCategory] = useState([]);
  
	useEffect(() => {
	  const settings = {
		async: false,
		crossDomain: true,
		url: `${Baseurl.baseUrl}getCategory`,
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
	  };
  
	  $.ajax(settings).done(function (response) {
		setCategory(response);
	  });
	}, []);
  
  

  return (
    <section>
      <div className="container ">
        <div className="row ">
		<div className="col-4 mobile-toggler d-lg-none">
  <Link to="" data-bs-toggle="modal" data-bs-target="#navbarmodel">
    <FaBars className="cnhgyt" />
  </Link>
</div>

          <div
            className="modal fade modpd p-0"
            id="navbarmodel"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog mgzero" style={{ margin: "0px" }}>
              <div
                className="modal-content"
                style={{
                  overflow: "hidden",
                  width: "70%",
                  margin: "0",
                  left: "0",
                  borderRadius: "0px",
				  height: "100vh",
                }}
              >
                <div className="modal-body modpd" style={{ padding: "0px" }}>
                  <div className="modal-line-hm">
                    <Link to="" className="anchmsd">
                      <FaUser className="cnhgyt" />{" "}
                      <span className="pdsdbr aasd">User Name</span>{" "}
                    </Link>
                  </div>

                  

                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="/" className="anchmsd">
                    <FaHome className="cnhgyt" />
                      <span className="pdsdbr">Home</span>{" "}
                    </Link>
                  </div>

                  <div className="modal-line" data-bs-dismiss="modal">
                    <Link to="/Order" className="anchmsd"
                    
                    
                    
                    >
                      <FaList className="cnhgyt" />
                      <span className="pdsdbr">My Orders</span>
                    </Link>
                  </div>
                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="/Cart" className="anchmsd">
                      <FaShoppingCart className="cnhgyt" />
                      <span className="pdsdbr">My Cart</span>
                    </Link>
                  </div>
                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="/Wishlist" className="anchmsd">
                    <FaHeart className="cnhgyt" />
                      <span className="pdsdbr">My Wishlist</span>
                    </Link>
                  </div>
                  <div className="modal-line"  data-bs-dismiss="modal">
                    {/* <Link to="/Myaccount" className="anchmsd">
                    <FaHome className="cnhgyt" />
                      <span className="pdsdbr">My Account</span>
                    </Link> */}


					{userid ? (
						<Link to="/Myaccount" className="anchmsd">
                    <FaHome className="cnhgyt" />
                      <span className="pdsdbr">My Account</span>
                    </Link>
) : (
<Link to="/Login" className="anchmsd">
                    <FaHome className="cnhgyt" />
                      <span className="pdsdbr">Login</span>
                    </Link>
)}


                  </div>
                  <hr />
                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="" className="anchmsd">
                      <i className="fa-solid fa-phone"></i>
                      <span className="pdsdbr">Customer Support</span>
                    </Link>
                  </div>
                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="/Privacy" className="anchmsd">
                      <i className="fa-solid fa-lock"></i>
                      <span className="pdsdbr">Privacy Policy</span>
                    </Link>
                  </div>
                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="/Terms" className="anchmsd">
                      <i className="fa-sharp fa-solid fa-list"></i>
                      <span className="pdsdbr">Terms &amp; Conditions</span>
                    </Link>
                  </div>
                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="" className="anchmsd">
                      <i className="fa-solid fa-envelope"></i>
                      <span className="pdsdbr">Feedback</span>
                    </Link>
                  </div>
                  <div className="modal-line"  data-bs-dismiss="modal">
                    <Link to="" className="anchmsd">
                      <i className="fa-solid fa-book-open"></i>
                      <span className="pdsdbr">About</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-4 navb-logo d-lg-none text-center">
            <Link to="/" className="logo1">
              <img src={logo} style={{ height: "40px" }} />
            </Link>
          </div>

          <div className="col-4 text-end mobile-toggler d-lg-none">
            <div className="dropdown "  data-bs-dismiss="modal">
              <Link
                to="/Cart"
                className="mx-2 suxped"
                role="button"
                id="dropdownMenuLink"
              >
                <FaCartPlus className="cnhgyt suxped" />{" "}
                <sup className="suxped bxda">({variable1})</sup>
              </Link>
              &nbsp;
              <Link
                to="/Myaccount	"
                className="mx-2"
                role="button"
                id="dropdownMenuLink"
              >
                <FaUserAlt className="cnhgyt" />
              </Link>
            </div>
          </div>
        </div>
      </div>


	  <nav class="mobile-nav">

<div class="mbalig">

<Link to="/" className="anchmsd">
	<div className="text-center"><FaHome className="cnhgyt" /></div>
		<span className="pdsdbr">Home</span>
</Link>

</div>

<div class="mbalig">

<Link to="/Order" className="anchmsd">
	<div className="text-center"><FaGift className="cnhgyt" /></div>
		<span className="pdsdbr">My Orders</span>
</Link>

</div>


<div class="mbalig">

<Link to="/Wishlist" className="anchmsd">
	<div className="text-center"><FaHeart className="cnhgyt" /></div>
		<span className="pdsdbr">Whistlist</span>
</Link>

</div>

<div class="mbalig">

<Link to="/Cart" className="anchmsd">
	<div className="text-center"><FaShoppingCart className="cnhgyt" /></div>
		<span className="pdsdbr">Cart</span>
</Link>

</div>

<div class="mbalig">
{userid ? (
<Link to="/Myaccount" className="anchmsd">
	<div className="text-center"><FaUserAlt className="cnhgyt" /></div>
		<span className="pdsdbr">Account</span>
</Link>
) : (
<Link to="/login" className="anchmsd">
	<div className="text-center"><FaUserAlt className="cnhgyt" /></div>
		<span className="pdsdbr">Login</span>
</Link>
)}
</div>

</nav>



    </section>









  );
}

export default Mobilehead2;
