import React, { useEffect, useState } from "react";
import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";

function ImagesUpload({reqType, onFileDataChange, defaultImage}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [defaultImagePreviews, setDefaultImagePreviews] = useState([]);
  const [requesttype, setRequestType] = useState(reqType);


  useEffect(() => {
    if (defaultImage && defaultImage.length > 0) {
      const defaultPreviews = defaultImage.map((imageUrl, index) => ({
        base64Data: imageUrl,
      }));

     // setDefaultImagePreviews(defaultPreviews);
      if(reqType == "listing"){
        defaultPreviews.forEach((preview, index) => {
          var mainstr =  '<img className="preview-image" src=' +preview.base64Data + ' alt=Preview'+index+'/>';
          if(preview.base64Data != "") {
           $(".prop"+(index+1)).append(mainstr);
          }
          
         });
         
      }
     
    }
  }, [defaultImage]);


  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 10) {
      alert("You can only upload up to 10 files.");
      event.target.value = null;
      clearPreview(); // Clear the preview
      return;
    }
    const newSelectedFiles = Array.from(files);
    let allFilesValid = true;
    const validatedFiles = await Promise.all(
      newSelectedFiles.map(async (file) => {
        const maxSize = file.type.startsWith("image")
          ? 512 * 1024
          : 6 * 1024 * 1024; // 512 KB for images, 6 MB for videos

        if (file.size > maxSize) {
          allFilesValid = false;
          toast.error(
            `File ${file.name} size exceeds ${maxSize / 1024} ${
              file.type.startsWith("image") ? "KB" : "MB"
            } limit.`
          );
          event.target.value = null; // Clear the input field
          setSelectedFiles([]); // Clear selected files

          return null;
        }

        file.previewUrl = URL.createObjectURL(file);
        return file;
      })
    );

    if (!allFilesValid) {
      clearPreview(); // Clear the preview when any file exceeds the limit
      return;
    }

    const validFiles = validatedFiles.filter((file) => file !== null);
    setSelectedFiles(validFiles);

    ////////////////////
    const retArr = [];
    let allFilenames = "";
    let allFullFilenames = "";

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const base64Data = await fileToBase64(file);
      const base64Parts = base64Data.split(",");
      const base64Value = base64Parts[1];
      const fileExtension = file.name.split(".").pop();
      const customFilename = `Image${i + 1}.${fileExtension}`;
      const filenameWithoutExtension = customFilename.replace(/\.[^/.]+$/, "");
      var filenameWithExtension =
        filenameWithoutExtension +
        "_" +
        $("#_skuId").val().toUpperCase() +
        "." +
        fileExtension;
      retArr.push({
        filename: filenameWithoutExtension,
        extension: fileExtension,
        base64Data: base64Value,
        fullFileName: filenameWithExtension,
      });
      allFilenames += `${filenameWithoutExtension}, `;
      allFullFilenames += `${filenameWithExtension}, `;
    }
    allFilenames = allFilenames.slice(0, -2);
    allFullFilenames = allFullFilenames.slice(0, -2);
    console.log(retArr);
    onFileDataChange(retArr, allFilenames, allFullFilenames);
  };

  const clearPreview = () => {
    setSelectedFiles([]); // Clear selected files
    const inputElement = document.getElementById("inputGroupFile01");
    if (inputElement) {
      inputElement.value = null; // Clear the input field
    }
  };

  const nextimages = () => {

    var atLeastOneFilled = false;
        $(".propFiles").each(function() {
          if ($(this).val() !== '') {
            atLeastOneFilled = true;
            return false; // exit the loop if at least one input is filled
          }
        });


    if (requesttype != 'listing' && !atLeastOneFilled) {
      toast.error("Image(s) is Required.");
      return false;
    }
    handleConversionButtonClick();
    $("#tab-3").click();
  };

  const previmages = () => {
    $("#tab-1").click();
  };


const [files, setFiles] = useState([]);
  const [base64Files, setBase64Files] = useState([]);
  const [retArr, setRetArr] = useState([]);

  const MAX_IMAGE_SIZE = 512 * 1024; // 512 KB in bytes
  const MAX_VIDEO_SIZE = 6 * 1024 * 1024; // 6 MB in bytes

  const handleFileChange = (fileIndex, event) => {
    const selectedFile = event.target.files[0];
    const maxSize = selectedFile.type.startsWith('image/') ? MAX_IMAGE_SIZE : MAX_VIDEO_SIZE;
    if (selectedFile.size > maxSize) {
      alert(`File size exceeds the limit. Maximum allowed size: ${maxSize / (1024 * 1024)} MB`);
      event.target.value = null;
      return;
    }
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[fileIndex - 1] = selectedFile;
      return updatedFiles;
    });
  };

  const handleConversionButtonClick = () => {
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((base64Results) => {
        setBase64Files(base64Results);
        let allFilenames = "";
        let allFullFilenames = "";
        const newRetArr = files.map((file, index) => {
          var filenameWithExtension = file.name;
          const filenameWithoutExtension = filenameWithExtension.split('.')[0];
          const fileExtension = filenameWithExtension.split('.').pop();
          const base64Parts = base64Results[index].split(",");
          const base64Value = base64Parts[1];
          const customFilename = `Image${index + 1}`;
          filenameWithExtension = customFilename + "_" + $("#_skuId").val().toUpperCase() +
          "." + fileExtension;

          return {
            filename: customFilename,//filenameWithoutExtension,
            extension: fileExtension,
            base64Data: base64Value,
            fullFileName: filenameWithExtension,
          };
          allFilenames += `${customFilename}, `;
          allFullFilenames += `${filenameWithExtension}, `;
        });
        allFilenames = allFilenames.slice(0, -2);
    allFullFilenames = allFullFilenames.slice(0, -2);
        console.log(newRetArr)
        onFileDataChange(newRetArr, allFilenames, allFullFilenames);
      })
      .catch((error) => {
        console.error('Error converting to base64:', error);
      });
  };

  return (
    <>
      <div className="tab-content" style={{ width: "100%" }}>
        {/* <div className="row rwhvr1 aic1 pt-2 pb-1 mb-2">
          <div className="col-md-4 col-12 ">
            <div class="input-group mb-3 align-items-center">
              <label>1. &nbsp;</label>
              <input
                type="file"
                class="form-control input-file"
                id="inputGroupFile01"
                multiple
                onChange={handleFileUpload}
              />
              <label class="input-group-text" for="inputGroupFile02">
                Upload
              </label>
            </div>
          </div>

          <div className="col-md-8 col-12">
            <div className="preview-container">
              {selectedFiles.map((file, index) => (
                <img
                  key={index}
                  className="preview-image"
                  src={file.previewUrl}
                  alt={`Preview ${index}`}
                />
              ))}

              {selectedFiles.length > 0 ? (
                selectedFiles.map((file, index) => (
                  <img
                    key={index}
                    className="preview-image"
                    src={file.previewUrl}
                    alt={`Preview ${index}`}
                  />
                ))
              ) : (
                defaultImages.map((imageUrl, index) => (
                  <img
                    key={index}
                    className="default-image"
                    src={imageUrl}
                    alt={`Default Image ${index}`}
                  />
                ))
              )}
            </div>
          </div>
        </div> */}


{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((fileIndex) => (
        <div key={fileIndex} className="row rwhvr1 aic1 pt-2 pb-1 mb-2">
          <div className="col-md-4 col-12">
            <div className="input-group mb-3 align-items-center">
              <label>{fileIndex}. &nbsp;</label>
              <input
                type="file"
                className="form-control input-file propFiles"
                id={`selectFile${fileIndex}`}
                onChange={(event) => handleFileChange(fileIndex, event)}
              />
              <label className="input-group-text" htmlFor={`selectFile${fileIndex}`}>
                Upload
              </label>
            </div>
          </div>
          <div className="col-md-8 col-12">
            <div className={`preview-container prop${fileIndex}`}>
              {base64Files.length >= fileIndex && (
                <img
                  className={`preview-image  `}
                  src={base64Files[fileIndex - 1]}
                  alt={`Preview ${fileIndex}`}
                />
              )}
            </div>
          </div>
        </div>
      ))}
{/* 
{defaultImagePreviews.map((fileInfo, index) => (
          <div key={index} className="row rwhvr1 aic1 pt-2 pb-1 mb-2">
            <div className="col-md-4 col-12">
              <div className="input-group mb-3 align-items-center">
                <label>{index + 1}. &nbsp;</label>
                <input
                  type="file"
                  className="form-control input-file propFiles"
                  id={`selectFile${index + 1}`}
                  onChange={(event) => handleFileChange(index + 1, event)}
                />
                <label className="input-group-text" htmlFor={`selectFile${index + 1}`}>
                  Upload
                </label>
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="preview-container">
                {fileInfo.base64Data && (
                  <img
                    className="preview-image"
                    src={fileInfo.base64Data}
                    alt={`Preview ${index + 1}`}
                  />
                )}
              </div>
            </div>
          </div>
        ))} */}

        <div className="row d-flex justify-content-end">
          <div className="p-3 col-md-3 text-end">
            <button className="btnvd add-cart-btn" onClick={previmages}>
              Previous
            </button>
          </div>

          <div className="p-3 col-md-3 text-end">
            <button className="btnvd add-cart-btn" onClick={nextimages}>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImagesUpload;
