import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import "./Common.css";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import { Link, useLocation } from "react-router-dom";

function Vdlistingreport() {

	const [listings, setListings] = useState([]);

  
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchData();
    
  }, []);

  const downloadExcelFile = (Jsondata) => {
	const worksheet = XLSX.utils.json_to_sheet(Jsondata);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
	const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
	const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
	FileSaver.saveAs(excelData, 'Report.xlsx');
  toast.success('Excel file downloaded successfully!');
  };


  const downloadExcel = () => {
	var modifiedData = listings.map(function(obj) {
		 return {
				 "PRODUCT NAME" : obj.PRODUCT_NAME,
			   "LISTING DATE" : obj.TIME_STAMP,
			   "LISTING PRICE" : obj.CURRENT_AMOUNT,
			   "MRP":obj.MRP,
			   "STOCKS" :	obj.STOCK_QUANTITY,
			   "CATEGORY" :	obj.SUB_CATEGORY_NAME,
			   "FULLFILMENT" : 'SELLER ONLY',
			   "STATUS" :	(obj.PI_STATUS == "A" ?'Active' : (obj.PI_STATUS == "D" ? "Removed" : "Inactive")),

			
		 };
	   });
    downloadExcelFile(modifiedData);
  };

  
  const fetchData = (pytype) => {
    var valdata = {
      vendorId: $("#vendorId").val(),
      piType : pytype
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getListingDetails`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setListings(response.po_DETAIL);
	  
    });
  };

  const navigatetoblank = (catId, prodId, subcatId) => {
    const url = `Product?catId=${catId}&productId=${prodId}&q=search&requestType=product&subCategory=${subcatId}`;
    window.location.href = url;
    navigate(url);
  };


  return (
    <>
    	<ToastContainer />
      <section className="" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <div className=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p className="vhp3">Listing Report </p>
            <span className="hdspn1">Learn More</span>
          </div>

          <div>
            <div className=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
              <div className="d-flex">
                <div>
                  {" "}
                  <p className="mgrt2">Search Product:</p>
                </div>
                <form className="d-flex" style={{ height: "30px" }}>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="button1 mxhtsh m-0" type="submit">
                    Search
                  </button>
                </form>

                <div>
                  {" "}
                  <p className="mgrt2"> &nbsp;{listings.length} product(s)</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-6 pt-2 pb-2  ">
                <div className="vhp1 pt-0">Listing Report </div>
              </div>
              <div className="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
                
                <div className="vhp1 pt-0">
                  <button className="button1" disabled={listings.length === 0} onClick={downloadExcel}>Download </button>
                </div>
              </div>
            </div>

            <table className="table table-bordered ft13">
              <thead className="tbhd1">
                <tr>
                  <td>Product Details</td>
                  <td>Listing Date</td>
                  <td>Listing Price</td>
                  <td>MRP</td>
                  <td>Total Stock</td>
                  <td>Category</td>
                  <td>Fulfilment</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>

			 {listings.map(listing =>  (
				 
          <tr  key={`listing${listing.ID}`}>
          
          <td>
            <div className="d-flex ">
              <img src={`${Baseurl.getImgUrl}prodImages/${listing.IMAGES_NAME_1}`} style={{ height: "40px" }} />
              <span onClick={()=>navigatetoblank(listing.CATEGORY_ID, listing.ID, listing.SUB_CATEGORY_ID)}><p>{listing.PRODUCT_NAME}</p></span>
            </div>
          </td>
          <td>{listing.TIME_STAMP}</td>
          <td>₹{listing.CURRENT_AMOUNT}</td>
          <td>₹{listing.MRP}</td>
          <td>{listing.STOCK_QUANTITY}</td>
          <td>{listing.SUB_CATEGORY_NAME}</td>
          <td>Seller Only</td>
          <td>{listing.PI_STATUS == "A" ?'Active' : (listing.PI_STATUS == "D" ? "Removed" : "Inactive")}</td>
          
        </tr>
			))}

                
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdlistingreport;
