import React from 'react'
import "./Common.css"
import img1 from '../media/prod11.jpg';

import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa'


function Vdfooter() {
  return (
    <>


<section className="ftcol1 pb-2 ">
    <div className="container ">
        
        <hr/>
        <div className="row">
            <div className="col-md-6 col-lg-6 col-xl-6 d-flex flex-row crtaln">
                <p className="crfttxt"><a href="" className="ftrr1">Help</a></p>
                <p className="crfttxt"><a href="" className="ftrr2" > Programme Policies</a></p>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 d-flex flex-row crtaln text-end">
                <p className="crfttxt">Copyright &#169; 2023 TezBazar. All Rights Reserved.</p>
            </div>
            
            
        </div>
    </div>


</section>


    
    </>
    
  )
}

export default Vdfooter