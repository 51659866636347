import React from "react";
import ReactDOM  from "react-dom/client";
import App from "./App"


let x = ReactDOM.createRoot(document.getElementById("root"));


x.render(
  <>
  <App/>
  </>
)