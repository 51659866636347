import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";
import logo from "../media/logo6.png";
import { FaFacebook } from "react-icons/fa";
import queryString from "query-string";

function Invoice() {
    const [orderDtl, setOrderDtl] = useState([]);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const orderId = queryParams.orderId;
  const requestType = queryParams.requestType;
  const q = queryParams.q;
  const productId = queryParams.productId;

  const getinvoice = () => {
    var valdata = {
        orderId: orderId,
        prodId: productId,
      
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getInvoice`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (
        parseInt(response.po_STATUS) === 1
      ) {
        setOrderDtl(response.po_DETAIL[0]);
        //toast.success("Product Added To WishList.");
      } else {
        if (![null, undefined, "null", "undefined"].includes(response.po_MSG)) {
          //toast.error(response.po_MSG);
        } else {
          //toast.error("Failed To Remove From Cart.");
        }
      }
    });
  };

  useEffect(() => {
    getinvoice();
  }, []);

  return (
    <>
      <section className="sectey121">
        <div className="container ">
          <div className="row justify-content-center text-center pt-4">
            {/* <div className="collapsible  crtitmhd text-center p-1 mb-3">
              <h2>ORDER INVOICE</h2>
            </div> */}

            <div className="col-12 col-md-9 text-start">
              <div class="container card p-3 clssinv">
                <div class="row pad-top-botm ">
                  <div class="col-lg-2 col-md-2 col-12">
                    <h5 className=" brand-name">
                      <Link to="/" className="logo1">
                        <img src={logo} style={{ height: "40px" }} />
                      </Link>
                    </h5>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div className="hambss">
                      Contact us : 088099974673 || cs@tejbazar.com
                    </div>
                    <div className="">Tejbazar Retail Private Lmited,</div>
                    <div className="jswnds">
                      example road, city, state, Pincode{" "}
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-12">
                    <div className="uxiaye p-1">
                      <b>Tax Invoice:</b> # FOPIU673524663
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row pad-top-botm ">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div className="crtitmhd">Order ID: {orderDtl.ORDER_ID}</div>
                    <div>
                      <span className="crtitmhd">Order Date: &nbsp;</span>
                      <span className="jswnds">{orderDtl.ORDER_DATE}</span>
                    </div>

                    <div>
                      <span className="crtitmhd">Invoice Date:&nbsp;</span>
                      <span className="jswnds">{orderDtl.ORDER_DATE}</span>
                    </div>

                    <div>
                      <span className="crtitmhd">Gst No.:&nbsp;</span>
                      <span className="jswnds">381273123293127</span>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-12">
                    <div className="crtitmhd">Billing Address</div>
                    <div className="jswnds">{orderDtl.NAME}</div>
                    <div className="jswnds">
                      {orderDtl.PI_ADDRESS +  (["", null, "null", undefined, "undefined"].includes(orderDtl.LOCALITY) ? "" : " ,"+orderDtl.LOCALITY)+  (["", null, "null", undefined, "undefined"].includes(orderDtl.PI_LANDMARK) ? "" : " ,"+orderDtl.PI_LANDMARK) + (orderDtl.STATE_NAME ? " ,"+orderDtl.STATE_NAME : "") }
                    </div>
                    <div className="jswnds">Phone: {orderDtl.PHONENO}</div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-12">
                    <div className="crtitmhd">Shipping Address</div>
                    <div className="jswnds">
                      <b> {orderDtl.NAME}</b>
                    </div>
                    <div className="jswnds">
                    {orderDtl.PI_ADDRESS +  (["", null, "null", undefined, "undefined"].includes(orderDtl.LOCALITY) ? "" : " ,"+orderDtl.LOCALITY)+  (["", null, "null", undefined, "undefined"].includes(orderDtl.PI_LANDMARK) ? "" : " ,"+orderDtl.PI_LANDMARK) + (orderDtl.STATE_NAME ? " ,"+orderDtl.STATE_NAME : "") }
                    </div>
                    <div className="jswnds">Phone: {orderDtl.PHONENO}</div>
                  </div>
                </div>

                <div class="row text-center contact-info">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <hr />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="table-responsive">
                      <table className="table table-hover ft13">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Price (&#x20b9;)</th>
                            <th>Tax (&#x20b9;)</th>
                            <th>Total (&#x20b9;)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{orderDtl.PRODUCT_NAME}</td>
                            <td>{orderDtl.QUANTITY}</td>
                            <td>{orderDtl.PRICE}</td>
                            <td>0 </td>
                            <td>{orderDtl.PRICE} </td>
                          </tr>
                          
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <div class="ttl-amts text-end">
                      <h5> Grand Total: &#x20b9; {orderDtl.PRICE} </h5>
                    </div>
                    <hr />

                    <div class="ttl-amts text-center">
                      <div className="jswnds">
                        <i>
                          This is Computer generated invoice. No signature
                          required
                        </i>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Invoice;
