import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";

import { FaFacebook } from "react-icons/fa";

function Adtransactions() {
  const [payout, setPayout] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    loadPayouts("");
  }, []);

  function loadPayouts(filterBy) {
    var valdata = {
      vendorId: localStorage.getItem("vendorId"),
      filterBy: filterBy,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAdminTransaction`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      setPayout(response.po_DETAIL);
    });
  }

  const handleDateChange = (event) => {
    loadPayouts(event.target.value);
  };

  return (
    <>
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">All Transaction(s) </p>
            <span class="hdspn1">Learn More</span>
          </div>

          <div class="">
            <div class="row p-3 bgcldiv1 mb-2 bdrtb1">
              <div class=" mb-1 p-2 pb-0 d-none d-sm-none d-md-block d-lg-block ft13">
                <div class="d-flex justify-content-end">
                  <div>
                    {" "}
                    <p className="mgrt2">Find a transaction</p>
                  </div>
                  <form class="d-flex" style={{ height: "30px" }}>
                    <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Enter Order Number"
                      aria-label="Search"
                    />
                    <button class="button1 mxhtsh m-0 " type="submit">
                      Search
                    </button>
                  </form>
                  <div>
                    {" "}
                    <p className="mgrt2">&nbsp; 3 transaction(s)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="p-3 ft13">
        <div class=" mb-1 p-2 pb-0">
          <div class="d-flex">
            <div>
              {" "}
              <p className="mgrt2">Filters:</p>
            </div>
            <div class="p-3 pt-0 pb-0">
              <label className="mgrt2">Transaction Type:</label>
              <select className="selectoption">
                <option>All Transaction Type</option>
                <option>Order Payment</option>
                <option>Refund</option>
                <option>Cancelled</option>
              </select>
            </div>

            <div class="p-3 pt-0 pb-0">
              <label className="mgrt2">Date:</label>
              <input
                type="date"
                className="selectoption"
                name=""
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div>
      </section>

      <section class="bgcldiv1 p-3">
        <div class="card p-3">
          <div class="row">
            <div class="col-md-6 col-6 pt-2 pb-2  ">
              <div class="vhp1 pt-0">Transactions</div>
            </div>
            <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">
              <div class="vhp1 pt-0">
                <button class="button1">Download </button>
              </div>
            </div>
          </div>

          <table class="table table-bordered table-striped ft13">
            <thead className="tbhd1">
              <tr>
                <td>Date</td>
                <td>Transaction type</td>
				        <td>Vendor Name</td>
                <td>Order ID</td>
                <td>Product Details</td>
                <td>Total product charges</td>
                <td>Tej Bazar fees</td>
                <td>Other</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
			{payout.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={8}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    payout.map((pyout) => (
						<tr key={`payout${pyout.ID}`}>
						<td>{pyout.TIME_STAMP}</td>
						<td>{pyout.PAYMENTMODE}</td>
						<td>{pyout.NAME}</td>
						<td>{pyout.ORDER_ID}</td>
						<td>{pyout.PRODUCT_NAME}</td>
						<td>₹ {parseFloat(pyout.PAYOUT_AMOUNT) + parseFloat(pyout.PLATFORM_CHARGES) + parseFloat(pyout.GST_AMOUNT)}</td>
						<td >
                        ₹ {pyout.PLATFORM_CHARGES}
						</td>
						<td>
						₹ {pyout.GST_AMOUNT}
						</td>
						<td>{pyout.PAYOUT_AMOUNT ? parseFloat(pyout.PAYOUT_AMOUNT) : ""}</td>
					  </tr>
                    ))
                  )}

              
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

export default Adtransactions;
