import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import logo from "../media/logo6.png";

import { Link, useLocation } from "react-router-dom";

import { FaFacebook, FaCaretDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Adheader() {
  const navigate = useNavigate();

  const [adminId, setAdminId] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminMobile, setAdminMobile] = useState("");
  const [adminMail, setAdminMail] = useState("");

  const [menuId, setMenuId] = useState("");


  useEffect(() => {
    const userId = localStorage.getItem("addminId");
    getmenuData(userId);
    if (!userId) {
      navigate("/Adlogin");
      return;
    }

    setAdminId(localStorage.getItem("addminId"));
    setAdminMail(localStorage.getItem("MOBILENO"));
  }, []);
  const logout = () => {
    localStorage.removeItem("addminMOBILENO");
    localStorage.removeItem("addminId");
    localStorage.removeItem("Addauthenticated");
    navigate("/Adlogin");
  };



  const getmenuData = (userId) => {
    var valdata = {
      adminId: userId
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getAdminMenuPer`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setMenuId(response.other_DETAILS);
      } else {
        
      }
    });
  };


  return (
    <>
      <input type="hidden" id="adminId" value={adminId} />

      <nav className="navbar navbar-expand-lg navbar-light navvbgcl1">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/Adhome">
            <img src={logo} style={{ height: "40px" }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li role="menuitem " style={{ listStyle: "none" }}>
                <Link
                  className="nav-link navhv1"
                  style={{ fontWeight: "600", color: "#000" }}
                  aria-current="page"
                  to="/Adhome"
                >
                  Home
                </Link>
              </li>
              {menuId !== null && menuId.includes("1") ? ( <li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  Abondon Cart <FaCaretDown size={15} />
                </Link>
                <div className="mega-menu " aria-hidden="true" role="menu">
                  <div className="nav-column row m-0">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adcart">
                          Abondon Cart(s)
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li> ) : (
                    ""
                  )}


{menuId !== null && menuId.includes("2") ? (<li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  All SKU <FaCaretDown size={15} />
                </Link>
                <div className="mega-menu " aria-hidden="true" role="menu">
                  <div className="nav-column row m-0">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adsku">
                          All SKU(s)
                        </Link>
                      </li>

                      

                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Searchasin">
                          Search ASIN(s)
                        </Link>
                      </li>


                    </ul>
                  </div>
                </div>
              </li>) : (
                    ""
                  )}

{menuId !== null && menuId.includes("3") ? (<li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  Orders <FaCaretDown size={15} />
                </Link>
                <div className="mega-menu " aria-hidden="true" role="menu">
                  <div className="nav-column row m-0">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adorder">
                          Orders
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>) : (
                    ""
                  )}

{menuId !== null && menuId.includes("4") ? (<li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  Payments <FaCaretDown size={15} />
                </Link>
                <div className="mega-menu " aria-hidden="true" role="menu">
                  <div className="nav-column row m-0">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adpayout">
                          All Payout
                        </Link>
                      </li>
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adtransactions">
                          All Transactions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>) : (
                    ""
                  )}

{menuId !== null && menuId.includes("5") ? (<li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  Reports <FaCaretDown size={15} />
                </Link>
                <div
                  className="mega-menu "
                  aria-hidden="true"
                  role="menu"
                  style={{ zIndex: "1" }}
                >
                  <div className="nav-column row m-0">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adlistingreport">
                          Listing Report
                        </Link>
                      </li>
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adinventoryreport">
                          Inventory Report
                        </Link>
                      </li>
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adorderreport">
                          Order Report
                        </Link>
                      </li>
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adpayoutreport">
                          Payout Report
                        </Link>
                      </li>
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Aduser">
                          User Report
                        </Link>
                      </li>

                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item   py-1" to="/Advendor">
                          Vendor Report
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>) : (
                    ""
                  )}

{menuId !== null && menuId.includes("6") ? (<li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  Ticktes <FaCaretDown size={15} />
                </Link>
                <div className="mega-menu " aria-hidden="true" role="menu">
                  <div className="nav-column row m-0">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Adticket">
                          Vendor Ticktes
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>) : (
                    ""
                  )}


{parseInt(adminId) == 1 ? (<li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  Administration <FaCaretDown size={15} />
                </Link>
                <div className="mega-menu zindx1" aria-hidden="true" role="menu">
                  <div className="nav-column row m-0 ">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Addadmin">
                          Add Admin
                        </Link>
                      </li>
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Viewadmin">
                          View Admin
                        </Link>
                      </li>
                      
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Homesetup">
                          Index Setup
                        </Link>
                      </li>
                      
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Herosetup">
                          Add Hero
                        </Link>
                      </li>

                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Fullbanner">
                          Add Full-Banner
                        </Link>
                      </li>

                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item py-1" to="/Halfbanner">
                          Add Half-Banner
                        </Link>
                      </li>
                      
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item py-1" to="/Sellerregister">
                          Create Vendor
                        </Link>
                      </li>

                      <li role="menuitem " style={{ listStyle: "none" }}>
                        <Link className="dropdown-item py-1" to="/Coupons">
                          Coupon Management
                        </Link>
                      </li>

                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item  py-1" to="/Usernotification">
                          User Notification
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>) : (
                    ""
                  )}
              

              

              

              

              

              

              
            </ul>

            <form className="d-flex d-none d-sm-none d-md-block d-lg-block">
              <li
                role="menuitem "
                style={{ listStyle: "none", padding: "10px" }}
              >
                <Link
                  href="#"
                  className="dmhvr text-dark txtdeco22"
                  style={{ backgroundColor: "#fff600", fontWeight: "600" }}
                >
                  My Account <FaCaretDown size={15} />
                </Link>
                <div className="mega-menu rtac1" aria-hidden="true" role="menu">
                  <div className="nav-column row m-0">
                    <ul className="p-0">
                      <li role="menuitem" style={{ listStyle: "none" }}>
                        <Link className="dropdown-item" to="/Adaccount">
                          My Account
                        </Link>
                      </li>
                      <li
                        role="menuitem"
                        style={{ listStyle: "none" }}
                        onClick={logout}
                      >
                        <Link className="dropdown-item">Logout</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Adheader;
