import React, { useEffect, useState } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Cart.css";
import imgprod1 from "../media/img1.jpg";
import img1 from "../media/img4.png";
import img2 from "../media/img6.jpg";
import Featured from "./Featured.jsx";
import { Link, useLocation } from "react-router-dom";
import Counter from "./Counter.js"
import { toast } from "react-toastify";
import queryString from "query-string";

function Cart() {
  const [inputvalue, setInputvalue] = useState(1);
  const [cartamount, setCartamount] = useState({
    currentAmt: 0,
    currentDisc: 0,
    oldAmt: 0,
  });
  const [cartval, setCartval] = useState({
    resp: [],
    resplength: 0,
  });
  useEffect(() => {
    loadData();
  }, []);


  const handlePlaceOrder = () => {
    const params = {
      q: 'search',
      requestType: 'placeOrder',
      userId: localStorage.getItem("userId"),
      productDtls : "",
      prodqty : ""
    };

    const queryStringParams = queryString.stringify(params);
    const url = `Checkout?${queryStringParams}`;
    window.location.href = url;
  };



  function loadData() {
    var valdata = {
      userId: localStorage.getItem('userId'),
    };
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}getCartlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      setCartval({
        resp: response,
        resplength: response.length,
      });

      let cAmount = 0;
      let oldAmount = 0;
      let discAmt = 0;
      for (let i = 0; i < response.length; i++) {
        cAmount = parseFloat(cAmount) + parseFloat(response[i].PRICE);
        oldAmount = parseFloat(oldAmount) + parseFloat(response[i].MRP);
        discAmt = discAmt + parseFloat(response[i].discountAmt);
      }
      // setCartamount({
      //   currentAmt: cAmount - discAmt,
      //   currentDisc: discAmt,
      //   oldAmt: oldAmount,
      // });

       setCartamount({
        currentAmt: cAmount,
        currentDisc: discAmt,
        oldAmt: oldAmount,
      });
    });
  }

  const removeCart = (prodId, Id) => {
    var valdata = {
      userId: localStorage.getItem('userId'),
      prodId: prodId,
      iD: Id,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}dtlCartlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      console.log();
      if (
        parseInt(response.po_STATUS) === 1 &&
        parseInt(response.po_DETAIL[0].INDEX) === 1
      ) {
        // toast.success("Product Successfully Removed From Cart.");
        loadData();
        window.location.reload();
      } else {
        if (![null, undefined, "null", "undefined"].includes(response.po_MSG)) {
          toast.error(response.po_MSG);
        } else {
          toast.error("Failed To Remove From Cart.");
        }
      }
    });
  };

  const moveWishList = (prodId, Id) => {
    var valdata = {
      userId: localStorage.getItem('userId'),
      prodId: prodId,
      iD: Id,
      typeW: "CART",
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}addWishlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (
        parseInt(response.po_STATUS) === 1 &&
        parseInt(response.po_DETAIL[0].INDEX) === 1
      ) {
        //alert("Product Successfully Removed From WishList.");
        // loadData();
        removeCart(prodId, Id);
      } else {
        if (![null, undefined, "null", "undefined"].includes(response.po_MSG)) {
          toast.error(response.po_MSG);
        } else {
          toast.error("Failed To Remove From Cart.");
        }
      }
    });
  };

  const sendDataToParent = (qty,prodId, rowId) => {
    console.log(qty+" =="+prodId);
    updateQtyProd(qty,prodId, rowId);
  };


  function updateQtyProd(qty,prodId, rowId) {
    var valdata = {
      userId: localStorage.getItem('userId'),
      prodId : prodId,
      qytno : qty,
      rowId : rowId
    };
    const settings = {
      async: false,
      crossDomain: true,
      url: `${Baseurl.baseUrl}updateQtyCart`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {

      loadData();
    });
  }
  
  return (
    <>
      <section className="bgcrt1">
        <div className="container-fluid ">
          <div className="row p-4 d-flex justify-content-center">
            <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 bgcrt2 pt-3 m-3">
              <div>
                <div>
                  <h1 className="crthd m-0">
                    Shopping Cart | ({cartval.resplength})
                  </h1>
                </div>
              </div>
              <hr className="crthr" />
              {cartval.resp.map((cartv) => (
                <div key={`cart${cartv.ID}`}>
                  <div className="row">
                    <div className="col-md-3 col-lg-3 col-xl-3 text-center">
                      <img src={`${Baseurl.getImgUrl}prodImages/${cartv.IMAGES_NAME_1}`} className="igvr" />
                    </div>

                    <div className="col-md-9">
                      <h1 className="crtitmhd">{cartv.PRODUCT_NAME}</h1>
                      <div>
                        <h3 className="p-0 m-0">
                          &#8377; {cartv.CURRENT_AMOUNT}
                        </h3>
                        <p className="p-0 m-0">
                          <s>&#8377; {cartv.MRP}</s>{" "}
                          <span className="spncrt2">
                            {Math.round(
                              (parseFloat(cartv.CURRENT_AMOUNT) * 100) /
                                parseFloat(cartv.MRP)
                            )}{" "}
                            &#37; Off
                          </span>
                        </p>
                      </div>
                      <div className="p-0 m-0">
                        <span
                          className={`crtsp1 bgcl-${
                            cartv.STOCK_QUANTITY > 0 ? "1" : "2"
                          }`}
                        >
                          {cartv.STOCK_QUANTITY > 0
                            ? "In Stock"
                            : "Out Of Stock"}
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={img1} className="igvr flfl" />
                        </span>
                      </div>
                      <div>
                        {/* <span className="a-button-inner mrgncrt-1">
                          <input
                            type="number"
                            className="inptnb a-button-inner"
                            placeholder="Qty:"
                            name=""
                            value={inputvalue}
                          />
                          <i className="a-icon a-icon-dropdown"></i>
                        </span> */}
                        {cartv.STOCK_QUANTITY > 0
                            ? <Counter id={1} min={1} max={cartv.STOCK_QUANTITY} qty={cartv.QUANTITY} prodId={1} rowId={cartv.ID} sendDataToParent={sendDataToParent}/>
                            : null}
                        
                        <span
                          className="aclass acljs1"
                          onClick={() => removeCart(cartv.PRODUCT_ID, cartv.ID)}
                        >
                          Remove
                        </span>
                        <span
                          className="aclass acljs1"
                          onClick={() =>
                            moveWishList(cartv.PRODUCT_ID, cartv.ID)
                          }
                        >
                          Move To Wishlist
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>

            <div className= {`col-md-3 col-lg-3 col-xl-3 col-sm-12 mt-3 ${cartval.resplength === 0 ? "d-none" : "d-block"}`}>
              <div className="hyeus1">
                <div className="p-2 d-flex justify-content-center">
                  <span>
                    <img src={img2} className="imgcrt1" />
                    100&#37; Safe Payments
                  </span>
                </div>
                <hr />
                <div className="">
                  <ul className="smrycrt1">
                    <li className="loio">
                      <h5 className="crtitmhd">Price Details</h5>
                    </li>
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">Total MRP:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt2">
                            &#8377; {cartamount.currentAmt}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">Saving On MRP:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt3">
                            -&#8377; {(parseFloat(cartamount.oldAmt)- parseFloat(cartamount.currentAmt)).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </li>
                    
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">Sub Total:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt2">
                            &#8377; {cartamount.currentAmt}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">Shipping Charges:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt2">&#8377; Free</span>
                        </div>
                      </div>
                    </li>
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">Discount:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt3">
                            -&#8377; {cartamount.currentDisc}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr />
                  <ul className="smrycrt1">
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="crtitmhd">Total MRP:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="crtitmhd">
                            &#8377; {cartamount.currentAmt}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="loio">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6">
                          <span className="spnsbt2">You Save:</span>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                          <span className="spnsbt3">
                            &#8377; {cartamount.oldAmt - cartamount.currentAmt - cartamount.currentDisc}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="p-3">
                    <button
                      className="btn w-100"
                      style={{ backgroundColor: "#fff600" }}
                      onClick={handlePlaceOrder}
                      disabled = {cartval.resplength === 0}
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <Featured pindex='featured' subCatId=''/>
    </>
  );
}

export default Cart;
