



import React from 'react'
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa'


function Cancelrequest() {
  return (
    <>

<section>
    <div className="container-fluid">
    <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Order Cancel/Return Request(s)</p>
            <span class="hdspn1">Learn More</span>
          </div>


          <div className="table-responsive">
            <table class="table table-bordered ft13">
              <thead className="tbhd1">
                <tr>
                 
                  <td>User Name</td>
                  <td>UserID</td>
                  <td>Product</td>
                  <td>Order ID</td>
                  <td>Order Date</td>
                  <td>SKU/ASIN</td>
                  
                  <td>Remark</td>
                  <td>Action</td>
                 
                </tr>
              </thead>
              <tbody>
                <tr>
                  
                  
                  <td>User Name</td>
                  <td>939172</td>
                  <td>20-july-2023</td>
                  <td>20-july-2023</td>
                  <td>20-july-2023</td>
                  <td>Nagpur, Maharashtra</td>
                  <td>The product did not match the description</td>
                  <td className="text-end">
                  <button class="btnvd add-cart-btn w-100">Cancel Order</button>
                  </td>
                  
                </tr>                 
              </tbody>
            </table>
            </div>


    </div>
</section>

    
    </>
	
  )
}

export default Cancelrequest
