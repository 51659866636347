import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css"
import img1 from '../media/prod11.jpg';
import {  Link, useLocation } from 'react-router-dom';
import queryString from "query-string";
import { FaFile } from 'react-icons/fa'


function Userdetails() {

  const [otherDtls, setOtherDtls] = useState([]);

  const { pathname } = useLocation();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const userId = queryParams.getfilter;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var valdata = {
      filterBy: userId,

    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchUserdtlsAdmin`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };

    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        $("#_userName").text(response.po_DETAIL[0].username);
        $("#_mobNo").text(response.po_DETAIL[0].MOBILENO ? response.po_DETAIL[0].MOBILENO : "NA");
        $("#_address").text(response.po_DETAIL[0].PI_ADDRESS ? response.po_DETAIL[0].PI_ADDRESS : "NA");
        $("#_city").text(response.po_DETAIL[0].PI_CITY ? response.po_DETAIL[0].PI_CITY : "NA");
        $("#_state").text(response.po_DETAIL[0].PI_STATE ? response.po_DETAIL[0].PI_STATE : "NA");
        $("#_rDate").text(response.po_DETAIL[0].TIME_STAMP ? response.po_DETAIL[0].TIME_STAMP : "NA");
        $("#_gender").text(response.po_DETAIL[0].GENDER === "M"? "MALE" : "FEMALE");
        $("#_email").text(response.po_DETAIL[0].EMAIL ? response.po_DETAIL[0].EMAIL : "NA");
        console.log(response.po_DETAIL_OTHER);
        setOtherDtls(response.po_DETAIL_OTHER);
      } else {
        
      }
    });
  };




  return (
    <>

<section className="mt-4">
          <div className="container-fluid">

          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
          <p class="vhp3">User Detail(s)</p>
        </div>

        <section className="seccl1 p-3">
        <div className="container py-4 card ">
        <div className="row">

        <div className="vhp1 seccl1 my-2 p-3">
            User Detail
        </div>

             <div className="col-md-6">
                 <div className="row">
                     <div className="col-6 crtitmhd">
                     User Name 
                     </div>
                     <div className="col-6">
                     <p >: <span id="_userName"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                     Phone 
                     </div>
                     <div className="col-6">
                     <p>: <span id="_mobNo"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                     Address 
                     </div>
                     <div className="col-6">
                     <p>: <span id="_address"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                    City
                     </div>
                     <div className="col-6">
                     <p>: <span id="_city"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                          State
                     </div>
                     <div className="col-6">
                     <p>: <span id="_state"></span></p>
                     </div>
                 </div>
                 
                 
             </div>

             <div className="col-md-6">
                 <div className="row">
                     <div className="col-6 crtitmhd">
                  Registration Date
                     </div>
                     <div className="col-6">
                     <p>: <span id="_rDate"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                     Gender 
                     </div>
                     <div className="col-6">
                     <p>: <span id="_gender">
                       </span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                     Status 
                     </div>
                     <div className="col-6">
                     <p>: <span id="_email"></span></p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                     City
                     </div>
                     <div className="col-6">
                     <p>: Nagpur</p>
                     </div>
                 </div>

                 <div className="row">
                     <div className="col-6 crtitmhd">
                     State
                     </div>
                     <div className="col-6">
                     <p>: Maharashtra</p>
                     </div>
                 </div>
                 
                 
             </div>
             
            </div>
        </div>
        </section>

        <div class="row">
              <div class="col-md-6 col-6 pt-2 pb-2  ">
                <div class="vhp1 pt-0">Transaction Detail(s)</div>
              </div>
              <div class="col-md-6 col-6 pt-2 pb-2 d-flex justify-content-end">

              </div>
            </div>

        <div class="card mt-2 p-2 tblht1">
              <table class="table table-hover ft13">
                <thead className="tbhd1">
                  <tr>
                    <td>User ID</td>
                    <td>User Name</td>
                    <td>Mobile</td>
                    <td>Purchase</td>
                    <td>Amount</td>
                    <td>Invoice</td>
                  </tr>
                </thead>
                <tbody>
                   {/* <tr>
                    <td>45871254</td>
                    <td>Example Name</td>
                    <td>8574961236</td> 
                    <td>20/09/2020 14:27:15</td>
                    <td>Delhi</td>
                    <td><button class="btnvd add-cart-btn"><FaFile/></button></td>
                  </tr> */}
                  {otherDtls.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        No data found
                      </td>
                    </tr>
                  ) : (
                    otherDtls.map((orDtls) => (
                      <tr key={`dtls`}>
                        <td>{orDtls.USERID}</td>
                        <td>{orDtls.NAME}</td>
                        <td>{orDtls.PHONENO}</td>
                        <td>{orDtls.PHONENO}</td>
                        <td>{orDtls.PHONENO}</td>
                        <td>{orDtls.PHONENO}</td>
                      </tr>
                    ))
                  )}
                  
                </tbody>
              </table>
            </div>



            
          </div>
        </section>

    
    </>
	
  )
}

export default Userdetails
