import React from 'react'
import "./Common.css"
import img1 from '../media/prod11.jpg';
import { Link, useLocation } from 'react-router-dom';

import { FaFacebook } from 'react-icons/fa'


function Entersku() {
 return (
  <>

<section class="" style={{backgroundColor:"#fff"}}>

  

  <div class="container-fluid">
    
<div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
      <p class="vhp3">Add SKU(s)</p><span class="hdspn1">Learn More</span>
    </div>

    <div>

      

      
      <table class="table table-hover ft13" >
        <thead className="tbhd1">
          <tr>
            
            <td>Select Category</td>
            <td>Select Sub-Category</td>
            <td>Enter SKU</td>
            <td>Action</td>
          </tr>
          
        </thead>
        <tbody>
          <tr>
            
            <td>
            <select className="form-control">
                <option selected disabled>Please Select</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
              </select>
            </td>

            
            <td>
              <select className="form-control">
                <option selected disabled>Please Select</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
                <option value="">Electronics</option>
              </select>
            </td>
            <td><input type="" className="form-control" /></td>
            
            <td><button class="btnvd add-cart-btn">Add SKU</button></td>
          </tr>
          
          
          

          
        </tbody>
      </table>
    </div>

    
  </div>
</section>


  
  </>
  
 )
}

export default Entersku