import React, { useEffect, useState, useContext } from "react";
import Baseurl from "../component/Baseurl";
import $ from "jquery";
import "./Common.css";
import img1 from "../media/prod11.jpg";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaFacebook, FaStar } from "react-icons/fa";
import queryString from "query-string";

function Vdticketaction() {
  const [report, setReport] = useState([]);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const ticketNo = queryParams.ticketNo;
  const requestType = queryParams.requestType;
  const q = queryParams.q;

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = () => {
    var valdata = {
      filterBy: "",
      requestType: "actionPage",
      did: ticketNo,
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}fetchTicketDtls`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        setReport(response.po_DETAIL[0]);
      } else {
        toast.error(response.po_MSG);
      }
    });
  };

  const closeQuery = () => {
    if (!$("#remarks").val().trim()) {
      toast.error("Please Enter Valid Remarks.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return false;
    }
    var valdata = {
      didId: ticketNo,
      remarks: $("#remarks").val().trim(),
    };

    const settings = {
      async: true,
      crossDomain: true,
      url: `${Baseurl.baseUrl}closeTicket`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(valdata),
    };
    $.ajax(settings).done(function (response) {
      if (parseInt(response.po_STATUS) === 1) {
        fetchData("");
        toast.success("Ticket Closed");

        setTimeout(function () {
          const url = `Adticket`;
          window.location.href = url;
        }, 3000);
      } else {
        toast.error("Failed To Close Ticket.");
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <section class="" style={{ backgroundColor: "#fff" }}>
        <div class="container-fluid">
          <div class=" vhdiv1 p-3 pb-1 d-flex align-items-baseline">
            <p class="vhp3">Vendor Ticket</p>
            <span class="hdspn1"></span>
          </div>
        </div>
      </section>

      <section className="bgsec1 p-3">
        <div className="container-fluid">
          <div className="row  mt-3 mb-3">
            <div className="col-md-3 mb-2 bdradis">
              <div className="hyeus1  bdradis">
                <div className="p-2 ">
                  <span className="vhp1 pt-0">Vendor Details</span>
                </div>
                <hr />
                <div className="p-2  ">
                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">Vendor</div>
                    </div>
                    <div className="col-8 hstd1">{report.VENDOR_NAME}</div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">Store</div>
                    </div>
                    <div className="col-8 hstd1">{report.STORENAME}</div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">ASIN</div>
                    </div>
                    <div className="col-8 hstd1">{report.ASINNO}</div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">Phone</div>
                    </div>
                    <div className="col-8 hstd1">{report.PHONE_NUMBER}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 mb-2 bdradis">
              <div className="hyeus1  bdradis">
                <div className="p-2 ">
                  <span className="vhp1 pt-0">Ticket </span>
                </div>
                <hr />
                <div className="p-2  ">
                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">Tokken No.</div>
                    </div>
                    <div className="col-8 hstd1">
                      <input
                        type="text"
                        class="inputform"
                        id="usrname"
                        placeholder="Enter Name"
                        value={report.FormattedID}
                        readOnly
                        maxlength="255"
                        required=""
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">Subject</div>
                    </div>
                    <div className="col-8 hstd1">
                      <input
                        type="text"
                        class="inputform"
                        id="usrname"
                        placeholder="Enter Name"
                        value={report.QUERY_SUB}
                        readOnly
                        maxlength="255"
                        required=""
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">Message</div>
                    </div>
                    <div className="col-8 hstd1">
                      <textarea
                        class="inputform"
                        id="usrtextrating"
                        rows="5"
                        value={report.MESSAGE_QUERY}
                        readOnly
                        required=""
                      ></textarea>
                    </div>
                  </div>

                  <hr />

                  <div className="row mb-2">
                    <div className="col-4 ">
                      <div className="crtitmhd">Solution/Reply</div>
                    </div>
                    <div className="col-8 hstd1">
                      <textarea
                        class="inputform"
                        id="remarks"
                        rows="5"
                        placeholder="Solution Here"
                        required=""
                      ></textarea>
                    </div>
                  </div>

                  <div className="row mb-2 justify-content-end d-flex">
                    <div className="col-4 ">
                      <button class="btnvd add-cart-btn" onClick={closeQuery}>
                        Close Ticket
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vdticketaction;
